import { ReactElement } from 'react';
import * as Styled from './styles';
import { RadioButton } from '../RadioButton';

export interface IOption {
  label: string;
  value: string;
  name: string;
  disabled?: boolean;
}

export interface IOptionGroup {
  label: string;
  options: IOption[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selecionado: string;
}

export const RadioButtonGroup = ({
  label,
  options,
  onChange,
  selecionado,
}: IOptionGroup): ReactElement => {
  function renderOptions() {
    return options.map(({ label, value, name, disabled }: IOption) => {
      return (
        <RadioButton
          value={value}
          label={label}
          key={value}
          name={name}
          disabled={disabled}
          selecionado={selecionado}
          onChange={onChange}
        />
      );
    });
  }

  return (
    <Styled.Fieldset>
      <Styled.Legend>{label}</Styled.Legend>
      <Styled.Wrapper>{renderOptions()}</Styled.Wrapper>
    </Styled.Fieldset>
  );
};
