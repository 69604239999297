import segurancaAxios from '../config/axios/SegurancaAxios';

class AuthApi {
  /**
   * Get Faturas
   * @return {Promise<any>} Retorna um array de faturas vinculadas ao usuario logado.
   */
  public LogOut = async (): Promise<any> => {
    const response = await segurancaAxios.get('/logout');
    return response.data;
  };
}
export default new AuthApi();
