import contratos, { ContratoSelecionado } from '../../../api/contrato';
import { setContratoSelecionado, setListaContratos } from './actions';
import { Dispatch } from 'react';
import { ListaContratosActionTypes } from './types';

export const fetchListaContratos =
  () => async (dispatch: Dispatch<ListaContratosActionTypes>) => {
    try {
      const listaContratosResponse = await contratos.getContratos();

      if (listaContratosResponse && listaContratosResponse.length > 0) {
        dispatch(setListaContratos(listaContratosResponse));
      }

      return listaContratosResponse;
    } catch (error: any) {
      console.error(error);
    }
  };

export const guardarContratoSelecionado =
  (contrato: ContratoSelecionado) =>
  async (dispatch: Dispatch<ListaContratosActionTypes>) => {
    dispatch(setContratoSelecionado(contrato));

    return contrato;
  };
