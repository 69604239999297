import styled from 'styled-components';

interface DisabledProps {
  disabled?: boolean;
  campoPreenchido?: boolean;
}

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 6vw;
`;

export const Label = styled.label<DisabledProps>`
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: ${(props) =>
    (props.disabled || props.campoPreenchido) && 'var(--black)'};
  height: fit-content;

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

export const Campo = styled.input<DisabledProps>`
  width: 27.65vw;
  height: 2.28vw;
  border: 0.05vw solid var(--cinza-60);
  border: ${(props) =>
    (props.disabled || props.campoPreenchido) && '0.1vw solid var(--cinza-30)'};
  border-radius: 0.55vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.28vw;
  padding: 0.64vw;
  color: ${(props) =>
    (props.disabled || props.campoPreenchido) && 'var(--cinza-30)'};
  cursor: ${(props) =>
    (props.disabled || props.campoPreenchido) && 'not-allowed'};

  @media (max-width: 768px) {
    width: 80vw;
    font-size: 4vw;
  }
`;

export const Erro = styled.span`
  height: 10%;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1.47vw;
  color: var(--salmon);
  display: flex;
  align-items: center;
  max-width: 27.5vw;

  @media (max-width: 768px) {
    max-width: 80vw;
    font-size: 2vw;
  }
`;

export const IconeErro = styled.img`
  margin-right: 2%;
  width: 0.7vw;
  height: 0.7vw;

  @media (max-width: 768px) {
    width: 2vw;
    height: 2vw;
  }
`;
