import { NovoCentroCusto } from '../@types/interfaces/cadastroNovoCentroCusto';
import segurancaAxios from '../config/axios/SegurancaAxios';
import { logger } from '../util/logger';
import { NovoCentroCustoResponse } from '../@types/interfaces/novoCentroCustoResponse';

class NovoCentroCustoApi {
  public cadastrarNovoCentroCusto = async (
    headers: NovoCentroCusto
  ): Promise<NovoCentroCustoResponse | void> => {
    try {
      const config = {
        headers: {
          'id-contrato-mobilidade': headers.idContratoMobilidade,
        },
      };

      const body = {
        idContratoMobilidade: headers.idContratoMobilidade,
        nome: headers.nome,
        descricao: headers.descricao,
        orcamento: headers.orcamento,
        dadosCobranca: headers.dadosCobranca,
      };
      return await segurancaAxios.post(
        'contrato/centroDeCusto/criar',
        body,
        config
      );
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "cadastrarNovoCentroCusto".
        <'ERRO'>
          message: Não foi possível realizar o cadastro no centro de custo!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${headers.idContratoMobilidade},
          NOME: ${headers.nome},
          DESCRIÇÃO: ${headers.descricao},
          ORÇAMENTO: ${headers.orcamento},
          DADOS DA COBRANÇA: ${headers.dadosCobranca},
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    }
  };
}
export default new NovoCentroCustoApi();
