import { AnimatePresence } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import { ContainerModal, ContentModal } from './styles';

interface CalculatorModalProps {
  children: ReactElement;
  visivel: boolean;
  onCloseRequest: () => void;
  style?: any;
}

export function Modal({
  children,
  visivel,
  onCloseRequest,
  style,
}: CalculatorModalProps) {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.body);
  }, []);

  if (!portalNode) return null;

  return ReactDOM.createPortal(
    <>
      <AnimatePresence>
        {visivel && (
          <ContainerModal
            className="modal"
            onClick={onCloseRequest}
            style={style}>
            <ContentModal>{children}</ContentModal>
          </ContainerModal>
        )}
      </AnimatePresence>
    </>,
    portalNode
  );
}

export default Modal;
