import React, { MouseEvent } from 'react';

import SetaBaixo from '../../../assets/chevron_down_gray.svg';

import InputFormularioNovoColaborador from '../../InputFormularioNovoColaborador';

import { celularMask } from '../../../helpers/mascaras';
import { formatarCpf, regex } from '../../../helpers/formatacoes';
import {
  handleSubmit,
  handleBlur,
} from '../../../helpers/utilsFormulariosColaborador';

import * as Styled from '../../FormulariosNovoColaborador/PrimeiroFormularioNovoColaborador/styles';

import { PrimeiroFormularioProps } from '../../../@types/interfaces/primeiroFormularioNovoColaborador';

const PrimeiroFormularioEditarColaborador = ({
  register,
  errors,
  setMostrarPrimeiraPagina,
  dados,
  setDados,
  camposDesabilitados,
  setError,
  clearErrors,
  setValue,
  setBotaoSubmitDesabilitado,
}: PrimeiroFormularioProps) => {
  const handleInputChange = (campo: string, value: string) => {
    setDados({ ...dados, [campo]: value });
  };

  const isEmailFilled = dados.email && !errors?.email;
  const isCelularFilled = dados.celular && !errors?.celular;
  const canSubmit = isEmailFilled && isCelularFilled;

  return (
    <>
      <Styled.NumeracaoPagina id="NumeracaoPagina">
        <Styled.IconeSetaBaixo id="IconeSetaBaixo" src={SetaBaixo} />
        <Styled.Pagina id="Pagina">
          <Styled.PaginaNumero className="PaginaNumero">1</Styled.PaginaNumero>
          <Styled.Linha />
          <Styled.PaginaNumero className="PaginaNumero" disabled>
            2
          </Styled.PaginaNumero>
        </Styled.Pagina>
      </Styled.NumeracaoPagina>
      <Styled.Form id="PrimeiroFormulario">
        <InputFormularioNovoColaborador
          id="inputCpf"
          label="CPF"
          campoPreenchido={camposDesabilitados}
          register={register}
          campo={'cpf'}
          value={formatarCpf(dados?.cpf)}
        />
        <InputFormularioNovoColaborador
          id="inputNome"
          label="Nome Completo"
          campoPreenchido={camposDesabilitados}
          value={dados?.nome}
          register={register}
          campo={'nome'}
        />
        <InputFormularioNovoColaborador
          id="inputEmail"
          label="E-mail"
          register={register}
          campo={'email'}
          value={dados.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('email', e.target.value)
          }
          onBlur={() =>
            handleBlur({
              campo: 'email',
              value: dados.email,
              regex: regex.email,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.email}
        />
        <InputFormularioNovoColaborador
          id="inputCelular"
          label="Celular"
          register={register}
          campo={'celular'}
          value={celularMask(dados.celular)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange('celular', celularMask(e.target.value))
          }
          onBlur={() =>
            handleBlur({
              campo: 'celular',
              value: celularMask(dados.celular),
              regex: regex.celular,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.celular}
        />
        <Styled.BotaoSubmit
          id={'botaoContinuar'}
          onClick={(e: MouseEvent) =>
            handleSubmit({
              e,
              dados,
              errors,
              setMostrarPrimeiraPagina,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          disabled={!canSubmit}>
          Continuar
        </Styled.BotaoSubmit>
      </Styled.Form>
    </>
  );
};

export default PrimeiroFormularioEditarColaborador;
