import styled from 'styled-components';

interface BackgroundProps {
  tipo: string;
}

const tipoBadge = (tipo: string) => {
  switch (tipo) {
    case 'blue':
      return 'var(--action)';
    case 'green':
      return 'var(--green)';
    case 'red':
      return 'var(--red)';
    case 'gray':
      return 'var(--cinza-40)';
    case 'A pagar':
      return 'var(--action)';
    case 'Aprovado':
      return 'var(--green)';
    case 'Paga':
      return 'var(--green)';
    case 'Pago':
      return 'var(--green)';
    case 'Atrasado':
      return 'var(--red)';
    case 'Cancelado':
      return 'var(--cinza-40)';
    case 'Filtro':
      return 'var(--action)';
    case 'Em Processamento':
      return 'var(--em-processamento)';
    case 'Com Inconsistência':
      return 'var(--red)';
    case 'Concluída':
      return 'var(--concluida)';
    case 'Iniciada':
      return 'var(--iniciada)';
    case 'Em aberto':
      return 'var(--blueSoft)';
    default:
      return 'black';
  }
};

export const BadgeContainer = styled.div<BackgroundProps>`
  align-items: center;
  background-color: ${({ tipo }) => `${tipoBadge(tipo)}`};
  width: fit-content;
  border-radius: 6.41vw;
  padding: 0.32vw 0.64vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  height: fit-content;
`;

export const BadgeText = styled.p`
  color: var(--white);
  font-size: 0.89vw;
  font-weight: 500;
`;

export const Circle = styled.div`
  background-color: var(--white);
  width: 0.51vw;
  height: 0.51vw;
  border-radius: 6.41vw;
  margin-right: 0.32vw;
`;
