import { FiltrosHeaderCentroDeCusto } from '../../../@types/interfaces/contratoApi';
import contrato from '../../../api/contrato';
import * as centrosDeCustosActions from './actions';
import { Dispatch } from 'react';
import { CentrosDeCustosActionTypes } from './types';

export const fetchCentrosDeCustosPorContrato =
  (headers?: FiltrosHeaderCentroDeCusto) =>
  async (dispatch: Dispatch<CentrosDeCustosActionTypes>) => {
    try {
      const centrosDeCustoResponse = await contrato.getCentroDeCustosDoContrato(
        headers
      );

      dispatch(
        centrosDeCustosActions.setCentrosDeCustosDoContrato(
          centrosDeCustoResponse
        )
      );

      return centrosDeCustoResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchControlePaginacao =
  (headers: FiltrosHeaderCentroDeCusto, numeroPagina: number) =>
  async (dispatch: Dispatch<CentrosDeCustosActionTypes>) => {
    try {
      const centroDeCustoResponse = await contrato.getCentroDeCustosDoContrato(
        headers
      );

      dispatch(
        centrosDeCustosActions.controlePaginacao(
          centroDeCustoResponse,
          headers,
          numeroPagina
        )
      );

      return centroDeCustoResponse;
    } catch (error: any) {
      console.error(error);
    }
  };
