import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';

import IsLoadingHOC from '../../hoc/IsLoadingHOC';

import useErro from '../../hooks/useErro';
import useContratos from '../../hooks/useContratos';
import useColaboradores from '../../hooks/useColaboradores';

import lodashHelpers from '../../helpers/lodashHelpers';
import { formatarCpf, formatarReal } from '../../helpers/formatacoes';

import { IColaboradores } from '../../@types/interfaces/colaboradoresApi';

import IconeNovoColaborador from '../../assets/icone-novo-colaborador.svg';

import Switch from '../../components/SwitchColaboradores';
import BotaoExportarRelatorio from '../../components/BotaoExportarRelatorio';

import { Erro } from '../../components/Erro';
import { Pagination } from '../../components/Pagination';
import { CardNoData } from '../../components/Cards/CardNoData';
import { InputListaColaborador } from '../../components/InputListaColaborador';

export interface CentroCustoProps {
  setLoading: (value: boolean) => void;
}

const Colaboradores = ({ setLoading }: CentroCustoProps) => {
  const quantidadeItemsPaginaCentroCusto = 8;

  const { contratoSelecionado } = useContratos();

  const { codigoErro, limpaErro } = useErro();

  const navigate = useNavigate();

  const quantidadePaginacao = (
    quantidadeTotalRegistro: number,
    itensPagina: number
  ) => {
    return Math.ceil(quantidadeTotalRegistro / itensPagina);
  };

  const {
    downloadCSV,
    downloadXLSX,
    handleColaboradores,
    handleBuscaColaborador,
    handlePaginacaoColaboradores,
    colaboradoresResponse,
  } = useColaboradores();

  const buscaColaborador = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => handleBuscaColaborador(event, setLoading);

  const buscaColaboradorDebounce = lodashHelpers.debounce(
    buscaColaborador,
    500
  );

  const PadraoUpperCase = (str: string) => {
    return str.toUpperCase();
  };

  useEffect(() => {
    handleColaboradores(setLoading).then(() => ({}));
  }, [contratoSelecionado]);

  return (
    <React.Fragment>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Styled.Content>
          <React.Fragment>
            <Styled.WrapperConteudo>
              <Styled.Titulo>Colaboradores</Styled.Titulo>
            </Styled.WrapperConteudo>
            <Styled.WrapperSelectorColaboradores>
              <Styled.WrapperFiltros>
                <InputListaColaborador
                  label="Buscar"
                  id="input-busca-colaborador"
                  inputGrande
                  placeholder={
                    (colaboradoresResponse?.headers &&
                      colaboradoresResponse.headers['texto-filtro']) ||
                    'Colaborador, E-mail, CPF '
                  }
                  tipo="texto"
                  onChange={buscaColaboradorDebounce}
                />
                <BotaoExportarRelatorio
                  habilitado={colaboradoresResponse.listaRegistro.length > 0}
                  downloadRelatorio={downloadCSV}
                  setLoading={setLoading}
                  textoBotao="Exportar CSV"
                />
                <BotaoExportarRelatorio
                  habilitado={colaboradoresResponse.listaRegistro.length > 0}
                  downloadRelatorio={downloadXLSX}
                  setLoading={setLoading}
                  textoBotao="Exportar Excel"
                />
              </Styled.WrapperFiltros>
              <Styled.BotaoNovoColaborador
                onClick={() => {
                  navigate('novo-colaborador');
                }}>
                <Styled.Icone src={IconeNovoColaborador} />
                Adicionar Colaborador
              </Styled.BotaoNovoColaborador>
            </Styled.WrapperSelectorColaboradores>
            {colaboradoresResponse?.listaRegistro.length > 0 ? (
              <React.Fragment>
                <Styled.TableContent>
                  <Styled.TabelaColaboradores>
                    <Styled.TabelaHeadColaboradores>
                      <Styled.TabelaRowColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            Nome do colaborador
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            E-mail
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          CPF
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            Valor utilizado (R$)
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            Status
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            {' '}
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                        <Styled.TabelaHeaderColaboradores>
                          <Styled.TabelaComIconeColaborador>
                            {' '}
                          </Styled.TabelaComIconeColaborador>
                        </Styled.TabelaHeaderColaboradores>
                      </Styled.TabelaRowColaboradores>
                    </Styled.TabelaHeadColaboradores>
                    <Styled.TabelaBodyColaboradores>
                      {colaboradoresResponse.listaRegistro.map(
                        (item: IColaboradores) => {
                          const chavesItem = Object.keys(item);

                          const existe = chavesItem.find((chaveAtivo) => {
                            return chaveAtivo === 'status';
                          });

                          if (!existe) {
                            item.status = item.ativo === true;
                          }

                          return (
                            <Styled.TabelaRowColaboradores
                              key={item.idColaborador.toString()}>
                              <Styled.TabelaDataCellColaboradores>
                                <Styled.WrapperENome>
                                  {PadraoUpperCase(item.nome)}
                                </Styled.WrapperENome>
                              </Styled.TabelaDataCellColaboradores>
                              <Styled.TabelaDataCellColaboradores>
                                {item.email}
                              </Styled.TabelaDataCellColaboradores>
                              <Styled.TabelaDataCellColaboradores>
                                {formatarCpf(item.cpf)}
                              </Styled.TabelaDataCellColaboradores>
                              <Styled.TabelaDataCellColaboradores>
                                {formatarReal(item.valorUtilizadoColaborador)}
                              </Styled.TabelaDataCellColaboradores>
                              <Styled.TabelaDataCellColaboradores>
                                <Switch
                                  setLoading={setLoading}
                                  id={item.idColaborador}
                                  status={item.status}
                                  idContrato={contratoSelecionado.id}
                                />
                              </Styled.TabelaDataCellColaboradores>
                            </Styled.TabelaRowColaboradores>
                          );
                        }
                      )}
                    </Styled.TabelaBodyColaboradores>
                  </Styled.TabelaColaboradores>
                </Styled.TableContent>
                <Pagination
                  quantidadeDePaginas={quantidadePaginacao(
                    colaboradoresResponse.quantidadeTotalRegistro,
                    quantidadeItemsPaginaCentroCusto
                  )}
                  onPageChange={(selectedItem) => {
                    handlePaginacaoColaboradores(selectedItem, setLoading).then(
                      () => ({})
                    );
                  }}
                  forcePage={colaboradoresResponse.numeroPagina - 1}
                />
              </React.Fragment>
            ) : (
              <CardNoData
                titulo="Essa área está muito vazia, não acha?"
                descricao="No momento não há centros de custo para este contrato. Tente trocar de contrato!"
              />
            )}
          </React.Fragment>
        </Styled.Content>
      )}
    </React.Fragment>
  );
};

export default IsLoadingHOC(Colaboradores);
