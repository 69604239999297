import {
  FiltrosHeadersCard,
  ICardCentroCusto,
} from '../../../@types/interfaces/cardCentroCusto';

export const GET_CARD_CENTRO_CUSTO = 'CARD_CENTRO_CUSTO/GET_CARD_CENTRO_CUSTO';

export const FILTRA_CAMPO = 'CARD_CENTROCUSTO/FILTRA_CAMPO';

export interface GetCardCentroCusto {
  type: typeof GET_CARD_CENTRO_CUSTO;
  headers: FiltrosHeadersCard;
  cardCentroCustoResponse: ICardCentroCusto;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  headers: FiltrosHeadersCard;
  cardCentroCustoResponse: ICardCentroCusto;
}
export type CardCentroCustoActionTypes = GetCardCentroCusto | FiltraCampo;
