import { IColaboradores } from '../../../@types/interfaces/colaboradoresApi';
import {
  ColaboradoresActionTypes,
  ColaboradorState,
  CONTROLE_PAGINACAO,
  FILTRA_CAMPO,
  LIMPA_COLABORADORES,
  SET_COLABORADORES,
} from './types';

const initialState: ColaboradorState = {
  colaboradoresResponse: {
    numeroPagina: 1,
    listaRegistro: [],
    numeroDeIdentificacao: 1,
    nome: '',
    orcamento: {
      periodicidade: '',
      valor: 1,
    },
    valorFaturadoDoCentroDeCusto: 1,
    quantidadeTotalRegistro: 1,
  },
  colaboradores: [],
  colaboradoresSelecionado: undefined,
};

const colaboradoresReducer = (
  state = initialState,
  action: ColaboradoresActionTypes
): ColaboradorState => {
  switch (action.type) {
    case SET_COLABORADORES: {
      const valorDefaultColaboradores: IColaboradores = {
        cpf: '74152908793',
        email: 'email74152908793@bb.com.br',
        idColaborador: '614496b9b6322bd15ed2004f',
        nome: 'Teste 74152908793',
        status: true,
        valorUtilizadoColaborador: 0.0,
        nomeCentroDeCusto: 'Matriz',
      };
      return {
        ...state,
        colaboradoresResponse: action.colaboradoresResponse,
        colaboradores: action.colaboradoresResponse?.listaRegistro,
        colaboradoresSelecionado: valorDefaultColaboradores,
      };
    }

    case FILTRA_CAMPO: {
      let newColaboradoresResponse;

      if (action?.colaboradoresResponse) {
        newColaboradoresResponse = {
          ...state.colaboradoresResponse,
          ...action.colaboradoresResponse,
          headers: action.headers,
        };
      } else {
        newColaboradoresResponse = {
          ...state.colaboradoresResponse,
          headers: action.headers,
        };
      }

      return {
        ...state,
        colaboradoresResponse: newColaboradoresResponse,
      };
    }

    case CONTROLE_PAGINACAO: {
      const newColaboradoresResponse = {
        ...state.colaboradoresResponse,
        ...action.colaboradoresResponse,
        headers: action.headers,
        numeroPagina: action.numeroPagina,
      };

      return {
        ...state,
        colaboradoresResponse: newColaboradoresResponse,
      };
    }

    case LIMPA_COLABORADORES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default colaboradoresReducer;
