import { combineReducers } from 'redux';

import authReducer from './auth/reducers';
import contratoReducer from './contrato/reducers';
import faturasReducer from './faturas/reducers';
import centrosDeCustosReducer from './centrosDeCustos/reducers';
import detalheFaturasReducer from './detalharFatura/reducers';
import colaboradoresReducer from './colaboradores/reducers';
import erroReducer from './erro/reducers';
import graficoReducer from './grafico/reducers';
import cardReducer from './cardDashboard/reducers';
import cardUltimasViagensReducer from './cardUltimasViagens/reducers';
import cardCentroCustoReducer from './cardCentroCusto/reducers';
import cardColaboradorReducer from './cardColaborador/reducers';
import DetalheViagemReducer from './detalheViagem/reducers';
import ColaboradorReducer from './colaborador/reducers';
import PassageiroReducer from './PassageiroAtivar/reducers';

export const rootReducer = combineReducers({
  auth: authReducer,
  contrato: contratoReducer,
  faturas: faturasReducer,
  centrosDeCustos: centrosDeCustosReducer,
  detalheFatura: detalheFaturasReducer,
  colaboradores: colaboradoresReducer,
  erro: erroReducer,
  grafico: graficoReducer,
  card: cardReducer,
  cardCentroCusto: cardCentroCustoReducer,
  cardColaborador: cardColaboradorReducer,
  cardUltimasViagens: cardUltimasViagensReducer,
  detalheViagem: DetalheViagemReducer,
  colaborador: ColaboradorReducer,
  passageiroAtivo: PassageiroReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
