import { IDetalharFatura } from '../../../@types/interfaces/detalharFatura';
import { DetalheViagemResponse } from '../../../@types/interfaces/detalheViagem';
import {
  DetalheViagemActionType,
  GET_DETALHES_VIAGEM,
  SET_DETALHE_VIAGEM_SELECIONADA,
} from './types';

export function setDetalheViagemResponse(
  detalheViagemResponse: DetalheViagemResponse
): DetalheViagemActionType {
  return {
    type: GET_DETALHES_VIAGEM,
    detalheViagemResponse,
  };
}

export function setDetalheViagemSelecionado(
  detalheViagemSelecionado: IDetalharFatura
): DetalheViagemActionType {
  return {
    type: SET_DETALHE_VIAGEM_SELECIONADA,
    detalheViagemSelecionado,
  };
}
