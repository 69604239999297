import { CardUltimasViagensState } from '../../../@types/interfaces/cardUltimasViagens';
import {
  CardUltimasViagensActionTypes,
  GET_CARD_ULTIMAS_VIAGENS,
} from './types';

const initialState: CardUltimasViagensState = {
  cardUltimasViagensResponse: {
    cardUltimasViagens: [],
  },
};

const cardUltimasViagensReducer = (
  state = initialState,
  action: CardUltimasViagensActionTypes
) => {
  const actionHandlers = {
    [GET_CARD_ULTIMAS_VIAGENS]: () => ({
      ...state,
      cardUltimasViagensResponse: action.cardUltimasViagensResponse,
    }),
  };

  const actionHandler = actionHandlers[action.type];

  return actionHandler ? actionHandler() : state;
};

export default cardUltimasViagensReducer;
