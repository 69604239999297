import React from 'react';
import * as Styled from './styles';
import grayStarsImg from '../../assets/grey-star.svg';
import yellowStarsImg from '../../assets/yellow-star.svg';

interface Props {
  value: number;
  descricao: string;
}

const Avaliacao: React.FC<Props> = ({ value, descricao }) => {
  const stars = [];
  const starImg =
    value == 0 || undefined || null ? grayStarsImg : yellowStarsImg;

  for (let i = 0; i < 5; i++) {
    stars.push(
      <Styled.StarImgWrapper key={i}>
        <Styled.StarImg
          src={i < value ? starImg : grayStarsImg}
          alt={`Avaliação de ${value} estrelas`}
          style={{ filter: `brightness(${i < value ? '100%' : '80%'})` }}
        />
      </Styled.StarImgWrapper>
    );
  }

  return (
    <div>
      <Styled.RatingWrapper>{stars}</Styled.RatingWrapper>
      {value > 0 && (
        <Styled.DescriptionWrapper>
          <Styled.Description>{descricao}</Styled.Description>
        </Styled.DescriptionWrapper>
      )}
    </div>
  );
};

export default Avaliacao;
