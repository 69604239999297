import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  CardResponse,
  FiltrosHeadersCard,
} from '../@types/interfaces/cardCentroCusto';

class CardDashboardApi {
  /**
   * Get Card Centro de Custo
   * @return {Promise<any>} Retorna um array de card de centro de custo.
   */
  public getCardCentroCusto = async (
    headers?: FiltrosHeadersCard
  ): Promise<CardResponse> => {
    const response = await segurancaAxios.get('contrato/cardCentroCusto', {
      headers,
    });
    return response?.data;
  };
}
export default new CardDashboardApi();
