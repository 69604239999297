import { IGrafico } from '../../@types/interfaces/graficoApi';

export const marginLineChart = {
  top: 28,
  right: 30,
  left: 20,
  bottom: 5,
};
export const ticksXAxis = (grafico: IGrafico) => [
  5,
  10,
  15,
  20,
  25,
  grafico?.ultimoDiaDoMes || 31,
];
export const labelXAxis = {
  value: 'Dia',
  position: 'insideBottomRight',
  fontSize: '1vw',
  dx: 14,
  dy: 14,
};
export const labelYAxis = {
  value: 'N° de Viagens',
  position: 'insideTopRight',
  fontSize: '1vw',
  dy: -32,
  dx: 90,
};
