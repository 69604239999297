import React, { Dispatch, SetStateAction } from 'react';
import * as Styled from './styles';
import SetaCimaAzul from '../../../assets/chevron_up_blue.svg';
import SetaBaixoAzul from '../../../assets/chevron_down_blue.svg';
import {
  formatarDataExtensa,
  formatarReal,
} from '../../../helpers/formatacoes';
import IconeCheckedGreen from '../../../assets/icone-checked-green.svg';
import IconeCheckedRed from '../../../assets/icone-checked-red.svg';
import IconeCheckedGrey from '../../../assets/icone-checked-grey.svg';
import useContratos from '../../../hooks/useContratos';
import useCentrosDeCustos from '../../../hooks/useCentrosDeCustos';
import { Viagem } from '../../../@types/interfaces/Viagem';
import {
  Contrato,
  ICentroDeCusto,
} from '../../../@types/interfaces/contratoApi';
import { StatusViagem } from '../../../@types/interfaces/statusViagem';

interface MostrarInfos {
  menuGerenciamento: boolean;
  contratosAtivos: boolean;
  centrosCusto: boolean;
  ultimasViagens: boolean;
}

interface InformacoesExtrasProps {
  setMostrar: Dispatch<SetStateAction<MostrarInfos>>;
  mostrar: MostrarInfos;
  ultimasViagens: Viagem[];
}

const InformacoesExtras = ({
  setMostrar,
  mostrar,
  ultimasViagens,
}: InformacoesExtrasProps) => {
  const { listaContratos } = useContratos();
  const { centrosDeCustosResponse } = useCentrosDeCustos();

  const mensagemErroSemDados =
    'Não encontramos dados referentes à este mês para apresentar.';

  function getStatusIconSrc(status: string | undefined): string {
    if (status === 'Finalizado') {
      return IconeCheckedGreen;
    } else if (status === 'Cancelado Motorista') {
      return IconeCheckedRed;
    } else {
      return IconeCheckedGrey;
    }
  }

  function getStatusDescription(
    statusViagem: StatusViagem | undefined,
    distancia: number | undefined,
    valor: number,
    duracao: number | undefined
  ): string {
    if (statusViagem?.statusViagem === 'Finalizado') {
      return `Finalizou uma viagem de ${distancia} km por ${formatarReal(
        valor
      )} com duração de ${duracao} min.`;
    } else if (statusViagem?.statusViagem === 'Cancelado Motorista') {
      return `Viagem no valor de ${formatarReal(
        valor
      )} cancelada pelo motorista.`;
    } else {
      return `Viagem no valor de ${formatarReal(
        valor
      )} cancelada pelo usuário.`;
    }
  }

  return (
    <Styled.InformacoesExtras>
      <Styled.Extra
        id={'MostrarContratos'}
        onClick={() =>
          setMostrar({
            ...mostrar,
            contratosAtivos: !mostrar.contratosAtivos,
          })
        }>
        <Styled.Item>
          Contratos Ativos
          <Styled.Quantidade>[{listaContratos?.length}]</Styled.Quantidade>
        </Styled.Item>
        <Styled.SetaBaixoAzul
          src={mostrar.contratosAtivos ? SetaCimaAzul : SetaBaixoAzul}
        />
      </Styled.Extra>
      <Styled.ExtraInfos>
        {mostrar.contratosAtivos &&
          (listaContratos?.length > 0 ? (
            listaContratos.map((item: Contrato) => (
              <Styled.ItemLista key={item?.id}>
                {item?.nomeDeExibicao}
                <Styled.ItemListaSpan>
                  Agencia
                  {item?.numeroDeIdentificacao}
                </Styled.ItemListaSpan>
              </Styled.ItemLista>
            ))
          ) : (
            <Styled.ItemLista>{mensagemErroSemDados}</Styled.ItemLista>
          ))}
      </Styled.ExtraInfos>
      <Styled.Extra
        id={'MostrarCentrosCusto'}
        onClick={() =>
          setMostrar({
            ...mostrar,
            centrosCusto: !mostrar.centrosCusto,
          })
        }>
        <Styled.Item>
          Centro de Custo vinculados
          <Styled.Quantidade>
            [{centrosDeCustosResponse?.quantidadeTotalRegistro}]
          </Styled.Quantidade>
        </Styled.Item>
        <Styled.SetaBaixoAzul
          src={mostrar.centrosCusto ? SetaCimaAzul : SetaBaixoAzul}
        />
      </Styled.Extra>
      <Styled.ExtraInfos>
        {mostrar.centrosCusto &&
          (centrosDeCustosResponse?.quantidadeTotalRegistro !== 0 ? (
            centrosDeCustosResponse?.listaRegistro.map(
              (item: ICentroDeCusto) => (
                <Styled.ItemLista key={item?.numeroDeIdentificacao}>
                  {item?.nome}
                  <Styled.ItemListaSpan>
                    {formatarReal(item?.orcamento.valor)}
                  </Styled.ItemListaSpan>
                </Styled.ItemLista>
              )
            )
          ) : (
            <Styled.ItemLista>{mensagemErroSemDados}</Styled.ItemLista>
          ))}
      </Styled.ExtraInfos>
      <Styled.Extra
        id={'MostrarUltimasViagens'}
        onClick={() =>
          setMostrar({
            ...mostrar,
            ultimasViagens: !mostrar.ultimasViagens,
          })
        }>
        <Styled.Item>
          Últimas viagens
          <Styled.Quantidade>[{ultimasViagens?.length}]</Styled.Quantidade>
        </Styled.Item>
        <Styled.SetaBaixoAzul
          src={mostrar.ultimasViagens ? SetaCimaAzul : SetaBaixoAzul}
        />
      </Styled.Extra>
      {mostrar.ultimasViagens &&
        (ultimasViagens?.length > 0 ? (
          ultimasViagens.map((item: Viagem, index: number) => (
            <Styled.ItemUltimasViagens key={item.idViagem}>
              <Styled.WrapperDataImg>
                <Styled.IconeViagem
                  src={getStatusIconSrc(item?.statusViagem?.statusViagem)}
                />
                <Styled.WrapperData>
                  <Styled.DataExtenso>
                    {formatarDataExtensa(item?.dataViagem)}
                  </Styled.DataExtenso>
                  <Styled.DescricaoViagem>
                    <Styled.TextoDescricaoViagem>
                      {getStatusDescription(
                        item?.statusViagem,
                        item?.distancia,
                        item?.valor,
                        item?.duracao
                      )}
                    </Styled.TextoDescricaoViagem>
                  </Styled.DescricaoViagem>
                </Styled.WrapperData>
              </Styled.WrapperDataImg>
              {ultimasViagens?.length - 1 !== index && <Styled.DivisaoViagem />}
            </Styled.ItemUltimasViagens>
          ))
        ) : (
          <Styled.ItemLista>{mensagemErroSemDados}</Styled.ItemLista>
        ))}
    </Styled.InformacoesExtras>
  );
};

export default InformacoesExtras;
