import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  ColaboradorResponse,
  FiltrosHeaderColaborador,
} from '../@types/interfaces/Colaborador';

class ColaboradorApi {
  /**
   * Get Colaborador
   * @return {Promise<ColaboradorResponse>} Retorna os dados do colaborador para edição.
   */
  public getColaborador = async (
    headers?: FiltrosHeaderColaborador
  ): Promise<ColaboradorResponse> => {
    const response = await segurancaAxios.get('passageiro/dados/portal', {
      headers,
    });
    return response.data;
  };
}
export default new ColaboradorApi();
