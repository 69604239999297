import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerGeral = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
`;

export const SwitchButton = styled.div<{ isActive: boolean }>`
  width: 3.2vw;
  height: 1.5vw;
  border-radius: 2.48vw;
  align-items: center;
  background-color: ${(props) => (props.isActive ? '#6FCF97' : '#D6D6D6')};
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .toggle {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    justify-content: ${(props) => (props.isActive ? 'flex-end' : 'flex-start')};
    background-color: white;
    margin: 0.24vw;
    transition: all 0.3s ease-in-out;
    transform: ${(props) =>
      props.isActive ? 'translateX(1.7vw)' : 'translateX(0)'};
  }
`;

export const SwitchText = styled.span`
  margin-left: 0.99vw;
  display: flex;
  align-items: center;
`;
