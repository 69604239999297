import * as cardActions from './actions';
import card from '../../../api/cardCentroCusto';
import { FiltrosHeadersCard } from '../../../@types/interfaces/cardCentroCusto';
import { Dispatch } from 'react';
import { CardCentroCustoActionTypes } from './types';

export const fetchCardCentroCusto =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardCentroCustoActionTypes>) => {
    try {
      const cardCentroCustoResponse = await card.getCardCentroCusto(headers);

      dispatch(
        cardActions.setCardCentroCusto(headers, cardCentroCustoResponse)
      );

      return cardCentroCustoResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeadersCentrodeCusto =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardCentroCustoActionTypes>) => {
    try {
      const cardCentroCustoResponse = await card.getCardCentroCusto(headers);
      dispatch(cardActions.filtraCampo(headers, cardCentroCustoResponse));

      return cardCentroCustoResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
