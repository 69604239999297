import {
  FiltrosHeadersCard,
  ICardColaborador,
} from '../../../@types/interfaces/cardColaborador';
import {
  CardColaboradorActionTypes,
  GET_CARD_COLABORADOR,
  FILTRA_CAMPO,
} from './types';

export function setCardColaborador(
  headers: FiltrosHeadersCard,
  cardColaboradorResponse: ICardColaborador
): CardColaboradorActionTypes {
  return {
    type: GET_CARD_COLABORADOR,
    headers,
    cardColaboradorResponse,
  };
}

export function filtraCampo(
  headers: FiltrosHeadersCard,
  cardColaboradorResponse: ICardColaborador
): CardColaboradorActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    cardColaboradorResponse,
  };
}
