/* eslint-disable no-unused-vars */
import {
  FiltrosHeadersCard,
  ICard,
} from '../../../@types/interfaces/dashboardCardApi';
import { CardActionTypes, GET_CARD_DASHBOARD, FILTRA_CAMPO } from './types';

export function setCardDashboard(
  headers: FiltrosHeadersCard,
  cardResumoResponse: ICard
): CardActionTypes {
  return {
    type: GET_CARD_DASHBOARD,
    headers,
    cardResumoResponse,
  };
}

export function filtraCampo(
  headers: FiltrosHeadersCard,
  cardResumoResponse: ICard
): CardActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    cardResumoResponse,
  };
}
