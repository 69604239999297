import { css, FlattenSimpleInterpolation } from 'styled-components';

interface ChevronProps {
  left: number;
  rotate: number;
  imageUrlActive?: string;
  imageUrlDisabled?: string;
}
export const Chevron = ({
  left,
  rotate,
  imageUrlActive,
  imageUrlDisabled,
}: ChevronProps): FlattenSimpleInterpolation => css`
  a {
    color: transparent;
    visibility: hidden;
    position: relative;
    &:after {
      visibility: visible;
      position: absolute;
      top: 0;
      left: ${left + 'px'};
      content: 'C';
    }
  }
  background-image: url(${imageUrlActive});

  &.disabled {
    background-image: url(${imageUrlDisabled});

    a {
      cursor: default !important;
    }
  }
  content: '';

  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position-x: center;
  background-position-y: center;
  background-color: transparent;
  transform: rotate(${rotate + 'deg'}) scaleX(-1);
  -webkit-transform: rotate(${rotate + 'deg'}) scaleX(-1);
  -moz-transform: rotate(${rotate + 'deg'}) scaleX(-1);
  -o-transform: rotate(${rotate + 'deg'}) scaleX(-1);
`;
