import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/colaborador/operations';
import * as actions from '../store/ducks/colaborador/actions';

import useContratos from './useContratos';
import useErro from './useErro';
import { useCallback } from 'react';

function useColaborador() {
  const { colaboradorResponse } = useSelector(
    (state: RootState) => state.colaborador
  );

  const { limpaErro, mostraErro } = useErro();

  const { contratoSelecionado } = useContratos();

  const { fetchColaborador } = bindActionCreators(
    {
      ...actions,
      ...operations,
    },
    useDispatch()
  );

  const consultaColaborador = useCallback(
    async (idColaborador: string, setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        const headers = {
          ...colaboradorResponse?.headers,
          'id-contrato-mobilidade': contratoSelecionado.id,
          'id-passageiro': idColaborador,
        };
        const response = await fetchColaborador(headers);
        limpaErro();
        return response;
      } catch (error: any) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
      } finally {
        setLoading(false);
      }
    },
    [contratoSelecionado, contratoSelecionado?.id]
  );

  return {
    consultaColaborador,
    colaboradorResponse,
  };
}

export default useColaborador;
