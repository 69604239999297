import styled from 'styled-components';

interface ButtonProps {
  disabled?: boolean;
}

export const BotaoExportarRelatorio = styled.button<ButtonProps>`
  font-weight: 500;
  font-size: 0.83vw;
  color: ${(props) => (props.disabled ? 'var(--cinza-40)' : 'var(--cinza-60)')};
  width: 8.82vw;
  height: 2.4vw;
  padding: 0 0.55vw;
  border: 0.05vw solid;
  border-color: var(--cinza-60);
  border-radius: 0.41vw;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: ${(props) => props.disabled && 'not-allowed'};
  margin-right: 10px;
`;

export const Icone = styled.img`
  width: 1.22vw;
`;
