import React from 'react';
import * as Styled from './styles';
import setaBaixo from '../../assets/chevron_down_blue.svg';
import setaCima from '../../assets/chevron_up_blue.svg';

interface TabProps {
  label: string;
  icon: boolean;
}

export class Tab extends React.Component<TabProps> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

interface TabsProps {
  children: React.ReactElement<TabProps>[];
}

interface TabsState {
  activeIndex: number | null;
  icons: boolean[];
}

export class Tabs extends React.Component<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      icons: props.children.map(() => false),
    };
  }

  handleClick = (index: number) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? null : index,
      icons: prevState.icons.map((icon, i) => (i === index ? !icon : icon)),
    }));
  };

  render() {
    const { children } = this.props;
    const { activeIndex, icons } = this.state;

    return (
      <div>
        {children.map((tab: React.ReactElement<TabProps>, index: number) => (
          <div key={tab.props.label}>
            <Styled.WrapperText>
              <Styled.DropdownText>{tab.props.label}</Styled.DropdownText>
              <Styled.DropdownImage
                onClick={() => this.handleClick(index)}
                src={activeIndex !== index ? setaBaixo : setaCima}
              />
            </Styled.WrapperText>
            {activeIndex === index &&
              React.cloneElement(tab, { icon: icons[index] })}
          </div>
        ))}
      </div>
    );
  }
}
