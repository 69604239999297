import { Container } from '../../styles/globals';
import styled from 'styled-components';

interface ImgProps {
  imageUrl?: string;
}

export const Footer = styled.div`
  background: linear-gradient(90deg, var(--blueNav), var(--action));
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding-top: 1.2vw;
  padding-bottom: 1vw;
  height: fit-content;
`;

export const FooterContainer = styled(Container)`
  width: 100%;
  padding: 0;
  margin-left: 17vw;
`;

export const Solucao = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SpanSolucao = styled.span`
  margin-right: 0.22vw;
  color: var(--white);
  font-weight: 500;
`;

export const Logo = styled.div`
  margin-right: 0.22vw;
`;

export const LogoBanco = styled.div<ImgProps>`
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  width: 1.11vw;
  height: 1.11vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const SpanBanco = styled.span`
  color: var(--white);
  font-weight: 500;
`;
