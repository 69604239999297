import { ReactElement } from 'react';
import * as Styled from './styles';
import ImgSearchError from '../../../assets/icon-search-error.svg';

interface ICard {
  titulo: string;
  descricao: string;
}

export const CardNoData = ({ titulo, descricao }: ICard): ReactElement => {
  return (
    <Styled.CardContainer>
      <Styled.WrapperIcon>
        <Styled.IconSearchError src={ImgSearchError}></Styled.IconSearchError>
      </Styled.WrapperIcon>

      <Styled.WrapperTitulo>
        <Styled.TituloError>{titulo}</Styled.TituloError>
      </Styled.WrapperTitulo>
      <Styled.WrapperDescricao>
        <Styled.TituloDescricao>{descricao}</Styled.TituloDescricao>
      </Styled.WrapperDescricao>
    </Styled.CardContainer>
  );
};
