import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import * as Styled from './styles';

import useErro from '../../hooks/useErro';
import useGrafico from '../../hooks/useGrafico';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import useContratos from '../../hooks/useContratos';
import useCardDashboard from '../../hooks/useCardDashboard';
import useCardCentroCusto from '../../hooks/useCardCentroCusto';
import useCardColaborador from '../../hooks/useCardColaborador';
import useCardUltimasViagens from '../../hooks/useCardUltimasViagens';

import Chart from '../../components/Chart';
import { Erro } from '../../components/Erro';
import { CardNoData } from '../../components/Cards/CardNoData';
import CardDashboard from '../../components/Cards/CardDashboard';
import CardCentroCusto from '../../components/Cards/CardCentroCusto';
import CardColaborador from '../../components/Cards/CardColaborador';
import { SeletorDashboard } from '../../components/SeletorDashboard';
import CardUltimasViagens from '../../components/Cards/CardUltimasViagens';

import {
  anoAtual,
  anoPassado,
  anoRetrasado,
  mesesSelect,
} from '../../helpers/constantes';

export interface DashboardProps {
  setLoading: (value: boolean) => void;
}

const Dashboard = ({ setLoading }: DashboardProps) => {
  const navigate = useNavigate();
  const {
    consultaCardResumo,
    handleFiltraMesCorridasRealizadas,
    handleFiltraAnoCorridasRealizadas,
  } = useCardDashboard();
  const {
    consultaCardColaborador,
    handleFiltraMesColaborador,
    handleFiltraAnoColaborador,
  } = useCardColaborador();
  const { consultaGrafico, handleFiltraAno, handleFiltraMes, grafico } =
    useGrafico();
  const { consultaCardUltimasViagens } = useCardUltimasViagens();
  const {
    consultaCardCentroCusto,
    handleFiltraMesCentroCusto,
    handleFiltraAnoCentroCusto,
  } = useCardCentroCusto();
  const { contratoSelecionado } = useContratos();
  const { codigoErro, limpaErro } = useErro();

  const [anosMenoresQueAtual, setAnoMenorQueAtual] = useState(false);

  const [mesSelecionado, setMesSelecionado] = useState(
    mesesSelect[new Date().getMonth()]
  );

  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual);

  const anos = [anoRetrasado, anoPassado, anoAtual];
  const currentMonth = mesesSelect[new Date().getMonth()];

  const dataAtual = new Date();
  const monthActual = mesesSelect.slice(0, dataAtual.getMonth() + 1);

  const handleAno = (option: any) => {
    if (option) {
      if (option.label === anoAtual.label) {
        setAnoMenorQueAtual(false);
      }
      if (option.label !== anoAtual.label) {
        setAnoMenorQueAtual(true);
      }
      setAnoSelecionado(option);
      handleFiltraAno(option, setLoading).then(() => ({}));
      handleFiltraAnoCorridasRealizadas(option, setLoading).then(() => ({}));
      handleFiltraAnoColaborador(option, setLoading).then(() => ({}));
      handleFiltraAnoCentroCusto(option, setLoading).then(() => ({}));
    }
  };

  const handleMesChange = (option: any) => {
    setMesSelecionado(option);
    handleFiltraMes(option, setLoading).then(() => ({}));
    handleFiltraMesCorridasRealizadas(option, setLoading).then(() => ({}));
    handleFiltraMesColaborador(option, setLoading).then(() => ({}));
    handleFiltraMesCentroCusto(option, setLoading).then(() => ({}));
  };

  useEffect(() => {
    if (contratoSelecionado?.id) {
      consultaGrafico(setLoading).then(() => ({}));
      consultaCardResumo(setLoading).then(() => ({}));
      consultaCardColaborador(setLoading).then(() => ({}));
      consultaCardUltimasViagens(setLoading).then(() => ({}));
      consultaCardCentroCusto(setLoading).then(() => ({}));
      setMesSelecionado(currentMonth);
      setAnoSelecionado(anoAtual);
    }
  }, [contratoSelecionado]);

  return (
    <React.Fragment>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Styled.Content>
          <React.Fragment>
            <Styled.Dashboard>
              <Styled.WrapperTitulo>
                <Styled.Titulo>Visão Geral</Styled.Titulo>
                <Styled.WrapperSelector>
                  <SeletorDashboard
                    id="dashboard-mes"
                    value={mesSelecionado}
                    options={anosMenoresQueAtual ? mesesSelect : monthActual}
                    onChange={handleMesChange}
                  />
                  <SeletorDashboard
                    id="dashboard-ano"
                    value={anoSelecionado}
                    options={anos}
                    onChange={handleAno}
                  />
                </Styled.WrapperSelector>
              </Styled.WrapperTitulo>
              <Styled.ContainerDashboard>
                <Styled.ContentTop>
                  <CardDashboard />
                  <Styled.GraphicContainerDashboard>
                    <Styled.FilterContainer>
                      <h1>Viagens</h1>
                    </Styled.FilterContainer>
                    <Styled.ContentGraphic>
                      {grafico?.listaViagens?.length > 0 ? (
                        <Chart mesAtual={currentMonth.value.toString()} />
                      ) : (
                        <CardNoData
                          titulo="Não tivemos resultados com essas informações"
                          descricao="Tente alterar os filtros para visualizar o resultado desejado!"
                        />
                      )}
                    </Styled.ContentGraphic>
                  </Styled.GraphicContainerDashboard>
                </Styled.ContentTop>
                <Styled.ContentBottom>
                  <CardUltimasViagens />
                  <Styled.WrapperCards>
                    <CardColaborador />
                    <CardCentroCusto />
                  </Styled.WrapperCards>
                </Styled.ContentBottom>
              </Styled.ContainerDashboard>
            </Styled.Dashboard>
          </React.Fragment>
        </Styled.Content>
      )}
    </React.Fragment>
  );
};

export default IsLoadingHOC(Dashboard);
