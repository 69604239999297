import { ReactElement } from 'react';
import * as Styled from './styles';
import data from '../../assets/calendar.svg';
import buscar from '../../assets/buscar.svg';
import { ICentroDeCusto } from '../../@types/interfaces/contratoApi';

interface IInput {
  label?: string;
  tipo: string;
  seletores?: string[] | ICentroDeCusto[];
  valor?: any;
  placeholder?: string;
  id?: string;
  inputGrande?: boolean;
  onChange: (event: any) => void;
}

export const InputDetalharFatura = (props: IInput): ReactElement => {
  const tipoSelect = () => {
    if (props.tipo == 'data') {
      return data;
    }
    if (props.tipo == 'texto') {
      return buscar;
    }
  };

  return (
    <Styled.InputContainer>
      <Styled.Label>{props.label}</Styled.Label>

      {props.tipo == 'data' && (
        <Styled.Input
          {...props}
          inputGrande={props.inputGrande}
          id={props.id}
          type="date"
          placeholder={props.valor}
          imageUrl={tipoSelect()}
          required
          value={props.valor}
          onChange={props.onChange}
        />
      )}
      {props.tipo == 'texto' && (
        <Styled.Input
          {...props}
          type="text"
          value={props.valor}
          placeholder={props.placeholder}
          onChange={props.onChange}
          imageUrl={tipoSelect()}
        />
      )}
    </Styled.InputContainer>
  );
};
