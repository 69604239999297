import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as Styled from './styles';

import SegundoFormularioNovoColaborador from '../../components/FormulariosNovoColaborador/SegundoFormularioNovoColaborador';
import PrimeiroFormularioNovoColaborador from '../../components/FormulariosNovoColaborador/PrimeiroFormularioNovoColaborador';

import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import useContratos from '../../hooks/useContratos';

import NovoColaboradorApi from '../../api/novoColaborador';

import ErroModal from '../../assets/erro-modal.svg';
import SucessoModal from '../../assets/sucesso-modal.svg';
import ImagemFormulario from '../../assets/profile-data.svg';
import SetaEsquerda from '../../assets/icone-seta-esquerda.svg';

import ModalNovoColaborador from '../../components/ModalNovoColaborador';

import { DadosProps } from '../../@types/interfaces/dadosNovoColaborador';
import { CadastroNovoColaborador } from '../../@types/interfaces/cadastroNovoColaborador';

import { CardListaLateral } from '../../components/CardListaLateral';

import {
  enviarDados,
  resetarDados,
  navegarPaginaAnterior,
} from '../../helpers/utilsFormulariosColaborador';

interface NovoColaboradorProps {
  setLoading: (value: boolean) => void;
}

const NovoColaborador = ({ setLoading }: NovoColaboradorProps) => {
  const navigate = useNavigate();

  const { contratoSelecionado } = useContratos();

  const [mostrarPrimeiraPagina, setMostrarPrimeiraPagina] = useState(true);

  const [modalSucessoVisivel, setModalSucessoVisivel] = useState(false);
  const [modalErroVisivel, setModalErroVisivel] = useState(false);

  const [camposDesabilitados, setCamposDesabilitados] = useState(true);
  const [botaoSubmitDesabilitado, setBotaoSubmitDesabilitado] = useState(true);
  const [camposIniciaisDesabilitados, setCamposIniciaisDesabilitados] =
    useState(false);

  const dadosDefault = {
    cpf: '',
    dataNascimento: '',
    nome: '',
    email: '',
    celular: '',
    cargo: '',
    centroCustoVinculado: [0],
    categoria: 'comum',
    status: true,
  };

  const [dados, setDados] = useState(dadosDefault);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm<DadosProps>({ defaultValues: dadosDefault });

  const cadastrarColaborador = async ({
    nome,
    cpf,
    email,
    dataNascimento,
    celular,
    centroCustoVinculado,
    cargo,
    categoria,
  }: CadastroNovoColaborador) => {
    const headers = {
      nome,
      cpf: cpf,
      email: email,
      dataNascimento,
      numeroTelefoneCelular: celular,
      idContrato: contratoSelecionado.id,
      'id-contrato-mobilidade': contratoSelecionado.id,
      numeroDeIdentificacaoDoCentroDeCusto: centroCustoVinculado,
      cargo: cargo,
      solicitarCategoriaVeiculo: categoria,
    };
    return NovoColaboradorApi.cadastrarNovoColaborador(headers);
  };

  const onSubmit: SubmitHandler<DadosProps> = () => {
    setBotaoSubmitDesabilitado(true);
    setLoading(true);
    enviarDados(
      dados,
      cadastrarColaborador,
      setLoading,
      setModalSucessoVisivel,
      setModalErroVisivel
    ).then();
  };

  return (
    <Styled.Content>
      <CardListaLateral>
        <>
          <Styled.WrapperTitulo>
            <Styled.IconeVoltar
              id={'BotaoVoltar'}
              src={SetaEsquerda}
              onClick={() =>
                navegarPaginaAnterior(
                  mostrarPrimeiraPagina,
                  navigate,
                  setMostrarPrimeiraPagina
                )
              }
            />
            <Styled.Titulo>Adicionar Colaborador</Styled.Titulo>
          </Styled.WrapperTitulo>
          <Styled.WrapperContent>
            <Styled.Formulario
              id="Formulario"
              onSubmit={handleSubmit(onSubmit)}>
              {mostrarPrimeiraPagina ? (
                <PrimeiroFormularioNovoColaborador
                  setValue={setValue}
                  setError={setError}
                  register={register}
                  errors={errors}
                  setMostrarPrimeiraPagina={setMostrarPrimeiraPagina}
                  dados={dados}
                  setDados={setDados}
                  setLoading={setLoading}
                  camposDesabilitados={camposDesabilitados}
                  setCamposDesabilitados={setCamposDesabilitados}
                  camposIniciaisDesabilitados={camposIniciaisDesabilitados}
                  setCamposIniciaisDesabilitados={
                    setCamposIniciaisDesabilitados
                  }
                  clearErrors={clearErrors}
                  setBotaoSubmitDesabilitado={setBotaoSubmitDesabilitado}
                />
              ) : (
                <SegundoFormularioNovoColaborador
                  botaoSubmitDesabilitado={botaoSubmitDesabilitado}
                  setBotaoSubmitDesabilitado={setBotaoSubmitDesabilitado}
                  dados={dados}
                  setDados={setDados}
                  setLoading={setLoading}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              )}
            </Styled.Formulario>
            <Styled.WrapperImg>
              <Styled.Img src={ImagemFormulario} />
            </Styled.WrapperImg>
          </Styled.WrapperContent>
          <ModalNovoColaborador
            visivel={modalSucessoVisivel}
            onCloseRequest={() => navigate('/colaboradores')}
            imagem={SucessoModal}
            textoPai="Colaborador adicionado com sucesso!"
            textoFilho="Confira na lista de colaboradores."
            onClick={() => navigate('/colaboradores')}
            labelBotao="OK"
          />
          <ModalNovoColaborador
            visivel={modalErroVisivel}
            onCloseRequest={() =>
              resetarDados(
                setModalErroVisivel,
                setMostrarPrimeiraPagina,
                setCamposDesabilitados,
                setCamposIniciaisDesabilitados,
                setDados,
                dadosDefault,
                reset
              )
            }
            imagem={ErroModal}
            textoPai="Não foi possível adicionar o colaborador."
            textoFilho="Tivemos um problema ao realizar o cadastro, mas não se preocupe,
                você pode tentar novamente clicando no botão abaixo."
            onClick={() =>
              resetarDados(
                setModalErroVisivel,
                setMostrarPrimeiraPagina,
                setCamposDesabilitados,
                setCamposIniciaisDesabilitados,
                setDados,
                dadosDefault,
                reset
              )
            }
            labelBotao="Tentar Novamente"
          />
        </>
      </CardListaLateral>
    </Styled.Content>
  );
};

export default IsLoadingHOC(NovoColaborador);
