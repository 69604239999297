import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw 0;
  flex-direction: row;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
  margin-bottom: 3vw;
`;

export const WrapperTitulo = styled.div`
  width: 23.6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  padding-left: 3vw;
  justify-content: flex-start;
  align-items: center;
`;

export const IconeVoltar = styled.img`
  width: 6%;
  height: 1.3vw;
  cursor: pointer;
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 2vw;
  padding-left: 1.5vw;

  @media (max-width: 768px) {
    font-size: 5.5vw;
  }
`;

export const Formulario = styled.form`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  width: 50%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const WrapperImg = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  padding: 1.6vw;
  width: 32vw;
  height: 34vw;
`;
