import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  DetalharFaturaResp,
  FiltrosHeaderDetalheFatura,
} from '../@types/interfaces/detalharFatura';

class DetalharFaturaApi {
  /**
   * Get Faturas
   * @return {Promise<any>} Retorna um array de faturas vinculadas ao usuario logado.
   */
  public getDetalharFatura = async (
    headers?: FiltrosHeaderDetalheFatura
  ): Promise<DetalharFaturaResp> => {
    const response = await segurancaAxios.get('fatura/detalhar', {
      headers,
    });
    return response.data;
  };

  public getDetalharFaturaDownloadPDF = async (
    headers?: FiltrosHeaderDetalheFatura
  ): Promise<void> => {
    const response = await segurancaAxios.get('download/gerarPDF', {
      headers,
    });

    return response.data;
  };
}
export default new DetalharFaturaApi();
