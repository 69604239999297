import React from 'react';
import * as Styled from './styles';
import PassageiroAtivarApi from '../../api/PassageiroAtivar';
import PassageiroDesativarApi from '../../api/PassageiroDesativar';
import { AlterarStatusColaborador } from '../../@types/interfaces/AlterarStatusColaborador';
import { useForm } from 'react-hook-form';
import IconeEditarColaborador from '../../assets/icon-edit.svg';
import IconeEditarBloqueado from '../../assets/grey-icon-edit.svg';
import Icons from '../Icons';
import ImgPointsColaborador from '../../assets/points.svg';
import { SwitchProps } from '../../@types/interfaces/SwitchProps';
import { logger } from '../../util/logger';
import useColaboradores from '../../hooks/useColaboradores';

const Switch: React.FC<SwitchProps> = (props) => {
  const { handleColaboradores } = useColaboradores();

  const dadosDefault = {
    'id-passageiro': '',
    'id-contrato-mobilidade': '',
  };

  const { handleSubmit } = useForm({
    defaultValues: dadosDefault,
  });

  const mudarEstadoPassageiro = async () => {
    if (props.status) {
      try {
        const headers = {
          'id-passageiro': props.id,
          'id-contrato-mobilidade': props.idContrato,
        };
        return await PassageiroDesativarApi.desativarPassageiro(headers);
      } catch (error) {
        const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "mudarEstadoPassageiro".
        <'ERRO'>
          message: Não foi possível desativar o colaborador!
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
        logger.error(errorFormatado);
      }
    } else {
      try {
        const headers = {
          'id-passageiro': props.id,
          'id-contrato-mobilidade': props.idContrato,
        };
        return await PassageiroAtivarApi.ativarPassageiro(headers);
      } catch (error) {
        const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "mudarEstadoPassageiro".
        <'ERRO'>
          message: Não foi possível ativar o colaborador!
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
        logger.error(errorFormatado);
      }
    }
  };

  const mudarEstado = async (data: AlterarStatusColaborador) => {
    data['id-passageiro'] = props.id;
    data['id-contrato-mobilidade'] = props.idContrato;
    return await mudarEstadoPassageiro();
  };

  const handleClick = async (data: AlterarStatusColaborador) => {
    props.setLoading(true);
    await mudarEstado(data);
    await handleColaboradores(props.setLoading).then(() => ({}));
    props.setLoading(false);
  };

  return (
    <>
      <Styled.ContainerGeral>
        <Styled.Container>
          <Styled.SwitchContainer>
            <Styled.SwitchButton
              isActive={props.status}
              onClick={handleSubmit(handleClick)}>
              <div className="toggle" />
            </Styled.SwitchButton>
            <Styled.SwitchText>
              {props.status ? 'Ativo' : 'Inativo'}
            </Styled.SwitchText>
          </Styled.SwitchContainer>
        </Styled.Container>
        <Styled.Container>
          <Icons
            setLoading={props.setLoading}
            id={props.id}
            status={props.status}
            imageUrl={
              props.status ? IconeEditarColaborador : IconeEditarBloqueado
            }
            width={18}
            height={18}
          />
          <Icons
            setLoading={props.setLoading}
            id={props.id}
            status={props.status}
            imageUrl={ImgPointsColaborador}
            width={4}
            height={18}
            disabled
          />
        </Styled.Container>
      </Styled.ContainerGeral>
    </>
  );
};

export default Switch;
