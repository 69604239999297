import styled, { css } from 'styled-components';
import { Icon } from '../../styles/globals';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 1.02vw;
  letter-spacing: 0.01vw;
  margin-bottom: 2.3vw;
  height: fit-content;
`;

interface PointsProps {
  activated?: boolean;
}

interface ButtonProps {
  habilitado?: string;
}
interface TdProps {
  hasPoints?: boolean;
}

interface MenuProps {
  activated?: boolean;
}

interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
  activated?: boolean;
}

export const TabelaFaturasDetalhar = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  max-height: 28.34vw;
  height: fit-content;
`;

export const TabelaHeadFaturasDetalhar = styled.thead`
  background-color: var(--cinza-10);
  text-align: center;
  height: 2.8vw;
`;

export const TabelaRowFaturasDetalhar = styled.tr`
  div {
    display: flex;
    justify-content: center;
  }
  border-bottom: 0.05vw solid var(--cinza-20);
  transition: var(--cinza-20) 300ms ease-in-out;
  text-align: center;
`;

export const TabelaHeaderFaturasDetalhar = styled.th`
  padding: 0 0;
  div {
    display: flex;
    justify-content: center;
  }
  font-weight: 400;
  color: var(--black);
  text-align: center;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.03vw;
`;

export const BotaoRedirectDetalhar = styled.div`
  display: flex;
  align-items: center;
`;

export const TabelaBodyFaturasDetalhar = styled.tbody`
  height: fit-content;
`;

export const TabelaDataCellFaturasDetalhar = styled.td<TdProps>`
  text-align: center;
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.01vw;
  border-top: 0.06vw solid var(--cinza-20);
  border-bottom: 0.06vw solid var(--cinza-20);

  ${({ hasPoints }) =>
    hasPoints &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const TabelaComIconeDetalhar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContentDetalhar = styled.div<PointsProps>`
  width: 1.33vw;
  height: 1.33vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;

  &:active {
    opacity: 0.7;
    transition: opacity 100ms ease-in-out;
    background-color: var(--cinza-30);
  }

  ${({ activated }) =>
    activated &&
    css`
      &:active {
        opacity: 0.2;
        transition: opacity 100ms ease-in-out;
      }
      background-color: var(--cinza-30);
    `}
`;

export const WrapperSelectorDetalhar = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-end;
  margin-bottom: 2vw;
`;

export const MenuDetalhar = styled.div<MenuProps>`
  opacity: 0;
  position: absolute;
  background-color: var(--white);
  left: -6.5vw;
  top: 0.5vw;
  border-radius: 0.25vw;
  box-shadow: 0 0.64vw 1.02vw rgba(0, 0, 0, 0.06),
    0 0.19vw 0.38vw rgba(0, 0, 0, 0.06), 0 0 0.06vw rgba(0, 0, 0, 0.04);
  z-index: 1;
  height: fit-content;
  pointer-events: none;

  ${({ activated }) =>
    activated &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const MenuListDetalhar = styled.ul`
  display: flex;
`;
export const MenuItemDetalhar = styled.li`
  color: var(--black);
  background-color: transparent;
  display: flex;
  align-items: center;
`;
export const MenuRowDetalhar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 300ms ease-in-out;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.04vw;
  width: 7vw;
  height: 2.5vw;
  justify-content: space-around;
  padding-right: 0.9vw;
`;

export const IconDetalheDetalhar = styled(Icon)`
  margin-right: 0.48vw;
  width: 0.9vw;
  height: 0.97vw;
`;

export const IconOrderDetalhar = styled(Icon)<ImgProps>`
  ${({ activated }) =>
    activated &&
    css`
      transform: rotate(180deg) scaleX(-1);
      -webkit-transform: rotate(180deg) scaleX(-1);
      -moz-transform: rotate(180deg) scaleX(-1);
      -o-transform: rotate(180deg) scaleX(-1);
    `}
  transition: all 200ms ease-in-out;
  margin: 0 0.19vw;
  width: 0.96vw;
  height: 0.76vw;
`;

export const IconPointsDetalhar = styled(Icon)`
  margin: 0;
  width: 0.25vw;
  height: 1.15vw;
`;

export const IconDateDetalhar = styled(Icon)`
  margin-left: 0;
  margin-right: 0.558vw;
  cursor: default;
  padding: 1.39vw;
  border-radius: 0.446vw;
  height: 0.83vw;
  width: 3.5vw;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
`;

export const Status = styled.div`
  display: flex;
  margin-bottom: 2vw;
`;

export const MainExtract = styled.div`
  display: flex;
  margin-bottom: 2vw;
  align-items: center;
  height: fit-content;
`;

export const Extract = styled.div`
  display: flex;
  margin-right: 2.23vw;
  align-items: center;
`;

export const ContentExtract = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextExtract = styled.p`
  font-size: 0.89vw;
  margin-bottom: 0.27vw;
`;

export const SubTextExtract = styled.p`
  font-size: 0.78vw;
  color: var(--cinza-50);
`;

export const IconParceiroDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const IconParceiro = styled.div<ImgProps>`
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  width: 1.67vw;
  height: 1.67vw;
  border-radius: 0.446vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 0.558vw;
`;

export const IconParceiroText = styled.p`
  color: var(--cinza-60);
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 50%;
`;

export const ImgContainerDetalhar = styled.div<ButtonProps>`
  display: flex;
  pointer-events: ${({ habilitado }) => `${habilitado}`};
`;

export const IconDownloadDetalhar = styled(Icon)<ButtonProps>`
  margin-right: 1.11vw;
  opacity: ${({ habilitado }) => `${habilitado}`};
  background-position: inherit;
  cursor: pointer;
`;

export const WrapperImgDetalhar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin-bottom: 2vw;
`;
