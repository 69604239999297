import styled from 'styled-components';
import { Icon } from '../../styles/globals';
import { StylesConfig } from 'react-select';
import colors from '../../styles/colors';

interface SelectProps {
  opacity?: string;
}

export const Content = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const SeletorContratos = styled.div<SelectProps>`
  padding: 0 0.5vw 0 0.5vw;
  height: 75%;
  border-left: solid 0.05vw var(--white);
  border-right: solid 0.05vw var(--white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${({ opacity }) => `${opacity}`};
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: content-box;

  p {
    color: var(--white);
    border: 0.05vw solid transparent;
    font-weight: 400;
    font-size: 1.15vw;
    line-height: 1.45vw;
    cursor: default;
    @media (max-width: 835px) {
      font-size: 1.72vw;
    }
  }
`;

interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
}

export const SeletorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 2vw;
  border-radius: 1vw;
  @media (max-width: 835px) {
    height: 3vw;
  }
`;

export const IconSeletor = styled(Icon)<ImgProps>`
  width: 0.72vw;
  height: 0.44vw;
  margin: 0;
  cursor: default;
`;

const getBackgroundColor = (
  isDisabled: boolean,
  isFocused: boolean,
  isSelected: boolean
) => {
  if (!isDisabled && isFocused && !isSelected) {
    return colors.bgSecondaryDefault;
  }
  if (!isDisabled && isSelected) {
    return colors.action;
  }
};

const msgStyles = {
  backgroundColor: 'transparent',
  color: colors.cinza40,
  borderRadius: '3.41vw',
  fontSize: '0.79vw',
};

export const styles: StylesConfig<any> = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const fontWeight = '500';
    return {
      ...styles,
      backgroundColor: getBackgroundColor(isDisabled, isFocused, isSelected),
      fontSize: '0.79vw',
      fontWeight,
      '&:first-child': {
        borderTopRightRadius: '0.6vw',
        borderTopLeftRadius: '0.6vw',
      },
      '&:last-child': {
        borderBottomRightRadius: '0.6vw',
        borderBottomLeftRadius: '0.6vw',
      },
    };
  },
  control: (styles) => ({
    ...styles,
    border: '0.05vw solid white',
    padding: '0.11vw 0.44vw',
    backgroundColor: 'transparent',
    borderRadius: '0.41vw',
    fontSize: '0.79vw',
    height: '1.5vw',
    minHeight: '1.5vw',
    maxWidth: '12.5vw',
    minWidth: '12.5vw',
    flexWrap: 'nowrap',
    boxShadow: `0 0 0 0 ${colors.white}`,
    '&:hover': {
      border: '0.05vw solid white',
      opacity: '50%',
    },
  }),
  placeholder: (styles) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  singleValue: (provided) => {
    const fontWeight = '500';
    const display = 'flex';
    const alignItems = 'center';

    return {
      ...provided,
      textAlign: 'center',
      color: 'white',
      fontWeight,
      display,
      alignItems,
    };
  },
  noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
  input: (styles) => {
    return {
      ...styles,
      color: 'white',
      fontWeight: '500',
      margin: 0,
      padding: 0,
    };
  },
  menu: (base) => ({
    ...base,
    height: 'max-content',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
  }),
};
