import {
  FiltrosHeadersCard,
  ICardCentroCusto,
} from '../../../@types/interfaces/cardCentroCusto';
import {
  CardCentroCustoActionTypes,
  GET_CARD_CENTRO_CUSTO,
  FILTRA_CAMPO,
} from './types';

export function setCardCentroCusto(
  headers: FiltrosHeadersCard,
  cardCentroCustoResponse: ICardCentroCusto
): CardCentroCustoActionTypes {
  return {
    type: GET_CARD_CENTRO_CUSTO,
    headers,
    cardCentroCustoResponse,
  };
}

export function filtraCampo(
  headers: FiltrosHeadersCard,
  cardCentroCustoResponse: ICardCentroCusto
): CardCentroCustoActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    cardCentroCustoResponse,
  };
}
