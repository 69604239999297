import styled, { css } from 'styled-components';
import { Icon } from '../../styles/globals';

interface ThProps {
  withIcon?: boolean;
}

interface PointsProps {
  activated?: boolean;
}

interface TdProps {
  hasPoints?: boolean;
}

interface MenuProps {
  activated?: boolean;
}

interface ColorProps {
  color?: string;
}

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
  @media (max-width: 835px) {
    border-left: 5vw var(--branco-laterais) solid;
    border-right: 5vw var(--branco-laterais) solid;
  }
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 1.02vw;
  letter-spacing: 0.01vw;
  @media (max-width: 835px) {
    font-size: 2.67vw;
  }
`;

export const WrapperSelectorColaboradores = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2.3vw;
  width: 100%;
  height: fit-content;
  @media (max-width: 835px) {
    margin-bottom: 3.45vw;
  }
`;

export const TabelaFaturasCentro = styled.table`
  width: 100%;
  border-spacing: 0 0.06vw;
  height: fit-content;

  @media (max-width: 835px) {
    border-spacing: 0 0.09vw;
  }
`;

export const TabelaHeadFaturasCentro = styled.thead`
  background-color: var(--cinza-10);
  height: 2.8vw;
  @media (max-width: 835px) {
    height: 4.2vw;
  }
`;

export const TabelaRowFaturasCentro = styled.tr`
  transition: var(--cinza-20) 300ms ease-in-out;
`;

export const TabelaHeaderFaturasCentro = styled.th<ThProps>`
  color: var(--black);
  font-weight: 400;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.03vw;

  div {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 835px) {
    font-size: 1.33vw;
  }

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const TabelaBodyFaturasCentro = styled.tbody``;

export const TabelaDataCellFaturasCentro = styled.td<TdProps>`
  padding: 0 1.92vw;
  text-align: center;
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.01vw;
  border-top: 0.06vw solid var(--cinza-20);
  border-bottom: 0.06vw solid var(--cinza-20);

  @media (max-width: 835px) {
    padding: 0 2.88vw;
    font-size: 1.33vw;
    line-height: 1.92vw;
    border-top: 0.09vw solid var(--cinza-20);
    border-bottom: 0.09vw solid var(--cinza-20);
  }

  ${({ hasPoints }) =>
    hasPoints &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const TabelaComIconeCentro = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContentCentro = styled.div<PointsProps>`
  width: 1.53vw;
  height: 1.53vw;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;

  @media (max-width: 835px) {
    width: 2.29vw;
    height: 2.29vw;
    border-radius: 0.37vw;
    margin: 1.53vw 0;
  }

  &:active {
    opacity: 0.7;
    transition: opacity 100ms ease-in-out;
  }

  ${({ activated }) =>
    activated &&
    css`
      &:active {
        opacity: 0.2;
        transition: opacity 100ms ease-in-out;
      }
    `}
`;

export const MenuCentro = styled.div<MenuProps>`
  opacity: 0;
  position: absolute;
  background-color: var(--white);
  left: -7.5vw;
  top: 1.8vw;
  border-radius: 0.25vw;
  box-shadow: 0 0.64vw 1.02vw rgba(0, 0, 0, 0.06),
    0 0.19vw 0.38vw rgba(0, 0, 0, 0.06), 0 0 0.06vw rgba(0, 0, 0, 0.04);
  pointer-events: none;
  z-index: 1;

  ${({ activated }) =>
    activated &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`;

export const MenuListCentro = styled.ul`
  display: flex;
`;

export const MenuItemCentro = styled.li``;

export const MenuRowCentro = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0.76vw 1.02vw;
  width: 8.65vw;
  height: 3.07vw;
  border-bottom: 0.06vw solid var(--cinza-30);
  transition: all 300ms ease-in-out;
  font-weight: 500;
  font-size: 1.02vw;
  line-height: 100%;
`;

export const TextDefaultCentro = styled.p``;

export const IconDetalheCentro = styled(Icon)`
  margin: 0;
  width: 1.28vw;
  height: 1.28vw;

  @media (max-width: 835px) {
    width: 1.92vw;
    height: 1.92vw;
  }
`;

export const IconPointsCentro = styled(Icon)`
  margin: 0;
  opacity: 0.4;
  cursor: not-allowed;
  width: 0.25vw;
  height: 1.15vw;
  @media (max-width: 835px) {
    width: 0.37vw;
    height: 1.72vw;
  }
`;

export const TextTotalValue = styled.p<ColorProps>`
  color: ${({ color }) => `${color}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperConteudo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: fit-content;
`;

export const BotaoCadastro = styled.button`
  width: 16vw;
  height: 2.23vw;
  box-shadow: 0 0.1116vw 0.1116vw rgba(0, 0, 0, 0.25);
  border-radius: 0.83vw;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1vw;
  letter-spacing: 0.011vw;
  padding: 0.5vw;
  @media (max-width: 835px) {
    width: 24vw;
    height: 3.34vw;
    border-radius: 1.24vw;
    font-size: 1.5vw;
    padding: 0.75vw;
  }
`;

export const Icone = styled.img`
  width: 1vw;
  @media (max-width: 835px) {
    width: 1.5vw;
  }
`;
