import log from 'pino';
import { format } from 'date-fns';

export const logger = log({
  level:
    process.env.LOG_LEVEL && process.env.LOG_LEVEL !== 'test'
      ? process.env.LOG_LEVEL
      : 'debug',
  timestamp: () => `,"time":"${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}"`,
});
