import styled from 'styled-components';

export const DropdownContent = styled.div`
  display: flex;
  font-size: 0.94vw;
  font-weight: 450;
  height: 10.04vw;
  width: 36.66294642857143vw;
  align-items: center;
  justify-content: center;
  border: 0.05vw var(--cinza-30) solid;
`;

export const Rest = styled.div`
  display: flex;
  flex-direction: column;
  width: 36.66294642857143vw;
  justify-content: space-evenly;
`;

export const RestMainContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.83vw;
`;

export const RestInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.83vw;
  margin-top: 1.11vw;
`;
export const RestBoldInfo = styled.p`
  font-size: 0.83vw;
  font-weight: 500;
`;

export const RestInfo = styled.p`
  font-size: 0.83vw;
  font-weight: 400;
`;

export const RestMainImg = styled.img`
  height: 3.34vw;
  width: 3.34vw;
  margin-right: 0.83vw;
`;

export const CotacoesRealizadasMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const CotacoesRealizadasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 35.7vw;
  padding-bottom: 0.27vw;
  border-bottom: 0.05vw var(--cinza-30) solid;
`;

export const CotacoesRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.83vw;
  margin-top: 0.27vw;
`;

export const RealizadasBold = styled.p`
  font-size: 0.55vw;
  font-weight: 500;
`;
export const RealizadasInfo = styled.p`
  font-size: 0.55vw;
  font-weight: 400;
`;

export const RealizadasImg = styled.img`
  height: 1.11vw;
  width: 1.11vw;
  margin-right: 0.83vw;
  margin-top: 1.11vw;
`;
