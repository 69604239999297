import styled from 'styled-components';
import { Icon } from '../../styles/globals';

export const Icons = styled(Icon)<{ isActive: boolean; disabled?: boolean }>`
  margin: 0.5vw;
  opacity: ${(props) => (props.isActive && !props.disabled ? '1' : '0.4')};
`;

export const IconsContainer = styled.button<{
  isActive: boolean;
  disabled?: boolean;
}>`
  &:hover ${Icons} {
    cursor: ${(props) =>
      props.isActive && !props.disabled ? 'pointer' : 'not-allowed'};
  }
  display: flex;
  align-items: center;
  background-color: transparent;
`;
