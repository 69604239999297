import { ContratoResponse, ContratoSelecionado } from '../../../api/contrato';
import { Contrato } from '../../../@types/interfaces/contratoApi';

export const SET_LISTA_CONTRATOS = 'CONTRATO/SET_LISTA_CONTRATO';
export const SET_CONTRATO_SELECIONADO = 'CONTRATO/SET_CONTRATO_SELECIONADO';
export const LIMPA_CONTRATO = 'CONTRATO/LIMPA_CONTRATO';

export interface ContratoState {
  contratoResponse: ContratoResponse;
  listaContratos: Contrato[];
  contratoSelecionado: Contrato;
}

interface SetListaContratosAction {
  type: typeof SET_LISTA_CONTRATOS;
  contratoResponse: ContratoResponse;
}

interface SetContratoSelecionadoAction {
  type: typeof SET_CONTRATO_SELECIONADO;
  contratoSelecionado: ContratoSelecionado;
}

interface LimpaContrato {
  type: typeof LIMPA_CONTRATO;
}

export type ListaContratosActionTypes =
  | SetListaContratosAction
  | SetContratoSelecionadoAction
  | LimpaContrato;
