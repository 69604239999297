import {
  ColaboradorActionType,
  ColaboradorState,
  SET_COLABORADOR,
} from './types';

const initialState: ColaboradorState = {
  colaboradorResponse: {
    _id: '',
    nome: '',
    cpf: '',
    codigoMCI: '',
    email: '',
    numeroTelefoneCelular: '',
    dadosDeFaturamento: {
      idContratoMobilidade: '',
      numeroDeIdentificacaoDoCentroDeCusto: [0],
    },
    headers: {
      'id-passageiro': '',
      'id-contrato-mobilidade': '',
    },
    autorizacoesUsuario: [
      {
        tipo: '',
        idContratoMobilidade: '',
        solicitarCategoriaVeiculo: '',
        'numeroDeIdentificacaoDoCentroDeCusto_@CMU': [0],
      },
    ],
    dadosDoUsuarioVinculadosAoContrato: [
      {
        idContratoMobilidade: '',
        cargo: '',
        email: '',
        numeroTelefoneCelular: '',
      },
    ],
  },
};

const ColaboradorReducer = (
  state = initialState,
  action: ColaboradorActionType
) => {
  const actionHandlers = {
    [SET_COLABORADOR]: () => ({
      ...state,
      colaboradorResponse: action.colaboradorResponse,
    }),
  };

  const actionHandler = actionHandlers[action.type];

  return actionHandler ? actionHandler() : state;
};

export default ColaboradorReducer;
