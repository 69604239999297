import React, { SetStateAction, useEffect, useState } from 'react';
import * as Styled from './styles';
import IconCloseButton from '../../assets/icone-fechar-modal.svg';
import { formatarReal } from '../../helpers/formatacoes';
import Modal from '../Modal';
import useCardDashboard from '../../hooks/useCardDashboard';
import useContratos from '../../hooks/useContratos';
import UltimasViagens from '../../api/ultimasViagens';
import { Viagem } from '../../@types/interfaces/Viagem';
import InformacoesExtras from './InformacoesExtras';

interface ModalUsuarioProps {
  visivel: boolean;
  setVisivel: React.Dispatch<
    SetStateAction<{ menuGerenciamento: boolean; modalVisivel: boolean }>
  >;
}

const ModalUsuario = ({ visivel, setVisivel }: ModalUsuarioProps) => {
  const { cardResumoResponse } = useCardDashboard();
  const { contratoSelecionado } = useContratos();
  const [ultimasViagens, setUltimasViagens] = useState<Viagem[]>([]);

  const listarUltimasViagens = async () => {
    setUltimasViagens(
      await UltimasViagens.listarUltimasViagens(contratoSelecionado)
    );
  };

  useEffect(() => {
    if (contratoSelecionado?.id) {
      listarUltimasViagens().then(() => ({}));
    }
  }, [contratoSelecionado]);

  const mostrarDefault = {
    menuGerenciamento: false,
    contratosAtivos: false,
    centrosCusto: false,
    ultimasViagens: false,
  };

  const [mostrar, setMostrar] = useState(mostrarDefault);

  const resetar = () => {
    setMostrar(mostrarDefault);
    setVisivel({ ...mostrar, modalVisivel: false });
  };

  return (
    <Modal visivel={visivel} onCloseRequest={() => ({})}>
      <Styled.DadosUsuarioContainer id={'modal-usuario'}>
        <Styled.HeaderUsuario>
          <Styled.IconeUsuario></Styled.IconeUsuario>
          <Styled.BotaoFecharModal id={'BotaoFecharModal'} onClick={resetar}>
            <Styled.IconeBotaoFecharModal src={IconCloseButton} />
          </Styled.BotaoFecharModal>
        </Styled.HeaderUsuario>
        <Styled.DadosUsuario>
          <Styled.DadosUsuario_1></Styled.DadosUsuario_1>
          <Styled.DadosUsuario_2>
            <Styled.EmailUsuario></Styled.EmailUsuario>
          </Styled.DadosUsuario_2>
        </Styled.DadosUsuario>
        <Styled.InfosUsuario>
          <Styled.Info>
            <Styled.InfoTitle>Valor Disponível</Styled.InfoTitle>
            <Styled.InfoItem></Styled.InfoItem>
          </Styled.Info>
          <Styled.InfoCenter>
            <Styled.InfoTitle>Valor Utilizado</Styled.InfoTitle>
            <Styled.InfoItem>
              {formatarReal(cardResumoResponse?.cardResumoViagem?.totalGasto)}
            </Styled.InfoItem>
          </Styled.InfoCenter>
          <Styled.Info>
            <Styled.InfoTitle>Qtd. Viagens</Styled.InfoTitle>
            <Styled.InfoItem>
              {cardResumoResponse?.cardResumoViagem?.viagensRealizadas}
            </Styled.InfoItem>
          </Styled.Info>
        </Styled.InfosUsuario>
        <InformacoesExtras
          setMostrar={setMostrar}
          mostrar={mostrar}
          ultimasViagens={ultimasViagens}
        />
      </Styled.DadosUsuarioContainer>
    </Modal>
  );
};

export default ModalUsuario;
