import styled from 'styled-components';

export const DadosUsuarioContainer = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  width: 25.64vw;
  border-radius: 0.66vw;
`;

export const HeaderUsuario = styled.div`
  display: flex;
  flex-direction: row;
  height: 2.7vw;
  width: 100%;
  margin-top: 2.3vw;
  margin-bottom: 0.62vw;
`;

export const IconeUsuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cinza-10);
  height: 2.73vw;
  width: 3.3vw;
  margin-left: 11.2vw;
  border-radius: 0.27vw;
`;

export const IniciaisUsuario = styled.label`
  font-size: 0.89vw;
  font-weight: 500;
  line-height: 1.33vw;
  letter-spacing: -0.011em;
  text-align: center;
`;

export const BotaoFecharModal = styled.div`
  height: 1.12vw;
  width: 1.12vw;
  margin-left: 7.81vw;
  border-radius: 0.55vw;
  cursor: pointer;
`;

export const IconeBotaoFecharModal = styled.img`
  height: 1.12vw;
  width: 1.12vw;
`;

export const DadosUsuario = styled.div`
  display: flex;
  flex-direction: column;
  height: 1.9vw;
  width: 100%;
`;

export const DadosUsuario_1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const DadosUsuario_2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  color: var(--cinza-50);
`;

export const NomeUsuario = styled.label`
  font-size: 0.83vw;
  font-weight: 500;
  line-height: 1.06vw;
  letter-spacing: 0;
`;

export const EmailUsuario = styled.label`
  font-size: 0.55vw;
  font-weight: 400;
  line-height: 0.72vw;
  letter-spacing: 0;
  text-align: left;
`;

export const InfosUsuario = styled.ul`
  display: flex;
  width: 80%;
  margin-top: 0.7vw;
  justify-content: space-evenly;
  align-self: center;
  height: 1.55vw;
`;

export const Info = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.75vw;
  width: 25%;
  &:last-child {
    border-right: none;
  }
`;

export const InfoCenter = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.75vw;
  border-right: 0.05vw solid var(--cinza-30);
  border-left: 0.05vw solid var(--cinza-30);
  width: 25%;
  &:last-child {
    border-right: none;
    border-left: none;
  }
`;

export const InfoTitle = styled.div`
  font-weight: 400;
  font-size: 0.44vw;
  line-height: 125%;
  color: var(--cinza-50);
`;

export const InfoItem = styled.div`
  font-weight: 400;
  font-size: 0.55vw;
  line-height: 125%;
  color: var(--cinza-90);
`;
