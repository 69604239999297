import auth from '../../../api/auth';
import * as authActions from './actions';

export const fetchDeslogaUsuario = () => async (dispatch: any) => {
  try {
    const authResponse = await auth.LogOut();

    dispatch(authActions.deslogaUsuario(authResponse));

    return authResponse;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};
