import segurancaAxios from '../config/axios/SegurancaAxios';

import {
  FaturasResponse,
  FiltrosHeaderFatura,
  IAtesteFatura,
} from '../@types/interfaces/faturaApi';

class FaturaApi {
  /**
   * Get Faturas
   * @return {Promise<any>} Retorna um array de faturas vinculadas ao usuario logado.
   */
  public getFaturas = async (
    headers?: FiltrosHeaderFatura
  ): Promise<FaturasResponse> => {
    const response = await segurancaAxios.get('fatura/listar', {
      headers,
    });
    return response?.data;
  };

  public atesteFatura = async (
    ateste: IAtesteFatura
  ): Promise<IAtesteFatura | undefined> => {
    const config = {
      headers: {
        'id-contrato-mobilidade': ateste.idContrato,
      },
    };

    const body = {
      idFatura: ateste.idFatura,
      estado: ateste.estado,
      justificativa: ateste.justificativa,
    };

    const response = await segurancaAxios.post(
      'fatura/atesteFatura',
      body,
      config
    );
    return response.data;
  };
}
export default new FaturaApi();
