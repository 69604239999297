export const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const situacoesPagamento = [
  'Todas',
  'Em aberto',
  'A pagar',
  'Pago',
  'Atrasado',
].map((option: string, index: number) => {
  if (index === 0) {
    return {
      value: '',
      label: option,
    };
  }
  return {
    value: option,
    label: option,
  };
});

export const situacoesFatura = [
  'Todas',
  'Iniciada',
  'Em Processamento',
  'Concluída',
  'Com Inconsistência',
].map((optionFat: string, indexFat: number) => {
  if (indexFat === 0) {
    return {
      value: '',
      label: optionFat,
    };
  }
  return {
    value: optionFat,
    label: optionFat,
  };
});

export const mesesSelect = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
].map((option, index) => {
  return {
    value: index + 1,
    label: option,
  };
});

export const diasSemana = [
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
];

export const saudacoes = () => {
  const data = new Date();
  const hora = data.getHours();
  if (hora < 12) {
    return 'Bom dia';
  }
  if (hora < 18) {
    return 'Boa tarde';
  }
  return 'Boa noite';
};

export const anoRetrasado = {
  value: (new Date().getFullYear() - 2).toString(),
  label: (new Date().getFullYear() - 2).toString(),
};

export const anoPassado = {
  value: (new Date().getFullYear() - 1).toString(),
  label: (new Date().getFullYear() - 1).toString(),
};

export const anoAtual = {
  value: new Date().getFullYear().toString(),
  label: new Date().getFullYear().toString(),
};

export const periodicidades = [{ value: 'Mensal', label: 'Mensal' }];
