import { CardDashboardState } from '../../../@types/interfaces/cardCentroCusto';
import {
  CardCentroCustoActionTypes,
  GET_CARD_CENTRO_CUSTO,
  FILTRA_CAMPO,
} from './types';

const initialState: CardDashboardState = {
  cardCentroCustoResponse: {
    cardCentroCusto: [],
    headers: {
      'mes-referencia': '',
      'id-contrato-mobilidade': '',
      'ano-referencia': '',
    },
  },
};

const cardCentroCustoReducer = (
  state = initialState,
  action: CardCentroCustoActionTypes
) => {
  switch (action.type) {
    case GET_CARD_CENTRO_CUSTO: {
      return {
        ...state,
        cardCentroCustoResponse: {
          cardCentroCusto: action.cardCentroCustoResponse?.cardCentroCusto,
          headers: action.headers,
        },
      };
    }

    case FILTRA_CAMPO: {
      let newCardCentroCusto;
      if (action?.cardCentroCustoResponse) {
        newCardCentroCusto = {
          cardCentroCusto: action.cardCentroCustoResponse.cardCentroCusto,
          headers: action.headers,
        };
      } else {
        newCardCentroCusto = {
          ...state.cardCentroCustoResponse,
          headers: action.headers,
        };
      }
      return {
        ...state,
        cardCentroCustoResponse: newCardCentroCusto,
      };
    }

    default:
      return state;
  }
};

export default cardCentroCustoReducer;
