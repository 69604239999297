import React from 'react';

import * as Styled from './styles';

import {
  encurtadorDeTexto,
  formatarValorColaboradorTotal,
} from '../../../helpers/formatacoes';

import { CardNoData } from '../CardNoData';

import IsLoadingHOC from '../../../hoc/IsLoadingHOC';
import useCardColaborador from '../../../hooks/useCardColaborador';

import { IColaborador } from '../../../@types/interfaces/cardColaborador';

const CardColaborador = () => {
  const { cardColaboradorResponse } = useCardColaborador();

  return (
    <>
      <Styled.CardContainerColaborador>
        <Styled.TituloCardColaborador>
          <Styled.TituloColaborador>Colaboradores</Styled.TituloColaborador>
          <Styled.VejaMaisColaborador to={'/colaboradores'}>
            Veja mais
          </Styled.VejaMaisColaborador>
        </Styled.TituloCardColaborador>
        {cardColaboradorResponse?.cardColaborador.length > 0 ? (
          <>
            {cardColaboradorResponse?.cardColaborador
              ?.slice(0, 5)
              .map((item: IColaborador) => (
                <Styled.ProgressBarWrapper key={item.passageiro}>
                  <Styled.ProgressBarTitle>
                    <Styled.ProgressBarContentText>
                      <Styled.ProgressBarText>
                        {encurtadorDeTexto(item.passageiro, 20)}
                      </Styled.ProgressBarText>
                      <Styled.ProgressBarSubText>
                        {formatarValorColaboradorTotal(
                          item.valorTotal,
                          item.percentualUtilizado
                        )}
                      </Styled.ProgressBarSubText>
                    </Styled.ProgressBarContentText>
                  </Styled.ProgressBarTitle>
                  <Styled.ProgressBarDiv>
                    <Styled.ProgressBar
                      value={item.percentualUtilizado}
                      max={100}
                    />
                  </Styled.ProgressBarDiv>
                </Styled.ProgressBarWrapper>
              ))}
          </>
        ) : (
          <CardNoData
            titulo="Oops!"
            descricao="Não encontramos dados referentes à este mês para apresentar."
          />
        )}
      </Styled.CardContainerColaborador>
    </>
  );
};

export default IsLoadingHOC(CardColaborador);
