import {
  FaturasResponse,
  FiltrosHeaderFatura,
  IAtesteFatura,
  IFatura,
} from '../../../@types/interfaces/faturaApi';
import {
  ATIVA_MENU_FATURA,
  FaturaActionTypes,
  GET_FATURAS_POR_CONTRATO,
  ORDENA_NUMERO_FATURA,
  ORDENA_PERIODO,
  ORDENA_VENCIMENTO,
  ORDENA_TOTAL,
  CONTROLE_PAGINACAO,
  SET_FATURA_SELECIONADA,
  FILTRA_CAMPO,
  LIMPA_FATURAS,
  ATESTE_FATURA,
} from './types';

export function setFaturasResponse(
  faturasResponse: FaturasResponse
): FaturaActionTypes {
  return {
    type: GET_FATURAS_POR_CONTRATO,
    faturasResponse,
  };
}

export function ativaMenuFatura(fatura: IFatura): FaturaActionTypes {
  return {
    type: ATIVA_MENU_FATURA,
    fatura,
  };
}

export function ordenaNumeroFatura(
  faturasResponse: FaturasResponse,
  headers: FiltrosHeaderFatura
): FaturaActionTypes {
  return {
    type: ORDENA_NUMERO_FATURA,
    faturasResponse,
    headers,
  };
}

export function ordenaPeriodo(
  faturasResponse: FaturasResponse,
  headers: FiltrosHeaderFatura
): FaturaActionTypes {
  return {
    type: ORDENA_PERIODO,
    faturasResponse,
    headers,
  };
}

export function ordenaVencimento(
  faturasResponse: FaturasResponse,
  headers: FiltrosHeaderFatura
): FaturaActionTypes {
  return {
    type: ORDENA_VENCIMENTO,
    faturasResponse,
    headers,
  };
}
export function ordenaTotal(
  faturasResponse: FaturasResponse,
  headers: FiltrosHeaderFatura
): FaturaActionTypes {
  return {
    type: ORDENA_TOTAL,
    faturasResponse,
    headers,
  };
}

export function controlePaginacao(
  faturasResponse: FaturasResponse,
  headers: FiltrosHeaderFatura,
  numeroPagina: number
): FaturaActionTypes {
  return {
    type: CONTROLE_PAGINACAO,
    faturasResponse,
    headers,
    numeroPagina,
  };
}

export function filtraCampo(
  headers?: FiltrosHeaderFatura,
  faturasResponse?: FaturasResponse
): FaturaActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    faturasResponse,
  };
}
export function limpaFaturas(): FaturaActionTypes {
  return {
    type: LIMPA_FATURAS,
  };
}

export function setFaturaSelecionada(
  faturaSelecionada: IFatura
): FaturaActionTypes {
  return {
    type: SET_FATURA_SELECIONADA,
    faturaSelecionada,
  };
}

export function atesteFatura(ateste: IAtesteFatura): FaturaActionTypes {
  return {
    type: ATESTE_FATURA,
    ateste,
  };
}
