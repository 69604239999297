import {
  AuthActionTypes,
  AuthState,
  LOGAR,
  SET_TIPO_USUARIO,
  SET_USUARIO_LOGADO,
  SET_MODAL_COOKIE,
  DESLOGAR,
} from './types';

const initialState: AuthState = {
  auth: {
    usuarioLogado: false,
    tipoUsuario: '',
    cookieAceito: false,
  },
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    case LOGAR: {
      return {
        ...state,
        auth: action.auth,
      };
    }

    case DESLOGAR: {
      return {
        ...state,
        auth: {
          ...state.auth,
          usuarioLogado: false,
          tipoUsuario: '',
        },
      };
    }

    case SET_USUARIO_LOGADO: {
      const usuarioLogado = action.usuarioLogado;
      return {
        ...state,
        auth: {
          ...state.auth,
          usuarioLogado,
        },
      };
    }

    case SET_TIPO_USUARIO: {
      const tipoUsuario = action.tipoUsuario;
      return {
        ...state,
        auth: {
          ...state.auth,
          tipoUsuario,
        },
      };
    }

    case SET_MODAL_COOKIE: {
      return {
        ...state,
        auth: {
          ...state.auth,
          cookieAceito: action.cookieAceito,
        },
      };
    }

    default:
      return state;
  }
};

export default authReducer;
