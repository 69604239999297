import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NovoColaborador from '../pages/NovoColaborador';
import EditarColaborador from '../pages/EditarColaborador';
import Colaboradores from '../pages/Colaboradores';
import CentroDeCusto from '../pages/CentroDeCusto';
import Fatura from '../pages/Fatura';
import { NotFound } from '../pages/NotFound';
import Detalhar from '../pages/Detalhar';
import Login from '../pages/Login';
import useAuth from '../hooks/useAuth';
import Dashboard from '../pages/Dashboard';
import CadastroCentroDeCusto from '../pages/CadastroCentroDeCusto';

const _Routes: React.FC = () => {
  const { auth } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={auth.usuarioLogado ? <Navigate to="/dashboard" /> : <Login />}
      />
      {/* <Route path="/" element={<Navigate to="/login" />} /> */}
      <Route
        path="/centro-de-custo/cadastro-centro-de-custo"
        element={<CadastroCentroDeCusto />}
      />
      <Route
        path="/colaboradores/novo-colaborador"
        element={<NovoColaborador />}
      />
      <Route
        path="/colaboradores/editar-colaborador"
        element={<EditarColaborador />}
      />
      <Route path="/centro-de-custo" element={<CentroDeCusto />} />
      <Route path="/colaboradores" element={<Colaboradores />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/fatura" element={<Fatura />} />
      <Route path="/login" element={<Login />} />
      <Route path="/fatura/detalhar" element={<Detalhar />} />
      <Route path="/fatura/{id}" element={<Fatura />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default _Routes;
