import styled, { css } from 'styled-components';
import { Icon } from '../../styles/globals';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
`;

interface ThProps {
  withIcon?: boolean;
}
interface TdProps {
  hasPoints?: boolean;
}

export const WrapperConteudo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: fit-content;
`;

export const BotaoNovoColaborador = styled.button`
  width: 13.8vw;
  height: 2.23vw;
  box-shadow: 0 0.1116vw 0.1116vw rgba(0, 0, 0, 0.25);
  border-radius: 0.83vw;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1vw;
  letter-spacing: 0.011vw;
  padding: 0.5vw;
`;

export const Icone = styled.img`
  width: 1.22vw;
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 1.02vw;
  letter-spacing: 0.01vw;
`;

export const TableContent = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const TabelaColaboradores = styled.table`
  width: 108.035vw;
  max-height: 28.34vw;
  height: fit-content;
  border-collapse: collapse;
  text-align: center;
`;

export const TabelaHeadColaboradores = styled.thead`
  background-color: var(--cinza-10);
  height: 2.8vw;
`;

export const TabelaRowColaboradores = styled.tr`
  border-bottom: 0.05vw solid var(--cinza-20);
  transition: var(--cinza-20) 300ms ease-in-out;
`;

export const TabelaHeaderColaboradores = styled.th<ThProps>`
  font-weight: 400;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.03vw;
  color: var(--black);
  text-align: center;

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const TabelaBodyColaboradores = styled.tbody``;

export const TabelaDataCellColaboradores = styled.td<TdProps>`
  padding-left: 0.55vw;
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.01vw;

  ${({ hasPoints }) =>
    hasPoints &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const TabelaComIconeColaborador = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TabelaComIconeColaboradorCheck = styled.div`
  padding-left: 0.55vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperSelectorColaboradores = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-end;
  justify-content: space-between;
  margin: 2.3vw 0;
  width: 100%;
  height: fit-content;
`;

export const WrapperENome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3.5vw;
`;

export const Switch = styled.div<{ selecionado: boolean }>`
  display: flex;
  width: 2.79vw;
  height: 1.39vw;
  border: 0.16vw solid var(--white);
  justify-content: ${(props) =>
    props.selecionado ? 'flex-end' : 'flex-start'};
  background-color: ${(props) =>
    props.selecionado ? 'var(--action)' : 'var(--cinza-30)'};
  border-radius: 0.83vw;
  margin-left: 1.11vw;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
`;

export const Icons = styled(Icon)`
  margin: 0.5vw;
`;

export const WrapperFiltros = styled.div`
  display: flex;
  align-items: flex-end;
`;
