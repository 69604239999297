import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  CardResponse,
  FiltrosHeadersCard,
} from '../@types/interfaces/dashboardCardApi';

class CardDashboardApi {
  /**
   * Get Card de Resumo de Viagens
   * @return {Promise<any>} Retorna um card de resumo de viagens.
   */
  public getCard = async (
    headers?: FiltrosHeadersCard
  ): Promise<CardResponse> => {
    const response = await segurancaAxios.get('dashboard/cardResumoViagem', {
      headers,
    });

    return response?.data;
  };
}
export default new CardDashboardApi();
