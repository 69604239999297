import * as Styled from './styles';
import Modal from '../Modal';
import React from 'react';
import Close from '../../assets/icon-close-cinza.svg';
import ImgPoints from '../../assets/icon-points-borda.svg';
import ImgDownload from '../../assets/icon-dowload-borda.svg';

interface ModalAteste {
  visivel: boolean;
  onCloseRequest: () => void;
  imagem: string;
  numeroDaFatura: number;
  dataVencimento: string | undefined;
  quantidadeViagens: string;
  valorTotal: string;
  onClick: () => void;
  onAteste: () => void;
}

const ModalAteste = ({
  visivel,
  onCloseRequest,
  imagem,
  numeroDaFatura,
  dataVencimento,
  quantidadeViagens,
  valorTotal,
  onClick,
  onAteste,
}: ModalAteste) => {
  return (
    <React.Fragment>
      <Modal visivel={visivel} onCloseRequest={() => false}>
        <Styled.WrapperConteudoModal
          id="Modal"
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <Styled.ConteudoModalAteste
            transition={{ duration: 0.5, delay: 0.2 }}
            initial={{ translateY: -1000 }}
            animate={{ translateY: 0 }}
            exit={{ translateY: -1000 }}>
            <Styled.IconeFechar
              id={'Fechar'}
              onClick={() => onCloseRequest()}
              imageUrl={Close}
              width={5}
              height={10}
            />
            <Styled.WrapperConteudo>
              <Styled.ModalImg src={imagem} />
              <Styled.ModalTitulo>Atestar fatura</Styled.ModalTitulo>
              <Styled.ConferirDados>
                <Styled.ModalTextoPai>
                  Conferir dados da fatura
                </Styled.ModalTextoPai>
                <Styled.ModalTextoFilho>
                  Conferir os dados de sua fatura é de inteira importância, por
                  isso, verifique atentamente todas as informações
                  disponibilizadas.
                </Styled.ModalTextoFilho>
              </Styled.ConferirDados>
              <Styled.LinhaCinza />
              <Styled.Indicadores>Indicadores de uso: </Styled.Indicadores>
              <Styled.WrapperDetalhamento>
                <Styled.TextoIndicadores>
                  Número da fatura
                  <Styled.ValorIndicadores>
                    {numeroDaFatura}
                  </Styled.ValorIndicadores>
                </Styled.TextoIndicadores>
                <Styled.TextoIndicadores>
                  Quantidade de viagens
                  <Styled.ValorIndicadores>
                    {quantidadeViagens}
                  </Styled.ValorIndicadores>
                </Styled.TextoIndicadores>
                <Styled.TextoIndicadores>
                  Vencimento
                  <Styled.ValorIndicadores>
                    {dataVencimento}
                  </Styled.ValorIndicadores>
                </Styled.TextoIndicadores>
                <Styled.TextoIndicadores>
                  Valor total
                  <Styled.ValorIndicadores>
                    {valorTotal}
                  </Styled.ValorIndicadores>
                </Styled.TextoIndicadores>
              </Styled.WrapperDetalhamento>
              <Styled.LinhaCinza />
              <Styled.ModalTextoNota>
                <strong>Nota:</strong> O <strong>débito da fatura </strong>
                atestada ocorrerá no <strong>dia {dataVencimento}</strong>.
              </Styled.ModalTextoNota>
              <Styled.ModalTextoNotaFilho>
                Você pode conferir os detalhes dessa fatura clicando em detalhes
                da fatura no ícone de três pontinhos.
                <Styled.ModalImgPoints src={ImgPoints} />
              </Styled.ModalTextoNotaFilho>
              <Styled.ModalSegundoTextoNotaFilho>
                Se preferir, baixe a sua fatura por meio do ícone
                <Styled.ModalImgPoints src={ImgDownload} />
              </Styled.ModalSegundoTextoNotaFilho>
            </Styled.WrapperConteudo>
            <Styled.LinhaLongaCinza />
            <Styled.ButtonContainer>
              <Styled.ButtonCancelar id={'Contestar'} onClick={() => onClick()}>
                Contestar
              </Styled.ButtonCancelar>
              <Styled.ButtonAtestar id={'Atestar'} onClick={onAteste}>
                Atestar
              </Styled.ButtonAtestar>
            </Styled.ButtonContainer>
          </Styled.ConteudoModalAteste>
        </Styled.WrapperConteudoModal>
      </Modal>
    </React.Fragment>
  );
};

export default ModalAteste;
