import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import ImgOrder from '../../assets/icon-order.svg';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { IFatura } from '../../@types/interfaces/faturaApi';
import {
  formatarData,
  formatarReal,
  formatarValor,
} from '../../helpers/formatacoes';
import { Badge } from '../Badge';
import ImgDetalhe from '../../assets/icone-detalhar.svg';
import ImgDownload from '../../assets/icone-download-fatura.svg';
import ImgDownloadDesabled from '../../assets/icone-seta-cinza.svg';
import ImgPoints from '../../assets/points.svg';
import useFaturas from '../../hooks/useFaturas';
import { useNavigate } from 'react-router-dom';
import useDetalhesFatura from '../../hooks/useDetalhesFatura';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import { FaturaProps } from '../../pages/Fatura';
import ModalAteste from '../ModalAteste';
import ImgFaturaAtestada from '../../assets/icon-fatura-atestada.svg';
import ImgFaturaRecusada from '../../assets/icon-fatura-recusada.svg';
import ImgFaturaAtestar from '../../assets/icon-fatura-atestar.svg';
import ImgModalAteste from '../../assets/icon-modal-ateste.svg';
import SucessoJustificativa from '../../assets/sucesso-justificativa.svg';
import SucessoModal from '../../assets/sucesso-modal-novo-centro-custo.svg';
import ErroModal from '../../assets/erro-modal.svg';
import ModalRetornoAteste from '../ModalRetornoAteste';
import ModalJustificativaAteste from '../ModalJustificativaAteste';
import ModalRetornoContestar from '../ModalRetornoRecusa';
import useContratos from '../../hooks/useContratos';
import { FaturaActionTypes } from '../../store/ducks/faturas/types';

const TabelaFaturas = ({ setLoading }: FaturaProps) => {
  const {
    downloadXLSX,
    downloadCsv,
    downloadPDF,
    consultaDetalheFaturasPorContrato,
  } = useDetalhesFatura();

  const { contratoSelecionado } = useContratos();

  const {
    faturasResponse,
    ativaMenuFatura,
    handleOrdenaNumeroFatura,
    handleOrdenaPeriodo,
    handleOrdenaVencimento,
    handleOrdenaTotal,
    setFaturaSelecionada,
    faturaSelecionada,
    handleAtesteFatura,
    handleContestarAtesteFatura,
  } = useFaturas();

  const refsIcons: MutableRefObject<Array<HTMLDivElement | null>> = useRef([]);

  const navigation = useNavigate();

  const handleSelecionarFatura = () => {
    navigation('detalhar');
  };

  const getFaturaTooltipData = (
    fatura: IFatura,
    setModalAtesteVisivel: React.Dispatch<React.SetStateAction<boolean>>,
    setFaturaSelecionada: {
      (faturaSelecionada: IFatura): FaturaActionTypes;
      (arg0: IFatura): void;
    }
  ) => {
    if (
      fatura.estadoDaFatura === 'Iniciada' &&
      fatura.estadoDoPagamentoDaFatura === 'Em aberto'
    ) {
      return {
        title: 'Ateste indisponível: Fatura em andamento.',
        Icon: Styled.IconFaturaAtesteDesativado,
        iconSrc: ImgFaturaAtestada,
        onClick: null,
      };
    }

    if (
      fatura.estadoDaFatura === 'Concluída' &&
      fatura.estadoDoPagamentoDaFatura === 'Pago'
    ) {
      return {
        title: 'Ateste indisponível: Fatura finalizada.',
        Icon: Styled.IconFaturaAtesteDesativado,
        iconSrc: ImgFaturaAtestada,
        onClick: null,
      };
    }

    if (
      fatura.estadoDaFatura === 'Concluída' &&
      fatura.estadoDoPagamentoDaFatura === 'A pagar'
    ) {
      if (fatura.atesteFatura === 'Efetivado') {
        return {
          title: 'Fatura atestada.',
          Icon: Styled.IconFaturaAtestada,
          iconSrc: ImgFaturaAtestada,
          onClick: null,
        };
      }

      if (fatura.atesteFatura === undefined) {
        return {
          title: 'Clique para atestar fatura.',
          Icon: Styled.IconFaturaAAtestar,
          iconSrc: ImgFaturaAtestar,
          onClick: () => {
            setModalAtesteVisivel((prev) => !prev);
            setFaturaSelecionada(fatura);
          },
        };
      }

      if (fatura.atesteFatura === 'Recusado') {
        return {
          title:
            'Fatura recusada. Após averiguação, clique novamente para nova tentativa.',
          Icon: Styled.IconFaturaAAtestar,
          iconSrc: ImgFaturaRecusada,
          onClick: () => {
            setModalAtesteVisivel((prev) => !prev);
            setFaturaSelecionada(fatura);
          },
        };
      }
    }

    return null;
  };

  const [abrirOpcoesDownload, setAbrirOpcoesDownload] = useState(false);

  const [modalAtesteVisivel, setModalAtesteVisivel] = useState(false);

  const [modalSucessoVisivel, setModalSucessoVisivel] = useState(false);

  const [modalErroVisivel, setModalErroVisivel] = useState(false);

  const [modalJustificativaAteste, setModalJustificativaAteste] =
    useState(false);

  const [modalSucessoContestarVisivel, setModalSucessoContestarVisivel] =
    useState(false);

  const [modalContestarErroVisivel, setModalContestarErroVisivel] =
    useState(false);

  const abrirModalJustificativa = () => {
    setModalAtesteVisivel(false);
    setModalJustificativaAteste(true);
  };

  useEffect(() => {
    if (faturaSelecionada?.numeroFatura !== 0) {
      consultaDetalheFaturasPorContrato(setLoading).then(() => ({}));
    }
  }, [faturaSelecionada]);

  return (
    <Styled.TabelaFaturas>
      <Styled.TabelaHeadFaturas>
        <Styled.TabelaRowFaturas>
          <Styled.TabelaHeaderFaturas>
            <Styled.TabelaComIcone>
              Nº da Fatura
              <Styled.IconOrder
                id="iconeNumeroFatura"
                activated={faturasResponse.faturaOrdenada}
                src={ImgOrder}
                width={12}
                height={10}
                onClick={handleOrdenaNumeroFatura}
              />
            </Styled.TabelaComIcone>
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>
            <Styled.TabelaComIcone>
              Período
              <Styled.IconOrder
                id="iconePeriodo"
                activated={faturasResponse.periodoOrdenado}
                src={ImgOrder}
                width={12}
                height={10}
                onClick={handleOrdenaPeriodo}
              />
            </Styled.TabelaComIcone>
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>
            <Styled.TabelaComIcone>
              Vencimento
              <Styled.IconOrder
                id="iconeVencimento"
                activated={faturasResponse.vencimentoOrdenado}
                src={ImgOrder}
                width={12}
                height={10}
                onClick={handleOrdenaVencimento}
              />
            </Styled.TabelaComIcone>
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>Qtd. viagens</Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>
            <Styled.TabelaComIcone>
              Total da fatura(R$)
              <Styled.IconOrder
                id="iconeTotal"
                activated={faturasResponse.totalOrdenado}
                src={ImgOrder}
                width={12}
                height={10}
                onClick={handleOrdenaTotal}
              />
            </Styled.TabelaComIcone>
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>
            Situação da Fatura
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas>
            Situação do Pagamento
          </Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas></Styled.TabelaHeaderFaturas>
          <Styled.TabelaHeaderFaturas></Styled.TabelaHeaderFaturas>
        </Styled.TabelaRowFaturas>
      </Styled.TabelaHeadFaturas>
      <Styled.TabelaBodyFaturas>
        {faturasResponse.listaRegistro.map((fatura: IFatura, index: number) => (
          <Styled.TabelaRowFaturas key={fatura.id}>
            <Styled.TabelaDataCellFaturas>
              {fatura.numeroFatura}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              {new Date(fatura.periodoReferencia.dataFim).getMonth() ===
                new Date().getMonth() &&
              new Date(fatura.periodoReferencia.dataFim).getFullYear() ===
                new Date().getFullYear()
                ? 'Mês Atual'
                : `De ${formatarData(
                    fatura.periodoReferencia.dataInicio
                  )} à ${formatarData(fatura.periodoReferencia.dataFim)}`}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              {formatarData(fatura.dataDeVencimento)}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              {fatura.quantidadeViagem}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              {formatarValor(fatura.valorTotalDaFatura)}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              <Badge texto={fatura.estadoDaFatura} />
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              <Badge texto={fatura.estadoDoPagamentoDaFatura} />
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas>
              {(() => {
                const tooltipData = getFaturaTooltipData(
                  fatura,
                  setModalAtesteVisivel,
                  setFaturaSelecionada
                );
                return tooltipData ? (
                  <Tooltip
                    title={tooltipData.title}
                    position="top"
                    arrow={true}
                    arrowSize="regular">
                    <tooltipData.Icon
                      src={tooltipData.iconSrc}
                      onClick={
                        tooltipData.onClick
                          ? () => tooltipData.onClick()
                          : undefined
                      }
                    />
                  </Tooltip>
                ) : null;
              })()}
            </Styled.TabelaDataCellFaturas>
            <Styled.TabelaDataCellFaturas hasPoints>
              <Styled.IconContent
                id={`Menu-${index}`}
                ref={(ref) => {
                  refsIcons.current[index] = ref;
                }}
                activated={fatura.menuAtivado}
                onClick={() => ativaMenuFatura(fatura)}>
                <Styled.Menu activated={fatura.menuAtivado}>
                  <Styled.MenuList>
                    <Styled.MenuItem>
                      <Styled.MenuRow
                        id={`Menu-Detalhar-${index}`}
                        onClick={() => {
                          setLoading(true);
                          setFaturaSelecionada(fatura);
                          handleSelecionarFatura();
                        }}>
                        <Styled.IconDetalhe src={ImgDetalhe} />
                        <Styled.BotaoRedirect>Detalhar</Styled.BotaoRedirect>
                      </Styled.MenuRow>
                    </Styled.MenuItem>
                    <Styled.MenuItem>
                      <Styled.MenuRow
                        id={`Menu-Download-${index}`}
                        onClick={() => {
                          setFaturaSelecionada(fatura);
                          setAbrirOpcoesDownload(!abrirOpcoesDownload);
                          ativaMenuFatura(fatura);
                        }}>
                        <Styled.IconDetalhe src={ImgDownload} />
                        <Styled.BotaoRedirect>Download</Styled.BotaoRedirect>
                        <Styled.MenuDownload activated={abrirOpcoesDownload}>
                          <Styled.MenuListDownload>
                            <Styled.MenuItemDownload
                              id={`Menu-csv-${index}`}
                              disabled={fatura.quantidadeViagem === 0}
                              onClick={() => downloadCsv(setLoading)}>
                              {fatura.quantidadeViagem === 0 ? (
                                <Tooltip
                                  title="Não há viagens disponíveis para detalhamento da fatura"
                                  position="bottom"
                                  arrow={true}
                                  arrowSize="regular">
                                  <Styled.MenuRowDownload>
                                    <Styled.IconDetalhe
                                      src={ImgDownloadDesabled}
                                    />
                                    <Styled.BotaoRedirect>
                                      CSV
                                    </Styled.BotaoRedirect>
                                  </Styled.MenuRowDownload>
                                </Tooltip>
                              ) : (
                                <Styled.MenuRowDownload>
                                  <Styled.IconDetalhe src={ImgDownload} />
                                  <Styled.BotaoRedirect>
                                    CSV
                                  </Styled.BotaoRedirect>
                                </Styled.MenuRowDownload>
                              )}
                            </Styled.MenuItemDownload>
                            <Styled.MenuItemDownload
                              id={`Menu-pdf-${index}`}
                              disabled={
                                fatura.estadoDaFatura !== 'Concluída' ||
                                fatura.quantidadeViagem === 0
                              }
                              onClick={() => downloadPDF(setLoading)}>
                              {fatura.estadoDaFatura !== 'Concluída' &&
                                fatura.quantidadeViagem > 0 && (
                                  <Tooltip
                                    title="O download do PDF estará disponível após o fechamento da fatura."
                                    position="bottom"
                                    arrow={true}
                                    arrowSize="regular">
                                    <Styled.MenuRowDownload>
                                      <Styled.IconDetalhe
                                        src={ImgDownloadDesabled}
                                      />
                                      <Styled.BotaoRedirect>
                                        PDF
                                      </Styled.BotaoRedirect>
                                    </Styled.MenuRowDownload>
                                  </Tooltip>
                                )}
                              {fatura.quantidadeViagem === 0 && (
                                <Tooltip
                                  title="Não há viagens disponíveis para detalhamento da fatura"
                                  position="bottom"
                                  arrow={true}
                                  arrowSize="regular">
                                  <Styled.MenuRowDownload>
                                    <Styled.IconDetalhe
                                      src={ImgDownloadDesabled}
                                    />
                                    <Styled.BotaoRedirect>
                                      PDF
                                    </Styled.BotaoRedirect>
                                  </Styled.MenuRowDownload>
                                </Tooltip>
                              )}
                              {fatura.estadoDaFatura === 'Concluída' &&
                                fatura.quantidadeViagem > 0 && (
                                  <Styled.MenuRowDownload>
                                    <Styled.IconDetalhe src={ImgDownload} />
                                    <Styled.BotaoRedirect>
                                      PDF
                                    </Styled.BotaoRedirect>
                                  </Styled.MenuRowDownload>
                                )}
                            </Styled.MenuItemDownload>
                            <Styled.MenuItemDownload
                              id={`Menu-xlsx-${index}`}
                              disabled={fatura.quantidadeViagem === 0}
                              onClick={() => downloadXLSX(setLoading)}>
                              {fatura.quantidadeViagem === 0 ? (
                                <Tooltip
                                  title="Não há viagens disponíveis para detalhamento da fatura."
                                  position="bottom"
                                  arrow={true}
                                  arrowSize="regular">
                                  <Styled.MenuRowDownload>
                                    <Styled.IconDetalhe
                                      src={ImgDownloadDesabled}
                                    />
                                    <Styled.BotaoRedirect>
                                      Excel
                                    </Styled.BotaoRedirect>
                                  </Styled.MenuRowDownload>
                                </Tooltip>
                              ) : (
                                <Styled.MenuRowDownload>
                                  <Styled.IconDetalhe src={ImgDownload} />
                                  <Styled.BotaoRedirect>
                                    Excel
                                  </Styled.BotaoRedirect>
                                </Styled.MenuRowDownload>
                              )}
                            </Styled.MenuItemDownload>
                          </Styled.MenuListDownload>
                        </Styled.MenuDownload>
                      </Styled.MenuRow>
                    </Styled.MenuItem>
                  </Styled.MenuList>
                </Styled.Menu>
                <Styled.IconPoints
                  id={`icon-points-${index}`}
                  onClick={() => setAbrirOpcoesDownload(false)}
                  imageUrl={ImgPoints}
                  width={4}
                  height={18}
                />
              </Styled.IconContent>
            </Styled.TabelaDataCellFaturas>
          </Styled.TabelaRowFaturas>
        ))}
      </Styled.TabelaBodyFaturas>
      <ModalAteste
        visivel={modalAtesteVisivel}
        numeroDaFatura={faturaSelecionada?.numeroFatura}
        dataVencimento={formatarData(faturaSelecionada?.dataDeVencimento)}
        quantidadeViagens={`${faturaSelecionada?.quantidadeViagem} viagens`}
        valorTotal={formatarReal(faturaSelecionada?.valorTotalDaFatura)}
        imagem={ImgModalAteste}
        onCloseRequest={() => setModalAtesteVisivel(false)}
        onClick={() => abrirModalJustificativa()}
        onAteste={(): void => {
          handleAtesteFatura(
            contratoSelecionado.id,
            faturaSelecionada.id,
            setModalSucessoVisivel,
            setModalErroVisivel,
            setLoading
          ).then();
          setModalAtesteVisivel(false);
        }}
      />
      <ModalRetornoAteste
        visivel={modalSucessoVisivel}
        onCloseRequest={() => navigation('/fatura')}
        imagem={SucessoModal}
        textoPai="Processamento concluído!"
        textoFilho="Registros sendo atualizados..."
        labelBotao="Ok"
        onClick={() => setModalSucessoVisivel(false)}
      />
      <ModalRetornoAteste
        visivel={modalErroVisivel}
        onCloseRequest={() => navigation('/fatura')}
        imagem={ErroModal}
        textoPai="Erro no processamento!"
        textoFilho="Tente novamente em instantes..."
        labelBotao="Ok"
        onClick={() => setModalErroVisivel(false)}
      />
      <ModalJustificativaAteste
        visivel={modalJustificativaAteste}
        onCloseRequest={() => setModalJustificativaAteste(false)}
        onContestar={(texto: string): void => {
          handleContestarAtesteFatura(
            contratoSelecionado.id,
            faturaSelecionada.id,
            texto,
            setModalSucessoContestarVisivel,
            setModalContestarErroVisivel,

            setLoading
          ).then();
          setModalJustificativaAteste(false);
        }}
        onClick={() => setModalJustificativaAteste(false)}
      />
      <ModalRetornoContestar
        visivel={modalSucessoContestarVisivel}
        onCloseRequest={() => navigation('/fatura')}
        imagem={SucessoJustificativa}
        textoPai="Justificativa enviada!"
        textoFilho="A fatura retornará para conferência de dados e em breve estará disponível."
        labelBotao="Ok"
        onClick={() => setModalSucessoContestarVisivel(false)}
      />
      <ModalRetornoContestar
        visivel={modalContestarErroVisivel}
        onCloseRequest={() => navigation('/fatura')}
        imagem={ErroModal}
        textoPai="Erro no processamento!"
        textoFilho="Tente novamente em instantes..."
        labelBotao="Ok"
        onClick={() => setModalContestarErroVisivel(false)}
      />
    </Styled.TabelaFaturas>
  );
};

export default IsLoadingHOC(TabelaFaturas);
