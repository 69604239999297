import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Icon } from '../../styles/globals';

interface TabProps {
  selecionadoTab?: string;
  selecionadoTexto?: string;
}

interface MapaProps {
  justifyContent: string;
}

export const ConteudoModalViagem = styled(motion.div)`
  background-color: var(--white);
  height: 36.83vw;
  width: 40vw;
  padding: 2.51vw 1.67vw;
  position: relative;
  .leaflet-control-attribution {
    display: none;
  }
`;

export const WrapperConteudoModal = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 100%;
    align-items: flex-end;
  }
`;

export const WrapperConteudo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  .leaflet-tooltip-bottom {
    height: auto;
  }
`;

export const TituloModal = styled.h1`
  font-size: 1.78vw;
  color: var(--action);
`;

export const SituacaoUsuario = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const WrapperUsuarioDetalhes = styled.div`
  display: flex;
  margin-top: 1.11vw;
  margin-bottom: 1.11vw;
  align-items: center;
`;

export const FotoUsuarioDetalhes = styled.div`
  padding: 0.83vw;
  background-color: var(--action);
  border-radius: 0.44vw;
  color: var(--white);
  font-weight: 500;
  font-size: 1.22vw;
  margin-right: 1.11vw;
  display: flex;
  align-items: center;
  height: fit-content;
`;

export const NomeCompletoUsuarioDetalhes = styled.div`
  height: fit-content;
`;

export const NomeUsuarioDetalhes = styled.p`
  font-size: 1.39vw;
  font-weight: 500;
`;

export const EmpresaUsuario = styled.p`
  font-size: 0.78vw;
  font-weight: 500;
  color: var(--cinza-60);
`;

export const ConteudoUsuarioDetalhes = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TabContent = styled.div<TabProps>`
  border-bottom: 0.111vw solid ${({ selecionadoTab }) => `${selecionadoTab}`};
  width: 50%;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.55vw;
`;

export const TabText = styled.p<TabProps>`
  color: ${({ selecionadoTexto }) => `${selecionadoTexto}`};
  font-weight: 500;
  margin-bottom: 0.27vw;
`;

export const DetalhesConteudoUsuario = styled.div`
  width: 100%;
`;

export const DetalhesInternoUsuario = styled.div`
  width: 100%;
  display: flex;
`;

export const TextoUsuarioDetalhes = styled.p`
  margin-top: 0.55vw;
  font-size: 0.78vw;
  width: 8.92vw;
  min-width: 8.92vw;
  word-break: break-all;
`;

export const TextoUsuarioLargoDetalhes = styled.p`
  margin-top: 0.55vw;
  font-size: 0.78vw;
  max-width: 100%;
  word-break: break-all;
`;

export const IconeFechar = styled(Icon)`
  margin-left: 0;
  margin-right: 0.55vw;
  position: absolute;
  right: 1.67vw;
  padding: 0.55vw;
  border-radius: 0.446vw;
  margin-top: 0.55vw;
  cursor: pointer;
`;

export const IconeOrigem = styled(Icon)`
  margin-left: 0;
  margin-right: 0.558vw;
  padding: 0.558vw;
  border-radius: 0.446vw;
  cursor: pointer;
`;

export const IconeDestino = styled(Icon)`
  margin-left: 0;
  margin-right: 0.558vw;
  padding: 0.558vw;
  border-radius: 0.446vw;
  cursor: pointer;
`;

export const BotaoProblema = styled.div`
  color: var(--white);
  background-color: var(--red);
  font-weight: 500;
  padding: 0.558vw 0.837vw;
  border-radius: 0.446vw;
  cursor: pointer;
  right: 1.67vw;
  bottom: 1.67vw;
  height: 2.23vw;
  display: flex;
  align-items: center;
`;

export const MailTo = styled.a`
  color: var(--white);
  font-size: 0.78vw;
`;

export const WrapperMap = styled.div``;

export const OrigemDestino = styled.div``;

export const WrapperBotaoMarcas = styled.div<MapaProps>`
  position: absolute;
  bottom: 1.11vw;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: 90%;
  height: fit-content;
`;

export const WrapperOrigem = styled.div`
  display: flex;
`;

export const WrapperDestino = styled.div`
  display: flex;
  align-items: center;
`;

export const TextoOrigemMapa = styled.p`
  font-size: 0.78vw;
`;

export const TextoDestinoMapa = styled.p`
  font-size: 0.78vw;
`;

export const Oi = styled.p`
  font-size: 0.78vw;
  color: red;
`;

export const Tchau = styled.p`
  font-size: 0.78vw;
  color: blue;
`;
