/* eslint-disable no-unused-vars */
import { CardDashboardState } from '../../../@types/interfaces/dashboardCardApi';
import { CardActionTypes, GET_CARD_DASHBOARD, FILTRA_CAMPO } from './types';

const initialState: CardDashboardState = {
  cardResumoResponse: {
    cardResumoViagem: {
      viagensRealizadas: 0,
      ticketMedio: 0,
      totalGasto: 0,
      parceiro: [],
    },
    headers: {
      'mes-referencia': '',
      'id-contrato-mobilidade': '',
      'ano-referencia': '',
    },
  },
};

const cardReducer = (state = initialState, action: CardActionTypes) => {
  switch (action.type) {
    case GET_CARD_DASHBOARD: {
      return {
        ...state,
        cardResumoResponse: {
          cardResumoViagem: action.cardResumoResponse?.cardResumoViagem,
          headers: action.headers,
        },
      };
    }

    case FILTRA_CAMPO: {
      let newCard;
      if (action?.cardResumoResponse) {
        newCard = {
          cardResumoViagem: action.cardResumoResponse.cardResumoViagem,
          headers: action.headers,
        };
      } else {
        newCard = {
          ...state.cardResumoResponse,
          headers: action.headers,
        };
      }
      return {
        ...state,
        cardResumoResponse: newCard,
      };
    }

    default:
      return state;
  }
};

export default cardReducer;
