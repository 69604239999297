import moment from 'moment';
import colors from '../styles/colors';
import { diasSemana, meses } from './constantes';
import { ParceiroEnum } from '../@types/enums/Parceiro.enum';
import { MascaraParceiroEnum } from '../@types/enums/MascaraParceiro.enum';

export const formatarValor = (valor: number): string => {
  return valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 });
};

export const formatarReal = (valor: number): string => {
  return valor?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export const formatarData = (data: Date): string | undefined => {
  if (String(data).includes('T')) {
    const utcOffset = moment(data.toString()).utcOffset(data.toString());
    const dataFormatada = moment(utcOffset).format('DD/MM/yyyy');

    return `${dataFormatada}`;
  }
  const [ano, mes, dia] = String(data).split('-');
  return `${dia}/${mes}/${ano}`;
};

export const formatarDataDiaEMes = (data: Date): string | undefined => {
  if (data) {
    const utcOffset = moment(data.toString()).utcOffset(data.toString());
    const dataFormatada = moment(utcOffset).format('DD/MM');
    return `${dataFormatada}`;
  }
};

export const formatarDataComHora = (data: Date): string | undefined => {
  if (data) {
    const utcOffset = moment(data.toString()).utcOffset(data.toString());
    const dataFormatada = moment(utcOffset).format('DD/MM/yyyy HH:mm');
    return `${dataFormatada}`;
  }
};

export const formatarDataComHifen = (data: Date): string | undefined => {
  if (data) {
    const utcOffset = moment(data.toString()).utcOffset(data.toString());
    const dataFormatada = moment(utcOffset).format('yyyy-MM-DD');
    return `${dataFormatada}`;
  }
};

export const formatarDataInternacional = (dataNascimento?: string) => {
  const dia = dataNascimento?.split('/')[0];
  const mes = dataNascimento?.split('/')[1];
  const ano = dataNascimento?.split('/')[2];

  return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
};

export const formatarDataSemDia = (data: Date): string | undefined => {
  if (data) {
    const dataFormatada = moment(data).format('MM/yy');
    return `${dataFormatada}`;
  }
};

export const formatarDataMesEAno = (data: Date): string | undefined => {
  if (data) {
    const dataFormatada = moment(data).format('MM/YY');
    return `${dataFormatada}`;
  }
};

export const formatarDataAtualExtensa = () => {
  const data = new Date();
  const diaSemana = formatarDiaSemana(data.getDay());
  const dia = data.getDate();
  const mes = formatarMes(data.getMonth());
  const ano = data.getFullYear();
  return `${diaSemana}, ${dia} de ${mes} de ${ano}`;
};

export const formatarDataExtensa = (data: string) => {
  const newData = new Date(data);
  const dia = newData.getDate();
  const mes = formatarMes(newData.getMonth());
  const ano = newData.getFullYear();
  return `${dia} de ${mes} de ${ano}`;
};

export const formatarDiaSemana = (dia: number) => {
  for (let i = 0; i < diasSemana.length; i++) {
    if (i === dia - 1) {
      return diasSemana[i];
    }
  }
};

export const formatarMes = (mes: number) => {
  for (let i = 0; i < meses.length; i++) {
    if (i === mes) {
      return meses[i];
    }
  }
};

export const formatarTextoMaiusculo = (texto: string) => {
  return texto.charAt(0).toUpperCase() + texto.slice(1);
};

export const formatarNomeParceiro = (texto: string) => {
  const parceiroMap: { [key: string]: string } = {
    [ParceiroEnum.noveNovePop]: MascaraParceiroEnum.novenoveapp,
    [ParceiroEnum.transpataxi]: MascaraParceiroEnum.transpataxi,
  };

  return parceiroMap[texto] || texto.charAt(0).toUpperCase() + texto.slice(1);
};
export const formatarCpf = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
};

export const removerMascara = (campo: string) => {
  campo = campo?.replace(/\D+/g, '');
  return campo;
};

export const formatarValorRestante = (total: number, utilizado: number) => {
  let percentualRestante = '0%';
  const valorRestante = total - utilizado;

  if (valorRestante > 0 && total > 0) {
    percentualRestante = Math.round((valorRestante / total) * 100)
      .toString()
      .concat('%');
  }

  let valorFormatado = 'R$0,00 (0%)';

  if (valorRestante >= 0) {
    valorFormatado = valorRestante.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    valorFormatado = valorFormatado + ' (' + percentualRestante + ')';
  }

  return valorFormatado;
};

export const regex = {
  email:
    /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@((\\d{1,3}\\.){3}\\d{1,3}|([a-z0-9.-]+.+[a-z]{2,}))$/i,
  celular: /(\(?\d{2}\)?\s)?(\d{4,5}-\d{3,4})/i,
  dataNascimento: /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3}$/i,
};

export const formatarValorRestanteCardCentroCusto = (
  valorGasto: number,
  porcentagemGasta: number
) => {
  const valorFormatado = valorGasto.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const porcentagemFormatada = porcentagemGasta.toString().replace('.', ',');

  return `${valorFormatado} (${porcentagemFormatada}%)`;
};

export const formatarValorColaboradorTotal = (
  total: number,
  porcentagem: number
) => {
  let valorFormatado = 'R$0,00 (0%)';

  if (total >= 0) {
    valorFormatado = total.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    valorFormatado = valorFormatado + ' (' + porcentagem + '%)';
  }

  return valorFormatado;
};

export const setarBackground = (total: number, utilizado: number) => {
  const restante = total - utilizado;
  if (restante > 0 && total > 0) {
    const percentualRestante = (restante / total) * 100;
    if (percentualRestante <= 10) {
      return colors.red;
    }
  }
  if (total === 0 || restante === 0) {
    return colors.red;
  }
  return colors.green;
};

export const encurtadorDeTexto = (texto: string, limite: number) => {
  if (texto) {
    const removerPalavra = texto.replace(/(\w+\+)\w+\s/, '');

    const novoTexto = removerPalavra.substring(0, limite).trim();
    if (texto.length < limite) {
      return novoTexto;
    }
    return `${novoTexto}...`;
  }
};

export const primeiraUltimaLetraNome = (nome: string) => {
  const regex: RegExpMatchArray | null = nome.match(/([A-Z])/g);
  if (nome && regex) {
    const acronimo = regex.join('');
    const nomeFormatado =
      acronimo.charAt(0) + acronimo.charAt(acronimo.length - 1);

    if (acronimo.length === 1) {
      return acronimo.charAt(0).toUpperCase();
    }

    return nomeFormatado.toUpperCase();
  }
  return '?';
};

export const primeiroUltimoNome = (nome: string) => {
  const primeiroNome = nome.split(' ').slice(0, 1).join(' ');
  const sobrenome = nome.split(' ').slice(-1).join(' ');
  return `${primeiroNome} ${sobrenome}`;
};

export const trocarNomeNoveNove = (parceiro: string) => {
  if (parceiro == 'NoveNovePop' || parceiro == 'noveNovePop') {
    return '99Pop';
  }
  return formatarTextoMaiusculo(parceiro);
};

export const corrigirNomeParceiro = (nome: string) => {
  if (nome === '99Pop') {
    return 'noveNovePop';
  }
  return nome.toLowerCase();
};

export const filtrarData = (
  data: Date,
  handleFiltraData: (dataStr: string) => void,
  setData: (data: Date) => void
) => {
  const novaData: string | undefined = formatarDataComHifen(data);
  const dataVazia = '';
  if (novaData && novaData?.length > 0) {
    handleFiltraData(novaData);
  }
  if (data === null) {
    handleFiltraData(dataVazia);
  }
  setData(data);
};

export const orcamentoParaNumber = (orcamento: string) => {
  return parseFloat(orcamento.replace('.', '').replace(',', '.'));
};
