import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  background-color: var(--white);
  padding: 1.15vw 0 0 0;
  flex-direction: column;
  height: 20vw;
  width: 17vw;
  margin-top: 1.52vw;
  box-shadow: 0.111vw 0.111vw 0.39vw 0.167vw var(--cinza-30);
  border-radius: 0.223vw;
`;

export const CardContent = styled.div`
  width: 100%;
`;

export const WrapperIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.4vw;
  justify-content: space-between;
  padding: 0 3vw 0 3vw;
  height: fit-content;
`;

export const IconTrips = styled.img`
  margin: 0;
  cursor: default;
  width: 2.8vw;
  height: 2.8vw;
`;

export const IconOperators = styled.img`
  margin: 0.5vw 0.3vw 0.3vw 0.3vw;
  cursor: default;
  border-radius: 5.58vw;
  color: var(--action);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vw;
  width: 3vw;
`;

export const NumberTrips = styled.p`
  margin: 0 0.2vw 0 0.36vw;
  font-weight: 500;
  font-size: 2vw;
  line-height: 1.2vw;
  text-align: center;
`;

export const SupportTripsText = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 1vw;
  line-height: 0.94vw;
  letter-spacing: 0.01vw;
  mix-blend-mode: normal;
  color: var(--cinza-50);
  height: fit-content;
`;

export const SupportTripsDoneText = styled.p`
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.2vw;
  letter-spacing: 0.01vw;
  mix-blend-mode: normal;
  color: var(--cinza-50);
`;

export const WrapperAmount = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 1vw 0 0 0;
  height: fit-content;
`;

export const TextMoney = styled.p`
  font-size: 1.5vw;
  margin-top: 0.8vw;
  margin-bottom: 0.8vw;
  color: var(--blueLabel);
  font-weight: 500;
`;

export const SpanReal = styled.span`
  color: var(--blueLabel);
  font-weight: 500;
  font-size: 1.04vw;
`;

export const Line = styled.hr`
  color: var(--cinza-5);
  opacity: 70%;
  margin-top: 1.2vw;
  margin-bottom: 1.2vw;
  width: 80%;
`;

export const WrapperOperators = styled.div`
  display: flex;
  margin-top: 0.8vw;
  margin-left: 3vw;
  margin-right: 3vw;
  height: fit-content;
`;

export const WrapperOperatorsIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Titulo = styled.p`
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 0.85vw;
  letter-spacing: 0;
  text-align: left;
  color: var(--blueLabel);
  height: fit-content;
`;

export const CardDashboardNoData = styled.div`
  padding: 0 0.78vw;
`;
