import React, { ReactElement, useEffect, useState } from 'react';
import MarcaOrigem from '../../assets/marca-origem.svg';
import MarcaDestino from '../../assets/marca-destino.svg';
import * as Styled from './styles';
import Modal from '../Modal';
import {
  formatarDataComHora,
  formatarReal,
  primeiraUltimaLetraNome,
  primeiroUltimoNome,
  trocarNomeNoveNove,
} from '../../helpers/formatacoes';
import { Badge } from '../Badge';
import useDetalheViagem from '../../hooks/useDetalheViagem';
import Close from '../../assets/close-spid.svg';
import { CardNoData } from '../Cards/CardNoData';
import useErro from '../../hooks/useErro';

import { Erro } from '../Erro';
import { useNavigate } from 'react-router-dom';

import { MapaDetalheFatura } from './MapaDetalheFatura';
import { CotacaoFatura } from '../CotacaoFatura';
import Avaliacao from '../AvaliacaoEstrelas';

interface Props {
  modalVisivel: boolean;
  onClose: () => void;
  setLoading: (value: boolean) => void;
}

function ModalDetalharFatura({
  modalVisivel,
  onClose,
  setLoading,
}: Props): ReactElement {
  const {
    consultaDetalheViagem,
    detalheViagemSelecionado,
    detalheViagemResponse,
  } = useDetalheViagem();
  const { codigoErro, limpaErro } = useErro();
  const [tabSelecionada, setTabSelecionada] = useState<number>(1);
  const mailTo = `mailto:mobilidade@bb.com.br?subject=SPID - Contestação de Viagem ${detalheViagemSelecionado?.numeroIdentificacaoViagem}`;
  const navigate = useNavigate();
  useEffect(() => {
    setTabSelecionada(1);
    if (modalVisivel) {
      consultaDetalheViagem(setLoading).then(() => ({}));
    }
  }, [detalheViagemSelecionado]);

  const hasDetalheViagem =
    detalheViagemResponse && Object.values(detalheViagemResponse).length > 0;

  const isSelectedTab1 = tabSelecionada === 1;
  const isSelectedTab2 = tabSelecionada === 2;
  const isSelectedTab3 = tabSelecionada === 3;

  const renderTabsContent = () => {
    if (isSelectedTab1) {
      return (
        <Styled.ConteudoUsuarioDetalhes>
          <Styled.DetalhesConteudoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Parceiro:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {trocarNomeNoveNove(detalheViagemResponse.nomeParceiro)}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Passageiro:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.nomeColaborador}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>Email:</Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.emailColaborador}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Código da viagem:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.codigoViagem}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>Valor:</Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {formatarReal(detalheViagemResponse.valorViagem)}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Distância:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {`${detalheViagemResponse.distancia} km`}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>Origem:</Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.coordenadas.tituloOrigemViagem}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Destino:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.coordenadas.tituloDestinoViagem}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Início da viagem:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {formatarDataComHora(detalheViagemResponse.dataInicio)}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Fim da viagem:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {formatarDataComHora(detalheViagemResponse.dataFim)}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Centro de custo:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.nomeCentroCusto}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Justificativa:
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                {detalheViagemResponse.justificativa}
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
            <Styled.DetalhesInternoUsuario>
              <Styled.TextoUsuarioDetalhes>
                Avaliação
              </Styled.TextoUsuarioDetalhes>
              <Styled.TextoUsuarioLargoDetalhes>
                <Avaliacao
                  value={detalheViagemResponse.avaliacao.nota}
                  descricao={
                    detalheViagemResponse.avaliacao == null || undefined
                      ? 'Não há avaliação para esta viagem.'
                      : detalheViagemResponse.avaliacao.motivo
                  }
                />
              </Styled.TextoUsuarioLargoDetalhes>
            </Styled.DetalhesInternoUsuario>
          </Styled.DetalhesConteudoUsuario>
        </Styled.ConteudoUsuarioDetalhes>
      );
    } else if (isSelectedTab2) {
      return <MapaDetalheFatura />;
    } else {
      return <CotacaoFatura />;
    }
  };

  return (
    <React.Fragment>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Modal visivel={modalVisivel} onCloseRequest={() => false}>
          <Styled.WrapperConteudoModal
            transition={{ duration: 0.2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <Styled.ConteudoModalViagem
              transition={{ duration: 0.5, delay: 0.2 }}
              initial={{ translateY: -1000 }}
              animate={{ translateY: 0 }}
              exit={{ translateY: -1000 }}>
              <Styled.IconeFechar
                onClick={() => onClose()}
                imageUrl={Close}
                width={5}
                height={10}
              />
              <Styled.WrapperConteudo>
                <Styled.TituloModal>Detalhes da viagem</Styled.TituloModal>
                {hasDetalheViagem &&
                Object.values(detalheViagemResponse).length > 0 ? (
                  <React.Fragment>
                    <Styled.SituacaoUsuario>
                      <Styled.WrapperUsuarioDetalhes>
                        <Styled.FotoUsuarioDetalhes>
                          {primeiraUltimaLetraNome(
                            detalheViagemResponse.nomeColaborador
                          )}
                        </Styled.FotoUsuarioDetalhes>
                        <Styled.NomeCompletoUsuarioDetalhes>
                          <Styled.NomeUsuarioDetalhes>
                            {primeiroUltimoNome(
                              detalheViagemResponse.nomeColaborador
                            )}
                          </Styled.NomeUsuarioDetalhes>
                          <Styled.EmpresaUsuario>
                            {detalheViagemResponse.empresa}
                          </Styled.EmpresaUsuario>
                        </Styled.NomeCompletoUsuarioDetalhes>
                      </Styled.WrapperUsuarioDetalhes>
                      <Badge texto="Aprovado" />
                    </Styled.SituacaoUsuario>
                    <Styled.Tabs>
                      <Styled.TabContent
                        onClick={() => setTabSelecionada(1)}
                        selecionadoTab={
                          isSelectedTab1 ? 'var(--action)' : 'var(--cinza-40)'
                        }>
                        <Styled.TabText
                          selecionadoTexto={
                            isSelectedTab1
                              ? 'var(--action)'
                              : 'var(--txtDefault)'
                          }>
                          Informações
                        </Styled.TabText>
                      </Styled.TabContent>
                      <Styled.TabContent
                        onClick={() => setTabSelecionada(2)}
                        selecionadoTab={
                          isSelectedTab2 ? 'var(--action)' : 'var(--cinza-40)'
                        }>
                        <Styled.TabText
                          selecionadoTexto={
                            isSelectedTab2
                              ? 'var(--action)'
                              : 'var(--txtDefault)'
                          }>
                          Mapa
                        </Styled.TabText>
                      </Styled.TabContent>
                      <Styled.TabContent
                        onClick={() => setTabSelecionada(3)}
                        selecionadoTab={
                          isSelectedTab3 ? 'var(--action)' : 'var(--cinza-40)'
                        }>
                        <Styled.TabText
                          selecionadoTexto={
                            isSelectedTab3
                              ? 'var(--action)'
                              : 'var(--txtDefault)'
                          }>
                          Cotações
                        </Styled.TabText>
                      </Styled.TabContent>
                    </Styled.Tabs>
                    {renderTabsContent()}
                  </React.Fragment>
                ) : (
                  <CardNoData titulo="Oops!" descricao="nao" />
                )}
              </Styled.WrapperConteudo>
              <Styled.WrapperBotaoMarcas
                justifyContent={
                  tabSelecionada === 2 ? 'space-between' : 'flex-end'
                }>
                {tabSelecionada === 2 && (
                  <Styled.OrigemDestino>
                    <Styled.WrapperOrigem>
                      <Styled.IconeOrigem
                        onClick={() => onClose()}
                        imageUrl={MarcaOrigem}
                        width={5}
                        height={10}
                      />
                      <Styled.TextoOrigemMapa>
                        {detalheViagemResponse.coordenadas.tituloOrigemViagem}
                      </Styled.TextoOrigemMapa>
                    </Styled.WrapperOrigem>
                    <Styled.WrapperDestino>
                      <Styled.IconeDestino
                        onClick={() => onClose()}
                        imageUrl={MarcaDestino}
                        width={5}
                        height={10}
                      />
                      <Styled.TextoDestinoMapa>
                        {detalheViagemResponse.coordenadas.tituloDestinoViagem}
                      </Styled.TextoDestinoMapa>
                    </Styled.WrapperDestino>
                  </Styled.OrigemDestino>
                )}
                <Styled.BotaoProblema>
                  <Styled.MailTo href={mailTo}>APONTAR PROBLEMA</Styled.MailTo>
                </Styled.BotaoProblema>
              </Styled.WrapperBotaoMarcas>
            </Styled.ConteudoModalViagem>
          </Styled.WrapperConteudoModal>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default ModalDetalharFatura;
