import { ReactElement } from 'react';
import * as Styled from './styles';
import Img from '../../assets/notfound.svg';
import useErro from '../../hooks/useErro';
import { useNavigate } from 'react-router-dom';

interface IErro {
  codigo: string;
  textoBotao: string;
  onClick?: () => void;
}

export const Erro = ({ textoBotao, onClick }: IErro): ReactElement => {
  const { codigoErro } = useErro();
  const navigate = useNavigate();

  const mensagemDeErro = (codigoDoErro: string) => {
    if (codigoDoErro === '107') {
      navigate('/login');
    }
    if (codigoDoErro === '110') {
      return (
        <Styled.SubTitle>
          Houve um erro na autenticação. <br /> Por favor, tente refazer sua
          autenticação.
        </Styled.SubTitle>
      );
    }
    return (
      <Styled.SubTitle>
        Algo de errado aconteceu. <br />
        Por favor, tente refazer sua autenticação ou entre em contato com o
        canal:
        <br />
        <Styled.LinkEmail href="mailto:mobilidade@bb.com.br">
          mobilidade@bb.com.br
        </Styled.LinkEmail>
      </Styled.SubTitle>
    );
  };

  return (
    <Styled.ErroContainer>
      <Styled.Img imageUrl={Img} />
      <Styled.Title>Ops!</Styled.Title>
      {mensagemDeErro(codigoErro)}
      <Styled.Text>
        Código de erro: <b>{codigoErro}</b>
      </Styled.Text>
      <Styled.Botao onClick={onClick}>{textoBotao}</Styled.Botao>
    </Styled.ErroContainer>
  );
};
