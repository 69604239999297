import {
  FaturasResponse,
  FiltrosHeaderFatura,
  IAtesteFatura,
  IFatura,
} from '../../../@types/interfaces/faturaApi';

export const GET_FATURAS_POR_CONTRATO = 'FATURAS/GET_FATURAS_POR_CONTRATO';
export const ATIVA_MENU_FATURA = 'FATURAS/ATIVA_MENU_FATURA';
export const ORDENA_NUMERO_FATURA = 'FATURAS/ORDENA_NUMERO_FATURA';
export const ORDENA_PERIODO = 'FATURAS/ORDENA_PERIODO';
export const ORDENA_VENCIMENTO = 'FATURAS/ORDENA_VENCIMENTO';
export const FILTRA_CAMPO = 'FATURAS/FILTRA_CAMPO';
export const ORDENA_TOTAL = 'FATURAS/ORDENA_TOTAL';
export const CONTROLE_PAGINACAO = 'FATURAS/CONTROLE_PAGINACAO';

export const SET_FATURA_SELECIONADA = 'FATURAS/SET_FATURA_SELECIONADA';

export const LIMPA_FATURAS = 'FATURAS/LIMPA_FATURAS';
export const ATESTE_FATURA = 'FATURAS/ATESTE_FATURA';

export interface FaturasState {
  faturasResponse: FaturasResponse;
  faturaSelecionada: IFatura;
}
interface GetFaturasAction {
  type: typeof GET_FATURAS_POR_CONTRATO;
  faturasResponse: FaturasResponse;
}
interface AtivaMenuFaturaAction {
  type: typeof ATIVA_MENU_FATURA;
  fatura: IFatura;
}
interface OrdenaNumeroFatura {
  type: typeof ORDENA_NUMERO_FATURA;
  faturasResponse: FaturasResponse;
  headers: FiltrosHeaderFatura;
}
interface OrdenaPeriodo {
  type: typeof ORDENA_PERIODO;
  faturasResponse: FaturasResponse;
  headers: FiltrosHeaderFatura;
}
interface OrdenaVencimento {
  type: typeof ORDENA_VENCIMENTO;
  faturasResponse: FaturasResponse;
  headers: FiltrosHeaderFatura;
}

interface OrdenaTotal {
  type: typeof ORDENA_TOTAL;
  faturasResponse: FaturasResponse;
  headers: FiltrosHeaderFatura;
}
interface ControlePaginacao {
  type: typeof CONTROLE_PAGINACAO;
  faturasResponse: FaturasResponse;
  headers: FiltrosHeaderFatura;
  numeroPagina: number;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  headers?: FiltrosHeaderFatura;
  faturasResponse?: FaturasResponse;
}

interface SetFaturaSelecionada {
  type: typeof SET_FATURA_SELECIONADA;
  faturaSelecionada: IFatura;
}

interface LimpaFaturas {
  type: typeof LIMPA_FATURAS;
}

interface AtesteFatura {
  type: typeof ATESTE_FATURA;
  ateste: IAtesteFatura;
}

export type FaturaActionTypes =
  | GetFaturasAction
  | AtivaMenuFaturaAction
  | OrdenaNumeroFatura
  | OrdenaPeriodo
  | OrdenaVencimento
  | OrdenaTotal
  | ControlePaginacao
  | SetFaturaSelecionada
  | FiltraCampo
  | LimpaFaturas
  | AtesteFatura;
