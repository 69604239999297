import { IOption } from '../../../@types/interfaces/components';
import {
  CentroDeCustosDoContratoResponse,
  FiltrosHeaderCentroDeCusto,
} from '../../../@types/interfaces/contratoApi';

export const SET_CENTROS_DE_CUSTOS_DO_CONTRATO =
  'CONTRATO/SET_CENTROS_DE_CUSTOS_DO_CONTRATO';

export const CONTROLE_PAGINACAO = 'CONTRATO/CONTROLE_PAGINACAO';

export const LIMPA_CENTRO_CUSTO = 'CONTRATO/LIMPA_CENTRO_CUSTO';

export interface CentrosDeCustosState {
  centrosDeCustosResponse: CentroDeCustosDoContratoResponse;
  centrosDeCustos: IOption[];
  centroDeCustoSelecionado: IOption | undefined;
}

interface SetCentrosDeCustosDoContrato {
  type: typeof SET_CENTROS_DE_CUSTOS_DO_CONTRATO;
  centrosDeCustosResponse: CentroDeCustosDoContratoResponse;
}

interface ControlePaginacao {
  type: typeof CONTROLE_PAGINACAO;
  centrosDeCustosResponse: CentroDeCustosDoContratoResponse;
  headers: FiltrosHeaderCentroDeCusto;
  numeroPagina: number;
}

interface LimpaCentroCusto {
  type: typeof LIMPA_CENTRO_CUSTO;
}

export type CentrosDeCustosActionTypes =
  | SetCentrosDeCustosDoContrato
  | ControlePaginacao
  | LimpaCentroCusto;
