import styled from 'styled-components';

export const CotacoesRealizadasMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: fit-content;
`;

export const CotacoesRealizadasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 35.7vw;
  padding-bottom: 0.27vw;
  border-bottom: 0.05vw var(--cinza-30) solid;
`;

export const RightSubTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CotacoesRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.83vw;
  margin-top: 0.5vw;
`;

export const MainCotacoesRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.83vw;
  margin-top: 0.52vw;
  justify-content: space-evenly;
  align-items: center;
`;

export const RealizadasBold = styled.p`
  font-size: 0.75vw;
  font-weight: 500;
`;
export const RealizadasInfo = styled.p`
  font-size: 0.75vw;
  font-weight: 400;
`;

export const RealizadasImg = styled.img`
  height: 1.7vw;
  width: 1.7vw;
  margin-right: 0.558vw;
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
