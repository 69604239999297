import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { formatarDataDiaEMes } from '../../../helpers/formatacoes';
import * as Styled from './styles';

interface ICustomTooltip {
  mesSelecionado?: string;
}
const CustomTooltip = ({
  active,
  payload,
  label,
  mesSelecionado,
}: TooltipProps<ValueType, NameType> & ICustomTooltip) => {
  const getDia = (label: string) => {
    if (mesSelecionado) {
      const mes = parseInt(mesSelecionado);
      const dia = parseInt(label);
      const date = new Date(2022, mes - 1, dia);

      return formatarDataDiaEMes(date);
    }
  };

  if (active && payload && payload.length === 1 && payload[0].value === 0) {
    return null;
  }

  if (
    active &&
    payload &&
    payload.length === 2 &&
    payload[0].value === 0 &&
    payload[1].value === 0
  ) {
    return null;
  }

  if (active) {
    return (
      <Styled.TooltipContainer>
        <p>{getDia(label)}</p>
        {payload &&
          payload.length > 0 &&
          payload.map(
            (item) =>
              item.value !== 0 && (
                <Styled.TextoParceiro key={item.name} color={item.color}>
                  {`${item.name}: ${item.value}`}
                </Styled.TextoParceiro>
              )
          )}
      </Styled.TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
