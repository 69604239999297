import styled from 'styled-components';

export const Label = styled.label`
  font-size: 0.9vw;
  font-weight: 400;
  color: var(--black);
  font-family: var(--type-first);
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 0.417vw;
  align-items: center;
`;

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--action);
  border-radius: 50%;
  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 0.81em;
    height: 0.81em;
    margin: 3.5px;
  }
  :hover {
    ::after {
      background-color: var(--cinza-35);
    }
  }
  :checked {
    ::after {
      background-color: var(--action);
    }
    :hover {
      ::after {
        background-color: var(--blueHover);
      }
    }
  }
`;
