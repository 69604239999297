import * as Styled from './styles';
import Modal from '../Modal';
import React, { useState } from 'react';
import Close from '../../assets/icon-close-cinza.svg';

interface ModalJustificativaAteste {
  visivel: boolean;
  onCloseRequest: () => void;
  onClick: () => void;
  onContestar: (texto: string) => void;
}

const ModalJustificativaAteste = ({
  visivel,
  onCloseRequest,
  onClick,
  onContestar,
}: ModalJustificativaAteste) => {
  const [textoTemporario, setTextoTemporario] = useState('');

  const enviarDesabilitado = textoTemporario.length < 10;

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextoTemporario(e.target.value);
  };

  return (
    <Modal visivel={visivel} onCloseRequest={() => false}>
      <Styled.WrapperConteudoModalJustificativa
        id="Modal"
        transition={{ duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <Styled.ConteudoModalJustificativaAteste
          transition={{ duration: 0.5, delay: 0.2 }}
          initial={{ translateY: -1000 }}
          animate={{ translateY: 0 }}
          exit={{ translateY: -1000 }}>
          <Styled.IconeFecharJustificativa
            id={'Fechar'}
            onClick={() => onCloseRequest()}
            imageUrl={Close}
            width={5}
            height={10}
          />
          <Styled.WrapperConteudo>
            <Styled.ModalTitulo>Justificativa de recusa</Styled.ModalTitulo>
            <Styled.LinhaCinzaTitulo />
            <Styled.ModalTextoFilho>
              Explique com detalhes o motivo pelo qual você não concorda com as
              informações disponibilizadas em sua fatura.
            </Styled.ModalTextoFilho>
            <Styled.TextArea
              rows={4}
              cols={50}
              value={textoTemporario}
              onChange={handleInputChange}
            />
          </Styled.WrapperConteudo>
          <Styled.LinhaCinzaTexto />
          <Styled.ButtonContainer>
            <Styled.ButtonEnviar
              id={'Enviar'}
              onClick={() => {
                if (textoTemporario.length >= 10) {
                  onContestar(textoTemporario);
                  setTextoTemporario('');
                } else {
                  alert('Digite ao menos 10 caracteres');
                }
              }}
              disabled={enviarDesabilitado}>
              Enviar
            </Styled.ButtonEnviar>
            <Styled.ButtonCancelar id={'Cancelar'} onClick={() => onClick()}>
              Cancelar
            </Styled.ButtonCancelar>
          </Styled.ButtonContainer>
        </Styled.ConteudoModalJustificativaAteste>
      </Styled.WrapperConteudoModalJustificativa>
    </Modal>
  );
};

export default ModalJustificativaAteste;
