import {
  AuthActionTypes,
  DESLOGAR,
  IAuth,
  LOGAR,
  SET_MODAL_COOKIE,
  SET_TIPO_USUARIO,
  SET_USUARIO_LOGADO,
} from './types';

export function logaUsuario(auth: IAuth): AuthActionTypes {
  return {
    type: LOGAR,
    auth,
  };
}

export function deslogaUsuario(auth: IAuth): AuthActionTypes {
  return {
    type: DESLOGAR,
    auth,
  };
}

export function setUsuarioLogado(usuarioLogado: boolean): AuthActionTypes {
  return {
    type: SET_USUARIO_LOGADO,
    usuarioLogado,
  };
}

export function setTipoUsuario(tipoUsuario: string): AuthActionTypes {
  return {
    type: SET_TIPO_USUARIO,
    tipoUsuario,
  };
}

export function aceitaCookie(cookieAceito: boolean): AuthActionTypes {
  return {
    type: SET_MODAL_COOKIE,
    cookieAceito,
  };
}
