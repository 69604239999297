import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import * as Styled from './styles';
import { Erro } from '../../components/Erro';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useErro from '../../hooks/useErro';
import useContratos from '../../hooks/useContratos';
import SetaEsquerda from '../../assets/icone-seta-esquerda.svg';
import ModalSucesso from '../../assets/sucesso-modal-novo-centro-custo.svg';
import ImagemCentrodeCusto from '../../assets/img-principal-novo-centro-de-custo.svg';
import { orcamentoParaNumber } from '../../helpers/formatacoes';
import NovoCentroCustoApi from '../../api/novoCentroCusto';
import { DadosFormularioCentroCusto } from '../../@types/interfaces/formularioCentroCusto';
import FormularioNovoCentroCusto from '../../components/FormularioNovoCentroCusto';
import ErroModal from '../../assets/erro-modal.svg';
import ModalNovoCentroCusto from '../../components/ModalNovoCentroCusto';

interface CadastroCentroDeCustoProps {
  setLoading: (value: boolean) => void;
}

const CadastroCentroDeCusto = ({ setLoading }: CadastroCentroDeCustoProps) => {
  const navigate = useNavigate();

  const { codigoErro, limpaErro } = useErro();
  const { contratoSelecionado } = useContratos();

  const [modalSucessoVisivel, setModalSucessoVisivel] = useState(false);
  const [modalErroVisivel, setModalErroVisivel] = useState(false);

  const dadosDefault = {
    nome: '',
    descricao: '',
    valor: '',
    periodicidade: 'Mensal',
    agencia: '',
    conta: '',
  };

  const [botaoSubmitDesabilitado, setBotaoSubmitDesabilitado] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: dadosDefault,
  });

  const cadastrarCentroCusto = async ({
    nome,
    descricao,
    valor,
    periodicidade,
    agencia,
    conta,
  }: DadosFormularioCentroCusto) => {
    const body = {
      idContratoMobilidade: contratoSelecionado.id,
      nome: nome,
      descricao: descricao,
      orcamento: {
        periodicidade: periodicidade,
        valor: orcamentoParaNumber(valor),
      },
      dadosCobranca: {
        agencia: parseInt(agencia),
        contaCorrente: parseInt(conta),
      },
    };
    return NovoCentroCustoApi.cadastrarNovoCentroCusto(body);
  };

  const enviarDados = async (data: DadosFormularioCentroCusto) => {
    const response = await cadastrarCentroCusto({
      nome: data.nome,
      descricao: data.descricao,
      valor: data.valor,
      periodicidade: data.periodicidade,
      agencia: data.agencia,
      conta: data.conta,
    });
    setLoading(false);

    if (response) {
      return setModalSucessoVisivel(true);
    }

    setModalErroVisivel(true);
  };

  const onSubmit = (data: DadosFormularioCentroCusto) => {
    setBotaoSubmitDesabilitado(true);
    setLoading(true);
    enviarDados(data).then(() => ({}));
  };

  return (
    <>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Styled.Content>
          <>
            <Styled.WrapperTitulo>
              <Styled.IconeVoltar
                id={'BotaoVoltar'}
                src={SetaEsquerda}
                onClick={() => {
                  navigate('/centro-de-custo');
                }}
              />
              <Styled.Titulo>Adicionar Centro de Custo</Styled.Titulo>
            </Styled.WrapperTitulo>
            <Styled.WrapperContent>
              <Styled.WrapperForm onSubmit={handleSubmit(onSubmit)}>
                <FormularioNovoCentroCusto
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  botaoSubmitDesabilitado={botaoSubmitDesabilitado}
                />
              </Styled.WrapperForm>
              <Styled.WrapperImg>
                <Styled.Img src={ImagemCentrodeCusto} />
              </Styled.WrapperImg>
            </Styled.WrapperContent>
            <ModalNovoCentroCusto
              visivel={modalSucessoVisivel}
              onCloseRequest={() => navigate('/centro-de-custo')}
              imagem={ModalSucesso}
              textoPai={'O centro de custo foi criado!'}
              textoFilho={'Confira na tela de centro de custo.'}
              onClick={() => navigate('/centro-de-custo')}
              labelBotao={'OK'}
            />
            <ModalNovoCentroCusto
              visivel={modalErroVisivel}
              onCloseRequest={() => navigate('/centro-de-custo')}
              imagem={ErroModal}
              textoPai="Não foi possível criar centro de custo."
              textoFilho="Tivemos uma dificuldade para criar o centro de custo.
Por gentileza, tente novamente."
              onClick={() => navigate('/centro-de-custo')}
              labelBotao="Tentar novamente"
            />
          </>
        </Styled.Content>
      )}
    </>
  );
};

export default IsLoadingHOC(CadastroCentroDeCusto);
