import styled from 'styled-components';
import { Container } from '../../styles/globals';

export const CardContainer = styled(Container)`
  width: 72.54vw;
  padding: 0;
  display: flex;
  background: var(--white);
  border-radius: 4px;
  flex-direction: column;
`;

export const WrapperTitulo = styled.div`
  padding: 0 0 0 32px;
  width: 100%;
`;

export const WrapperConteudo = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--cinza-20);
  width: 100%;
  align-items: center;
`;

export const BotaoNovoColaborador = styled.button`
  width: 13vw;
  height: 2.54vw;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0.83vw;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1vw;
  letter-spacing: 0.011vw;
`;

export const Icone = styled.img`
  width: 1.22vw;
`;

export const TituloFatura = styled.h1``;
