import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import * as contratoActions from '../../store/ducks/contrato/actions';
import * as operations from '../../store/ducks/contrato/operations';
import { useDispatch } from 'react-redux';
import * as Styled from './styles';
import { Contrato } from '../../@types/interfaces/contratoApi';
import { useLocation } from 'react-router-dom';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import { logger } from '../../util/logger';
import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
} from 'react-select';
import ChevronDown from '../../assets/chevron_down.svg';

interface Props {
  setLoading: (value: boolean) => void;
}

interface ContratoSeletor {
  value?: string;
  label: string;
}

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Sem opçōes</span>
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Styled.IconSeletor imageUrl={ChevronDown} width={13} height={8} />
    </components.DropdownIndicator>
  );
};

const SelectContrato = ({ setLoading }: Props) => {
  const [listaContrato, setListaContrato] = useState([
    {
      id: '',
      numeroDeIdentificacao: undefined,
      nomeDeExibicao: undefined,
      dataInicioVigencia: undefined,
      dataFimVigencia: undefined,
      estado: undefined,
    },
  ]);
  const contratos: ContratoSeletor[] = [];

  const limiteCaracteres = 26;

  let limiteExcedido = false;

  const listarContratos = () => {
    listaContrato.forEach((item) => {
      contratos.push({
        value: item.id,
        label: `${item.numeroDeIdentificacao} / ${item.nomeDeExibicao}`,
      });
    });
  };

  const { fetchListaContratos, guardarContratoSelecionado } =
    bindActionCreators(
      {
        ...contratoActions,
        ...operations,
      },
      useDispatch()
    );

  const location = useLocation();

  const consultaContrato = async (): Promise<void> => {
    try {
      setLoading(true);
      const data = await fetchListaContratos();
      if (data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setListaContrato(data);
      }
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "downloadPDF".
        <'ERRO'>
          message: Não foi possível realizar o download do PDF!
        Parâmetros da requisição:
          MCI
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    consultaContrato().then(() => ({}));
  }, []);

  const handleChange = (option: { value: string; label: string }) => {
    limiteExcedido = option?.label.length > limiteCaracteres;

    const contratoSelecionado = listaContrato.find(
      (contrato: Contrato) => contrato.id === option?.value
    );

    if (contratoSelecionado?.id) {
      guardarContratoSelecionado({
        ...contratoSelecionado,
        contratoSelecionado: true,
      });
    }

    trocarLabel(option);
  };

  const trocarLabel = (option: { label: string | string[] }) => {
    let novaLabel = '';
    if (limiteExcedido) {
      for (let i = 0; i <= limiteCaracteres; i++) {
        novaLabel += option.label[i];
      }
      option.label = novaLabel + '...';
    }
  };

  listarContratos();

  const seletorContratoDesabilitado =
    location.pathname === '/colaboradores/editar-colaborador' ||
    location.pathname === '/fatura/detalhar' ||
    location.pathname === '/colaboradores/novo-colaborador' ||
    location.pathname === '/centro-de-custo/cadastro-centro-de-custo';

  return (
    <Styled.Content>
      {listaContrato[0].id && (
        <Styled.SeletorContratos
          opacity={seletorContratoDesabilitado ? '0.4' : '1'}>
          <Styled.WrapperText>
            <p>Contrato</p>
            <Styled.SeletorContainer>
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 15,
                })}
                defaultValue={contratos[0]}
                options={contratos}
                components={{
                  NoOptionsMessage,
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
                styles={Styled.styles}
                onChange={(option) => {
                  handleChange(option);
                }}
                isDisabled={seletorContratoDesabilitado}
              />
            </Styled.SeletorContainer>
          </Styled.WrapperText>
        </Styled.SeletorContratos>
      )}
    </Styled.Content>
  );
};

export default IsLoadingHOC(SelectContrato);
