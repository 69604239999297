import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  DetalheViagemResponse,
  FiltrosHeaderDetalheViagem,
} from '../@types/interfaces/detalheViagem';

class DetalheViagemApi {
  /**
   * Get Detalhe Viagem
   * @return {Promise<any>} Retorna um array de detalhes da viagem.
   */
  public getDetalheViagem = async (
    headers?: FiltrosHeaderDetalheViagem
  ): Promise<DetalheViagemResponse> => {
    const response = await segurancaAxios.get('viagem/detalhar/dadosViagem', {
      headers,
    });
    return response.data;
  };
}
export default new DetalheViagemApi();
