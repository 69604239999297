import {
  ColaboradoresResponse,
  FiltrosHeaderColaboradores,
} from '../../../@types/interfaces/colaboradoresApi';
import {
  ColaboradoresActionTypes,
  CONTROLE_PAGINACAO,
  FILTRA_CAMPO,
  LIMPA_COLABORADORES,
  SET_COLABORADORES,
} from './types';
export function setColaboradores(
  colaboradoresResponse: ColaboradoresResponse
): ColaboradoresActionTypes {
  return {
    type: SET_COLABORADORES,
    colaboradoresResponse,
  };
}

export function filtraCampo(
  headers: FiltrosHeaderColaboradores,
  colaboradoresResponse?: ColaboradoresResponse
): ColaboradoresActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    colaboradoresResponse,
  };
}

export function limpaColaboradores(): ColaboradoresActionTypes {
  return {
    type: LIMPA_COLABORADORES,
  };
}

export function controlePaginacao(
  colaboradoresResponse: ColaboradoresResponse,
  headers: FiltrosHeaderColaboradores,
  numeroPagina: number
): ColaboradoresActionTypes {
  return {
    type: CONTROLE_PAGINACAO,
    colaboradoresResponse,
    headers,
    numeroPagina,
  };
}
