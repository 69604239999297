import styled from 'styled-components';

export const CardContainerUltimasViagens = styled.div`
  background-color: var(--white);
  padding: 1.3vw;
  flex-direction: column;
  box-shadow: 0.111vw 0.111vw 0.39vw 0.167vw var(--cinza-30);
  border-radius: 0.223vw;
  max-height: 20.5vw;
  display: flex;
  margin-top: 1.52vw;
  height: 20vw;
  width: 17vw;
  overflow-y: auto;
`;

export const TituloCardUltimasViagens = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3vw;
  height: fit-content;
`;

export const TituloUltimasViagens = styled.p`
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 0.85vw;
  letter-spacing: 0;
  text-align: left;
  color: var(--blueLabel);
`;

export const ViagensWrapper = styled.div`
  height: 6vw;
`;

export const ViagensNome = styled.p`
  color: var(--cinza-60);
  font-size: 0.8vw;
  font-weight: 600;
  line-height: 0.94vw;
  letter-spacing: 0.03vw;
  text-align: left;

  margin-bottom: 0.3vw;
  margin-top: 0.7vw;
  height: fit-content;
`;

export const ViagensParceiro = styled.p`
  color: var(--blueNav);
  font-size: 0.7vw;
  font-weight: 500;
  line-height: 0.74vw;
  letter-spacing: 0;
  text-align: left;

  margin-bottom: 0.15vw;
  height: fit-content;
`;

export const ViagensTexto = styled.p`
  font-size: 0.7vw;
  font-weight: 400;
  line-height: 0.94vw;
  letter-spacing: 0.01vw;
  text-align: left;

  color: var(--cinza-60);
`;
