import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as authActions from '../store/ducks/auth/actions';
import * as operations from '../store/ducks/auth/operations';

function useAuth() {
  const { auth } = useSelector((state: RootState) => state.auth);

  const {
    setUsuarioLogado,
    setTipoUsuario,
    aceitaCookie,
    deslogaUsuario,
    fetchDeslogaUsuario,
  } = bindActionCreators(
    {
      ...authActions,
      ...operations,
    },
    useDispatch()
  );

  const deslogarUsuario = useCallback(async () => {
    try {
      await fetchDeslogaUsuario();
      deslogaUsuario(auth);
    } catch (error: any) {
      console.error('error', error);
    }
  }, []);

  return {
    auth,
    setUsuarioLogado,
    deslogarUsuario,
    setTipoUsuario,
    aceitaCookie,
  };
}

export default useAuth;
