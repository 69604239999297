import React, { ReactElement } from 'react';
import * as Styled from './styles';
import Lottie from 'react-lottie';
import animation from '../../assets/lottie/loading.json';

export default function Loading(): ReactElement {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Styled.LoadingContainer>
      <Lottie options={defaultOptions} height={250} width={250} />
    </Styled.LoadingContainer>
  );
}
