import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  GraficoResponse,
  FiltrosHeadersGrafico,
} from '../@types/interfaces/graficoApi';

class ViagensApi {
  /**
   * Get Viagens do Grafico
   * @return {Promise<any>} Retorna um array de faturas vinculadas ao usuario logado.
   */
  public getViagens = async (
    headers?: FiltrosHeadersGrafico
  ): Promise<GraficoResponse> => {
    const response = await segurancaAxios.get('dashboard/grafico/performance', {
      headers,
    });
    return response?.data;
  };
}
export default new ViagensApi();
