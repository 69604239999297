import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../components/DatePicker';
import * as Styled from './styles';
import { Pagination } from '../../components/Pagination';
import { CardNoData } from '../../components/Cards/CardNoData';
import { filtrarData } from '../../helpers/formatacoes';
import useFaturas from '../../hooks/useFaturas';
import useCentrosDeCustos from '../../hooks/useCentrosDeCustos';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import { useNavigate } from 'react-router-dom';
import useContratos from '../../hooks/useContratos';
import useErro from '../../hooks/useErro';
import { Erro } from '../../components/Erro';
import { situacoesPagamento, situacoesFatura } from '../../helpers/constantes';
import { Seletor } from '../../components/Seletor';
import TabelaFaturas from '../../components/TabelaFaturas';

export interface FaturaProps {
  setLoading: (value: boolean) => void;
}

const Fatura = ({ setLoading }: FaturaProps) => {
  const navigate = useNavigate();

  const quantidadeItemsPagina = 8;
  const situacaoDefault = {
    value: '',
    label: 'Todas',
  };

  const {
    faturasResponse,
    handleFiltraDataInicio,
    handleFiltraDataFim,
    handlePaginacaoFaturas,
    consultaFaturasPorContrato,
    handleFiltraPorSituacao,
    handleFiltraPorSituacaoPagamento,
    handleFiltraPorCentroDeCustos,
  } = useFaturas();

  const [dataInicial, setDataInicial] = useState<Date>();

  const [dataFinal, setDataFinal] = useState<Date>();

  const { contratoSelecionado } = useContratos();

  const { codigoErro, limpaErro } = useErro();

  const dataAtual = new Date();
  const {
    centrosDeCustos,
    centroDeCustoSelecionado,
    handleCentrosDeCustosPorContrato,
  } = useCentrosDeCustos();

  const buscaDataInicio = (dataStr: string) => {
    handleFiltraDataInicio(dataStr, setLoading).then(() => ({}));
  };

  const buscaDataFinal = (dataStr: string) => {
    handleFiltraDataFim(dataStr, setLoading).then(() => ({}));
  };

  const quantidadePaginacao = (
    quantidadeTotalRegistro: number,
    itensPagina: number
  ) => {
    return Math.ceil(quantidadeTotalRegistro / itensPagina);
  };

  useEffect(() => {
    if (contratoSelecionado?.id) {
      handleCentrosDeCustosPorContrato(setLoading).then(() => ({}));
      consultaFaturasPorContrato(setLoading).then(() => ({}));
    }
  }, [contratoSelecionado]);

  return (
    <React.Fragment>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Styled.Content>
          <React.Fragment>
            <Styled.Titulo>Faturas</Styled.Titulo>
            <Styled.WrapperSelector>
              {centrosDeCustos && centroDeCustoSelecionado && (
                <Seletor
                  id="centro-de-custo"
                  label="Centro de custo"
                  defaultValue={centroDeCustoSelecionado}
                  options={centrosDeCustos}
                  onChange={(option: any) => {
                    handleFiltraPorCentroDeCustos(option, setLoading).then(
                      () => ({})
                    );
                  }}
                />
              )}

              <DatePicker
                label="Vencimento"
                dataSelecionada={dataInicial ? dataInicial : undefined}
                placeholder="Data inicial"
                id="data-inicio"
                onChange={(data) =>
                  filtrarData(data, buscaDataInicio, setDataInicial)
                }
                dataMaxima={dataAtual}
              />
              <DatePicker
                dataSelecionada={dataFinal}
                placeholder="Data final"
                id="data-fim"
                onChange={(data) =>
                  filtrarData(data, buscaDataFinal, setDataFinal)
                }
                dataMaxima={dataAtual}
                dataMinima={dataInicial}
              />
              <Seletor
                id="situacaoFatura"
                label="Situação da Fatura"
                defaultValue={situacaoDefault}
                options={situacoesFatura}
                onChange={(option: any) => {
                  handleFiltraPorSituacao(option, setLoading).then(() => ({}));
                }}
              />
              <Seletor
                id="situacaoPagamento"
                label="Situação do Pagamento"
                defaultValue={situacaoDefault}
                options={situacoesPagamento}
                onChange={(option: any) => {
                  handleFiltraPorSituacaoPagamento(option, setLoading).then(
                    () => ({})
                  );
                }}
              />
            </Styled.WrapperSelector>
            {faturasResponse?.listaRegistro.length > 0 ? (
              <>
                <Styled.TableContent>
                  <TabelaFaturas />
                </Styled.TableContent>
                <Pagination
                  quantidadeDePaginas={quantidadePaginacao(
                    faturasResponse.quantidadeTotalRegistro,
                    quantidadeItemsPagina
                  )}
                  onPageChange={(selectedItem) => {
                    handlePaginacaoFaturas(selectedItem, setLoading).then(
                      () => ({})
                    );
                  }}
                  forcePage={faturasResponse.numeroPagina - 1}
                />
              </>
            ) : (
              <CardNoData
                titulo="Essa área está muito vazia, não acha?"
                descricao="Selecione os dados de sua preferência e mostraremos uma lista para você."
              />
            )}
          </React.Fragment>
        </Styled.Content>
      )}
    </React.Fragment>
  );
};

export default IsLoadingHOC(Fatura);
