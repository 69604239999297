import { ValidarCampoNovoColaborador } from '../@types/interfaces/validarCampoNovoColaborador';
import { DadosProps } from '../@types/interfaces/dadosNovoColaborador';
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { regex, removerMascara } from './formatacoes';
import React, { Dispatch, SetStateAction } from 'react';
import { CentroCusto } from '../@types/interfaces/CentroCusto';
import { CadastroNovoColaborador } from '../@types/interfaces/cadastroNovoColaborador';
import { celularMask } from './mascaras';

export const verificarCampo = ({
  campo,
  value,
  regex,
  setError,
  clearErrors,
}: ValidarCampoNovoColaborador) => {
  if (value === '') {
    setError(
      campo,
      { type: 'required', message: 'Campo Obrigatório' },
      { shouldFocus: true }
    );
    return false;
  }
  if (regex && !regex.test(value)) {
    let errorMessage = '';

    if (campo === 'dataNascimento') {
      errorMessage = 'Data inválida.';
    } else if (campo === 'email') {
      errorMessage = 'Email inválido.';
    } else {
      errorMessage = 'Número de celular inválido.';
    }

    setError(
      campo,
      { type: 'pattern', message: errorMessage },
      { shouldFocus: true }
    );
    return false;
  }

  clearErrors(campo);
  return true;
};

export const verificarCamposSubmit = (
  dados: DadosProps,
  setError: UseFormSetError<DadosProps>,
  clearErrors: UseFormClearErrors<DadosProps>,
  setValue: UseFormSetValue<DadosProps>,
  setBotaoSubmitDesabilitado: (value: boolean) => void
) => {
  verificarCampo({
    campo: 'email',
    value: dados.email,
    regex: regex.email,
    setError,
    clearErrors,
    setValue,
    setBotaoSubmitDesabilitado,
  });
  verificarCampo({
    campo: 'celular',
    value: celularMask(dados.celular),
    regex: regex.celular,
    setError,
    clearErrors,
    setValue,
    setBotaoSubmitDesabilitado,
  });
};

export const handleSubmit = ({
  e,
  dados,
  errors,
  setMostrarPrimeiraPagina,
  setError,
  clearErrors,
  setValue,
  setBotaoSubmitDesabilitado,
}: {
  e: React.MouseEvent;
  dados: DadosProps;
  errors: FieldErrorsImpl<DeepRequired<DadosProps>>;
  setMostrarPrimeiraPagina: React.Dispatch<SetStateAction<boolean>>;
  setError: UseFormSetError<DadosProps>;
  clearErrors: UseFormClearErrors<DadosProps>;
  setValue: UseFormSetValue<DadosProps>;
  setBotaoSubmitDesabilitado: (value: boolean) => void;
}): void => {
  e.preventDefault();
  verificarCamposSubmit(
    dados,
    setError,
    clearErrors,
    setValue,
    setBotaoSubmitDesabilitado
  );
  if (!errors?.email && !errors?.celular) {
    setMostrarPrimeiraPagina(false);
  }
};

export const checarCentroCusto = (
  centroCusto: CentroCusto[],
  setError: UseFormSetError<DadosProps>,
  clearErrors: UseFormClearErrors<DadosProps>
) => {
  if (centroCusto.length === 0) {
    setError(
      'centroCustoVinculado',
      { type: 'required', message: 'Selecione um centro de custo.' },
      { shouldFocus: true }
    );
    return;
  }
  clearErrors('centroCustoVinculado');
};

export const checarCamposPreenchidos = (
  setBotaoSubmitDesabilitado: (value: boolean) => void,
  errors?: FieldErrorsImpl<DeepRequired<DadosProps>>
) => {
  !errors?.cargo &&
    !errors?.centroCustoVinculado &&
    setBotaoSubmitDesabilitado(false);
};

export const handleChangeCentroCusto = (
  option: CentroCusto[],
  setCentroCusto: Dispatch<SetStateAction<CentroCusto[]>>
) => {
  setCentroCusto(option);
};

export const handleBlurCentroCusto = ({
  setDados,
  dados,
  numeroCentroCusto,
  setValue,
  centroCusto,
  setError,
  clearErrors,
  errors,
  setBotaoSubmitDesabilitado,
}: {
  setDados: (value: DadosProps) => void;
  dados: DadosProps;
  numeroCentroCusto: number[];
  setValue: UseFormSetValue<DadosProps>;
  centroCusto: CentroCusto[];
  setError: UseFormSetError<DadosProps>;
  clearErrors: UseFormClearErrors<DadosProps>;
  errors: FieldErrorsImpl<DeepRequired<DadosProps>>;
  setBotaoSubmitDesabilitado: (value: boolean) => void;
}) => {
  setDados({ ...dados, centroCustoVinculado: numeroCentroCusto });
  setValue('centroCustoVinculado', numeroCentroCusto);
  checarCentroCusto(centroCusto, setError, clearErrors);
  checarCamposPreenchidos(setBotaoSubmitDesabilitado, errors);
};

export const enviarDados = async (
  dados: DadosProps,
  cadastrarColaborador: ({
    nome,
    cpf,
    email,
    dataNascimento,
    celular,
    centroCustoVinculado,
    cargo,
    categoria,
  }: CadastroNovoColaborador) => Promise<boolean | undefined>,
  setLoading: (value: boolean) => void,
  setModalSucessoVisivel: (value: boolean) => void,
  setModalErroVisivel: (value: boolean) => void
) => {
  const cpfFormatado = removerMascara(dados.cpf);
  const celFormatado = removerMascara(dados.celular);
  const response = await cadastrarColaborador({
    nome: dados.nome,
    cpf: cpfFormatado,
    email: dados.email,
    dataNascimento: dados.dataNascimento,
    celular: celFormatado,
    centroCustoVinculado: dados.centroCustoVinculado,
    cargo: dados.cargo,
    categoria: dados.categoria,
  });
  setLoading(false);
  if (response) return setModalSucessoVisivel(true);
  setModalErroVisivel(true);
};

export const handleBlur = async ({
  campo,
  value,
  regex,
  setError,
  clearErrors,
  setValue,
  setBotaoSubmitDesabilitado,
}: ValidarCampoNovoColaborador) => {
  regex
    ? verificarCampo({
        campo,
        value,
        regex,
        setError,
        clearErrors,
        setValue,
        setBotaoSubmitDesabilitado,
      })
    : verificarCampo({
        campo,
        value,
        setError,
        clearErrors,
        setValue,
        setBotaoSubmitDesabilitado,
      });
};

export const resetarDados = (
  setModalErroVisivel: (value: boolean) => void,
  setMostrarPrimeiraPagina: (value: boolean) => void,
  setCamposDesabilitados: (value: boolean) => void,
  setCamposIniciaisDesabilitados: (value: boolean) => void,
  setDados: (value: DadosProps) => void,
  dadosDefault: DadosProps,
  reset: (value: DadosProps) => void
) => {
  setModalErroVisivel(false);
  setMostrarPrimeiraPagina(true);
  setCamposDesabilitados(true);
  setCamposIniciaisDesabilitados(false);
  setDados(dadosDefault);
  reset(dadosDefault);
};

export const navegarPaginaAnterior = (
  mostrarPrimeiraPagina: boolean,
  navigate: (value: string) => void,
  setMostrarPrimeiraPagina: (value: boolean) => void
) => {
  if (mostrarPrimeiraPagina) {
    navigate('/colaboradores');
  }
  setMostrarPrimeiraPagina(true);
};
