import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as erroActions from '../store/ducks/erro/actions';

function useErro() {
  const { codigoErro, mensagemErro } = useSelector(
    (state: RootState) => state.erro
  );

  const { mostraErro, limpaErro } = bindActionCreators(
    {
      ...erroActions,
    },
    useDispatch()
  );

  return {
    mostraErro,
    limpaErro,
    codigoErro,
    mensagemErro,
  };
}

export default useErro;
