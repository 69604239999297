import { ReactElement } from 'react';
import ReactPaginate from 'react-paginate';
import * as Styled from './styles';
import ChevronDisabled from '../../assets/chevron_down_gray.svg';
import ChevronActive from '../../assets/chevron_down_blue.svg';

interface IPagination {
  quantidadeDePaginas: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  forcePage?: number;
}
export const Pagination = ({
  quantidadeDePaginas,
  forcePage,
  onPageChange,
}: IPagination): ReactElement => {
  return (
    <>
      <Styled.StyledPaginateContainer
        imageUrlActive={ChevronActive}
        imageUrlDisabled={ChevronDisabled}>
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={Math.ceil(quantidadeDePaginas)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={forcePage}
        />
      </Styled.StyledPaginateContainer>
    </>
  );
};
