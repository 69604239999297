import {
  FiltrosHeaderFatura,
  IAtesteFatura,
} from '../../../@types/interfaces/faturaApi';
import fatura from '../../../api/fatura';
import * as faturasActions from './actions';

export const fetchFaturasPorContrato =
  (headers?: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(faturasActions.setFaturasResponse(faturasResponse));

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchOrdenaNumeroFatura =
  (headers: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(faturasActions.ordenaNumeroFatura(faturasResponse, headers));

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchOrdenaPeriodo =
  (headers: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(faturasActions.ordenaPeriodo(faturasResponse, headers));

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchOrdenaVencimento =
  (headers: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(faturasActions.ordenaVencimento(faturasResponse, headers));

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchOrdenaTotal =
  (headers: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(faturasActions.ordenaTotal(faturasResponse, headers));

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
export const fetchControlePaginacao =
  (headers: FiltrosHeaderFatura, numeroPagina: number) =>
  async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);

      dispatch(
        faturasActions.controlePaginacao(faturasResponse, headers, numeroPagina)
      );

      return faturasResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeaders =
  (headers?: FiltrosHeaderFatura) => async (dispatch: any) => {
    try {
      const faturasResponse = await fatura.getFaturas(headers);
      dispatch(faturasActions.filtraCampo(headers, faturasResponse));

      return faturasResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const fetchAtesteFatura =
  (ateste: IAtesteFatura) => async (dispatch: any) => {
    try {
      const atesteResponse = await fatura.atesteFatura(ateste);

      if (atesteResponse) {
        dispatch(faturasActions.atesteFatura(atesteResponse));
      }
      return atesteResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
