import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Icon } from '../../styles/globals';

export const WrapperConteudoModal = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-bottom: 50%;
  }
`;

export const ConteudoModalAteste = styled(motion.div)`
  border-radius: 15px;
  background-color: var(--white);
  //height: 60%;
  width: 55.5%;
  padding: 2.51vw 2vw;
  border-radius: 15px;
  position: relative;
`;

export const IconeFechar = styled(Icon)`
  position: absolute;
  right: 2.5vw;
  padding: 0.55vw;
  border-radius: 0.446vw;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0.55vw;
  margin-top: 0.55vw;
`;

export const WrapperConteudo = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;

  @media screen and (max-width: 600px) {
    width: 100%;
    //align-items: flex-end;
  }
`;

export const ModalTitulo = styled.div`
  font-size: 1.4vw;
  color: var(--black);
  height: 0px;
  margin-top: -2.2vw;
  margin-left: 4.2vw;
  font-weight: bold;
`;

export const ModalImg = styled.img`
  margin-left: 0.5vw;
  width: 8.3%;
  padding: 1.3%;
  margin-top: -1.2vw;

  background-color: #f4f4f4;
  border-radius: 5px;
`;

export const ConferirDados = styled.div`
  padding: 0.4vw;
  align-items: center;
`;

export const ModalTextoPai = styled.p`
  font-weight: bold;
  font-size: 0.9vw;
  color: var(--black);
  margin-top: 3.5vw;

  //@media (max-width: 768px) {
  //  margin-top: 1%;
  //}
`;

export const ModalTextoFilho = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  margin-top: 1.5%;
  line-height: 1.5;
  margin-bottom: 2%;
`;

export const LinhaCinza = styled.hr`
  width: 99%;
  height: 0px;
  border: none;
  border-top: 1px solid #d9d8d8;
  margin-left: 0.9%;
`;

export const LinhaLongaCinza = styled.hr`
  width: 100%;
  height: 0.1vw;
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 2%;
  left: 0vw;
  position: absolute;
`;

export const Indicadores = styled.p`
  display: flex;
  align-items: center;
  padding: 0.4vw;
  font-size: 0.9vw;
  margin-top: 10px;
  margin-bottom: 6px;
`;

export const WrapperDetalhamento = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5px;
`;

export const TextoIndicadores = styled.div`
  padding: 0.4vw;
  font-size: 0.9vw;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ValorIndicadores = styled.div`
  font-size: 0.9vw;
  font-weight: normal;
  display: flex;
  margin-top: 1.5%;
`;

export const ModalTextoNota = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  margin-top: 3%;
  padding: 0.4%;
`;

export const ModalTextoNotaFilho = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  padding: 0.4%;
  margin-top: 4%;
`;

export const ModalSegundoTextoNotaFilho = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  padding: 0.4%;
`;

export const ModalImgPoints = styled.img`
  height: auto;
  width: 4%;
  margin-left: 1%;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: -5%;
`;

export const ButtonAtestar = styled.button`
  margin-left: 1vw;
  margin-top: 5%;
  margin-bottom: -20%;
  height: 2.4vw;
  width: 24%;
  border-radius: 4px;
  background-color: #39ac3e;
  color: #ffffff;
  font-size: 0.9vw;
  font-weight: bold;
`;

export const ButtonCancelar = styled.button`
  margin-left: 15vw;
  margin-top: 5%;
  height: 2.4vw;
  width: 24%;
  border-radius: 4px;
  background-color: #e52626;
  color: #ffffff;
  font-size: 0.9vw;
  font-weight: bold;
`;
