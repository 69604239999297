import { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import useGrafico from '../../hooks/useGrafico';
import CustomTooltip from './CustomTooltip';
import { labelXAxis, labelYAxis, marginLineChart, ticksXAxis } from './styles';

interface IChart {
  mesAtual?: string;
}
const Chart = ({ mesAtual }: IChart) => {
  const { grafico } = useGrafico();
  const [mesSelecionado, setMesSelecionado] = useState('');

  useEffect(() => {
    if (grafico?.headers?.['mes-referencia']) {
      setMesSelecionado(grafico.headers?.['mes-referencia']);
    } else if (mesAtual && mesAtual.length) {
      setMesSelecionado(mesAtual);
    }
  }, [grafico?.headers]);

  return (
    <ResponsiveContainer id={'chart'} width={'100%'} height={'100%'}>
      <LineChart
        width={1100}
        height={300}
        data={grafico?.listaViagens}
        margin={marginLineChart}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          ticks={ticksXAxis(grafico)}
          dataKey="dia"
          label={labelXAxis}
          allowDecimals={false}
          fontSize={'1vw'}
        />
        <YAxis label={labelYAxis} allowDecimals={false} fontSize={'1vw'} />
        <Tooltip content={<CustomTooltip mesSelecionado={mesSelecionado} />} />
        <Legend />
        {grafico?.linhas?.map((linha) => (
          <Line
            key={linha.nome}
            type="linear"
            dataKey={linha.nome}
            stroke={linha?.cor}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
