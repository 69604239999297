import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 0 2.3vw;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
`;

export const ContentGraphic = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.78vw 1vw 0.78vw;
  height: 18vw;
`;

export const Dashboard = styled.div`
  background-color: var(--white);
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 4vw;
  letter-spacing: 0.01vw;
  padding: 1vw 5.5vw;
`;

export const ContainerDashboard = styled.div`
  max-width: 72.54vw;
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 6vw;
`;

export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20vw;
  margin: 0 0 1vw 0;
`;

export const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20vw;
  margin: 1vw 0 0 0;
`;

export const GraphicContainerDashboard = styled.div`
  display: flex;
  background: var(--white);
  margin-top: 1.55vw;
  flex-direction: column;
  height: 20vw;
  width: 47vw;
  box-shadow: 0.111vw 0.111vw 0.39vw 0.167vw var(--cinza-30);
  border-radius: 0.223vw;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.93vw 0 0.93vw 1.25vw;
  justify-content: center;
  height: fit-content;

  h1 {
    font-size: 1.1vw;
    font-weight: 500;
    line-height: 0.93vw;
    letter-spacing: 0;
    text-align: center;
    color: var(--blueLabel);
  }
`;

export const WrapperSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WrapperCards = styled.div`
  display: flex;
  width: 47vw;
  justify-content: space-between;
`;

export const WrapperTitulo = styled.div`
  font-weight: 700;
  font-size: 1.78vw;
  line-height: 1.02vw;
  letter-spacing: 0.03vw;
  display: flex;
  align-items: center;
  height: 4vw;
  justify-content: space-between;
`;
