import { ReactElement } from 'react';
import * as Styled from './styles';
import data from '../../assets/calendar.svg';
import MaskedTextInput from 'react-text-mask';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import portugues from 'date-fns/locale/pt-BR';
interface IDatePicker {
  label?: string;
  dataSelecionada?: Date;
  dataMinima?: Date;
  dataMaxima?: Date;
  placeholder?: string;
  id?: string;
  onChange: (event: any) => void;
}

export const DatePicker = ({
  label,
  dataSelecionada,
  dataMinima,
  dataMaxima,
  placeholder,
  id,
  onChange,
}: IDatePicker): ReactElement => {
  registerLocale('pt-BR', portugues);

  return (
    <Styled.DatePickerContainer>
      <Styled.Label>{label}</Styled.Label>
      <Styled.DateInput
        selected={dataSelecionada}
        placeholderText={placeholder}
        locale="pt-BR"
        id={id}
        dateFormat={'dd/MM/yyyy'}
        onChange={onChange}
        minDate={dataMinima}
        maxDate={dataMaxima}
        imageUrl={data}
        customInput={
          <MaskedTextInput
            type="text"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />
        }
      />
    </Styled.DatePickerContainer>
  );
};
