import { createContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useErro from '../hooks/useErro';
const AuthContext = createContext<boolean>({} as boolean);

export const AuthProvider: React.FC = ({ children }) => {
  const { auth, setUsuarioLogado } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { limpaErro } = useErro();

  const verificaSeExisteAlgumCookieValido = () => {
    return verificaSeCookieSSOExiste() || verificaSeCookieGCSExiste();
  };

  const verificaSeCookieSSOExiste = () => {
    const dia = new Date();
    dia.setTime(dia.getTime() + 1000);

    const tempoExpiracao = `expires=${dia.toUTCString()}`;
    document.cookie = `${process.env.REACT_APP_TIPO_TOKEN_BBSSO}=new_value;path=/;${tempoExpiracao};domain=${process.env.REACT_APP_DOMINIO}`;

    return (
      document.cookie.indexOf(`${process.env.REACT_APP_TIPO_TOKEN_BBSSO}=`) ===
      -1
    );
  };

  const verificaSeCookieGCSExiste = () => {
    const dia = new Date();
    dia.setTime(dia.getTime() + 1000);

    const tempoExpiracao = `expires=${dia.toUTCString()}`;
    document.cookie = `idtoken_pj=new_value;path=/;${tempoExpiracao};domain=.bb.com.br`;

    return document.cookie.indexOf('idtoken_pj=') === -1;
  };

  const verificaLogin = () => {
    const cookieExiste = verificaSeExisteAlgumCookieValido();

    if (auth.tipoUsuario === '') {
      navigate('/login');
    }

    if (cookieExiste || auth.tipoUsuario) {
      setUsuarioLogado(true);
    } else {
      setUsuarioLogado(false);
      limpaErro();
      navigate('/login');
    }
  };

  useEffect(() => {
    verificaLogin();
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={auth.usuarioLogado}>
      {auth.usuarioLogado || location.pathname === '/login' ? children : null}
    </AuthContext.Provider>
  );
};

export default AuthContext;
