import {
  FiltrosHeadersCard,
  ICardUltimasViagens,
} from '../../../@types/interfaces/cardUltimasViagens';

export const GET_CARD_ULTIMAS_VIAGENS =
  'CARD_ULTIMAS_VIAGENS/GET_CARD_ULTIMAS_VIAGENS';

export interface GetCardUltimasViagens {
  type: typeof GET_CARD_ULTIMAS_VIAGENS;
  headers: FiltrosHeadersCard;
  cardUltimasViagensResponse: ICardUltimasViagens;
}
export type CardUltimasViagensActionTypes = GetCardUltimasViagens;
