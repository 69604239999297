import { FiltrosHeaderDetalheFatura } from '../../../@types/interfaces/detalharFatura';
import detalheFatura from '../../../api/detalharFatura';
import * as detalharFaturaAction from './actions';
import { logger } from '../../../util/logger';

export const fetchDetalheFaturasPorContrato =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );
      dispatch(
        detalharFaturaAction.setDetalhesFaturaResponse(detalheFaturaResponse)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

export const fetchDetalheFaturasPorContratoDownloadPDF =
  (headers: FiltrosHeaderDetalheFatura, setLoading: (value: boolean) => void) =>
  async () => {
    try {
      setLoading(true);
      return await detalheFatura.getDetalharFaturaDownloadPDF(headers);
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "downloadPDF".
        <'ERRO'>
          message: Não foi possível realizar o download do PDF!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${headers['id-contrato-mobilidade']},
          NUMERO FATURA: ${headers['numero-fatura']},
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    }
  };

export const fetchOrdenaParceiro =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );

      dispatch(
        detalharFaturaAction.ordenaParceiro(detalheFaturaResponse, headers)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
    }
  };

export const fetchOrdenaColaborador =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );

      dispatch(
        detalharFaturaAction.ordenaColaborador(detalheFaturaResponse, headers)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
    }
  };

export const fetchOrdenaData =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );

      dispatch(detalharFaturaAction.ordenaData(detalheFaturaResponse, headers));

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
    }
  };

export const fetchOrdenaTotal =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );

      dispatch(
        detalharFaturaAction.ordenaValorTotal(detalheFaturaResponse, headers)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
    }
  };

export const fetchFiltrosHeaders =
  (headers: FiltrosHeaderDetalheFatura) => async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );
      dispatch(
        detalharFaturaAction.filtraCampo(headers, detalheFaturaResponse)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      throw error;
    }
  };

export const fetchControlePaginacao =
  (headers: FiltrosHeaderDetalheFatura, numeroPagina: number) =>
  async (dispatch: any) => {
    try {
      const detalheFaturaResponse = await detalheFatura.getDetalharFatura(
        headers
      );

      dispatch(
        detalharFaturaAction.controlePaginacao(
          detalheFaturaResponse,
          headers,
          numeroPagina
        )
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      console.log(error);
    }
  };
