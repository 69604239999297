import {
  ATIVA_MENU_DETALHE_FATURA,
  CONTROLE_PAGINACAO,
  DetalharFaturaActionType,
  DetalharFaturaState,
  FILTRA_CAMPO,
  GET_DETALHES_FATURA_POR_CONTRATO,
  GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD,
  GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD,
  ORDENA_COLABORADOR,
  ORDENA_DATA,
  ORDENA_PARCEIRO,
  ORDENA_VALOR_TOTAL,
} from './types';

const initialState: DetalharFaturaState = {
  detalheFaturaDownloadResponse: new File([], ''),
  detalheFaturaDownloadPDFResponse: new File([], ''),
  detalheFaturaSelecionada: undefined,
  detalheFaturaResponse: {
    listaViagem: [],
    numeroPagina: 1,
    qtdRegistroPagina: 10,
    qtdRegistroTotal: 10,
    listaNomesParceiros: 'Wappa',
    estadoPagamentoFatura: 'A pagar',
    estadoFatura: 'Concluída',
    dataVencimentoFatura: new Date(),
    headers: {
      'texto-estado-fatura': '',
      'numero-pagina': '',
      'quantidade-registro-pagina': '',
      'ordenacao-do-campo': '',
      'campo-ordenado': '',
      'data-inicio': '',
      'data-fim': '',
      'numero-identificacao-centro-custo': '',
      'nome-parceiro': '',
      'nome-colaborador': '',
      'id-contrato-mobilidade': '',
      'numero-fatura': '',
    },
    contrato: {
      numeroIdentificacaoContrato: '609194210562734c162a76c1',
      nomeCliente: 'Padaria de Aguiar Bono',
    },
    numeroFatura: 1,
    totalOrdenado: false,
    parceiroOrdenado: false,
    colaboradorOrdenado: false,
    dataOrdenada: false,
    dataReferencia: new Date(),
  },
};

const detalheFaturasReducer = (
  state = initialState,
  action: DetalharFaturaActionType
): DetalharFaturaState => {
  switch (action?.type) {
    case GET_DETALHES_FATURA_POR_CONTRATO: {
      const listaComMenusDesativados =
        action.detalheFaturaResponse?.listaViagem?.map((detalhe) => {
          return { ...detalhe, menuAtivado: false };
        });
      const newDetalheFaturasResponse = {
        ...action.detalheFaturaResponse,
        listaViagem: listaComMenusDesativados,
        headers: initialState.detalheFaturaResponse.headers,
      };
      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturasResponse,
      };
    }

    case GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD: {
      const detalheFaturaDownloadResponse =
        action.detalheFaturaDownloadResponse;
      return {
        ...state,
        detalheFaturaDownloadResponse,
      };
    }

    case GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD: {
      const detalheFaturaDownloadPDFResponse =
        action.detalheFaturaDownloadPDFResponse;
      return {
        ...state,
        detalheFaturaDownloadPDFResponse,
      };
    }

    case ATIVA_MENU_DETALHE_FATURA: {
      const newListViagem = state.detalheFaturaResponse?.listaViagem?.map(
        (detalheItem) => {
          if (
            detalheItem.numeroIdentificacaoViagem ===
            action.detalheFatura.numeroIdentificacaoViagem
          ) {
            return { ...detalheItem, menuAtivado: !detalheItem.menuAtivado };
          }
          return { ...detalheItem, menuAtivado: false };
        }
      );
      const newDetalheFaturasResponse = {
        ...state.detalheFaturaResponse,
        listaViagem: newListViagem,
      };
      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturasResponse,
      };
    }

    case ORDENA_PARCEIRO: {
      const newDetalheFaturaResponse = {
        ...state.detalheFaturaResponse,
        ...action.detalheFaturaResponse,
        headers: action.headers,
        parceiroOrdenado: !state.detalheFaturaResponse?.parceiroOrdenado,
        totalOrdenado: false,
        dataOrdenada: false,
        colaboradorOrdenado: false,
        textoOrdemCampo: 'parceiro',
      };

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }
    case ORDENA_VALOR_TOTAL: {
      const newDetalheFaturaResponse = {
        ...state.detalheFaturaResponse,
        ...action.detalheFaturaResponse,
        headers: action.headers,
        parceiroOrdenado: false,
        colaboradorOrdenado: false,
        dataOrdenada: false,
        totalOrdenado: !state.detalheFaturaResponse?.totalOrdenado,
        textoOrdemCampo: 'valorViagem',
      };

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }

    case ORDENA_COLABORADOR: {
      const newDetalheFaturaResponse = {
        ...state.detalheFaturaResponse,
        ...action.detalheFaturaResponse,
        headers: action.headers,
        parceiroOrdenado: false,
        colaboradorOrdenado: !state.detalheFaturaResponse?.colaboradorOrdenado,
        totalOrdenado: false,
        dataOrdenada: false,
        textoOrdemCampo: 'colaborador',
      };

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }

    case ORDENA_DATA: {
      const newDetalheFaturaResponse = {
        ...state.detalheFaturaResponse,
        ...action.detalheFaturaResponse,
        headers: action.headers,
        parceiroOrdenado: false,
        colaboradorOrdenado: false,
        totalOrdenado: false,
        dataOrdenada: !state.detalheFaturaResponse?.dataOrdenada,
        textoOrdemCampo: 'datafinal',
      };

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }

    case FILTRA_CAMPO: {
      let newDetalheFaturaResponse;

      if (action?.detalheFaturaResponse) {
        newDetalheFaturaResponse = {
          ...state.detalheFaturaResponse,
          ...action.detalheFaturaResponse,
          headers: action.headers,
        };
      } else {
        newDetalheFaturaResponse = {
          ...state.detalheFaturaResponse,
          headers: action.headers,
        };
      }

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }

    case CONTROLE_PAGINACAO: {
      const newDetalheFaturaResponse = {
        ...state.detalheFaturaResponse,
        ...action.detalheFaturaResponse,
        headers: action.headers,
        numeroPagina: action.numeroPagina,
      };

      return {
        ...state,
        detalheFaturaResponse: newDetalheFaturaResponse,
      };
    }

    default:
      return state;
  }
};

export default detalheFaturasReducer;
