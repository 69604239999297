import {
  FiltrosHeadersGrafico,
  IGrafico,
} from '../../../@types/interfaces/graficoApi';

export const GET_VIAGENS_GRAFICO = 'GRAFICO/GET_VIAGENS';

export const FILTRA_CAMPO = 'GRAFICO/FILTRA_CAMPO';

interface GetViagensGrafico {
  type: typeof GET_VIAGENS_GRAFICO;
  headers?: FiltrosHeadersGrafico;
  grafico: IGrafico;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  headers?: FiltrosHeadersGrafico;
  grafico: IGrafico;
}

export type GraficoActionTypes = GetViagensGrafico | FiltraCampo;
