import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import * as Styled from './styles';
import IconeErro from '../../assets/icone-erro.svg';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { DadosProps } from '../../@types/interfaces/dadosNovoColaborador';

interface InputFormularioNovoColaboradorProps {
  id?: string;
  label: string;
  campoPreenchido?: boolean;
  disabled?: boolean;
  register: UseFormRegister<DadosProps>;
  value?: string;
  readOnly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  errors?: FieldError;
  campo:
    | 'email'
    | 'cpf'
    | 'dataNascimento'
    | 'nome'
    | 'celular'
    | 'cargo'
    | 'centroCustoVinculado'
    | 'categoria'
    | `centroCustoVinculado.${number}`;
}

const InputFormularioNovoColaborador = ({
  id,
  label,
  campoPreenchido,
  disabled,
  register,
  campo,
  value,
  onChange,
  onBlur,
  errors,
}: InputFormularioNovoColaboradorProps) => {
  return (
    <Styled.Input>
      <Styled.Label campoPreenchido={campoPreenchido} disabled={disabled}>
        {label}
      </Styled.Label>
      <Styled.Campo
        id={id}
        campoPreenchido={campoPreenchido}
        disabled={disabled}
        {...register(campo)}
        value={value}
        readOnly={campoPreenchido}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Styled.Erro>
        {errors && (
          <>
            <Styled.IconeErro src={IconeErro} />
            {errors.message}
          </>
        )}
      </Styled.Erro>
    </Styled.Input>
  );
};

export default InputFormularioNovoColaborador;
