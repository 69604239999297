import styled from 'styled-components';
import ChevronDisabled from '../../assets/chevron_down_gray.svg';
import ChevronActive from '../../assets/chevron_down_blue.svg';
import { Chevron } from '../../styles/Mixins/Chevron';

interface ImgProp {
  imageUrlActive?: string;
  imageUrlDisabled?: string;
}
export const StyledPaginateContainer = styled.div<ImgProp>`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5vw;
  height: fit-content;
  margin-bottom: 3vw;

  a {
    color: var(--action);
    font-weight: 500;
    font-size: 0.9vw;
    cursor: pointer;
    padding: 0.64vw;
    border-radius: 1.28vw;
    display: flex;
    align-items: center;
    &:first-child {
      padding: 0;
      border-radius: 0;
    }
    &:last-child {
      padding: 0;
      border-radius: 0;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-color: transparent;
    background-color: var(--action);
    border-radius: 2.88vw;
    a {
      color: var(--white);
    }
  }
  .previous {
    ${Chevron({
      left: 28,
      rotate: 90,
      imageUrlActive: ChevronActive,
      imageUrlDisabled: ChevronDisabled,
    })};
    a:after {
      padding: 0.64vw;
      left: 0.32vw;
    }
  }
  .next {
    ${Chevron({
      left: 10,
      rotate: 270,
      imageUrlActive: ChevronActive,
      imageUrlDisabled: ChevronDisabled,
    })};
    a:after {
      padding: 0.64vw;
      left: 0.32vw;
    }
  }
  .break-me {
    background-color: transparent;
  }
  li {
    margin-left: 0.51vw;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 1.75vw;
    height: 1.75vw;
    background-color: var(--cinza-10);
    border-radius: 0.25vw;
  }

  @media (max-width: 835px) {
    margin-top: 2.25vw;
    a {
      font-size: 1.35vw;
      padding: 0.96vw;
      border-radius: 1.92vw;
    }
    .active {
      border-radius: 4.32vw;
    }
    .previous {
      a:after {
        padding: 0.96vw;
        left: 0.48vw;
      }
    }
    .next {
      a:after {
        padding: 0.96vw;
        left: 0.48vw;
      }
    }
    li {
      margin-left: 0.76vw;
      width: 2.62vw;
      height: 2.62vw;
      border-radius: 0.37vw;
    }
  }
`;
