import styled from 'styled-components';
import { Icon } from '../../styles/globals';
import { StylesConfig } from 'react-select';
import colors from '../../styles/colors';

interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
}

export const SeletorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.05vw;
  height: 3.87vw;
`;

export const IconSeletor = styled(Icon)<ImgProps>`
  width: 0.72vw;
  height: 0.44vw;
  margin: 0;
  cursor: default;
`;

export const Label = styled.label`
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.83vw;
  margin-bottom: 0.55vw;
`;

const getBackgroundColor = (
  isDisabled: boolean,
  isFocused: boolean,
  isSelected: boolean
) => {
  if (!isDisabled && isFocused && !isSelected) {
    return colors.bgSecondaryDefault;
  }
  if (!isDisabled && isSelected) {
    return colors.action;
  }
};

const msgStyles = {
  background: 'white',
  color: colors.cinza40,
  fontSize: '0.97vw',
};

export const styles: StylesConfig<any> = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const fontWeight = '500';
    return {
      ...styles,
      backgroundColor: getBackgroundColor(isDisabled, isFocused, isSelected),
      fontSize: '0.83vw',
      fontWeight,
    };
  },
  control: (styles) => ({
    ...styles,
    border: '0.05vw solid',
    padding: '0.11vw 0.44vw',
    borderColor: colors.cinza40,
    borderRadius: '0.41vw',
    fontSize: '0.83vw',
    height: '2.4vw',
    minHeight: '2.4vw',
    minWidth: '10vw',
    flexWrap: 'nowrap',
    '&:active': {
      boxShadow: `0 0 0 0.06vw ${colors.action}`,
    },
    '&:hover': {
      borderColor: colors.action,
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
    };
  },
  singleValue: (provided) => {
    const fontWeight = '500';
    const fontSize = '0.83vw';
    const display = 'flex';
    const alignItems = 'center';

    return {
      ...provided,
      color: colors.cinza60,
      fontWeight,
      fontSize,
      borderColor: colors.cinza40,
      display,
      alignItems,
    };
  },
  noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
  menu: (base) => ({
    ...base,
    height: 'max-content',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
  }),
};
