import { IDetalharFatura } from '../../../@types/interfaces/detalharFatura';
import { DetalheViagemResponse } from '../../../@types/interfaces/detalheViagem';

export const GET_DETALHES_VIAGEM = 'DETALHE_VIAGEM/GET_DETALHES_VIAGEM';
export const SET_DETALHE_VIAGEM_SELECIONADA =
  'DETALHE_VIAGEM/SET_DETALHE_FATURA_SELECIONADA';

export interface DetalheViagemState {
  detalheViagemResponse: DetalheViagemResponse;
  detalheViagemSelecionado: IDetalharFatura | undefined;
}

export interface GetDetalheViagem {
  type: typeof GET_DETALHES_VIAGEM;
  detalheViagemResponse: DetalheViagemResponse;
}
interface SetDetalheFaturaSelecionada {
  type: typeof SET_DETALHE_VIAGEM_SELECIONADA;
  detalheViagemSelecionado: IDetalharFatura;
}

export type DetalheViagemActionType =
  | GetDetalheViagem
  | SetDetalheFaturaSelecionada;
