import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Icon } from '../../styles/globals';

export const WrapperConteudoModalJustificativa = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 600px;
    height: auto;
  }
`;

export const ConteudoModalJustificativaAteste = styled(motion.div)`
  border-radius: 15px;
  background-color: var(--white);
  height: 50%;
  width: 59%;
  padding: 2.51vw 2vw;
  border-radius: 15px;
  position: relative;
`;

export const IconeFecharJustificativa = styled(Icon)`
  position: absolute;
  right: 2.5vw;
  padding: 0.5vw;
  top: 2.5vw;
  border-radius: 0.446vw;
  cursor: pointer;
  margin-left: 0;
  margin-right: -1vw;
  margin-top: -1vw;
`;

export const WrapperConteudo = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const ModalTitulo = styled.div`
  font-size: 1.2vw;
  color: var(--black);
  height: 0px;
  margin-top: -12px;
  margin-left: -0.7vw;
  font-weight: bold;
`;

export const ModalImg = styled.img`
  margin-left: 0.5vw;
  width: 8.3%;
  padding: 1.3%;
  margin-top: -1.2vw;

  background-color: #f4f4f4;
  border-radius: 5px;
`;

export const ConferirDados = styled.div`
  padding: 0.4vw;
  align-items: center;
`;

export const ModalTextoPai = styled.p`
  font-weight: bold;
  font-size: 0.9vw;
  color: var(--black);
  margin-top: 3.5vw;
`;

export const ModalTextoFilho = styled.p`
  font-size: 1vw;
  color: var(--black);
  margin-top: 6%;
  line-height: 2;
  margin-bottom: 2%;
  margin-left: -0.7vw;
`;

export const LinhaCinzaTitulo = styled.hr`
  width: 106%;
  height: 0px;
  border: none;
  border-top: 1px solid #d9d8d8;
  margin-left: -0.8vw;
  margin-top: 3.5vw;
`;

export const LinhaCinzaTexto = styled.hr`
  width: 106%;
  height: 0px;
  border: none;
  border-top: 1px solid #d9d8d8;
  margin-left: -1.2vw;
  margin-top: 4vw;
`;

export const LinhaLongaCinza = styled.hr`
  width: 100%;
  height: 0.1vw;
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 2%;
  left: 0vw;
  position: absolute;
`;

export const Indicadores = styled.p`
  display: flex;
  align-items: center;
  padding: 0.4vw;
  font-size: 0.9vw;
  margin-top: 10px;
  margin-bottom: 6px;
`;

export const WrapperDetalhamento = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 5px;
`;

export const TextoIndicadores = styled.div`
  padding: 0.4vw;
  font-size: 0.9vw;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ValorIndicadores = styled.div`
  font-size: 0.9vw;
  font-weight: normal;
  display: flex;
  margin-top: 1.5%;
`;

export const ModalTextoNota = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  margin-top: 3%;
  padding: 0.4%;
`;

export const ModalTextoNotaFilho = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  padding: 0.4%;
  margin-top: 4%;
`;

export const ModalSegundoTextoNotaFilho = styled.p`
  font-size: 0.9vw;
  color: var(--black);
  padding: 0.4%;
`;

export const ModalImgPoints = styled.img`
  height: auto;
  width: 4%;
  margin-left: 1%;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: -5%;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 600px;
    height: auto; /* Permitir que a altura se ajuste conforme o conteúdo */
  }
`;

export const ButtonEnviar = styled.button`
  margin-left: 23vw;
  margin-top: 5%;
  margin-bottom: -20%;
  height: 3.6vw;
  width: 20%;
  border-radius: 15px;
  background-color: ${(props) => (props.disabled ? '#cccccc' : '#005aa5')};
  color: ${(props) => (props.disabled ? '#666666' : '#ffffff')};
  font-size: 1.1vw;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border: none;
  outline: none;
`;

export const ButtonCancelar = styled.button`
  margin-left: -14vw;
  margin-top: 05%;
  height: 3.6vw;
  width: 25%;
  border-radius: 15px;
  background-color: #e2e2e2;
  color: black;
  font-size: 1.1vw;
  font-weight: bold;
`;

export const TextArea = styled.textarea`
  margin-left: -0.9vw;
  margin-top: 3%;
  width: 106%;
  height: 14vw;
  border-top: 1px solid #d9d8d8;
  border-radius: 15px;
  font-size: 1.1vw;
  padding: 10px;
  resize: none;
  border: 1px solid #ccc;
`;
