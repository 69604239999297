import * as graficoActions from './actions';
import grafico from '../../../api/grafico';
import { FiltrosHeadersGrafico } from '../../../@types/interfaces/graficoApi';

export const fetchViagensDoGrafico =
  (headers: FiltrosHeadersGrafico) => async (dispatch: any) => {
    try {
      const graficoResponse = await grafico.getViagens(headers);

      dispatch(graficoActions.setViagensDoGrafico(headers, graficoResponse));

      return graficoResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeaders =
  (headers: FiltrosHeadersGrafico) => async (dispatch: any) => {
    try {
      const graficoResponse = await grafico.getViagens(headers);
      dispatch(graficoActions.filtraCampo(headers, graficoResponse));
      return graficoResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
