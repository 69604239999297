import {
  LIMPA_CONTRATO,
  ListaContratosActionTypes,
  SET_CONTRATO_SELECIONADO,
  SET_LISTA_CONTRATOS,
} from './types';
import { ContratoResponse, ContratoSelecionado } from '../../../api/contrato';

export function setListaContratos(
  contratoResponse: ContratoResponse
): ListaContratosActionTypes {
  return {
    type: SET_LISTA_CONTRATOS,
    contratoResponse,
  };
}

export function setContratoSelecionado(
  contratoSelecionado: ContratoSelecionado
): ListaContratosActionTypes {
  return {
    type: SET_CONTRATO_SELECIONADO,
    contratoSelecionado,
  };
}

export function limpaContrato(): ListaContratosActionTypes {
  return {
    type: LIMPA_CONTRATO,
  };
}
