import styled from 'styled-components';

interface DisabledProps {
  disabled?: boolean;
  selecionado?: boolean;
}

export const NumeracaoPagina = styled.div`
  margin: 0.55vw 0 0.55vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconeSetaBaixo = styled.img`
  width: 1.05vw;
  height: 0.61vw;
  margin: 0.39vw 0.39vw 0.39vw 6.69vw;
`;

export const Pagina = styled.div`
  width: 8.03vw;
  display: flex;
  align-items: center;
`;

export const PaginaNumero = styled.div`
  font-weight: 600;
  font-size: 1.05vw;
  line-height: 1.05vw;
  color: var(--white);
  background-color: var(--action);
  width: 1.78vw;
  height: 1.78vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginaImagem = styled.img`
  width: 0.78vw;
  height: 0.61vw;
`;

export const Linha = styled.div`
  width: 4.46vw;
  height: 0.11vw;
  background-color: var(--action);
`;

export const InputSeletor = styled.div`
  padding-top: 0.11vw;
  height: fit-content;
  width: 27.65vw;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const Display = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  row-gap: 0.55vw;
  column-gap: 0.55vw;
  flex-wrap: wrap;
  max-width: 27.9vw;
  padding-top: 0.55vw;
  margin-bottom: 0.83vw;
`;

export const InputCategoria = styled.div`
  height: fit-content;
  width: 27.65vw;
  margin-top: 4%;

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const LabelSeletor = styled.label`
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

export const TextoPai = styled.label`
  margin-top: 1.67vw;
  margin-bottom: 1.67vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

export const TextoFilho = styled.label`
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.6vw;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 3.9vw;
  }
`;

export const Field = styled.div`
  display: flex;
  width: 27.65vw;
  justify-content: space-between;
  margin-top: 0.83vw;
`;

export const FieldButton = styled.div`
  display: flex;
  width: 27.65vw;
  justify-content: space-between;
  margin-top: 4vw;
`;

export const Switch = styled.div<{ selecionado: boolean }>`
  display: flex;
  width: 2.79vw;
  height: 1.39vw;
  border: 0.16vw solid var(--white);
  justify-content: ${(props) =>
    props.selecionado ? 'flex-end' : 'flex-start'};
  background-color: ${(props) =>
    props.selecionado ? 'var(--action)' : 'var(--cinza-30)'};
  border-radius: 0.83vw;
  margin-left: 1.11vw;
`;

export const SwitchBotao = styled.button<{
  selecionado: boolean;
  disabled?: boolean;
}>`
  width: 0.83vw;
  height: 0.83vw;
  background-color: var(--white);
  border-radius: 50%;
  align-self: center;

  margin: 0.11vw;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  animation: ${(props) => (props.selecionado ? 'ligado 1s' : 'desligado 1s')};

  @keyframes ligado {
    from {
      margin-right: 1.67vw;
    }
    to {
      margin-left: 0;
    }
  }

  @keyframes desligado {
    from {
      margin-left: 1.67vw;
    }
    to {
      margin-right: 0;
    }
  }
`;

export const BotaoCancel = styled.button`
  width: 12.8vw;
  height: 2.28vw;
  border-radius: 0.44vw;
  border: 0.16vw solid var(--cinza-30);
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.67vw;
  color: var(--action);
  background-color: var(--white);

  @media (max-width: 768px) {
    width: 37.5vw;
    font-size: 4.5vw;
  }
`;

export const BotaoSubmit = styled.button<DisabledProps>`
  width: 12.8vw;
  height: 2.28vw;
  border-radius: 0.44vw;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.67vw;
  color: var(--white);
  background-color: ${(props) => props.disabled && 'var(--cinza-30)'};
  cursor: ${(props) => props.disabled && 'auto'};

  @media (max-width: 768px) {
    width: 37.5vw;
    font-size: 4.5vw;
  }
`;

export const Erro = styled.span`
  height: 10%;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1.47vw;
  color: var(--salmon);
  display: flex;
  align-items: center;
  max-width: 27.5vw;
  margin-top: 0.5vw;

  @media (max-width: 768px) {
    max-width: 80vw;
    font-size: 2vw;
  }
`;

export const Form = styled.div`
  margin-top: 0.55vw;
  padding-bottom: 10.65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const IconeErro = styled.img`
  margin-right: 2%;
  width: 0.8vw;
  height: 0.8vw;

  @media (max-width: 768px) {
    width: 2vw;
    height: 2vw;
  }
`;
