import React, { ReactElement } from 'react';
import * as Styled from './styles';
import LogoBancoBranca from '../../assets/logo-bb-branco.svg';
import { useLocation } from 'react-router-dom';

export const Footer = (): ReactElement => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/login' && (
        <Styled.Footer>
          <Styled.FooterContainer>
            <Styled.Solucao>
              <Styled.SpanSolucao>Uma solução</Styled.SpanSolucao>
              <Styled.Logo>
                <Styled.LogoBanco imageUrl={LogoBancoBranca} />
              </Styled.Logo>
              <Styled.SpanBanco>Banco do Brasil</Styled.SpanBanco>
            </Styled.Solucao>
          </Styled.FooterContainer>
        </Styled.Footer>
      )}
    </>
  );
};
