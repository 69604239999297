import styled from 'styled-components';

export const Extra = styled.div`
  width: 21.45vw;
  height: 1.25vw;
  margin-bottom: 0.95vw;
  background-color: var(--cinza-10);
  font-weight: 500;
  font-size: 0.55vw;
  line-height: 125%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.45vw;
  cursor: pointer;
`;

export const Item = styled.div``;

export const Quantidade = styled.span`
  margin-left: 0.4vw;
  font-weight: 400;
  font-size: 0.55vw;
  line-height: 125%;
  color: var(--cinza-50);
`;

export const SetaBaixoAzul = styled.img`
  width: 0.41vw;
  height: 0.244vw;
`;

export const ItemLista = styled.div`
  font-weight: 500;
  font-size: 0.55vw;
  line-height: 125%;
  margin: 0.25vw 0 0.7vw 0.45vw;
  display: flex;
  flex-direction: column;
`;

export const ItemListaSpan = styled.span`
  font-weight: 500;
  font-size: 0.44vw;
  line-height: 125%;
  color: var(--cinza-40);
`;

export const InformacoesExtras = styled.div`
  align-self: center;
  margin-top: 1.1vw;
`;

export const ItemUltimasViagens = styled.div`
  font-weight: 500;
  font-size: 0.55vw;
  line-height: 0.55vw;
  color: var(--backdrop);
  margin: 0.4vw 0 0 0;
`;

export const WrapperDataImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: 1vw;
  width: fit-content;
`;

export const IconeViagem = styled.img`
  width: 0.68vw;
  height: 0.68vw;
  margin-right: 0.4vw;
  margin-top: 0.4vw;
`;

export const WrapperData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: fit-content;
  margin-right: 1vw;
`;

export const DataExtenso = styled.span`
  margin-left: 0;
`;

export const DescricaoViagem = styled.div`
  display: flex;
  align-items: center;
`;

export const TextoDescricaoViagem = styled.p`
  font-weight: 500;
  font-size: 0.55vw;
  line-height: 0.89vw;
  letter-spacing: 0.01vw;
  color: #202020;
  mix-blend-mode: normal;
  opacity: 0.65;
  margin-bottom: 0.3vw;
`;

export const DivisaoViagem = styled.div`
  height: 0.71vw;
  background-color: var(--cinza-60);
  width: 0.05vw;
  margin-left: 1.3vw;
`;

export const ExtraInfos = styled.ul`
  max-height: 6.17vw;
  overflow-y: scroll;
`;
