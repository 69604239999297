import React, { SetStateAction } from 'react';
import * as Styled from './styles';
import deleteItem from '../../assets/delete-item-white.svg';
import { DadosProps } from '../../@types/interfaces/dadosNovoColaborador';

interface CentroCusto {
  value: string;
  label: string;
}

interface ItemColaboradorProps {
  title: string;
  centroCusto: CentroCusto[];
  setCentroCusto: (newSelect: CentroCusto[]) => void;
  numeroCentroCusto: number[];
  dados: DadosProps;
  setDados: React.Dispatch<SetStateAction<DadosProps>>;
  disabled?: boolean;
}

const ItemColaborador = ({
  title,
  centroCusto,
  setCentroCusto,
  dados,
  setDados,
  numeroCentroCusto,
  disabled,
}: ItemColaboradorProps) => {
  const removeOption = (titleToRemove: string) => {
    const newSelect: CentroCusto[] = centroCusto.filter(
      (item: CentroCusto): boolean => item.label !== titleToRemove
    );

    const newNumeroCentroCusto: number[] = newSelect.map((item) =>
      parseInt(item.value)
    );

    setCentroCusto(newSelect);

    numeroCentroCusto.splice(
      0,
      numeroCentroCusto.length,
      ...newNumeroCentroCusto
    );

    setDados({
      ...dados,
      centroCustoVinculado: numeroCentroCusto,
    });
  };

  return (
    <Styled.ItemColaborador id="itemColaborador" disabled={disabled}>
      <Styled.Label disabled={disabled}> {title} </Styled.Label>
      <Styled.Botao
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          removeOption(title);
        }}>
        <Styled.BotaoIcone src={deleteItem} />
      </Styled.Botao>
    </Styled.ItemColaborador>
  );
};

export default ItemColaborador;
