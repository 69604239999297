import { ErroActionTypes, LIMPA_ERRO, SET_ERRO } from './types';

interface ErroState {
  codigoErro: string;
  mensagemErro?: string;
}

const initialState: ErroState = {
  codigoErro: '',
  mensagemErro: '',
};

const erroReducer = (
  state = initialState,
  action: ErroActionTypes
): ErroState => {
  switch (action?.type) {
    case SET_ERRO: {
      const codigoErro = action.codigoErro;
      const mensagemErro = action.mensagemErro;
      return {
        ...state,
        codigoErro,
        mensagemErro,
      };
    }

    case LIMPA_ERRO: {
      return initialState;
    }

    default:
      return state;
  }
};

export default erroReducer;
