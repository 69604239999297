import api from '../../../api/getColaborador';
import * as colaboradorAction from './actions';
import { logger } from '../../../util/logger';
import { Dispatch } from 'react';
import { SetColaborador } from './types';
import { FiltrosHeaderColaborador } from '../../../@types/interfaces/Colaborador';

export const fetchColaborador =
  (headers: FiltrosHeaderColaborador) =>
  async (dispatch: Dispatch<SetColaborador>) => {
    try {
      const colaboradorResponse = await api.getColaborador(headers);
      dispatch(colaboradorAction.setColaboradorResponse(colaboradorResponse));
      return colaboradorResponse;
    } catch (error: any) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "fetchColaborador".
        <'ERRO'>
          message: Não foi possível retornar o colaborador!
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    }
  };
