import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import ImgPointsCentroCusto from '../../assets/points.svg';
import ImgDetalheCentroCusto from '../../assets/icon-detalhe.svg';
import * as Styled from './styles';
import { Pagination } from '../../components/Pagination';
import {
  formatarValorRestante,
  formatarReal,
  setarBackground,
} from '../../helpers/formatacoes';
import { CardNoData } from '../../components/Cards/CardNoData';
import useContratos from '../../hooks/useContratos';
import useCentrosDeCustos from '../../hooks/useCentrosDeCustos';
import { ICentroDeCusto } from '../../@types/interfaces/contratoApi';
import IconeNovoCentroDeCusto from '../../assets/icone-novo-centro-de-custo.svg';

export interface CentroCustoProps {
  setLoading: (value: boolean) => void;
}

const CentroDeCusto = ({ setLoading }: CentroCustoProps) => {
  const quantidadeItemsPaginaCentroCusto = 8;

  const navigate = useNavigate();

  const { contratoSelecionado } = useContratos();

  const {
    handleCentrosDeCustosPorContrato,
    centrosDeCustosResponse,
    handlePaginacaoCentroDeCusto,
  } = useCentrosDeCustos();

  const quantidadePaginacao = (
    quantidadeTotalRegistro: number,
    itensPagina: number
  ) => {
    return Math.ceil(quantidadeTotalRegistro / itensPagina);
  };

  useEffect(() => {
    if (contratoSelecionado?.id) {
      handleCentrosDeCustosPorContrato(setLoading).then(() => ({}));
    }
  }, [contratoSelecionado]);

  return (
    <Styled.Content>
      <>
        <Styled.WrapperConteudo>
          <Styled.Titulo>Centro de custo</Styled.Titulo>
        </Styled.WrapperConteudo>
        <Styled.WrapperSelectorColaboradores>
          <Styled.BotaoCadastro
            onClick={() => {
              navigate('cadastro-centro-de-custo');
            }}>
            <Styled.Icone src={IconeNovoCentroDeCusto} />
            Adicionar Centro de Custo
          </Styled.BotaoCadastro>
        </Styled.WrapperSelectorColaboradores>
        {centrosDeCustosResponse?.listaRegistro.length > 0 ? (
          <React.Fragment>
            <Styled.TabelaFaturasCentro>
              <Styled.TabelaHeadFaturasCentro>
                <Styled.TabelaRowFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro>
                    <Styled.TabelaComIconeCentro>
                      Código
                    </Styled.TabelaComIconeCentro>
                  </Styled.TabelaHeaderFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro>
                    <Styled.TabelaComIconeCentro>
                      Nome
                    </Styled.TabelaComIconeCentro>
                  </Styled.TabelaHeaderFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro>
                    Periodicidade
                  </Styled.TabelaHeaderFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro>
                    <Styled.TabelaComIconeCentro>
                      Orçamento (R$)
                    </Styled.TabelaComIconeCentro>
                  </Styled.TabelaHeaderFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro>
                    <Styled.TabelaComIconeCentro>
                      Valor restante (R$)
                    </Styled.TabelaComIconeCentro>
                  </Styled.TabelaHeaderFaturasCentro>
                  <Styled.TabelaHeaderFaturasCentro></Styled.TabelaHeaderFaturasCentro>
                </Styled.TabelaRowFaturasCentro>
              </Styled.TabelaHeadFaturasCentro>
              <Styled.TabelaBodyFaturasCentro>
                {centrosDeCustosResponse.listaRegistro.map(
                  (item: ICentroDeCusto) => {
                    return (
                      <Styled.TabelaRowFaturasCentro
                        key={item.numeroDeIdentificacao.toString()}>
                        <Styled.TabelaDataCellFaturasCentro>
                          {item.numeroDeIdentificacao}
                        </Styled.TabelaDataCellFaturasCentro>
                        <Styled.TabelaDataCellFaturasCentro>
                          {item.nome.toUpperCase()}
                        </Styled.TabelaDataCellFaturasCentro>
                        <Styled.TabelaDataCellFaturasCentro>
                          {item.orcamento.periodicidade.toUpperCase()}
                        </Styled.TabelaDataCellFaturasCentro>
                        <Styled.TabelaDataCellFaturasCentro>
                          {formatarReal(item.orcamento.valor)}
                        </Styled.TabelaDataCellFaturasCentro>
                        <Styled.TabelaDataCellFaturasCentro>
                          <Styled.TextTotalValue
                            color={setarBackground(
                              item.orcamento.valor,
                              item.valorFaturadoDoCentroDeCusto
                            )}>
                            {formatarValorRestante(
                              item.orcamento.valor,
                              item.valorFaturadoDoCentroDeCusto
                            )}
                          </Styled.TextTotalValue>
                        </Styled.TabelaDataCellFaturasCentro>
                        <Styled.TabelaDataCellFaturasCentro hasPoints>
                          <Styled.IconContentCentro>
                            <Styled.MenuCentro>
                              <Styled.MenuListCentro>
                                <Styled.MenuItemCentro>
                                  <Styled.MenuRowCentro>
                                    <Styled.IconDetalheCentro
                                      imageUrl={ImgDetalheCentroCusto}
                                      width={20}
                                      height={20}
                                    />

                                    <Styled.TextDefaultCentro>
                                      Detalhar
                                    </Styled.TextDefaultCentro>
                                  </Styled.MenuRowCentro>
                                </Styled.MenuItemCentro>
                              </Styled.MenuListCentro>
                            </Styled.MenuCentro>
                            <Styled.IconPointsCentro
                              imageUrl={ImgPointsCentroCusto}
                              width={4}
                              height={18}
                            />
                          </Styled.IconContentCentro>
                        </Styled.TabelaDataCellFaturasCentro>
                      </Styled.TabelaRowFaturasCentro>
                    );
                  }
                )}
              </Styled.TabelaBodyFaturasCentro>
            </Styled.TabelaFaturasCentro>
            <Pagination
              quantidadeDePaginas={quantidadePaginacao(
                centrosDeCustosResponse.quantidadeTotalRegistro,
                quantidadeItemsPaginaCentroCusto
              )}
              onPageChange={(selectedItem) => {
                handlePaginacaoCentroDeCusto(selectedItem, setLoading).then(
                  () => ({})
                );
              }}
            />
          </React.Fragment>
        ) : (
          <CardNoData
            titulo="Essa área está muito vazia, não acha?"
            descricao="No momento não há centros de custo para este contrato. Tente trocar de contrato!"
          />
        )}
      </>
    </Styled.Content>
  );
};

export default IsLoadingHOC(CentroDeCusto);
