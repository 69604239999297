import React, { ReactElement } from 'react';
import * as Styled from './styles';

interface IBadge {
  tipo?: string;
  texto: string;
}

// @typescript-eslint/no-explicit-any
export const Badge = ({ tipo, texto }: IBadge): ReactElement => {
  return (
    <Styled.BadgeContainer tipo={tipo || texto}>
      {tipo != 'Filtro' && <Styled.Circle></Styled.Circle>}
      <Styled.BadgeText>{texto}</Styled.BadgeText>
    </Styled.BadgeContainer>
  );
};
