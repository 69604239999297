import { DetalheViagemState } from '../../../@types/interfaces/detalheViagem';
import {
  DetalheViagemActionType,
  GET_DETALHES_VIAGEM,
  SET_DETALHE_VIAGEM_SELECIONADA,
} from './types';

const initialState: DetalheViagemState = {
  detalheViagemResponse: {
    nomeParceiro: '',
    empresa: '',
    nomeColaborador: '',
    emailColaborador: '',
    valorViagem: 0,
    distancia: 0,
    dataInicio: new Date(),
    dataFim: new Date(),
    coordenadas: {
      posicaoUsuarioCMU: [-22.8973526, -47.0875219],
      origemViagem: [-22.8973526, -47.0875219],
      destinoViagem: [-22.8220586, -47.0646833],
      tituloOrigemViagem: '',
      tituloDestinoViagem: '',
    },
    numeroIdentificacaoCentroCustoViagem: 550,
    nomeCentroCusto: 'Matriz',
    justificativa: '-',
    codigoViagem: '',
    avaliacao: {
      nota: 0,
      motivo: '',
      razao: '',
    },
    cotacaoVencedora: {
      parceiro: '',
      tipoCotacao: '',
      valorMinimoCotacao: 0,
      valorUtilizado: 0,
      tempoEspera: 0,
      tsCriacao: new Date(),
      icone: '',
    },
    cotacoesRealizadas: [
      {
        parceiro: '',
        tipoCotacao: '',
        valorMinimoCotacao: 0,
        valorMaximoCotacao: 0,
        valorUtilizado: 0,
        dataHora: new Date(),
        tsCriacao: new Date(),
        iconeParceiro: '',
      },
    ],
    restricoesAplicadas: [
      {
        id: '636eba8d3ce2f264d8058b32',
        nomeParceiro: 'noveNovePop',
        motivoRestricao: 'Viagem não atendida',
        dataCriacao: new Date(),
        dataBaixa: new Date(),
        icone: '',
      },
    ],
  },
  detalheViagemSelecionado: {
    numeroIdentificacaoCentroCustoViagem: 10,
    nomeCentroCusto: '',
    numeroIdentificacaoViagem: '',
    nomeColaborador: '',
    codigoCliente: '',
    cpfCnpj: '',
    valorViagem: 10,
    dataViagem: new Date(),
    iconeParceiro: '',
    nomeParceiro: '',
    menuAtivado: false,
    dataReferencia: new Date(),
    detalheFaturaSelecionada: '',
  },
};

const DetalheViagemReducer = (
  state = initialState,
  action: DetalheViagemActionType
) => {
  switch (action.type) {
    case GET_DETALHES_VIAGEM: {
      return {
        ...state,
        detalheViagemResponse: action.detalheViagemResponse,
      };
    }
    case SET_DETALHE_VIAGEM_SELECIONADA: {
      const detalheViagemSelecionado = action.detalheViagemSelecionado;

      return {
        ...state,
        detalheViagemSelecionado,
      };
    }

    default:
      return state;
  }
};

export default DetalheViagemReducer;
