import styled from 'styled-components';

interface ITextoParceiro {
  color?: string;
}
export const TooltipContainer = styled.div`
  padding: 0.781vw;
  background-color: var(--blueBottom);
  border: 0.05vw solid #ccc !important;
  border-radius: 0.446vw;
  height: fit-content;

  p:first-child {
    color: var(--white);
  }

  p {
    padding-bottom: 0.223vw;
    font-weight: 500;
  }

  p:last-child {
    padding-bottom: 0;
  }
`;

export const TextoParceiro = styled.p<ITextoParceiro>`
  color: ${({ color }) => `${color}`};
`;
