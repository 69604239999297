import useAuth from '../../hooks/useAuth';
import SoftwareEngineer from '../../assets/software_engineer.svg';
import Logomarca from '../../assets/logomarca.svg';
import * as Styled from './styles';
import useErro from '../../hooks/useErro';

const Login = () => {
  const { setTipoUsuario } = useAuth();
  const { limpaErro } = useErro();
  const renderizaLoginGcs = async () => {
    limpaErro();
    try {
      setTipoUsuario('GCS');
      const response = `${process.env.REACT_APP_URL_AUTH_GCS}?tipo=ST_J&urlRetorno=${window.location.origin}`;

      window.location.replace(response);
    } catch (error) {
      console.error('Não foi possível efetuar o login.');
    }
  };

  const renderizaLoginBBSSO = async () => {
    limpaErro();
    try {
      setTipoUsuario('BBSSO');
      const response = `${process.env.REACT_APP_URL_AUTH_LOGIN_WEB_SSO}/&goto=${window.location.origin}`;

      window.location.replace(response);
    } catch (error) {
      console.error('Não foi possível efetuar o login.');
    }
  };

  return (
    <Styled.bodyLogin>
      <Styled.bemVindo>
        <Styled.titulo>Seja bem-vindo ao Spid!</Styled.titulo>
        <Styled.subtitulo>Painel de Gestão</Styled.subtitulo>
        <Styled.softwareEngineer src={SoftwareEngineer} id="Imagem" />
        <Styled.texto>
          Acesso fácil e rápido a todas as informações das viagens,
          gerenciamento de orçamento, geração de economias e lucros.
        </Styled.texto>
      </Styled.bemVindo>
      <Styled.conteudoLogin>
        <Styled.logomarca
          src={Logomarca}
          id="Logo do Spid"
          title="Logo do Spid"
        />
        <Styled.formularioLogin>
          <Styled.tituloLogin>Tipos de acesso</Styled.tituloLogin>
          <Styled.Button onClick={renderizaLoginGcs}>Chave J</Styled.Button>
          <Styled.Button onClick={renderizaLoginBBSSO}>Chave F</Styled.Button>
        </Styled.formularioLogin>
      </Styled.conteudoLogin>
    </Styled.bodyLogin>
  );
};

export default Login;
