import React, { useState } from 'react';
import InputCadastroCentroCusto from '../InputCadastroCentroCusto';
import * as Styled from './styles';
import { agenciaMask, contaMask, currencyMask } from '../../helpers/mascaras';
import Select from 'react-select';
import { periodicidades } from '../../helpers/constantes';
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { DadosFormularioCentroCusto } from '../../@types/interfaces/formularioCentroCusto';

interface FormularioNovoCentroCustoProps {
  register: UseFormRegister<DadosFormularioCentroCusto>;
  errors: FieldErrorsImpl<DeepRequired<DadosFormularioCentroCusto>>;
  setValue: UseFormSetValue<DadosFormularioCentroCusto>;
  botaoSubmitDesabilitado: boolean;
}

const FormularioNovoCentroCusto = ({
  register,
  errors,
  setValue,
  botaoSubmitDesabilitado,
}: FormularioNovoCentroCustoProps) => {
  const [dadosComMascara, setDadosComMascara] = useState({
    valor: '',
    agencia: '',
    conta: '',
  });

  return (
    <>
      <InputCadastroCentroCusto
        label={'Nome'}
        register={register}
        campo={'nome'}
        errors={errors.nome}
        width={'27.65vw'}
      />
      <InputCadastroCentroCusto
        label={'Descrição'}
        register={register}
        campo={'descricao'}
        errors={errors.descricao}
        width={'27.65vw'}
      />
      <Styled.WrapperCampoMenor>
        <InputCadastroCentroCusto
          id={'inputOrcamento'}
          label={'Orçamento (R$)'}
          register={register}
          campo={'valor'}
          errors={errors.valor}
          width={'12.5vw'}
          value={dadosComMascara.valor}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDadosComMascara({
              ...dadosComMascara,
              valor: currencyMask(e.target.value),
            })
          }
        />
        <Styled.CampoMenorBloq>
          <Styled.Label>Periodicidade</Styled.Label>
          <Select
            options={periodicidades}
            defaultValue={periodicidades[0]}
            styles={Styled.styles}
            isDisabled={true}
            onChange={(option) => setValue('periodicidade', option.value)}
          />
          <Styled.Erro />
        </Styled.CampoMenorBloq>
      </Styled.WrapperCampoMenor>
      <Styled.WrapperCampoMenor>
        <InputCadastroCentroCusto
          id={'inputAgencia'}
          label={'Agência'}
          labelSpan
          register={register}
          campo={'agencia'}
          errors={errors.agencia}
          width={'12.5vw'}
          value={dadosComMascara.agencia}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDadosComMascara({
              ...dadosComMascara,
              agencia: agenciaMask(e.target.value),
            })
          }
        />
        <InputCadastroCentroCusto
          label={'Conta'}
          labelSpan
          register={register}
          campo={'conta'}
          errors={errors.conta}
          width={'12.5vw'}
          value={dadosComMascara.conta}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDadosComMascara({
              ...dadosComMascara,
              conta: contaMask(e.target.value),
            })
          }
        />
      </Styled.WrapperCampoMenor>
      <Styled.BotaoCadastrar
        id={'botaoCadastrar'}
        disabled={botaoSubmitDesabilitado}>
        Cadastrar
      </Styled.BotaoCadastrar>
    </>
  );
};

export default FormularioNovoCentroCusto;
