import * as cardActions from './actions';
import card from '../../../api/cardColaborador';
import { FiltrosHeadersCard } from '../../../@types/interfaces/cardColaborador';
import { Dispatch } from 'react';
import { CardColaboradorActionTypes } from './types';

export const fetchCardColaborador =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardColaboradorActionTypes>) => {
    try {
      const cardColaboradorResponse = await card.getCardColaborador(headers);

      dispatch(
        cardActions.setCardColaborador(headers, cardColaboradorResponse)
      );

      return cardColaboradorResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeadersColaborador =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardColaboradorActionTypes>) => {
    try {
      const cardColaboradorResponse = await card.getCardColaborador(headers);
      dispatch(cardActions.filtraCampo(headers, cardColaboradorResponse));

      return cardColaboradorResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
