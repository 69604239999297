import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../../store';

class Axios {
  constructor(baseURL: string, config?: AxiosRequestConfig) {
    const configAxios = {
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': `${process.env.REACT_APP_URL_BIFROST_SEGURANCA_API}`,
        'Access-Control-Allow-Credentials': 'true',
      },
    };

    const axiosInstance = axios.create({ ...configAxios, ...config });

    const requestConfig = async (config: AxiosRequestConfig) => {
      const tipoUsuario = store.getState().auth.auth.tipoUsuario;
      return {
        ...config,
        headers: {
          ...config.headers,
          'tipo-login-usuario': tipoUsuario,
        },
      };
    };

    const axiosRequestConfig = (config: AxiosRequestConfig) =>
      requestConfig(config);

    axiosInstance.interceptors.request.use(axiosRequestConfig);

    return axiosInstance;
  }
}

export default Axios;
