import { ReactElement } from 'react';

import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import * as Styled from './styles';
import { customStyles } from './styles';

import ChevronDown from '../../assets/chevron_down_blue.svg';

interface ISeletor {
  label?: string;
}

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Sem opçōes</span>
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Styled.IconSeletor imageUrl={ChevronDown} width={13} height={8} />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <div>X</div>
    </components.MultiValueRemove>
  );
};

export const SeletorNovoColaborador = ({
  label,
  ...props
}: ISeletor & StateManagerProps): ReactElement => {
  return (
    <Select
      {...props}
      styles={customStyles}
      menuPosition={'absolute'}
      menuPortalTarget={document.getElementById('centro-de-custo')}
      components={{
        NoOptionsMessage,
        IndicatorSeparator: () => null,
        DropdownIndicator,
        MultiValueRemove,
      }}
    />
  );
};
