import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as detalheFaturasActions from '../store/ducks/detalharFatura/actions';
import * as operations from '../store/ducks/detalharFatura/operations';
import { ChangeEvent, useCallback } from 'react';
import useErro from './useErro';
import {
  corrigirNomeParceiro,
  formatarDataMesEAno,
} from '../helpers/formatacoes';
import FileDownload from 'js-file-download';
import { FiltrosHeaderDetalheFatura } from '../@types/interfaces/detalharFatura';
import segurancaAxios from '../config/axios/SegurancaAxios';
import { ExtensaoArquivoEnum } from '../@types/enums/ExtensaoArquivo.enum';

function useDetalhesFatura() {
  const { detalheFaturaResponse, detalheFaturaDownloadResponse } = useSelector(
    (state: RootState) => state.detalheFatura
  );

  const { faturaSelecionada } = useSelector(
    (state: RootState) => state.faturas
  );

  const { contratoSelecionado } = useSelector(
    (state: RootState) => state.contrato
  );

  const { mostraErro, limpaErro } = useErro();

  const {
    fetchDetalheFaturasPorContratoDownloadPDF,
    fetchDetalheFaturasPorContrato,
    ativaMenuDetalheFatura,
    fetchOrdenaColaborador,
    fetchOrdenaData,
    fetchOrdenaTotal,
    fetchFiltrosHeaders,
    fetchControlePaginacao,
    filtraCampo,
    limpaFaturas,
  } = bindActionCreators(
    {
      ...detalheFaturasActions,
      ...operations,
    },
    useDispatch()
  );

  const consultaDetalheFaturasPorContrato = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        const headers = {
          ...detalheFaturaResponse?.headers,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        };
        setLoading(true);
        await fetchDetalheFaturasPorContrato(headers);
        limpaErro();
      } catch (error: any) {
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '005'
        ) {
          limpaFaturas();
        } else {
          mostraErro(error?.response?.data?.code);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      faturaSelecionada,
      detalheFaturaResponse?.headers,
      detalheFaturaDownloadResponse,
    ]
  );

  const downloadXLSX = async (setLoading: (value: boolean) => void) => {
    const nomeExibicao = contratoSelecionado?.nomeDeExibicao;
    const numeroDeIdentificacao = contratoSelecionado?.numeroDeIdentificacao;

    setLoading(true);
    const response = await segurancaAxios.get('download/detalhar', {
      headers: {
        ...detalheFaturaResponse?.headers,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        'tipo-arquivo': ExtensaoArquivoEnum.XLSX,
      },
      responseType: 'arraybuffer',
    });

    const retorno = await response.data;

    const blob: Blob = new Blob([retorno], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    setLoading(false);

    FileDownload(
      blob,
      `fatura_Spid-${formatarDataMesEAno(
        detalheFaturaResponse?.dataReferencia
      )}-${numeroDeIdentificacao}-${nomeExibicao?.replace(/\s+/g, '_')}.xlsx`
    );
  };

  const downloadCsv = async (setLoading: (value: boolean) => void) => {
    const nomeExibicao = contratoSelecionado?.nomeDeExibicao;
    const numeroDeIdentificacao = contratoSelecionado?.numeroDeIdentificacao;

    setLoading(true);
    const response = await segurancaAxios.get('download/detalhar', {
      headers: {
        ...detalheFaturaResponse?.headers,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        'tipo-arquivo': ExtensaoArquivoEnum.CSV,
      },
      responseType: 'arraybuffer',
    });

    const retorno = await response.data;

    const blob: Blob = new Blob([retorno], {
      type: 'text/csv',
    });

    setLoading(false);

    FileDownload(
      blob,
      `fatura_Spid-${formatarDataMesEAno(
        detalheFaturaResponse?.dataReferencia
      )}-${numeroDeIdentificacao}-${nomeExibicao?.replace(/\s+/g, '_')}.csv`
    );
  };

  const downloadPDF = async (setLoading: (value: boolean) => void) => {
    const nomeExibicao = contratoSelecionado?.nomeDeExibicao;

    const headers = {
      ...detalheFaturaResponse?.headers,
      'id-contrato-mobilidade': contratoSelecionado?.id,
      'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      'flag-req-portal': 1,
    };

    const response = await fetchDetalheFaturasPorContratoDownloadPDF(
      headers,
      setLoading
    );

    setLoading(false);

    const linkSource = `data:application/pdf;base64,${response}`;
    const downloadLink = document.createElement('a');
    const fileName = `spid-fatura-${formatarDataMesEAno(
      detalheFaturaResponse?.dataReferencia
    )}-${nomeExibicao?.replace(/\s+/g, '_')}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleOrdenaParceiro = useCallback(async () => {
    try {
      const headers = {
        ...detalheFaturaResponse.headers,
        'campo-ordenado': 'nomeParceiro',
        'ordenacao-do-campo': detalheFaturaResponse.parceiroOrdenado
          ? 'asc'
          : 'desc',
        'numero-pagina': detalheFaturaResponse.numeroPagina?.toString(),
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      await fetchOrdenaColaborador(headers);
    } catch (error: any) {
      console.log('error', error);
    }
  }, [detalheFaturaResponse?.headers]);

  const handleOrdenaColaborador = useCallback(async () => {
    try {
      const headers = {
        ...detalheFaturaResponse.headers,
        'campo-ordenado': 'nomeColaborador',
        'ordenacao-do-campo': detalheFaturaResponse.colaboradorOrdenado
          ? 'asc'
          : 'desc',
        'numero-pagina': detalheFaturaResponse.numeroPagina?.toString(),
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      await fetchOrdenaColaborador(headers);
    } catch (error: any) {
      console.log('error', error);
    }
  }, [detalheFaturaResponse?.headers]);

  const handleOrdenaData = useCallback(async () => {
    try {
      const headers = {
        ...detalheFaturaResponse.headers,
        'campo-ordenado': 'dataViagem',
        'ordenacao-do-campo': !detalheFaturaResponse.dataOrdenada
          ? 'desc'
          : 'asc',
        'numero-pagina': detalheFaturaResponse.numeroPagina?.toString(),
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      await fetchOrdenaData(headers);
    } catch (error: any) {
      console.log('error', error);
    }
  }, [detalheFaturaResponse?.headers]);

  const handleOrdenaTotal = useCallback(async () => {
    try {
      const headers = {
        ...detalheFaturaResponse.headers,
        'campo-ordenado': 'valorViagem',
        'ordenacao-do-campo': !detalheFaturaResponse.totalOrdenado
          ? 'desc'
          : 'asc',
        'numero-pagina': detalheFaturaResponse.numeroPagina?.toString(),
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };
      await fetchOrdenaTotal(headers);
    } catch (error: any) {
      console.log('error', error);
    }
  }, [detalheFaturaResponse?.headers]);

  const handleFiltraNoDetalhes = async (
    headers: FiltrosHeaderDetalheFatura,
    setLoading: (arg0: boolean) => void
  ) => {
    try {
      setLoading(true);
      await fetchFiltrosHeaders(headers);
    } catch (error: any) {
      if (error?.response?.data?.code === '009') {
        limpaFaturas();
        filtraCampo(headers);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFiltraParceiroNoDetalhes = useCallback(
    async (option, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...detalheFaturaResponse.headers,
          'campo-ordenado':
            detalheFaturaResponse.headers?.['campo-ordenado'] || 'dataViagem',
          'numero-pagina': '1',
          'ordenacao-do-campo':
            detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
          'nome-parceiro': option.label && option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        };

        return handleFiltraNoDetalhes(headers, setLoading);
      }
    },
    [detalheFaturaResponse?.headers]
  );

  const handleFiltraCentroCustoNoDetalhes = useCallback(
    async (option, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...detalheFaturaResponse.headers,
          'campo-ordenado':
            detalheFaturaResponse.headers?.['campo-ordenado'] || 'dataViagem',
          'numero-pagina': '1',
          'ordenacao-do-campo':
            detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
          'nome-centro-custo': option.value && option.label,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        };

        return handleFiltraNoDetalhes(headers, setLoading);
      }
    },
    [detalheFaturaResponse?.headers]
  );

  const handleBuscaColaborador = useCallback(
    async (
      event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
      setLoading: (value: boolean) => void
    ) => {
      const textoDoInput = event.target?.value;
      const headers = {
        ...detalheFaturaResponse.headers,
        'numero-pagina': '1',
        'ordenacao-do-campo':
          detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
        'nome-colaborador': textoDoInput,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      return handleFiltraNoDetalhes(headers, setLoading);
    },
    [detalheFaturaResponse?.headers, detalheFaturaDownloadResponse]
  );

  const handleFiltraDataInicioViagemDetalhes = useCallback(
    async (dataStr: string, setLoading: (value: boolean) => void) => {
      const headers = {
        ...detalheFaturaResponse?.headers,
        'campo-ordenado':
          detalheFaturaResponse.headers?.['campo-ordenado'] || 'dataViagem',
        'numero-pagina': '1',
        'ordenacao-do-campo':
          detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
        'data-inicio': dataStr,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      try {
        setLoading(true);
        await fetchFiltrosHeaders(headers);
      } catch (error: any) {
        if (error?.response?.data?.code === '009') {
          limpaFaturas();
          filtraCampo(headers);
        } else {
          mostraErro(error?.response?.data?.code);
        }
      } finally {
        setLoading(false);
      }
    },
    [detalheFaturaResponse?.headers]
  );

  const handleFiltraDataFinalViagemDetalhes = useCallback(
    async (dataStr: string, setLoading: (value: boolean) => void) => {
      const headers = {
        ...detalheFaturaResponse?.headers,
        'campo-ordenado':
          detalheFaturaResponse.headers?.['campo-ordenado'] || 'dataViagem',
        'numero-pagina': '1',
        'ordenacao-do-campo':
          detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
        'data-fim': dataStr,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
      };

      try {
        setLoading(true);
        await fetchFiltrosHeaders(headers);
      } catch (error: any) {
        if (error?.response?.data?.code === '009') {
          limpaFaturas();
          filtraCampo(headers);
        } else {
          mostraErro(error?.response?.data?.code);
        }
      } finally {
        setLoading(false);
      }
    },
    [detalheFaturaResponse?.headers]
  );

  const handlePaginacaoNoDetalhes = useCallback(
    async (
      selectedItem: { selected: number },
      setLoading: (value: boolean) => void
    ) => {
      try {
        setLoading(true);
        const paginaSelecionada = selectedItem.selected + 1;

        const headers = {
          ...detalheFaturaResponse?.headers,
          'numero-pagina': paginaSelecionada.toString(),
          'nome-colaborador': detalheFaturaResponse?.headers?.[
            'nome-colaborador'
          ]
            ? detalheFaturaResponse.headers?.['nome-colaborador']
            : '',
          'data-inicio': detalheFaturaResponse.headers?.['data-inicio']
            ? detalheFaturaResponse.headers?.['data-inicio']
            : '',
          'data-fim': detalheFaturaResponse.headers?.['data-fim']
            ? detalheFaturaResponse.headers?.['data-fim']
            : '',
          'nome-parceiro': detalheFaturaResponse.headers?.['nome-parceiro']
            ? detalheFaturaResponse.headers?.['nome-parceiro']
            : '',
          'ordenacao-do-campo':
            detalheFaturaResponse.headers?.['ordenacao-do-campo'] || '',
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
        };

        await fetchControlePaginacao(headers, paginaSelecionada);
      } catch (error: any) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [detalheFaturaResponse?.headers]
  );

  return {
    detalheFaturaResponse,
    downloadXLSX,
    downloadCsv,
    downloadPDF,
    fetchDetalheFaturasPorContrato,
    ativaMenuDetalheFatura,
    consultaDetalheFaturasPorContrato,
    handleOrdenaParceiro,
    handleOrdenaColaborador,
    handleOrdenaData,
    handleOrdenaTotal,
    handleBuscaColaborador,
    handleFiltraParceiroNoDetalhes,
    handleFiltraCentroCustoNoDetalhes,
    handleFiltraDataInicioViagemDetalhes,
    handleFiltraDataFinalViagemDetalhes,
    handlePaginacaoNoDetalhes,
    corrigirNomeParceiro,
  };
}

export default useDetalhesFatura;
