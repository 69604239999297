import styled from 'styled-components';

export const Legend = styled.label`
  margin-top: 1.67vw;
  margin-bottom: 1.67vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--black);

  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.833vw;
  padding-top: 0.833vw;
  padding-right: 0.5vw;
`;
