import { IOption } from '../../../@types/interfaces/components';
import {
  CentrosDeCustosActionTypes,
  CentrosDeCustosState,
  CONTROLE_PAGINACAO,
  LIMPA_CENTRO_CUSTO,
  SET_CENTROS_DE_CUSTOS_DO_CONTRATO,
} from './types';

const centroDeCustoSelecionado: IOption = { value: '', label: 'Todos' };

const initialState: CentrosDeCustosState = {
  centrosDeCustosResponse: {
    listaRegistro: [],
    numeroDeIdentificacao: 1,
    nome: '',
    orcamento: {
      periodicidade: '',
      valor: 1,
    },
    valorFaturadoDoCentroDeCusto: 1,
    quantidadeTotalRegistro: 1,
  },
  centrosDeCustos: [],
  centroDeCustoSelecionado,
};

const contratoReducer = (
  state = initialState,
  action: CentrosDeCustosActionTypes
): CentrosDeCustosState => {
  switch (action.type) {
    case SET_CENTROS_DE_CUSTOS_DO_CONTRATO: {
      const centrosDeCustos = [{ value: '', label: 'Todos' }];

      action.centrosDeCustosResponse?.listaRegistro.map((centroDecusto) => {
        centrosDeCustos.push({
          value: centroDecusto.numeroDeIdentificacao.toString(),
          label: centroDecusto.nome,
        });
      });

      return {
        ...state,
        centrosDeCustosResponse: action.centrosDeCustosResponse,
        centrosDeCustos,
        centroDeCustoSelecionado,
      };
    }

    case CONTROLE_PAGINACAO: {
      const newCentrosDeCustosResponse = {
        ...state.centrosDeCustosResponse,
        ...action.centrosDeCustosResponse,
        headers: action.headers,
        numeroPagina: action.numeroPagina,
      };

      return {
        ...state,
        centrosDeCustosResponse: newCentrosDeCustosResponse,
      };
    }

    case LIMPA_CENTRO_CUSTO: {
      return initialState;
    }

    default:
      return state;
  }
};

export default contratoReducer;
