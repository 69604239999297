import React, { useState } from 'react';
import * as Styled from './styles';

import SetaBaixo from '../../../assets/chevron_down_gray.svg';

import InputFormularioNovoColaborador from '../../InputFormularioNovoColaborador';

import { PrimeiroFormularioProps } from '../../../@types/interfaces/primeiroFormularioNovoColaborador';

import {
  cpfMask,
  celularMask,
  dataNascimentoMask,
} from '../../../helpers/mascaras';
import {
  removerMascara,
  formatarDataInternacional,
  regex,
} from '../../../helpers/formatacoes';
import {
  verificarCampo,
  handleSubmit,
} from '../../../helpers/utilsFormulariosColaborador';
import { validaNumeroCpf } from '../../../helpers/validacoes';

import NovoColaboradorApi from '../../../api/novoColaborador';
import { ValidarCampoNovoColaborador } from '../../../@types/interfaces/validarCampoNovoColaborador';

const PrimeiroFormularioNovoColaborador = ({
  setValue,
  setError,
  register,
  errors,
  setLoading,
  setMostrarPrimeiraPagina,
  dados,
  setDados,
  camposDesabilitados,
  setCamposDesabilitados,
  camposIniciaisDesabilitados,
  setCamposIniciaisDesabilitados,
  clearErrors,
  setBotaoSubmitDesabilitado,
}: PrimeiroFormularioProps) => {
  const [cpfDataNascimentoVerificados, setCpfDataNascimentoVerificados] =
    useState(false);

  const buscarColaborador = async (cpf: string, dataNascimento: string) => {
    const cpfFormatado = removerMascara(cpf);
    const dataFormatada = formatarDataInternacional(dataNascimento);
    setLoading(true);
    const resultadoNome = await validaCpfReceita(cpfFormatado, dataFormatada);
    setLoading(false);

    if (resultadoNome) {
      setDados({ ...dados, nome: resultadoNome });
      setValue('cpf', cpf);
      setValue('dataNascimento', dataNascimento);
      setValue('nome', resultadoNome);
      setCamposDesabilitados(false);
      setCamposIniciaisDesabilitados(true);
    }
  };

  const validaCpfReceita = (cpf: string, dataNascimento: string) => {
    const headers = {
      cpf: cpf,
      'data-nascimento': dataNascimento,
    };

    return NovoColaboradorApi.validarReceita(
      setError,
      setCpfDataNascimentoVerificados,
      headers
    );
  };

  const handleBlur = async ({
    campo,
    value,
    regex,
  }: ValidarCampoNovoColaborador) => {
    regex
      ? verificarCampo({
          campo,
          value,
          regex,
          setError,
          clearErrors,
          setValue,
          setBotaoSubmitDesabilitado,
        })
      : verificarCampo({
          campo,
          value,
          setError,
          clearErrors,
          setValue,
          setBotaoSubmitDesabilitado,
        });
    if (!validaNumeroCpf(dados.cpf) && dados.cpf !== '') {
      return setError(
        'cpf',
        { type: 'invalid', message: 'CPF inválido.' },
        { shouldFocus: true }
      );
    }
    if (
      !cpfDataNascimentoVerificados &&
      dados.cpf.length === 14 &&
      dados.dataNascimento.length === 10
    ) {
      setCpfDataNascimentoVerificados(true);
      buscarColaborador(dados.cpf, dados.dataNascimento).then(() => ({}));
      return;
    }
    setValue(campo, value);
  };

  return (
    <>
      <Styled.NumeracaoPagina id="NumeracaoPagina">
        <Styled.IconeSetaBaixo id="IconeSetaBaixo" src={SetaBaixo} />
        <Styled.Pagina id="Pagina">
          <Styled.PaginaNumero className="PaginaNumero">1</Styled.PaginaNumero>
          <Styled.Linha />
          <Styled.PaginaNumero className="PaginaNumero" disabled>
            2
          </Styled.PaginaNumero>
        </Styled.Pagina>
      </Styled.NumeracaoPagina>
      <Styled.Form id="PrimeiroFormulario">
        <InputFormularioNovoColaborador
          id="inputCpf"
          label="CPF"
          campoPreenchido={camposIniciaisDesabilitados}
          register={register}
          campo={'cpf'}
          value={dados?.cpf}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDados({ ...dados, cpf: cpfMask(e.target.value) })
          }
          onBlur={() =>
            handleBlur({
              campo: 'cpf',
              value: dados.cpf,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.cpf}
        />
        <InputFormularioNovoColaborador
          id="inputDataNascimento"
          label="Data de Nascimento"
          campoPreenchido={camposIniciaisDesabilitados}
          register={register}
          campo={'dataNascimento'}
          value={dados?.dataNascimento}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDados({
              ...dados,
              dataNascimento: dataNascimentoMask(e.target.value),
            })
          }
          onBlur={() =>
            handleBlur({
              campo: 'dataNascimento',
              value: dados.dataNascimento,
              regex: regex.dataNascimento,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.dataNascimento}
        />
        <InputFormularioNovoColaborador
          id="inputNome"
          label="Nome Completo"
          campoPreenchido={camposIniciaisDesabilitados}
          disabled={camposDesabilitados}
          value={dados?.nome}
          register={register}
          campo={'nome'}
          onChange={() => ({})}
        />
        <InputFormularioNovoColaborador
          id="inputEmail"
          label="E-mail"
          disabled={camposDesabilitados}
          register={register}
          campo={'email'}
          value={dados?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDados({
              ...dados,
              email: e.target.value,
            })
          }
          onBlur={() =>
            handleBlur({
              campo: 'email',
              value: dados.email,
              regex: regex.email,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.email}
        />
        <InputFormularioNovoColaborador
          id="inputCelular"
          label="Celular"
          disabled={camposDesabilitados}
          register={register}
          campo={'celular'}
          value={dados?.celular}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDados({
              ...dados,
              celular: celularMask(e.target.value),
            })
          }
          onBlur={() =>
            handleBlur({
              campo: 'celular',
              value: dados.celular,
              regex: regex.celular,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.celular}
        />
        <Styled.BotaoSubmit
          id={'botaoContinuar'}
          onClick={(e) =>
            handleSubmit({
              e,
              dados,
              errors,
              setMostrarPrimeiraPagina,
              setError,
              clearErrors,
              setValue,
              setBotaoSubmitDesabilitado,
            })
          }
          disabled={camposDesabilitados}>
          Continuar
        </Styled.BotaoSubmit>
      </Styled.Form>
    </>
  );
};

export default PrimeiroFormularioNovoColaborador;
