export const SET_ERRO = 'ERRO/SET_ERRO';

export const LIMPA_ERRO = 'ERRO/LIMPA_ERRO';

interface IMostraErro {
  type: typeof SET_ERRO;
  codigoErro: string;
  mensagemErro?: string;
}

interface LimpaErro {
  type: typeof LIMPA_ERRO;
}

export type ErroActionTypes = IMostraErro | LimpaErro;
