import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export const slide = keyframes`
    from { width: 0; }
    to { width: 100%; }`;

export const CardContainerCentroCusto = styled.div`
  display: flex;
  margin-top: 1.52vw;
  background-color: var(--white);
  padding: 1.3vw;
  flex-direction: column;
  height: 20vw;
  width: 22vw;
  box-shadow: 0.111vw 0.111vw 0.39vw 0.167vw var(--cinza-30);
  border-radius: 0.223vw;
`;

export const TituloCardCentroCusto = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3vw;
  height: fit-content;
`;

export const TituloCentroCusto = styled.p`
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 0.85vw;
  letter-spacing: 0;
  text-align: left;
  color: var(--blueLabel);
`;

export const VejaMaisCentroCusto = styled(NavLink)`
  font-size: 0.9vw;
  font-weight: 400;
  line-height: 0.94vw;
  letter-spacing: 0;
  text-align: right;
  color: var(--blueLabel);

  &:hover {
    opacity: 0.6;
  }
`;

export const ProgressBarDiv = styled.div`
  display: flex;
  align-items: center;
  height: 0.47vw;
  border-radius: 0.55vw;
  background: #e6e6e6;
  margin-top: 0.27vw;
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 1.3vw;

  progress {
    border-radius: 50%;
    height: 1.04vw;
  }

  progress[value] {
    height: 0.47vw;
    border-radius: 0.55vw;
    animation: ${slide} 300ms ease forwards;
    background: transparent;
    border: none;

    ::-moz-progress-bar {
      border-radius: 0.55vw;
      background: var(--action);
    }
  }

  progress[value]::-webkit-progress-value {
    border-radius: 0.55vw;
    background: var(--action);
  }

  progress[value]::-webkit-progress-bar {
    border-radius: 0.55vw;
    background: #e6e6e6;
  }
`;

export const ProgressBarTitle = styled.div`
  height: fit-content;
`;

export const ProgressBarContentText = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressBarText = styled.p`
  font-size: 0.8vw;
  font-weight: 500;
  line-height: 1.11vw;
  letter-spacing: 0.01vw;
  text-align: left;
  color: var(--cinza-60);
`;

export const ProgressBarSubText = styled.p`
  font-size: 0.8vw;
  font-weight: 500;
  line-height: 1.11vw;
  letter-spacing: 0;
  text-align: right;
  color: var(--blueNav);
`;

export const ProgressBar = styled.progress`
  width: 100%;
`;
