import { ReactElement } from 'react';
import useAuth from '../../hooks/useAuth';
import * as Styled from './styles';

export const ModalCookie = (): ReactElement => {
  const { aceitaCookie } = useAuth();

  const handleClose = () => {
    aceitaCookie(true);
  };

  return (
    <Styled.ModalContainer>
      <Styled.ModalContent>
        <Styled.ModalText>
          Nós utilizamos cookies e outras tecnologias semelhantes para melhorar
          a sua experiência em nossa plataforma. Ao <br /> continuar navegando,
          você concorda com o uso de cookies previsto em nossa{' '}
          <Styled.TextoPolitica
            href="https://www.bb.com.br/pbb/pagina-inicial/minha-privacidade/politicas-de-uso-e-privacidade#/"
            target="_blank">
            Política de Privacidade
          </Styled.TextoPolitica>
          .
        </Styled.ModalText>
        <Styled.ModalButton onClick={handleClose}>Concordo</Styled.ModalButton>
      </Styled.ModalContent>
    </Styled.ModalContainer>
  );
};
