import styled from 'styled-components';

interface DisabledProps {
  disabled?: boolean;
  campoPreenchido?: boolean;
}

export const NumeracaoPagina = styled.div`
  margin: 0.55vw 0 0.55vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconeSetaBaixo = styled.img`
  width: 1.05vw;
  height: 0.61vw;
  margin: 0.39vw 6.69vw 0.39vw 0.39vw;
`;

export const Pagina = styled.div`
  width: 8.03vw;
  display: flex;
  align-items: center;
`;

export const PaginaNumero = styled.div<DisabledProps>`
  font-weight: 600;
  font-size: 1vw;
  line-height: 1vw;
  color: ${(props) => (props.disabled ? 'var(--cinza-50)' : 'var(--white)')};
  background-color: ${(props) =>
    props.disabled ? 'var(--white)' : 'var(--action)'};
  width: 1.78vw;
  height: 1.78vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.disabled && '0.11vw solid var(--cinza-50)'};
`;

export const Linha = styled.div`
  width: 4.46vw;
  height: 0.11vw;
  background-color: var(--action);
`;

export const Form = styled.div`
  margin-top: 0.45vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;
`;

export const BotaoSubmit = styled.button<DisabledProps>`
  width: 27.65vw;
  height: 2.28vw;
  border-radius: 0.51vw;
  font-weight: 700;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--white);
  background-color: ${(props) => props.disabled && 'var(--cinza-30)'};
  margin-top: 3vw;
  cursor: ${(props) => props.disabled && 'auto'};

  @media (max-width: 768px) {
    width: 80vw;
    font-size: 4.5vw;
  }
`;
