import styled from 'styled-components';

export const WrapperConteudo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 10.04vw;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1vw;
`;

export const DropdownContent = styled.div`
  display: flex;
  font-size: 0.94vw;
  font-weight: 450;
  height: 10.04vw;
  width: 36.66294642857143vw;
  align-items: center;
  justify-content: center;
  border: 0.05vw var(--cinza-30) solid;
`;

export const DropdownContentRealizadas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.94vw;
  font-weight: 450;
  height: 10.04vw;
  width: 36.66294642857143vw;
  border: 0.05vw var(--cinza-30) solid;
`;

export const DropdownScroll = styled.ul`
  max-height: 10.04vw;
`;

export const ContentDiv = styled.div`
  display: flex;
  height: 7.81vw;
  width: 80%;
  border-radius: 0.55vw;
  box-shadow: 0 0.1116vw 0.1116vw rgba(0, 0, 0, 0.25);
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 7.81vw;
  width: 40%;
  border-top-left-radius: 0.55vw;
  border-bottom-left-radius: 0.55vw;
`;

export const CotacaoMainText = styled.p`
  font-size: 0.94vw;
  font-weight: 450;
`;

export const CotacaoMainImg = styled.img`
  height: 4.46vw;
  width: 4.46vw;
`;

export const CotacaoWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
`;

export const CotacaoSubText = styled.p`
  font-size: 0.83vw;
  font-weight: 400;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  justify-content: flex-start;
  height: 7.81vw;
  width: 60%;
  border-bottom-right-radius: 0.55vw;
  border-top-right-radius: 0.55vw;
`;

export const RightSubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
  justify-content: space-evenly;
  height: 7.81vw;
  width: 100%;
`;

export const RightMainText = styled.p`
  margin-top: 0.4vw;
  font-size: 0.93vw;
  font-weight: 400;
  height: fit-content;
`;

export const RightSubTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SubTextIcon = styled.img`
  height: 0.83vw;
  width: 0.83vw;
  margin-right: 0.55vw;
`;

export const SubText = styled.p`
  font-size: 0.83vw;
  font-weight: 400;
  height: fit-content;
`;
