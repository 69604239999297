import styled from 'styled-components';

export const styles = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperText = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 36.66294642857143vw;
  border: 0.05vw var(--cinza-30) solid;
  background-color: var(--branco);
  height: 2.79vw;
  cursor: default;
`;

export const DropdownText = styled.p`
  font-size: 0.94vw;
  font-weight: 450;
  margin-left: 0.55vw;
  display: flex;
  align-items: center;
`;

export const DropdownImage = styled.img`
  width: 1.11vw;
  height: 1.11vw;
  cursor: pointer;
  margin-right: 0.55vw;
`;
