import * as cardActions from './actions';
import card from '../../../api/dashboardCard';
import { FiltrosHeadersCard } from '../../../@types/interfaces/dashboardCardApi';
import { Dispatch } from 'react';
import { CardActionTypes } from './types';

export const fetchCardDashboard =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardActionTypes>) => {
    try {
      const cardResumoResponse = await card.getCard(headers);

      dispatch(cardActions.setCardDashboard(headers, cardResumoResponse));

      return cardResumoResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeadersCorridasRealizadas =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<CardActionTypes>) => {
    try {
      const cardResumoResponse = await card.getCard(headers);
      dispatch(cardActions.filtraCampo(headers, cardResumoResponse));

      return cardResumoResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
