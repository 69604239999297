import React, { useCallback } from 'react';

import FileDownload from 'js-file-download';

import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import useErro from './useErro';

import { logger } from '../util/logger';

import { formatarDataMesEAno } from '../helpers/formatacoes';

import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/colaboradores/operations';
import * as colaboradoresActions from '../store/ducks/colaboradores/actions';
import segurancaAxios from '../config/axios/SegurancaAxios';
import { ExtensaoArquivoEnum } from '../@types/enums/ExtensaoArquivo.enum';

function useColaboradores() {
  const { colaboradores, colaboradoresSelecionado, colaboradoresResponse } =
    useSelector((state: RootState) => state.colaboradores);

  const { mostraErro, limpaErro } = useErro();

  const { contratoSelecionado, contratoResponse } = useSelector(
    (state: RootState) => state.contrato
  );

  const {
    fetchColaboradores,
    fetchControlePaginacao,
    fetchFiltrosHeaders,
    filtraCampo,
    limpaColaboradores,
  } = bindActionCreators(
    {
      ...colaboradoresActions,
      ...operations,
    },
    useDispatch()
  );

  const handleColaboradores = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        await fetchColaboradores({
          'id-contrato-mobilidade': contratoSelecionado?.id,
        });
        limpaErro();
      } catch (error: any) {
        if (
          error?.response?.data?.code === '005' ||
          error?.response?.data?.code === '009'
        ) {
          limpaColaboradores();
        } else {
          mostraErro(error?.response?.data?.code);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      contratoSelecionado,
      contratoSelecionado?.id,
      contratoResponse,
      colaboradoresResponse?.headers,
    ]
  );

  const handleBuscaColaborador = useCallback(
    async (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLSelectElement>,
      setLoading: (value: boolean) => void
    ) => {
      let textoDoInput = event.target?.value;

      const regex = /\d{3}(\.\d{3}){0,2}\d{2}/;

      const isCpf = regex.test(textoDoInput);
      if (isCpf) {
        textoDoInput = textoDoInput
          .toString()
          .trim()
          .replace('.', '')
          .replace('.', '')
          .replace('-', '');
      }

      const headers = {
        ...colaboradoresResponse?.headers,
        'numero-pagina': '1',
        'texto-filtro': textoDoInput,
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };
      try {
        setLoading(true);
        await fetchFiltrosHeaders(headers);
      } catch (error: any) {
        if (error?.response?.data?.code === '009') {
          limpaColaboradores();
          filtraCampo(headers);
        }
      } finally {
        setLoading(false);
      }
    },
    [colaboradoresResponse?.headers]
  );

  const handlePaginacaoColaboradores = useCallback(
    async (
      selectedItem: { selected: number },
      setLoading: (value: boolean) => void
    ) => {
      try {
        setLoading(true);
        const paginaSelecionada = selectedItem.selected + 1;

        const headers = {
          ...colaboradoresResponse?.headers,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-pagina': paginaSelecionada.toString(),
        };

        await fetchControlePaginacao(headers, paginaSelecionada);
      } catch (error) {
        const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "handlePaginacaoColaboradores".
        <'ERRO'>
          message: Não foi possível consultar a lista de colaboradores!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${contratoSelecionado?.id},
          ENDPOINT: 'contrato/colaborador/listar',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
        logger.error(errorFormatado);
      } finally {
        setLoading(false);
      }
    },
    [colaboradoresResponse?.headers]
  );

  const downloadCSV = async () => {
    const nomeExibicao = contratoSelecionado?.nomeDeExibicao;
    const numeroDeIdentificacao = contratoSelecionado?.numeroDeIdentificacao;

    const response = await segurancaAxios.get('relatorios', {
      headers: {
        ...colaboradoresResponse?.headers,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'tipo-arquivo': ExtensaoArquivoEnum.CSV,
      },
      responseType: 'arraybuffer',
    });

    const retorno = await response.data;

    const blob: Blob = new Blob([retorno], {
      type: 'text/csv',
    });

    FileDownload(
      blob,
      `colaboradores-${numeroDeIdentificacao}-${formatarDataMesEAno(
        new Date()
      )}-${nomeExibicao?.replace(/\s+/g, '_')}.csv`
    );
  };

  const downloadXLSX = async () => {
    const nomeExibicao = contratoSelecionado?.nomeDeExibicao;
    const numeroDeIdentificacao = contratoSelecionado?.numeroDeIdentificacao;

    const response = await segurancaAxios.get('relatorios', {
      headers: {
        ...colaboradoresResponse?.headers,
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'tipo-arquivo': ExtensaoArquivoEnum.XLSX,
      },
      responseType: 'arraybuffer',
    });

    const retorno = await response.data;

    const blob: Blob = new Blob([retorno], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    FileDownload(
      blob,
      `colaboradores-${numeroDeIdentificacao}-${formatarDataMesEAno(
        new Date()
      )}-${nomeExibicao?.replace(/\s+/g, '_')}.xlsx`
    );
  };

  return {
    downloadCSV,
    downloadXLSX,
    colaboradores,
    handleColaboradores,
    colaboradoresResponse,
    handleBuscaColaborador,
    colaboradoresSelecionado,
    handlePaginacaoColaboradores,
  };
}

export default useColaboradores;
