import {
  IColaboradores,
  ColaboradoresResponse,
  FiltrosHeaderColaboradores,
} from '../../../@types/interfaces/colaboradoresApi';

export const SET_COLABORADORES = 'CONTRATO/SET_COLABORADORES';

export const CONTROLE_PAGINACAO = 'CONTRATO/CONTROLE_PAGINACAO';

export const LIMPA_COLABORADORES = 'CONTRATO/LIMPA_COLABORADORES';

export const FILTRA_CAMPO = 'CONTRATO/FILTRA_CAMPO';

export interface ColaboradorState {
  colaboradoresResponse: ColaboradoresResponse;
  colaboradores: IColaboradores[];
  colaboradoresSelecionado: IColaboradores | undefined;
}

interface SetColaboradores {
  type: typeof SET_COLABORADORES;
  colaboradoresResponse: ColaboradoresResponse;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  colaboradoresResponse?: ColaboradoresResponse;
  headers: FiltrosHeaderColaboradores;
}

interface LimpaColaboradores {
  type: typeof LIMPA_COLABORADORES;
}

interface ControlePaginacao {
  type: typeof CONTROLE_PAGINACAO;
  colaboradoresResponse: ColaboradoresResponse;
  headers: FiltrosHeaderColaboradores;
  numeroPagina: number;
}

interface LimpaColaboradores {
  type: typeof LIMPA_COLABORADORES;
}

export type ColaboradoresActionTypes =
  | SetColaboradores
  | ControlePaginacao
  | FiltraCampo
  | LimpaColaboradores;
