import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/detalheViagem/operations';
import * as actions from '../store/ducks/detalheViagem/actions';

import useContratos from './useContratos';
import useErro from './useErro';

function useDetalheViagem() {
  const { detalheViagemResponse, detalheViagemSelecionado } = useSelector(
    (state: RootState) => state.detalheViagem
  );

  const { faturaSelecionada } = useSelector(
    (state: RootState) => state.faturas
  );

  const { limpaErro, mostraErro } = useErro();

  const { contratoSelecionado } = useContratos();

  const { fetchDetalheViagem, setDetalheViagemSelecionado } =
    bindActionCreators(
      {
        ...actions,
        ...operations,
      },
      useDispatch()
    );

  const consultaDetalheViagem = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        const headers = {
          ...detalheViagemResponse?.headers,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-fatura': faturaSelecionada?.numeroFatura.toString(),
          'numero-identificacao-viagem':
            detalheViagemSelecionado?.numeroIdentificacaoViagem,
        };
        await fetchDetalheViagem(headers);
        limpaErro();
      } catch (error: any) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
      } finally {
        setLoading(false);
      }
    },
    [
      contratoSelecionado,
      contratoSelecionado?.id,
      detalheViagemSelecionado?.numeroIdentificacaoViagem,
    ]
  );

  return {
    consultaDetalheViagem,
    setDetalheViagemSelecionado,
    detalheViagemSelecionado,
    detalheViagemResponse,
  };
}

export default useDetalheViagem;
