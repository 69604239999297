import Axios from './Axios';

class SegurancaAxios {
  axios: any;

  constructor() {
    this.axios = new Axios(
      `${process.env.REACT_APP_URL_BIFROST_SEGURANCA_API}`,
      {
        withCredentials: true,
      }
    );
  }
}

export default new SegurancaAxios().axios;
