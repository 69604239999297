import { FiltrosHeaderColaboradores } from '../../../@types/interfaces/colaboradoresApi';
import api from '../../../api/colaboradores';
import * as ColaboradoresActionType from './actions';
import { Dispatch } from 'react';
import { ColaboradoresActionTypes } from './types';

export const fetchColaboradores =
  (headers?: FiltrosHeaderColaboradores) =>
  async (dispatch: Dispatch<ColaboradoresActionTypes>) => {
    try {
      const colaboradoresResponse = await api.getColaboradores(headers);

      dispatch(ColaboradoresActionType.setColaboradores(colaboradoresResponse));

      return colaboradoresResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

export const fetchFiltrosHeaders =
  (headers: FiltrosHeaderColaboradores) =>
  async (dispatch: Dispatch<ColaboradoresActionTypes>) => {
    try {
      const detalheFaturaResponse = await api.getColaboradores(headers);
      dispatch(
        ColaboradoresActionType.filtraCampo(headers, detalheFaturaResponse)
      );

      return detalheFaturaResponse;
    } catch (error: any) {
      throw error;
    }
  };

export const fetchControlePaginacao =
  (headers: FiltrosHeaderColaboradores, numeroPagina: number) =>
  async (dispatch: Dispatch<ColaboradoresActionTypes>) => {
    try {
      const colaboradoresResponse = await api.getColaboradores(headers);

      dispatch(
        ColaboradoresActionType.controlePaginacao(
          colaboradoresResponse,
          headers,
          numeroPagina
        )
      );

      return colaboradoresResponse;
    } catch (error: any) {
      console.error(error);
    }
  };
