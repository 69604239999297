import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/globals';
import Navigation from './components/Navigation';
import Routes from './routes';
import '../src/@types/assets/index.d.ts';
import { Footer } from './components/Footer';
import { AuthProvider } from './contexts/auth';
import useAuth from './hooks/useAuth';
import modalCookie from './hoc/ModalCookieWrapperHOC';

const App: React.FC = function () {
  const { auth } = useAuth();

  return (
    <BrowserRouter>
      {auth.usuarioLogado && <Navigation />}
      <AuthProvider>
        <Routes />
      </AuthProvider>
      {auth.usuarioLogado && <Footer />}
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default modalCookie(App);
