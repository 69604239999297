import segurancaAxios from '../config/axios/SegurancaAxios';
import { Viagem } from '../@types/interfaces/Viagem';
import { Contrato } from '../@types/interfaces/contratoApi';

class UltimasViagensApi {
  public listarUltimasViagens = async (
    contratoSelecionado: Contrato
  ): Promise<Viagem[]> => {
    try {
      const config = {
        headers: {
          'id-contrato-mobilidade': contratoSelecionado.id,
        },
      };
      const result = await segurancaAxios.get(
        'viagem/retornarUltimasViagens',
        config
      );

      return result.data;
    } catch (error: any) {
      console.warn('Houve erro ao retornar últimas viagens! ', error);
      return error;
    }
  };
}
export default new UltimasViagensApi();
