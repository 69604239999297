import React from 'react';
import * as Styled from './styles';
import useCardUltimasViagens from '../../../hooks/useCardUltimasViagens';
import IsLoadingHOC from '../../../hoc/IsLoadingHOC';
import { CardNoData } from '../CardNoData';
import {
  formatarData,
  formatarReal,
  trocarNomeNoveNove,
} from '../../../helpers/formatacoes';
import { IViagens } from '../../../@types/interfaces/cardUltimasViagens';

const CardUltimasViagens = () => {
  const { cardUltimasViagensResponse } = useCardUltimasViagens();

  return (
    <React.Fragment>
      <Styled.CardContainerUltimasViagens>
        <Styled.TituloCardUltimasViagens>
          <Styled.TituloUltimasViagens>
            Últimas viagens
          </Styled.TituloUltimasViagens>
        </Styled.TituloCardUltimasViagens>
        {cardUltimasViagensResponse?.cardUltimasViagens.length > 0 ? (
          <React.Fragment>
            {cardUltimasViagensResponse?.cardUltimasViagens
              ?.slice(0, 3)
              .map((item: IViagens) => (
                <Styled.ViagensWrapper key={item.nome}>
                  <Styled.ViagensNome>{item?.nome}</Styled.ViagensNome>
                  <Styled.ViagensParceiro>
                    {trocarNomeNoveNove(item?.detalhesViagens?.parceiro)}
                  </Styled.ViagensParceiro>
                  <Styled.ViagensTexto>
                    {`Finalizou uma viagem de ${
                      item?.detalhesViagens?.distancia
                    } km por ${formatarReal(
                      item?.detalhesViagens?.valor
                    )} com duração de ${
                      item?.detalhesViagens?.duracao
                    } min em ${formatarData(item?.detalhesViagens?.data)}.`}
                  </Styled.ViagensTexto>
                </Styled.ViagensWrapper>
              ))}
          </React.Fragment>
        ) : (
          <CardNoData
            titulo="Oops!"
            descricao="Não encontramos dados referentes à este mês para apresentar."
          />
        )}
      </Styled.CardContainerUltimasViagens>
    </React.Fragment>
  );
};

export default IsLoadingHOC(CardUltimasViagens);
