import styled from 'styled-components';

interface ImgProps {
  imageUrl?: string;
  inputGrande?: boolean;
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 3.87vw;
`;

export const Label = styled.label`
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.83vw;
  margin-bottom: 0.55vw;
  height: fit-content;
`;

export const Option = styled.option`
  color: var(--cinza-60);
`;

export const Input = styled.input<ImgProps>`
  font-weight: 500;
  font-size: 0.83vw;
  color: var(--cinza-60);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 12.5vw;
  height: 2.4vw;
  margin-right: 0.83vw;
  padding: 0.44vw 2.45vw 0.44vw 0.55vw;
  border: 0.05vw solid;
  border-color: var(--cinza-60);
  border-radius: 0.41vw;
  background-color: var(--white);
  position: relative;
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 93%;
`;
