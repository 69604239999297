import React from 'react';
import * as Styled from './styles';
import IconTrips from '../../../assets/icone-viagens-realizadas.svg';
import useCardDashboard from '../../../hooks/useCardDashboard';
import IsLoadingHOC from '../../../hoc/IsLoadingHOC';
import { CardNoData } from '../CardNoData';
import { IParceiro } from '../../../@types/interfaces/dashboardCardApi';
import { formatarReal } from '../../../helpers/formatacoes';

const CardDashboard = () => {
  const { cardResumoResponse } = useCardDashboard();

  return (
    <Styled.CardContainer>
      {cardResumoResponse?.cardResumoViagem?.parceiro?.length > 0 ? (
        <Styled.CardContent>
          <Styled.WrapperIcon>
            <Styled.IconTrips src={IconTrips} />

            <Styled.NumberTrips>
              {cardResumoResponse?.cardResumoViagem?.viagensRealizadas}
            </Styled.NumberTrips>

            <Styled.SupportTripsDoneText>
              Corridas
              <br /> realizadas
            </Styled.SupportTripsDoneText>
          </Styled.WrapperIcon>
          <Styled.WrapperAmount>
            <Styled.SupportTripsText>Total de</Styled.SupportTripsText>
            <Styled.TextMoney>
              {formatarReal(cardResumoResponse?.cardResumoViagem?.totalGasto)}
            </Styled.TextMoney>
            <Styled.SupportTripsText>
              Ticket médio:
              <Styled.SpanReal>
                {' '}
                {formatarReal(
                  cardResumoResponse?.cardResumoViagem?.ticketMedio
                )}
              </Styled.SpanReal>
            </Styled.SupportTripsText>
            <Styled.Line />
          </Styled.WrapperAmount>

          <Styled.SupportTripsText>
            Operadores mais utilizados
          </Styled.SupportTripsText>
          <Styled.WrapperOperators
            style={{
              justifyContent: `${
                cardResumoResponse?.cardResumoViagem?.parceiro?.length === 1
                  ? 'center'
                  : 'space-between'
              }`,
              flexWrap: `${
                cardResumoResponse?.cardResumoViagem?.parceiro?.length >= 4
                  ? 'wrap'
                  : 'nowrap'
              }`,
            }}>
            {cardResumoResponse?.cardResumoViagem?.parceiro
              .sort((a, b) => a.posicao - b.posicao)
              .slice(0, 3)
              .map((item: IParceiro) => (
                <Styled.WrapperOperatorsIcons key={item.posicao}>
                  <Styled.SupportTripsText>
                    {item.posicao}º
                  </Styled.SupportTripsText>
                  <Styled.IconOperators
                    data-tip={`${item.qtdViagens} ${
                      item.qtdViagens === 1 ? 'viagem' : 'viagens'
                    }`}
                    src={item.imgParceiro}
                    alt={item.nome}
                  />
                </Styled.WrapperOperatorsIcons>
              ))}
          </Styled.WrapperOperators>
        </Styled.CardContent>
      ) : (
        <Styled.CardDashboardNoData>
          <Styled.Titulo>Corridas realizadas</Styled.Titulo>
          <CardNoData
            titulo="Oops!"
            descricao="Não encontramos dados referentes à este mês para apresentar."
          />
        </Styled.CardDashboardNoData>
      )}
    </Styled.CardContainer>
  );
};

export default IsLoadingHOC(CardDashboard);
