import NovoColaborador from '../@types/interfaces/novoColaborador';
import segurancaAxios from '../config/axios/SegurancaAxios';
import { ValidacaoReceita } from '../@types/interfaces/validacaoReceita';
import { UseFormSetError } from 'react-hook-form';
import { DadosProps } from '../@types/interfaces/dadosNovoColaborador';
import React, { SetStateAction } from 'react';
import moment from 'moment';

class NovoColaboradorApi {
  public cadastrarNovoColaborador = async (
    headers: NovoColaborador
  ): Promise<boolean> => {
    try {
      const config = {
        headers: {
          'id-contrato-mobilidade': headers.idContrato,
        },
      };

      const body: NovoColaborador = {
        nome: headers.nome,
        cpf: headers.cpf,
        email: headers.email,
        dataNascimento: moment(headers['dataNascimento'], 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        ),
        numeroTelefoneCelular: headers['numeroTelefoneCelular'],
        idContrato: headers['idContrato'],
        numeroDeIdentificacaoDoCentroDeCusto:
          headers['numeroDeIdentificacaoDoCentroDeCusto'],
        cargo: headers.cargo,
        solicitarCategoriaVeiculo: headers['solicitarCategoriaVeiculo'],
      };
      const result = await segurancaAxios.post(
        'passageiro/criar',
        body,
        config
      );
      return !!result;
    } catch (error) {
      console.warn('Houve erro no cadastro do colaborador! ', error);
      return false;
    }
  };

  public validarReceita = async (
    setError: UseFormSetError<DadosProps>,
    setCpfDataNascimentoVerificados: React.Dispatch<SetStateAction<boolean>>,
    headers: ValidacaoReceita
  ): Promise<string | void> => {
    try {
      const result = await segurancaAxios.get('passageiro/validarCpf', {
        headers,
      });

      return result.data;
    } catch (error: any) {
      setError(
        'dataNascimento',
        { type: 'negocial', message: error.response?.data.message },
        { shouldFocus: true }
      );
      setCpfDataNascimentoVerificados(false);
      console.warn('Houve erro ao buscar usuário na receita! ', error);
      return '';
    }
  };
}
export default new NovoColaboradorApi();
