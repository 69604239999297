import styled from 'styled-components';

export const Modal = styled.div`
  height: 36.83vw;
  width: 40vw;

  display: flex;
  flex-direction: column;

  border-radius: 25px;

  background-color: var(--white);

  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 53.6vw;
    height: 53.6vw;
  }

  @media (max-width: 600px) {
    margin-bottom: 100%;
  }
`;

export const ModalImg = styled.img`
  height: 57.6%;
  width: 48%;

  margin-top: 87px;

  @media (max-width: 768px) {
    margin-top: 20%;
    margin-bottom: 10%;

    height: 28.9vw;
    width: 17.4vw;
  }
`;

export const ModalTextoPai = styled.div`
  font-weight: 600;
  font-size: 1.7vw;
  line-height: 28px;
  color: var(--black);
  margin-top: 3%;
  height: fit-content;

  @media (max-width: 768px) {
    font-size: 60%;
    margin-top: 1%;
    line-height: 8px;
  }
`;

export const ModalTextoFilho = styled.div`
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: var(--cinza-60);
  margin-top: 3%;
  max-width: 592px;
  text-align: center;
  height: fit-content;

  @media (max-width: 768px) {
    font-size: 45%;
    line-height: 15px;
  }
`;

export const ModalBotao = styled.button`
  width: 49%;
  height: 8%;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 30px;
  color: var(--white);
  background-color: var(--action);
  margin-top: 5%;
  margin-bottom: 47px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 60%;
    height: 15%;

    font-size: 65%;
    margin-top: 1%;
  }
`;
