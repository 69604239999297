import * as Styled from './styles';
import IconeDownloadRelatorio from '../../assets/download_CSV.svg';
import React from 'react';

interface BotaoExportarRelatorioProps {
  downloadRelatorio: (value: (value: boolean) => void) => void;
  setLoading: (value: boolean) => void;
  habilitado?: boolean;
  textoBotao?: string;
}

const BotaoExportarRelatorio = ({
  downloadRelatorio,
  setLoading,
  habilitado,
  textoBotao,
}: BotaoExportarRelatorioProps) => {
  return (
    <Styled.BotaoExportarRelatorio
      disabled={!habilitado}
      onClick={() => downloadRelatorio(setLoading)}>
      <Styled.Icone src={IconeDownloadRelatorio} />
      {textoBotao}
    </Styled.BotaoExportarRelatorio>
  );
};

export default BotaoExportarRelatorio;
