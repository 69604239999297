import { Fragment } from 'react';
import useAuth from '../hooks/useAuth';
import { ModalCookie } from '../components/ModalCookie';

function ModalCookieWrapper(WrappedComponent: any) {
  return function ModalCookieWrapper(props: any) {
    const { auth } = useAuth();

    return (
      <Fragment>
        <WrappedComponent {...props} />
        {!auth.cookieAceito && <ModalCookie />}
      </Fragment>
    );
  };
}

export default ModalCookieWrapper;
