import { ReactElement } from 'react';

import Select, {
  components,
  DropdownIndicatorProps,
  NoticeProps,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import * as Styled from './styles';

import ChevronDown from '../../assets/chevron_down_blue.svg';

interface ISeletor {
  label?: string;
}

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Sem opçōes</span>
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Styled.IconSeletor imageUrl={ChevronDown} width={13} height={8} />
    </components.DropdownIndicator>
  );
};

export const SeletorDashboard = ({
  label,
  ...props
}: ISeletor & StateManagerProps): ReactElement => {
  return (
    <Styled.SeletorContainer>
      <Styled.Label>{label}</Styled.Label>

      <Select
        id={'select'}
        {...props}
        components={{
          NoOptionsMessage,
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        styles={Styled.styles}
      />
    </Styled.SeletorContainer>
  );
};
