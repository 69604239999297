import styled from 'styled-components';
import { StylesConfig } from 'react-select';
import colors from '../../styles/colors';

export const WrapperCampoMenor = styled.div`
  width: 27.65vw;
  display: flex;
  justify-content: space-between;
`;

export const CampoMenor = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CampoMenorBloq = styled.div`
  display: flex;
  flex-direction: column;
  cursor: not-allowed;
  height: 7vw;
  .css-3iigni-container {
    height: 2.28vw;
    .css-olqui2-singleValue {
      display: flex;
      align-items: center;
    }
  }
`;

export const Label = styled.label`
  padding-bottom: 0.5vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--cinza-90);
`;

export const Erro = styled.span`
  padding: 0.4vw 0;
  height: 2vw;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1.47vw;
  color: var(--salmon);
  display: flex;
  align-items: center;
  max-width: 27.5vw;
`;

export const BotaoCadastrar = styled.button`
  width: 27.65vw;
  height: 2.28vw;
  border-radius: 0.51vw;
  margin-top: 1.5vw;
  font-weight: 700;
  font-size: 1.2vw;
  line-height: 1.6vw;
  color: var(--white);
  background-color: ${(props) => props.disabled && 'var(--cinza-30)'};
  cursor: ${(props) => props.disabled && 'auto'};
`;

export const styles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,

    borderColor: colors.cinza60,
    borderWidth: '0.06vw',
    borderRadius: '0.51vw',
    fontSize: '1vw',
    height: '2.28vw',
    minHeight: '2.28vw',
    minWidth: '12.5vw',
    flexWrap: 'nowrap',
    cursor: 'not-allowed',
    '&:active': {
      boxShadow: `0 0 0 0.06vw ${colors.action}`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    width: '0',
  }),
  menuList: () => ({
    fontSize: '1.2vw',
    cursor: 'not-allowed',
  }),
  option: () => ({
    height: '2.28vw',
    display: 'flex',
    padding: '0.5vw',
  }),
};
