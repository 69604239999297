const colors = {
  bgSoft: '#F6F7FF',
  bgSecondaryDefault: '#C8CFFF',
  yellow: '#FCF800',
  action: '#3E4AFA',
  red: '#E81717',
  green: '#39AC3E',
  txtDefault: '#001624',
  backdrop: '#012741',
  salmon: '#FFAEA2',
  warning: '#FDCC3A',
  bkgWarning: '#FEF5D7',
  blueBottom: '#4BCCEF',
  blueNav: '#002d4b',
  cinza90: '#262626',
  cinza60: '#5E5E5E',
  cinza50: '#858585',
  cinza40: '#BABABA',
  cinza35: '#C1C1C1',
  cinza30: '#E0E0E0',
  cinza20: '#EDEDED',
  cinza10: '#F5F5F5',
  white: '#FFF',
  black: '#000',
};

export default colors;
