import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/cardCentroCusto/operations';
import useContratos from './useContratos';
import useErro from './useErro';
import { IOption } from '../@types/interfaces/components';

function useCardCentroCusto() {
  const { cardCentroCustoResponse } = useSelector(
    (state: RootState) => state.cardCentroCusto
  );

  const { limpaErro, mostraErro } = useErro();

  const { contratoSelecionado } = useContratos();

  const { fetchCardCentroCusto, fetchFiltrosHeadersCentrodeCusto } =
    bindActionCreators(
      {
        ...operations,
      },
      useDispatch()
    );

  const consultaCardCentroCusto = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        const mesAtual = `${new Date().getMonth() + 1}`;
        const anoAtual = `${new Date().getFullYear()}`;
        const initialHeaders = {
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'mes-referencia': mesAtual,
          'ano-referencia': anoAtual,
        };
        await fetchCardCentroCusto(initialHeaders);
        limpaErro();
      } catch (error) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
      } finally {
        setLoading(false);
      }
    },
    [
      contratoSelecionado,
      contratoSelecionado?.id,
      cardCentroCustoResponse?.headers,
    ]
  );

  const handleFiltraMesCentroCusto = useCallback(
    async (option: IOption | null, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...cardCentroCustoResponse.headers,
          'mes-referencia': option.value,
          'ano-referencia': cardCentroCustoResponse.headers?.['ano-referencia'],
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeadersCentrodeCusto(headers);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [cardCentroCustoResponse?.headers, cardCentroCustoResponse]
  );

  const handleFiltraAnoCentroCusto = useCallback(
    async (option: IOption | null, setLoading: (value: boolean) => void) => {
      if (option) {
        const anoAtual = new Date();
        const novaData = new Date();
        const mesAtual = novaData.getMonth();

        const headers = {
          ...cardCentroCustoResponse?.headers,
          'mes-referencia':
            option.value === anoAtual.getFullYear().toString()
              ? mesAtual + 1
              : cardCentroCustoResponse.headers?.['mes-referencia'],
          'ano-referencia': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeadersCentrodeCusto(headers);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      cardCentroCustoResponse?.headers,
      cardCentroCustoResponse,
      cardCentroCustoResponse?.headers?.['mes-referencia'],
    ]
  );

  return {
    fetchCardCentroCusto,
    consultaCardCentroCusto,
    cardCentroCustoResponse,
    handleFiltraMesCentroCusto,
    handleFiltraAnoCentroCusto,
  };
}

export default useCardCentroCusto;
