import {
  DetalharFaturaResp,
  FiltrosHeaderDetalheFatura,
  IDetalharFatura,
} from '../../../@types/interfaces/detalharFatura';

export const GET_DETALHES_FATURA_POR_CONTRATO =
  'DETALHES_FATURA/GET_DETALHES_FATURA_POR_CONTRATO';
export const GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD =
  'DETALHES_FATURA/GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD';
export const GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD =
  'DETALHES_FATURA/GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD';
export const ATIVA_MENU_DETALHE_FATURA = 'DETALHES/ATIVA_MENU_DETALHE_FATURA';
export const ORDENA_VALOR_TOTAL = 'DETALHES/ORDENA_VALOR_TOTAL';
export const ORDENA_PARCEIRO = 'DETALHES/ORDENA_PARCEIRO';
export const ORDENA_COLABORADOR = 'DETALHES/ORDENA_COLABORADOR';
export const ORDENA_DATA = 'DETALHES/ORDENA_DATA';
export const CONTROLE_PAGINACAO = 'DETALHES/CONTROLE_PAGINACAO';
export const FILTRA_CAMPO = 'DETALHES/FILTRA_CAMPO';

export const LIMPA_FATURAS = 'FATURAS/LIMPA_FATURAS';

export interface DetalharFaturaState {
  detalheFaturaResponse: DetalharFaturaResp;
  detalheFaturaDownloadResponse: File;
  detalheFaturaDownloadPDFResponse: File;
  detalheFaturaSelecionada: IDetalharFatura | undefined;
}

interface GetDetalharFaturaAction {
  type: typeof GET_DETALHES_FATURA_POR_CONTRATO;
  detalheFaturaResponse: DetalharFaturaResp;
}

interface GetDetalharFaturaDownloadPDFAction {
  type: typeof GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD;
  detalheFaturaDownloadPDFResponse: File;
}

interface GetDetalharFaturaDownloadAction {
  type: typeof GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD;
  detalheFaturaDownloadResponse: File;
}

interface AtivaMenuDetalheFaturaAction {
  type: typeof ATIVA_MENU_DETALHE_FATURA;
  detalheFatura: IDetalharFatura;
}

interface OrdenaParceiro {
  type: typeof ORDENA_PARCEIRO;
  detalheFaturaResponse: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
}

interface OrdenaColaborador {
  type: typeof ORDENA_COLABORADOR;
  detalheFaturaResponse: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
}

interface OrdenaData {
  type: typeof ORDENA_DATA;
  detalheFaturaResponse: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
}

interface OrdenaValorTotal {
  type: typeof ORDENA_VALOR_TOTAL;
  detalheFaturaResponse: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  detalheFaturaResponse?: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
}

interface LimpaFaturas {
  type: typeof LIMPA_FATURAS;
}

interface ControlePaginacao {
  type: typeof CONTROLE_PAGINACAO;
  detalheFaturaResponse: DetalharFaturaResp;
  headers: FiltrosHeaderDetalheFatura;
  numeroPagina: number;
}

export type DetalharFaturaActionType =
  | GetDetalharFaturaAction
  | GetDetalharFaturaDownloadAction
  | GetDetalharFaturaDownloadPDFAction
  | OrdenaParceiro
  | OrdenaColaborador
  | OrdenaData
  | OrdenaValorTotal
  | AtivaMenuDetalheFaturaAction
  | FiltraCampo
  | LimpaFaturas
  | ControlePaginacao;
