import styled, { createGlobalStyle } from 'styled-components';
interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
}

export default createGlobalStyle`

  // VARIÁVEIS
  :root {
    --bgSoft: #F6F7FF;
    --bgSecondaryDefault: #C8CFFF;
    --yellow: #FCF800;
    --action: #3E4AFA;
    --red: #E50606;
    --green: #39AC3E;
    --backdrop: #012741;
    --txtDefault: #001624;
    --salmon: #FFAEA2;
    --warning: #FDCC3A;
    --bkgWarning: #FEF5D7;
    --blueBottom: #4BCCEF;
    --blueNav: #002d4b;
    --blueHover: #2279C0;
    --blueLabel: #005AA5;
    --blueSoft: #9FC2DF;
    --iniciada: #A6C0DB;
    --em-processamento: #8596C0;
    --concluida: #465EFF;
    --com-inconscistência: #F24E1E;

    --cinza-90: #262626;
    --cinza-60: #5E5E5E;
    --cinza-50: #858585;
    --cinza-40: #BABABA;
    --cinza-35: #C1C1C1;
    --cinza-30: #E0E0E0;
    --cinza-20: #EDEDED;
    --cinza-10: #F5F5F5;
    --cinza-5: #D0D0D0;
    --white-form: #E5E5E5;
    --branco-laterais: #FDFDFD;
    --white: #FFF;
    --black: #000;

    --type-first: 'BancoDoBrasilTextos', Helvetica, Arial, sans-serif;
    --type-second: 'BancoDoBrasilTitulos', Georgia;
  }

  @font-face {
    font-family: 'BancoDoBrasilTextos';
    src: url('/fonts/BancoDoBrasilTextos/BancoDoBrasilTextos-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'BancoDoBrasilTextos';
    src: url('/fonts/BancoDoBrasilTextos/BancoDoBrasilTextos-Medium.ttf');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'BancoDoBrasilTitulos';
    src: url('/fonts/BancoDoBrasilTitulos/BancoDoBrasilTitulos-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'BancoDoBrasilTitulos';
    src: url('/fonts/BancoDoBrasilTitulos/BancoDoBrasilTitulos-Medium.ttf');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  // CSS RESET
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    text-decoration: none;
    height: 100%;
  }

  html, body {
    background-size: cover;
    color: var(--txtDefault);
    height: 100%;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  li,
  textarea,
  a,
  p {
    font-family: var(--type-first);
    color: var(--txtDefault);
  }

  button, input,  select, option {
    font-family: var(--type-first);
    color: var(--txtDefault);
    border: 0;
  }


  ul {
    list-style-type: none;
  }

  button {
    cursor: pointer;
    color: var(--txtDefault);
    background-color: var(--action);
    border: 0;
  }

`;

export const Container = styled.div`
  width: 100%;
  max-width: 72.54vw;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 960px) {
    padding-right: 22px;
    padding-left: 22px;
  }
`;
export const Icon = styled.div<ImgProps>`
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  margin-left: 20px;
  margin-right: auto;
  cursor: pointer;
`;
