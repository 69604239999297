import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  ColaboradoresResponse,
  FiltrosHeaderColaboradores,
} from '../@types/interfaces/colaboradoresApi';

class DetalharFaturaApi {
  /**
   * Get Faturas
   * @return {Promise<any>} Retorna um array de faturas vinculadas ao usuario logado.
   */
  public getColaboradores = async (
    headers?: FiltrosHeaderColaboradores
  ): Promise<ColaboradoresResponse> => {
    const response = await segurancaAxios.get('contrato/colaborador/listar', {
      headers,
    });
    return response.data;
  };
}
export default new DetalharFaturaApi();
