import styled from 'styled-components';
import { Container } from '../../styles/globals';

export interface ImgProps {
  imageUrl: string;
}

export const ErroContainer = styled(Container)`
  padding: 2.56vw 2.56vw 2.56vw 3.52vw;
  display: flex;
  background: var(--white);
  margin-top: 2.05vw;
  border-radius: 0.25vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 9.61vw;
  height: fit-content;
`;

export const Img = styled.div<ImgProps>`
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  width: 38.46vw;
  height: 15vw;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 835px) {
    width: 76.92vw;
    height: 25.64vw;
  } ;
`;

export const Title = styled.h1`
  color: var(--action);
  font-size: 2.24vw;
  height: fit-content;
  @media (max-width: 835px) {
    font-size: 3.36vw;
  } ;
`;

export const SubTitle = styled.h2`
  margin-top: 0.64vw;
  color: var(--action);
  font-weight: 400;
  font-size: 1.92vw;
  text-align: center;
  line-height: 2.56vw;
  height: fit-content;
  @media (max-width: 835px) {
    font-size: 2.88vw;
    line-height: 3.84vw;
  } ;
`;

export const Text = styled.h3`
  margin-top: 0.64vw;
  color: var(--action);
  font-weight: 400;
  height: fit-content;
  font-size: 1.82vw;
  @media (max-width: 835px) {
    font-size: 2.73vw;
    line-height: 3.84vw;
  } ;
`;

export const SubText = styled.p`
  margin-top: 10px;
  color: var(--cinza-50);
  font-weight: 400;
`;

export const Botao = styled.div`
  background-color: var(--action);
  color: var(--white);
  text-align: center;
  padding: 1.28vw;
  margin-top: 1.92vw;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  height: fit-content;
  font-size: 1.72vw;
  @media (max-width: 835px) {
    font-size: 2.58vw;
    line-height: 3.84vw;
  } ;
`;

export const LinkEmail = styled.a`
  color: var(--action);
  font-weight: bold;
  font-size: 1.62vw;
  @media (max-width: 835px) {
    font-size: 2.43vw;
    line-height: 3.84vw;
  } ;
`;
