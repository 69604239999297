import {
  FiltrosHeadersCard,
  ICardColaborador,
} from '../../../@types/interfaces/cardColaborador';

export const GET_CARD_COLABORADOR = 'CARD_COLABORADOR/GET_CARD_COLABORADOR';

export const FILTRA_CAMPO = 'CARD_COLABORADOR/FILTRA_CAMPO';

interface GetCardColaborador {
  type: typeof GET_CARD_COLABORADOR;
  headers: FiltrosHeadersCard;
  cardColaboradorResponse: ICardColaborador;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  headers: FiltrosHeadersCard;
  cardColaboradorResponse: ICardColaborador;
}

export type CardColaboradorActionTypes = GetCardColaborador | FiltraCampo;
