import styled from 'styled-components';

export const bodyLogin = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 835px) {
    flex-direction: row;
  } ;
`;

export const bemVindo = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, var(--salmon), var(--action));
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  color: white;
  @media (min-width: 835px) {
    width: 50%;
  } ;
`;

export const titulo = styled.div`
  max-width: 80%;

  padding-top: 10%;

  font-family: var(--type-first);
  font-style: normal;
  font-weight: 700;
  font-size: 3.2vw;
  line-height: 4.23vw;

  color: var(--white);

  @media (max-width: 835px) {
    font-size: 5.64vw;
  }
`;

export const subtitulo = styled.div`
  max-width: 80%;

  font-family: var(--type-first);
  font-style: normal;
  font-weight: 400;
  font-size: 2.56vw;
  line-height: 3.4vw;

  color: var(--white);

  @media (max-width: 834px) {
    font-size: 4.52vw;
  }
`;

export const softwareEngineer = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  width: 31.53vw;
  height: 19.93vw;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const texto = styled.div`
  max-width: 70%;

  padding-top: 10%;

  font-family: var(--type-first);
  font-style: normal;
  font-weight: 400;
  font-size: 1.28vw;
  line-height: 1.56vw;
  text-align: center;
  letter-spacing: 0.02em;

  color: var(--white);

  @media (max-width: 835px) {
    font-size: 2.26vw;
    line-height: 3.32vw;
  }
`;

export const conteudoLogin = styled.div`
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80%;
  @media (min-width: 835px) {
    width: 50%;
  } ;
`;

export const tituloLogin = styled.h2`
  color: var(--black);

  padding-top: 0.69vw;
  padding-bottom: 2.08vw;

  max-width: 17.36vw;
  text-align: center;
  font-weight: 500;
  font-size: 1.53vw;
  line-height: 140%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 835px) {
    font-size: 2.26vw;
  }
`;

export const logomarca = styled.img`
  background-size: contain;
  width: 7.3vw;
  height: 7.3vw;
  background-repeat: no-repeat;
`;

export const formularioLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 60%;
  background: rgba(241, 241, 241, 0.15);
  border-radius: 0.83vw;
`;

export const Button = styled.button`
  background-color: var(--white);
  margin-top: 1.38vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.83vw 1.66vw;
  gap: 1.11vw;
  width: 55%;
  height: 8%;
  border: 0.06vw solid var(--cinza-5);
  border-radius: 0.55vw;
  font-weight: 500;
  font-size: 0.89vw;
  line-height: 140%;

  @media (max-width: 835px) {
    font-size: 1.94vw;
  }
`;
