import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/cardUltimasViagens/operations';
import useContratos from './useContratos';
import useErro from './useErro';

function useCardUltimasViagens() {
  const { cardUltimasViagensResponse } = useSelector(
    (state: RootState) => state.cardUltimasViagens
  );

  const { limpaErro, mostraErro } = useErro();

  const { contratoSelecionado } = useContratos();

  const { fetchCardUltimasViagens } = bindActionCreators(
    {
      ...operations,
    },
    useDispatch()
  );

  const consultaCardUltimasViagens = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        const initialHeaders = {
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        await fetchCardUltimasViagens(initialHeaders);
        limpaErro();
      } catch (error: any) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
      } finally {
        setLoading(false);
      }
    },
    [contratoSelecionado, contratoSelecionado?.id, cardUltimasViagensResponse]
  );

  return {
    fetchCardUltimasViagens,
    consultaCardUltimasViagens,
    cardUltimasViagensResponse,
  };
}

export default useCardUltimasViagens;
