import * as Styled from './styles';
import Modal from '../Modal';
import React from 'react';

interface ModalNovoColaborador {
  visivel: boolean;
  onCloseRequest: () => void;
  imagem: string;
  textoPai: string;
  textoFilho: string;
  onClick: () => void;
  labelBotao: string;
}

const ModalNovoColaborador = ({
  visivel,
  onCloseRequest,
  imagem,
  textoPai,
  textoFilho,
  onClick,
  labelBotao,
}: ModalNovoColaborador) => {
  return (
    <Modal visivel={visivel} onCloseRequest={onCloseRequest}>
      <Styled.Modal id="Modal">
        <Styled.ModalImg src={imagem} />
        <Styled.ModalTextoPai>{textoPai}</Styled.ModalTextoPai>
        <Styled.ModalTextoFilho>{textoFilho}</Styled.ModalTextoFilho>
        <Styled.ModalBotao onClick={onClick}>{labelBotao}</Styled.ModalBotao>
      </Styled.Modal>
    </Modal>
  );
};

export default ModalNovoColaborador;
