import * as cardActions from './actions';
import card from '../../../api/cardUltimasViagens';
import { FiltrosHeadersCard } from '../../../@types/interfaces/cardUltimasViagens';
import { Dispatch } from 'react';
import { GetCardUltimasViagens } from './types';

export const fetchCardUltimasViagens =
  (headers: FiltrosHeadersCard) =>
  async (dispatch: Dispatch<GetCardUltimasViagens>) => {
    try {
      const cardUltimasViagensResponse = await card.getUltimasViagens(headers);

      dispatch(
        cardActions.setCardUltimasViagens(headers, cardUltimasViagensResponse)
      );

      return cardUltimasViagensResponse;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };
