import { ErroActionTypes, LIMPA_ERRO, SET_ERRO } from './types';

export function mostraErro(
  codigoErro: string,
  mensagemErro?: string
): ErroActionTypes {
  return {
    type: SET_ERRO,
    codigoErro,
    mensagemErro,
  };
}

export function limpaErro(): ErroActionTypes {
  return {
    type: LIMPA_ERRO,
  };
}
