import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  CentroDeCustosDoContratoResponse,
  Contrato,
  FiltrosHeaderCentroDeCusto,
} from '../@types/interfaces/contratoApi';

export interface FiltrosHeaderContratosUsuario {
  'chave-do-usuario': string;
}
export interface FiltrosHeaderCentroDeCustoDeContrato {
  'id-contrato-mobilidade'?: string;
}

export type ContratoResponse = Contrato[];

export type ContratoSelecionado = {
  id: string;
  numeroDeIdentificacao: string | undefined;
  nomeDeExibicao: string | undefined;
  dataInicioVigencia: Date | undefined;
  dataFimVigencia: Date | undefined;
  contratoSelecionado: boolean;
  estado: string | undefined;
};

class ContratoApi {
  /**
   * Get Contratos
   * @return {Promise<any>} Retorna um array de contratos vinculados ao usuario logado.
   *
   */
  public getContratos = async (
    headers?: FiltrosHeaderContratosUsuario
  ): Promise<Array<Contrato> | undefined> => {
    const response = await segurancaAxios.get(
      'passageiro/listaContratoGestor',
      {
        headers,
      }
    );
    return response?.data;
  };

  /**
   * Get Centro de Custos
   * @return {Promise<any>} Retorna um array de centro de custos vinculados ao usuario logado.
   */
  public getCentroDeCustosDoContrato = async (
    headers?: FiltrosHeaderCentroDeCusto
  ): Promise<CentroDeCustosDoContratoResponse> => {
    const response = await segurancaAxios.get('contrato/centrosDeCusto', {
      headers,
    });

    return response.data;
  };
}
export default new ContratoApi();
