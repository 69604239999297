import { ColaboradorActionType, SET_COLABORADOR } from './types';
import { ColaboradorResponse } from '../../../@types/interfaces/Colaborador';

export function setColaboradorResponse(
  colaboradorResponse: ColaboradorResponse
): ColaboradorActionType {
  return {
    type: SET_COLABORADOR,
    colaboradorResponse,
  };
}
