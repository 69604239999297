import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IOption } from '../@types/interfaces/components';
import { corrigirNomeParceiro } from '../helpers/formatacoes';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/grafico/operations';
import useContratos from './useContratos';
import useErro from './useErro';

function useGrafico() {
  const { grafico } = useSelector((state: RootState) => state.grafico);

  const { contratoSelecionado } = useContratos();

  const { mostraErro, limpaErro } = useErro();

  const { fetchViagensDoGrafico, fetchFiltrosHeaders } = bindActionCreators(
    {
      ...operations,
    },
    useDispatch()
  );

  const consultaGrafico = useCallback(
    async (setLoading: (value: boolean) => void) => {
      limpaErro();
      const mesAtual = `${new Date().getMonth() + 1}`;
      const anoAtual = `${new Date().getFullYear()}`;
      const initialHeaders = {
        'id-contrato-mobilidade': contratoSelecionado?.id,
        'mes-referencia': mesAtual,
        'ano-referencia': anoAtual,
      };
      try {
        setLoading(true);
        await fetchViagensDoGrafico(initialHeaders);
      } catch (error: any) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
      } finally {
        setLoading(false);
      }
    },
    [contratoSelecionado, contratoSelecionado?.id, grafico?.headers]
  );

  const handleFiltraAno = useCallback(
    async (option: IOption | null, setLoading: (value: boolean) => void) => {
      if (option) {
        const anoAtual = new Date();
        const novaData = new Date();
        const mesAtual = novaData.getMonth();

        const headers = {
          ...grafico?.headers,
          'mes-referencia':
            option.value === anoAtual.getFullYear().toString()
              ? mesAtual + 1
              : grafico?.headers?.['mes-referencia'],
          'ano-referencia': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'nome-parceiro': '',
        };
        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [grafico?.headers, grafico, grafico?.headers?.['mes-referencia']]
  );

  const handleFiltraMes = useCallback(
    async (option: any, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...grafico.headers,
          'mes-referencia': option.value,
          'ano-referencia': grafico.headers?.['ano-referencia'],
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [grafico?.headers, grafico]
  );

  const handleFiltraParceiro = useCallback(
    async (option: IOption | null, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...grafico.headers,
          'mes-referencia': grafico.headers?.['mes-referencia'],
          'ano-referencia': grafico.headers?.['ano-referencia'],
          'nome-parceiro': corrigirNomeParceiro(option.value),
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [grafico?.headers, grafico]
  );

  return {
    consultaGrafico,
    handleFiltraAno,
    handleFiltraMes,
    handleFiltraParceiro,
    fetchViagensDoGrafico,
    grafico,
  };
}

export default useGrafico;
