import React, { InputHTMLAttributes, ReactElement } from 'react';
import * as Styled from './styles';

interface InputElementProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  label: string;
  chave?: number;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selecionado: string;
}
export const RadioButton = ({
  name,
  value,
  label,
  chave,
  disabled = false,
  onChange,
  selecionado,
}: InputElementProps): ReactElement => {
  return (
    <Styled.Wrapper key={chave}>
      <Styled.Radio
        id="radio-button"
        type="radio"
        value={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
        checked={selecionado === value}
      />
      <Styled.Label htmlFor="radio-button">{label}</Styled.Label>
    </Styled.Wrapper>
  );
};
