import MarcaOrigem from '../../../assets/marca-origem.svg';
import MarcaDestino from '../../../assets/marca-destino.svg';

import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import useDetalheViagem from '../../../hooks/useDetalheViagem';

export const MapaDetalheFatura = () => {
  const { detalheViagemResponse } = useDetalheViagem();
  const getLatLngBounds = () => {
    const positions = [
      [detalheViagemResponse.coordenadas.origemViagem],
      [detalheViagemResponse.coordenadas.destinoViagem],
    ];

    const latLngs = positions.map((position: any) => {
      return L.latLng(position[0], position[1]);
    });

    return L.latLngBounds(latLngs);
  };

  return (
    <MapContainer
      dragging={false}
      bounds={getLatLngBounds()}
      zoomControl={false}
      doubleClickZoom={false}
      maxZoom={12}
      style={{ height: '20.5vw', width: '100%' }}
      scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        icon={
          new Icon({
            iconUrl: MarcaOrigem,
            iconSize: [35, 40],
            iconAnchor: [18, 41],
          })
        }
        position={detalheViagemResponse.coordenadas.origemViagem}>
        <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
          Origem
        </Tooltip>
      </Marker>
      {/* <Polyline pathOptions={limeOptions} positions={polyline} /> */}
      <Marker
        icon={
          new Icon({
            iconUrl: MarcaDestino,
            iconSize: [35, 40],
            iconAnchor: [18, 41],
          })
        }
        position={detalheViagemResponse.coordenadas.destinoViagem}>
        {' '}
        <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
          Destino
        </Tooltip>
      </Marker>
    </MapContainer>
  );
};
