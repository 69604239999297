import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as centrosDeCustosActions from '../store/ducks/centrosDeCustos/actions';
import * as operations from '../store/ducks/centrosDeCustos/operations';
import useErro from './useErro';

function useCentrosDeCustos() {
  const { centrosDeCustos, centroDeCustoSelecionado, centrosDeCustosResponse } =
    useSelector((state: RootState) => state.centrosDeCustos);

  const { contratoSelecionado, contratoResponse } = useSelector(
    (state: RootState) => state.contrato
  );

  const { mostraErro, limpaErro } = useErro();

  const {
    fetchCentrosDeCustosPorContrato,
    fetchControlePaginacao,
    limpaCentroCusto,
  } = bindActionCreators(
    {
      ...centrosDeCustosActions,
      ...operations,
    },
    useDispatch()
  );

  const handleCentrosDeCustosPorContrato = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);

        if (contratoSelecionado?.id) {
          await fetchCentrosDeCustosPorContrato({
            'id-contrato-mobilidade': contratoSelecionado?.id,
          });

          limpaErro();
        } else {
          mostraErro(
            '422',
            'Não foi encontrado id do Contrato e a listagem de centro de custo por contrato não foi efetuada'
          );
        }
      } catch (error: any) {
        if (
          error?.response?.data?.code === '005' ||
          error?.response?.data?.code === '009'
        ) {
          limpaCentroCusto();
        } else {
          mostraErro(error?.response?.data?.code);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      contratoSelecionado,
      contratoSelecionado?.id,
      contratoResponse,
      centrosDeCustosResponse?.headers,
    ]
  );

  const handlePaginacaoCentroDeCusto = useCallback(
    async (
      selectedItem: { selected: number },
      setLoading: (value: boolean) => void
    ) => {
      try {
        setLoading(true);
        const paginaSelecionada = selectedItem.selected + 1;

        const headers = {
          ...centrosDeCustosResponse?.headers,
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'numero-pagina': paginaSelecionada.toString(),
        };

        await fetchControlePaginacao(headers, paginaSelecionada);
      } catch (error: any) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
    },
    [centrosDeCustosResponse?.headers]
  );

  return {
    centrosDeCustos,
    centroDeCustoSelecionado,
    centrosDeCustosResponse,
    handleCentrosDeCustosPorContrato,
    handlePaginacaoCentroDeCusto,
  };
}

export default useCentrosDeCustos;
