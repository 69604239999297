import {
  FiltrosHeadersCard,
  ICardUltimasViagens,
} from '../../../@types/interfaces/cardUltimasViagens';
import {
  CardUltimasViagensActionTypes,
  GET_CARD_ULTIMAS_VIAGENS,
} from './types';

export function setCardUltimasViagens(
  headers: FiltrosHeadersCard,
  cardUltimasViagensResponse: ICardUltimasViagens
): CardUltimasViagensActionTypes {
  return {
    type: GET_CARD_ULTIMAS_VIAGENS,
    headers,
    cardUltimasViagensResponse,
  };
}
