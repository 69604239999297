/* eslint-disable no-unused-vars */
import {
  FiltrosHeadersGrafico,
  IGrafico,
} from '../../../@types/interfaces/graficoApi';
import { GraficoActionTypes, GET_VIAGENS_GRAFICO, FILTRA_CAMPO } from './types';

export function setViagensDoGrafico(
  headers: FiltrosHeadersGrafico,
  grafico: IGrafico
): GraficoActionTypes {
  return {
    type: GET_VIAGENS_GRAFICO,
    headers,
    grafico,
  };
}

export function filtraCampo(
  headers: FiltrosHeadersGrafico,
  grafico: IGrafico
): GraficoActionTypes {
  return {
    type: FILTRA_CAMPO,
    headers,
    grafico,
  };
}
