import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './styles';

import SetaBaixo from '../../assets/chevron_down.svg';

import IconLogout from '../../assets/icon-logout.svg';
import Logo from '../../assets/logomarca.svg';

import { formatarDataAtualExtensa } from '../../helpers/formatacoes';

import { saudacoes } from '../../helpers/constantes';

import SelectContrato from '../SelectContrato';
import useCentrosDeCustos from '../../hooks/useCentrosDeCustos';
import useContratos from '../../hooks/useContratos';

import useErro from '../../hooks/useErro';
import useAuth from '../../hooks/useAuth';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import ModalUsuario from '../ModalUsuario';

export interface NavigationProps {
  setLoading: (value: boolean) => void;
}

const Navigation = ({ setLoading }: NavigationProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const { limpaContrato, contratoSelecionado } = useContratos();
  const { handleCentrosDeCustosPorContrato } = useCentrosDeCustos();
  const { setUsuarioLogado, setTipoUsuario } = useAuth();
  const { codigoErro } = useErro();

  const visibilidadeDefault = {
    menuGerenciamento: false,
    modalVisivel: false,
  };

  const [visibilidade, setVisibilidade] = useState(visibilidadeDefault);

  const menuGerenciamentoRef = useRef<HTMLLIElement | null>(null);

  const deslogaUsuario = () => {
    setUsuarioLogado(false);
    setTipoUsuario('');
    limpaContrato();
  };

  useEffect(() => {
    if (contratoSelecionado?.id) {
      handleCentrosDeCustosPorContrato(setLoading).then(() => ({}));
      setVisibilidade(visibilidadeDefault);
    }
  }, [contratoSelecionado]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menuElement = menuGerenciamentoRef.current as HTMLElement | null;
      if (menuElement && !menuElement.contains(event.target as Node)) {
        setVisibilidade({
          ...visibilidade,
          menuGerenciamento: false,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visibilidade]);

  return (
    <>
      {location.pathname !== '/login' && (
        <Styled.Header>
          <Styled.NavbarContainer>
            <Styled.WrapperLogo>
              <Styled.Logo to="/">
                <Styled.LogoImg src={Logo} id="Logo do Spid" />
              </Styled.Logo>
              <Styled.InfoUsuario>
                <Styled.Saudacao>{saudacoes()}</Styled.Saudacao>
                <Styled.Data>{formatarDataAtualExtensa()}</Styled.Data>
              </Styled.InfoUsuario>
              <SelectContrato />
            </Styled.WrapperLogo>
            {!codigoErro && (
              <Styled.NavMenu>
                <Styled.NavItem>
                  <Styled.NavLinks
                    to="/dashboard"
                    onClick={() => setVisibilidade(visibilidadeDefault)}>
                    Visão Geral
                  </Styled.NavLinks>
                </Styled.NavItem>
                <Styled.NavItem ref={menuGerenciamentoRef}>
                  <Styled.DropdownGerenciamento
                    onClick={() =>
                      setVisibilidade({
                        ...visibilidade,
                        menuGerenciamento: !visibilidade.menuGerenciamento,
                      })
                    }>
                    Gerenciamento
                    <Styled.SetaBaixo src={SetaBaixo} />
                  </Styled.DropdownGerenciamento>
                  {visibilidade.menuGerenciamento && (
                    <Styled.MenuGerenciamento>
                      <Styled.OpcaoGerenciamento
                        onClick={() => {
                          navigate('/centro-de-custo');
                          setVisibilidade({
                            ...visibilidade,
                            menuGerenciamento: false,
                          });
                        }}>
                        Centro de Custo
                      </Styled.OpcaoGerenciamento>
                      <Styled.OpcaoGerenciamento
                        onClick={() => {
                          navigate('/colaboradores');
                          setVisibilidade({
                            ...visibilidade,
                            menuGerenciamento: false,
                          });
                        }}>
                        Colaboradores
                      </Styled.OpcaoGerenciamento>
                      <Styled.OpcaoGerenciamento
                        onClick={() => {
                          navigate('/fatura');
                          setVisibilidade({
                            ...visibilidade,
                            menuGerenciamento: false,
                          });
                        }}>
                        Faturas
                      </Styled.OpcaoGerenciamento>
                    </Styled.MenuGerenciamento>
                  )}
                </Styled.NavItem>
                <Styled.NavItem>
                  <Styled.BotaoSair onClick={deslogaUsuario} href="/login">
                    <Styled.IconLogout src={IconLogout} />
                    Sair
                  </Styled.BotaoSair>
                </Styled.NavItem>
              </Styled.NavMenu>
            )}
          </Styled.NavbarContainer>
          <ModalUsuario
            visivel={visibilidade.modalVisivel}
            setVisivel={setVisibilidade}
          />
        </Styled.Header>
      )}
    </>
  );
};

export default IsLoadingHOC(Navigation);
