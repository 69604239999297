import useDetalheViagem from '../../hooks/useDetalheViagem';
import * as Styled from './styles';
import {
  formatarDataComHora,
  trocarNomeNoveNove,
} from '../../helpers/formatacoes';
import { ICotacoesRealizadas } from '../../@types/interfaces/detalheViagem';
import noImage from '../../assets/no-image.svg';

interface CotacoesList {
  cotacoesRealizadas: ICotacoesRealizadas[];
}

export const CotacoesRealizadas: React.FC<CotacoesList> = () => {
  const { detalheViagemResponse } = useDetalheViagem();
  return (
    <ul style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      {detalheViagemResponse.cotacoesRealizadas ? (
        detalheViagemResponse.cotacoesRealizadas.map((cotacoesRealizadas) => (
          <li
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            key={cotacoesRealizadas.tsCriacao}
            style={{ height: 'fit-content' }}>
            <Styled.CotacoesRealizadasMainContainer>
              <Styled.CotacoesRealizadasContainer>
                <Styled.MainCotacoesRow>
                  <Styled.RealizadasImg
                    src={
                      detalheViagemResponse.cotacoesRealizadas == null ||
                      undefined
                        ? noImage
                        : cotacoesRealizadas.iconeParceiro
                    }
                  />
                  <Styled.RealizadasBold>
                    {detalheViagemResponse.cotacoesRealizadas == null ||
                    undefined
                      ? 'Não foi possível retornar os dados do parceiro.'
                      : trocarNomeNoveNove(cotacoesRealizadas.parceiro)}
                  </Styled.RealizadasBold>
                  <Styled.RealizadasBold>
                    {' '}
                    {' : '}{' '}
                    {detalheViagemResponse.cotacoesRealizadas == null ||
                    undefined
                      ? ''
                      : trocarNomeNoveNove(cotacoesRealizadas.tipoCotacao)}
                  </Styled.RealizadasBold>
                </Styled.MainCotacoesRow>
                <Styled.CotacoesRow>
                  <Styled.RightSubTextDiv>
                    <Styled.RealizadasBold>Valor Cotado </Styled.RealizadasBold>
                    <Styled.RealizadasInfo>
                      {' '}
                      {' : '}{' '}
                      {detalheViagemResponse.cotacoesRealizadas == null ||
                      undefined
                        ? 'Não foi possível retornar os dados do parceiro'
                        : cotacoesRealizadas.valorUtilizado}
                    </Styled.RealizadasInfo>
                  </Styled.RightSubTextDiv>
                </Styled.CotacoesRow>
                <Styled.CotacoesRow>
                  <Styled.RealizadasBold>Data/Hora </Styled.RealizadasBold>
                  <Styled.RealizadasInfo>
                    {' '}
                    {': '}
                    {cotacoesRealizadas.dataHora == null || undefined
                      ? 'Data indisponível'
                      : formatarDataComHora(cotacoesRealizadas.dataHora)}
                  </Styled.RealizadasInfo>
                </Styled.CotacoesRow>
              </Styled.CotacoesRealizadasContainer>
            </Styled.CotacoesRealizadasMainContainer>
          </li>
        ))
      ) : (
        <Styled.NoData>
          Não foi possível recuperar as cotações realizadas neste momento.
        </Styled.NoData>
      )}
    </ul>
  );
};
