import {
  GET_PASSAGEIRO_ATIVO,
  PassageiroActionType,
  PassageiroAtivoState,
  SET_PASSAGEIRO_ATIVO,
} from './types';

const initialState: PassageiroAtivoState = {
  passageiroAtivoResponse: {
    status: true,
    headers: {
      'id-passageiro': '6453ac85bd1410bf157bb4e3',
    },
  },
  passageiroSelecionado: undefined,
};

const PassageiroReducer = (
  state = initialState,
  action: PassageiroActionType
) => {
  switch (action?.type) {
    case GET_PASSAGEIRO_ATIVO: {
      return {
        ...state,
        passageiroAtivoResponse: action.passageiroAtivoResponse,
      };
    }
    case SET_PASSAGEIRO_ATIVO: {
      const passageiroSelecionado = action.passageiroSelecionado;

      return {
        ...state,
        passageiroSelecionado,
      };
    }

    default:
      return state;
  }
};

export default PassageiroReducer;
