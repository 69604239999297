/* eslint-disable no-unused-vars */
import { IOption } from '../../../@types/interfaces/components';
import {
  ILinha,
  IViagem,
  ViagensGraficoState,
} from '../../../@types/interfaces/graficoApi';
import { GraficoActionTypes, GET_VIAGENS_GRAFICO, FILTRA_CAMPO } from './types';

const seletorDefault: IOption = {
  value: '',
  label: 'Todos os parceiros',
};

const initialState: ViagensGraficoState = {
  grafico: {
    listaViagens: [],
    linhas: [],
    ultimoDiaDoMes: 31,
    headers: {
      'mes-referencia': '',
      'id-contrato-mobilidade': '',
      'ano-referencia': '',
    },
    seletores: [seletorDefault],
  },
};

const formataNoveNove = (arrayDeObj: ILinha[], arrayDeString: IViagem[]) => {
  const novoArrayDeObj = arrayDeObj?.map((linha) => {
    if (linha.nome === 'noveNovePop' || linha.nome === 'NoveNovePop') {
      return {
        nome: '99Pop',
        cor: linha.cor,
      };
    }
    return linha;
  });
  const novoArrayDeString = arrayDeString?.map((viagem) => {
    if (viagem['noveNovePop'] !== 0 || viagem['NoveNovePop'] !== 0) {
      return {
        ...viagem,
        '99Pop': viagem['NoveNovePop'],
      };
    }
    return viagem;
  });
  return { novoArrayDeObj, novoArrayDeString };
};

const graficoReducer = (state = initialState, action: GraficoActionTypes) => {
  switch (action.type) {
    case GET_VIAGENS_GRAFICO: {
      const { novoArrayDeObj: novasLinhas, novoArrayDeString: novaLista } =
        formataNoveNove(action?.grafico?.linhas, action?.grafico?.listaViagens);

      let novoSeletores: IOption[] = [];

      if (novasLinhas) {
        novoSeletores = novasLinhas?.map((item) => {
          return { value: item.nome, label: item.nome };
        });
      }

      return {
        ...state,
        grafico: {
          linhas: novasLinhas,
          listaViagens: novaLista,
          ultimoDiaDoMes: novaLista?.length,
          headers: action.headers,
          seletores:
            novoSeletores.length > 0
              ? [seletorDefault, ...novoSeletores]
              : [seletorDefault],
        },
      };
    }

    case FILTRA_CAMPO: {
      const { novoArrayDeObj: novasLinhas, novoArrayDeString: novaLista } =
        formataNoveNove(action?.grafico?.linhas, action?.grafico?.listaViagens);
      const seletores = novasLinhas?.map((item) => {
        return { value: item.nome, label: item.nome };
      });
      let newGrafico;

      if (action?.grafico) {
        newGrafico = {
          linhas: novasLinhas,
          listaViagens: novaLista,
          ultimoDiaDoMes: novaLista?.length,
          headers: action.headers,
          seletores:
            novasLinhas.length > 1
              ? [seletorDefault, ...seletores]
              : state.grafico.seletores,
        };
      } else {
        newGrafico = {
          ...state.grafico,
          headers: action.headers,
        };
      }

      return {
        ...state,
        grafico: newGrafico,
      };
    }

    default:
      return state;
  }
};

export default graficoReducer;
