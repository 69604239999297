import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  display: flex;
  justify-content: center;
`;

export const ContentModal = styled.div`
  margin: auto;
  height: fit-content;
  @media screen and (max-width: 600px) {
    margin-bottom: 0;
  }
`;
