import styled from 'styled-components';
import { Icon } from '../../styles/globals';

interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
}

export const IconSeletor = styled(Icon)<ImgProps>`
  margin: 0;
  cursor: default;
`;

export const customStyles = {
  container: () => ({
    minWidth: '27.65vw',
    height: '3.68vw',
    border: '0.05vw solid var(--cinza-60)',
    borderRadius: '0.44vw',
    fontWeight: '500',
    fontSize: '1.2vw',
    lineHeight: '1.11vw',
    marginTop: '0.83vw',

    '@media (max-width: 768px)': {
      minWidth: '80vw',
    },
  }),
  control: () => ({
    border: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0.55vw',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0',
  }),
  menu: () => ({
    marginTop: '0.1vw',
    width: '27.65vw',
    '@media (max-width: 768px)': {
      width: '80vw',
    },
  }),
  option: () => ({
    backgroundColor: 'var(--white)',
    height: '2vw',
    border: '0.05vw solid var(--black)',
    borderRadius: '0.44vw',
    fontWeight: '500',
    fontSize: '1vw',
    lineHeight: '1.11vw',
    padding: '0.55vw',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.05vw',
  }),
  menuList: () => ({
    padding: '0',
    maxHeight: '11.16vw',
    overflow: 'scroll',
  }),
  noOptionsMessage: () => ({
    display: 'flex',
    alignItems: 'center',
    width: '27.65vw',
    height: '3.68vw',
    border: '0.05vw solid var(--black)',
    borderRadius: '0.44vw',
    fontWeight: '500',
    fontSize: '1.2vw',
    lineHeight: '1.11vw',
    backgroundColor: 'var(--white)',
    padding: '0.55vw',
    '@media (max-width: 768px)': {
      width: '80vw',
    },
  }),
  multiValue: () => ({
    display: 'none',
  }),
  input: () => ({
    width: '27.65vw',
    '@media (max-width: 768px)': {
      width: '74vw',
    },
  }),
};
