import segurancaAxios from '../config/axios/SegurancaAxios';
import {
  CardResponse,
  FiltrosHeadersCard,
} from '../@types/interfaces/cardColaborador';

class CardColaboradorApi {
  /**
   * Get Card Colaborador
   * @return {Promise<any>} Retorna um array de card de colaborador.
   */
  public getCardColaborador = async (
    headers?: FiltrosHeadersCard
  ): Promise<CardResponse> => {
    const response = await segurancaAxios.get('contrato/cardColaborador', {
      headers,
    });
    return { cardColaborador: response?.data };
  };
}
export default new CardColaboradorApi();
