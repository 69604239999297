import { ColaboradorResponse } from '../../../@types/interfaces/Colaborador';

export const SET_COLABORADOR = 'PORTAL/SET_COLABORADOR';

export interface ColaboradorState {
  colaboradorResponse: ColaboradorResponse;
}

export interface SetColaborador {
  type: typeof SET_COLABORADOR;
  colaboradorResponse: ColaboradorResponse;
}

export type ColaboradorActionType = SetColaborador;
