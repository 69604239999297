import React from 'react';
import Loading from '../components/Loading';

const IsLoadingHOC = <P extends any & unknown>(
  Component: React.ComponentType<P>
) =>
  function HOC(props: any) {
    const [loading, setLoading] = React.useState(false);
    const setLoadingState = (isComponentLoading: boolean) => {
      setLoading(isComponentLoading);
    };

    React.useEffect(() => {
      return () => {
        setLoading(false);
      };
    }, []);
    return (
      <>
        <Component {...props} setLoading={setLoadingState} />
        {loading && <Loading />}
      </>
    );
  };

export default IsLoadingHOC;
