import {
  ContratoState,
  LIMPA_CONTRATO,
  ListaContratosActionTypes,
  SET_CONTRATO_SELECIONADO,
  SET_LISTA_CONTRATOS,
} from './types';

const initialState: ContratoState = {
  contratoResponse: [],
  listaContratos: [],
  contratoSelecionado: {
    id: '',
    numeroDeIdentificacao: '',
    nomeDeExibicao: '',
    dataInicioVigencia: new Date(),
    dataFimVigencia: new Date(),
    estado: '',
  },
};

const contratoReducer = (
  state = initialState,
  action: ListaContratosActionTypes
): ContratoState => {
  switch (action?.type) {
    case SET_LISTA_CONTRATOS: {
      const listaContratos = action.contratoResponse?.map((contrato: any) => ({
        ...contrato,
      }));

      const contratoSelecionado = action.contratoResponse[0];

      return {
        ...state,
        contratoResponse: action.contratoResponse,
        listaContratos,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        contratoSelecionado,
      };
    }
    case SET_CONTRATO_SELECIONADO: {
      return {
        ...state,
        contratoSelecionado: action.contratoSelecionado,
      };
    }

    case LIMPA_CONTRATO: {
      return initialState;
    }

    default:
      return state;
  }
};

export default contratoReducer;
