import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../styles/globals';

export const Header = styled.nav`
  background: linear-gradient(90deg, var(--blueNav), var(--action));
  height: 3.74vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  padding: 0;
  margin: 0;
  max-width: 100%;

  @media (max-width: 835px) {
    height: 5.61vw;
  }
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 1vw;
  padding: 0 7vw 0 3.5vw;
  min-width: 100%;
  @media (max-width: 835px) {
    padding: 0 10.5vw 0 5.25vw;
  }
`;

export const Logo = styled(NavLink)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LogoImg = styled.img`
  margin-left: 1.1vw;
  margin-right: 1.1vw;
  width: 2.6vw;
  height: 2.6vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 835px) {
    margin-left: 1.65vw;
    margin-right: 1.65vw;
    width: 3.9vw;
    height: 3.9vw;
  }
`;

export const InfoUsuario = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.66vw;
  height: 75%;
  justify-content: space-around;
  cursor: default;
  @media (max-width: 835px) {
    margin: 0 0.99vw;
    height: 65%;
  }
`;

export const Saudacao = styled.div`
  font-weight: 400;
  font-size: 1.15vw;
  line-height: 1.45vw;

  color: var(--white);

  @media (max-width: 835px) {
    font-size: 1.72vw;
  }
`;

export const Data = styled.div`
  padding-top: 0.25vw;
  font-weight: 500;
  font-size: 0.86vw;
  line-height: 0.86vw;
  letter-spacing: 0.01vw;
  display: flex;
  align-items: flex-end;

  color: var(--white);
  @media (max-width: 835px) {
    padding-top: 0.37vw;
    font-size: 1.29vw;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;
  width: 45%;
  justify-content: flex-end;
  @media (max-width: 835px) {
    width: 40%;
  }
`;

export const NavItem = styled.li`
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 50%;
  &:last-child {
    justify-content: center;
    border-left: 0.05vw solid var(--white);
    padding-left: 1vw;
  }
  @media (max-width: 835px) {
    height: 60%;
    &:last-child {
      justify-content: center;
      border-left: 0.07vw solid var(--white);
      padding-left: 1.5vw;
    }
  }
`;

export const NavLinks = styled(NavLink)`
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 0.1vw 3.8vw 0 0;
  height: 100%;
  width: 7vw;
  font-weight: 500;
  font-size: 1.15vw;
  line-height: 1.23vw;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    padding-bottom: 0.5vw;
    padding-top: 0.5vw;
    border-bottom: 0.05vw solid var(--white);
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const DropdownGerenciamento = styled.div`
  font-weight: 500;
  font-size: 1.15vw;
  line-height: 1.8vw;
  color: var(--white);
  cursor: pointer;
  width: 10vw;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  padding-right: 1.9vw;

  &:hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const SetaBaixo = styled.img`
  width: 0.6vw;
  height: 0.6vw;
  margin-left: 0.5vw;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const MenuGerenciamento = styled.ul`
  margin: 9vw 0 0 -0.85vw;
  position: absolute;
  width: 9.9vw;
  height: fit-content;
  border-radius: 0.6vw;
  background-color: var(--white);
  z-index: 1;
  box-shadow: 0 0.1116vw 0.1116vw rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const OpcaoGerenciamento = styled.li`
  height: 2.6vw;
  font-weight: 500;
  font-size: 0.82vw;
  line-height: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01vw;
  border-bottom: 0.05vw solid var(--cinza-30);
  cursor: pointer;
  &:first-child {
    border-top-right-radius: 0.6vw;
    border-top-left-radius: 0.6vw;
  }

  &:last-child {
    border-bottom-right-radius: 0.6vw;
    border-bottom-left-radius: 0.6vw;
    border-bottom: none;
  }
  &:hover {
    background-color: rgba(62, 74, 250, 0.5);
  }

  @media (max-width: 835px) {
    height: 3.9vw;
    font-size: 1.23vw;
    border-bottom: 0.075vw solid var(--cinza-30);
    &:first-child {
      border-top-right-radius: 0.9vw;
      border-top-left-radius: 0.9vw;
    }

    &:last-child {
      border-bottom-right-radius: 0.9vw;
      border-bottom-left-radius: 0.9vw;
    }
  }
`;

export const IconLogout = styled.img`
  width: 1.17vw;
  height: 2vw;
  margin: 0 0.2vw 0 1.2vw;

  @media (max-width: 835px) {
    width: 1.75vw;
    height: 3vw;
    margin: 0 0.3vw 0 1.8vw;
  }
`;

export const BotaoSair = styled.a`
  margin-left: 0.2vw;
  margin-right: -2.5vw;
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-weight: 500;
  font-size: 1.15vw;
  line-height: 1.23vw;
  &:hover {
    opacity: 0.6;
  }

  &.active {
    border-bottom: 0.111vw solid var(--white);
  }

  @media (max-width: 835px) {
    margin-left: 0.3vw;
    margin-right: -3.75vw;
    font-size: 1.72vw;
  }
`;

export const WrapperLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
