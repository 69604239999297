import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import * as Styled from '../NovoColaborador/styles';

import SegundoFormularioEditarColaborador from '../../components/FormularioEditarColaborador/SegundoFormularioEditarColaborador';
import PrimeiroFormularioEditarColaborador from '../../components/FormularioEditarColaborador/PrimeiroFormularioEditarColaborador';

import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import useContratos from '../../hooks/useContratos';

import EditarColaboradorApi from '../../api/PassageiroEditar';

import ErroModal from '../../assets/erro-modal.svg';
import SucessoModal from '../../assets/sucesso-modal.svg';
import ImagemFormulario from '../../assets/profile-data.svg';
import SetaEsquerda from '../../assets/icone-seta-esquerda.svg';

import ModalNovoColaborador from '../../components/ModalNovoColaborador';

import { DadosProps } from '../../@types/interfaces/dadosNovoColaborador';
import { CadastroNovoColaborador } from '../../@types/interfaces/cadastroNovoColaborador';

import { CardListaLateral } from '../../components/CardListaLateral';
import useColaborador from '../../hooks/useColaborador';
import { logger } from '../../util/logger';

import {
  enviarDados,
  resetarDados,
  navegarPaginaAnterior,
} from '../../helpers/utilsFormulariosColaborador';

interface NovoColaboradorProps {
  setLoading: (value: boolean) => void;
}

const EditarColaborador = ({ setLoading }: NovoColaboradorProps) => {
  const navigate = useNavigate();

  const { contratoSelecionado } = useContratos();
  const { colaboradorResponse } = useColaborador();

  const [mostrarPrimeiraPagina, setMostrarPrimeiraPagina] = useState(true);

  const [modalSucessoVisivel, setModalSucessoVisivel] = useState(false);
  const [modalErroVisivel, setModalErroVisivel] = useState(false);

  const [camposDesabilitados, setCamposDesabilitados] = useState(true);
  const [botaoSubmitDesabilitado, setBotaoSubmitDesabilitado] = useState(true);
  const [camposIniciaisDesabilitados, setCamposIniciaisDesabilitados] =
    useState(false);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm<DadosProps>();

  const dadosDefault = {
    cpf: colaboradorResponse?.cpf || '',
    dataNascimento: '',
    nome: colaboradorResponse?.nome || '',
    email: colaboradorResponse?.email || '',
    celular: colaboradorResponse?.numeroTelefoneCelular || '',
    cargo:
      colaboradorResponse?.dadosDoUsuarioVinculadosAoContrato[0]?.cargo ||
      'Não informado',
    centroCustoVinculado:
      colaboradorResponse?.autorizacoesUsuario[0]?.[
        'numeroDeIdentificacaoDoCentroDeCusto_@CMU'
      ] || '',
    categoria:
      colaboradorResponse?.autorizacoesUsuario[0].solicitarCategoriaVeiculo ||
      'comum',
    status: true,
  };

  const [dados, setDados] = useState<DadosProps>(dadosDefault);

  const editarColaborador = async ({
    email,
    celular,
    centroCustoVinculado,
    cargo,
    categoria,
  }: CadastroNovoColaborador) => {
    try {
      const headers = {
        cpf: colaboradorResponse.cpf,
        email: email,
        'numero-telefone-celular': celular,
        'id-contrato-mobilidade': contratoSelecionado.id,
        id: colaboradorResponse._id,
        'numero-identificacao-centro-custo': centroCustoVinculado,
        cargo: cargo,
        'solicitar-categoria-veiculo': categoria,
      };

      return await EditarColaboradorApi.editarPassageiro(headers);
    } catch (error) {
      const errorFormatado = `
      ERRO no MS "${process.env.REACT_SERVER_NAME}", método "editarNovoColaborador".
      <'ERRO'>
        message: Não foi possível editar o colaborador!
      Resposta:
      <'ERRO DB'>
        code: ${error?.code}
        message: ${error.message}.
    `;
      logger.error(errorFormatado);
    }
  };

  const onSubmit: SubmitHandler<DadosProps> = () => {
    setBotaoSubmitDesabilitado(true);
    setLoading(true);
    enviarDados(
      dados,
      editarColaborador,
      setLoading,
      setModalSucessoVisivel,
      setModalErroVisivel
    ).then(() => ({}));
  };

  return (
    <Styled.Content>
      <CardListaLateral>
        <>
          <Styled.WrapperTitulo>
            <Styled.IconeVoltar
              id={'BotaoVoltar'}
              src={SetaEsquerda}
              onClick={() =>
                navegarPaginaAnterior(
                  mostrarPrimeiraPagina,
                  navigate,
                  setMostrarPrimeiraPagina
                )
              }
            />
            <Styled.Titulo>Editar Colaborador</Styled.Titulo>
          </Styled.WrapperTitulo>
          <Styled.WrapperContent>
            <Styled.Formulario
              id="Formulario"
              onSubmit={handleSubmit(onSubmit)}>
              {mostrarPrimeiraPagina ? (
                <PrimeiroFormularioEditarColaborador
                  setValue={setValue}
                  setError={setError}
                  register={register}
                  errors={errors}
                  setMostrarPrimeiraPagina={setMostrarPrimeiraPagina}
                  dados={dados}
                  setDados={setDados}
                  setLoading={setLoading}
                  camposDesabilitados={camposDesabilitados}
                  setCamposDesabilitados={setCamposDesabilitados}
                  camposIniciaisDesabilitados={camposIniciaisDesabilitados}
                  setCamposIniciaisDesabilitados={
                    setCamposIniciaisDesabilitados
                  }
                  clearErrors={clearErrors}
                  setBotaoSubmitDesabilitado={setBotaoSubmitDesabilitado}
                />
              ) : (
                <SegundoFormularioEditarColaborador
                  botaoSubmitDesabilitado={botaoSubmitDesabilitado}
                  setBotaoSubmitDesabilitado={setBotaoSubmitDesabilitado}
                  dados={dados}
                  setDados={setDados}
                  setLoading={setLoading}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              )}
            </Styled.Formulario>
            <Styled.WrapperImg>
              <Styled.Img src={ImagemFormulario} />
            </Styled.WrapperImg>
          </Styled.WrapperContent>
          <ModalNovoColaborador
            visivel={modalSucessoVisivel}
            onCloseRequest={() => navigate('/colaboradores')}
            imagem={SucessoModal}
            textoPai="Colaborador editado com sucesso!"
            labelBotao="Ok"
            textoFilho=""
            onClick={() => navigate('/colaboradores')}
          />
          <ModalNovoColaborador
            visivel={modalErroVisivel}
            onCloseRequest={() =>
              resetarDados(
                setModalErroVisivel,
                setMostrarPrimeiraPagina,
                setCamposDesabilitados,
                setCamposIniciaisDesabilitados,
                setDados,
                dadosDefault,
                reset
              )
            }
            imagem={ErroModal}
            textoPai="Não foi possível editar o colaborador!"
            labelBotao="Tentar novamente"
            textoFilho="Ocorreu um erro ao tentar editar o colaborador. Por favor, tente novamente mais tarde."
            onClick={() => navigate('/colaboradores')}
          />
        </>
      </CardListaLateral>
    </Styled.Content>
  );
};

export default IsLoadingHOC(EditarColaborador);
