import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as faturasActions from '../store/ducks/faturas/actions';
import * as operations from '../store/ducks/faturas/operations';
import useContratos from './useContratos';
import { IOption } from '../@types/interfaces/components';

function useFaturas() {
  const { faturasResponse, faturaSelecionada } = useSelector(
    (state: RootState) => state.faturas
  );
  const { contratoSelecionado } = useContratos();

  const {
    fetchFaturasPorContrato,
    ativaMenuFatura,
    fetchOrdenaNumeroFatura,
    fetchOrdenaPeriodo,
    fetchOrdenaVencimento,
    fetchOrdenaTotal,
    fetchControlePaginacao,
    setFaturaSelecionada,
    fetchFiltrosHeaders,
    limpaFaturas,
    filtraCampo,
    fetchAtesteFatura,
  } = bindActionCreators(
    {
      ...faturasActions,
      ...operations,
    },
    useDispatch()
  );

  const handleOrdenaTotal = useCallback(async () => {
    try {
      const headers = {
        ...faturasResponse?.headers,
        'campo-ordenado': 'valorTotalDaFatura',
        'ordenacao-do-campo': faturasResponse.totalOrdenado ? 'asc' : 'desc',
        'numero-pagina': faturasResponse?.headers?.['numero-pagina'],
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };

      await fetchOrdenaTotal(headers);
    } catch (error: any) {
      if (
        error?.response?.data?.code === '009' ||
        error?.response?.data?.code === '-1'
      ) {
        limpaFaturas();
      }
    }
  }, [faturasResponse?.totalOrdenado, faturasResponse?.headers]);

  const handleOrdenaNumeroFatura = useCallback(async () => {
    try {
      const headers = {
        ...faturasResponse?.headers,
        'campo-ordenado': 'numeroFatura',
        'ordenacao-do-campo': faturasResponse.faturaOrdenada ? 'asc' : 'desc',
        'numero-pagina': faturasResponse?.headers?.['numero-pagina'],
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };
      await fetchOrdenaNumeroFatura(headers);
    } catch (error: any) {
      if (
        error?.response?.data?.code === '009' ||
        error?.response?.data?.code === '-1'
      ) {
        limpaFaturas();
      }
    }
  }, [faturasResponse?.faturaOrdenada, faturasResponse?.headers]);

  const handleOrdenaPeriodo = useCallback(async () => {
    try {
      const headers = {
        ...faturasResponse?.headers,
        'campo-ordenado': 'periodoReferencia',
        'ordenacao-do-campo': faturasResponse.periodoOrdenado ? 'asc' : 'desc',
        'numero-pagina': faturasResponse?.headers?.['numero-pagina'],
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };

      await fetchOrdenaPeriodo(headers);
    } catch (error: any) {
      if (
        error?.response?.data?.code === '009' ||
        error?.response?.data?.code === '-1'
      ) {
        limpaFaturas();
      }
    }
  }, [faturasResponse?.periodoOrdenado, faturasResponse?.headers]);

  const handleOrdenaVencimento = useCallback(async () => {
    try {
      const headers = {
        ...faturasResponse?.headers,
        'campo-ordenado': 'dataDeVencimento',
        'ordenacao-do-campo': faturasResponse.vencimentoOrdenado
          ? 'asc'
          : 'desc',
        'numero-pagina': faturasResponse?.headers?.['numero-pagina'],
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };

      await fetchOrdenaVencimento(headers);
    } catch (error: any) {
      if (
        error?.response?.data?.code === '009' ||
        error?.response?.data?.code === '-1'
      ) {
        limpaFaturas();
      }
    }
  }, [faturasResponse?.vencimentoOrdenado, faturasResponse?.headers]);

  const handleFiltraDataInicio = useCallback(
    async (dataStr: string, setLoading: (value: boolean) => void) => {
      const headers = {
        ...faturasResponse?.headers,
        'numero-pagina': '1',
        'data-inicio': dataStr,
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };
      try {
        setLoading(true);
        await fetchFiltrosHeaders(headers);
      } catch (error: any) {
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '-1'
        ) {
          limpaFaturas();
          filtraCampo(headers);
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.headers]
  );

  const handleFiltraDataFim = useCallback(
    async (dataStr: string, setLoading: (value: boolean) => void) => {
      const headers = {
        ...faturasResponse?.headers,
        'numero-pagina': '1',
        'data-fim': dataStr,
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };
      try {
        setLoading(true);
        await fetchFiltrosHeaders(headers);
      } catch (error: any) {
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '-1'
        ) {
          limpaFaturas();
          filtraCampo(headers);
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.headers]
  );

  const handlePaginacaoFaturas = useCallback(
    async (
      selectedItem: { selected: number },
      setLoading: (value: boolean) => void
    ) => {
      try {
        setLoading(true);
        const paginaSelecionada = selectedItem.selected + 1;
        const headers = {
          ...faturasResponse?.headers,
          'numero-pagina': paginaSelecionada.toString(),
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        await fetchControlePaginacao(headers, paginaSelecionada);
      } catch (error: any) {
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '-1'
        ) {
          limpaFaturas();
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.headers, contratoSelecionado, contratoSelecionado?.id]
  );

  const consultaFaturasPorContrato = useCallback(
    async (setLoading: (value: boolean) => void) => {
      const initialHeaders = {
        ...faturasResponse?.headers,
        'numero-pagina': '1',
        'numero-identificacao-centro-custo': '',
        'id-contrato-mobilidade': contratoSelecionado?.id,
      };
      try {
        setLoading(true);
        await fetchFaturasPorContrato(initialHeaders);
      } catch (error: any) {
        setLoading(false);
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '-1'
        ) {
          limpaFaturas();
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.headers, contratoSelecionado, contratoSelecionado?.id]
  );

  const handleFiltraPorSituacao = useCallback(
    async (option: IOption, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...faturasResponse?.headers,
          'numero-pagina': '1',
          'texto-estado-fatura': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error) {
          if (
            error?.response?.data?.code === '009' ||
            error?.response?.data?.code === '-1'
          ) {
            limpaFaturas();
            filtraCampo(headers);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [faturasResponse?.headers]
  );

  const handleFiltraPorSituacaoPagamento = useCallback(
    async (option: IOption, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...faturasResponse?.headers,
          'numero-pagina': '1',
          'texto-estado-pagamento-fatura': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error) {
          if (
            error?.response?.data?.code === '009' ||
            error?.response?.data?.code === '-1'
          ) {
            limpaFaturas();
            filtraCampo(headers);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [faturasResponse?.headers]
  );

  const handleFiltraPorCentroDeCustos = useCallback(
    async (option: any, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...faturasResponse?.headers,
          'numero-pagina': '1',
          'numero-identificacao-centro-custo': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };

        try {
          setLoading(true);
          await fetchFiltrosHeaders(headers);
        } catch (error: any) {
          if (
            error?.response?.data?.code === '009' ||
            error?.response?.data?.code === '-1'
          ) {
            limpaFaturas();
            filtraCampo(headers);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [faturasResponse?.headers]
  );

  const handleAtesteFatura = useCallback(
    async (
      idContrato: string,
      idFatura: string,
      setModalSucessoVisivel: (value: boolean) => void,
      setModalErroVisivel: (value: boolean) => void,
      setLoading: (value: boolean) => void
    ) => {
      try {
        const ateste = {
          idContrato,
          idFatura,
          estado: 'Efetivado',
        };
        setLoading(true);
        await fetchAtesteFatura(ateste);
        setLoading(false);
        setModalSucessoVisivel(true);
      } catch (error: any) {
        setLoading(false);
        setModalErroVisivel(true);
        if (
          error?.response?.data?.code === '009' ||
          error?.response?.data?.code === '-1'
        ) {
          limpaFaturas();
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.listaRegistro]
  );

  const handleContestarAtesteFatura = useCallback(
    async (
      idContrato: string,
      idFatura: string,
      justificativa: string,
      setModalSucessoContestarVisivel: (value: boolean) => void,
      setModalContestarErroVisivel: (value: boolean) => void,
      setLoading: (value: boolean) => void
    ) => {
      setLoading(true);

      try {
        const ateste = {
          idContrato,
          idFatura,
          justificativa,
          estado: 'Recusado',
        };

        await fetchAtesteFatura(ateste);
        setModalSucessoContestarVisivel(true);
      } catch (error: any) {
        setModalContestarErroVisivel(true);

        const errorCodesToClear = ['009', '-1'];
        if (
          error?.response?.data?.code &&
          errorCodesToClear.includes(error.response.data.code)
        ) {
          limpaFaturas();
        }
      } finally {
        setLoading(false);
      }
    },
    [faturasResponse?.listaRegistro]
  );

  return {
    faturasResponse,
    faturaSelecionada,
    fetchFaturasPorContrato,
    ativaMenuFatura,
    handleOrdenaNumeroFatura,
    handleOrdenaPeriodo,
    handleOrdenaVencimento,
    handleFiltraPorSituacao,
    handleFiltraPorSituacaoPagamento,
    handleFiltraDataInicio,
    handleFiltraDataFim,
    handleOrdenaTotal,
    handlePaginacaoFaturas,
    consultaFaturasPorContrato,
    handleFiltraPorCentroDeCustos,
    setFaturaSelecionada,
    limpaFaturas,
    handleAtesteFatura,
    handleContestarAtesteFatura,
  };
}

export default useFaturas;
