import { IColaboradores } from '../../../@types/interfaces/colaboradoresApi';
import { PassageiroStatusResponse } from '../../../@types/interfaces/passageiroAtivar';

export const GET_PASSAGEIRO_ATIVO = 'PASSAGEIRO/ATIVAR';
export const SET_PASSAGEIRO_ATIVO = 'PASSAGEIRO_SELECIONADO/ATIVAR';

export interface PassageiroAtivoState {
  passageiroAtivoResponse: PassageiroStatusResponse;
  passageiroSelecionado: IColaboradores | undefined;
}

export interface GetPassageiroAtivo {
  type: typeof GET_PASSAGEIRO_ATIVO;
  passageiroAtivoResponse: PassageiroStatusResponse;
}
interface SetPassageiroSelecionado {
  type: typeof SET_PASSAGEIRO_ATIVO;
  passageiroSelecionado: IColaboradores;
}

export type PassageiroActionType =
  | GetPassageiroAtivo
  | SetPassageiroSelecionado;
