import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  transition: all 0.6s ease-in-out 0s;
  animation: ${fadeIn} 1s, ${slideIn} 0.5s linear;
  height: fit-content;
`;

export const ModalContent = styled.div`
  background-color: var(--cinza-50);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

export const ModalText = styled.p`
  font-size: 15px;
  text-align: center;
  color: var(--bgSoft);
`;

export const ModalButton = styled.div`
  cursor: pointer;
  background-color: var(--action);
  padding: 15px;
  margin-left: 30px;
  border-radius: 8px;
  color: var(--white);
`;

export const TextoPolitica = styled.a`
  color: var(--white);
  text-decoration: underline;
  cursor: pointer;
`;
