import {
  CentroDeCustosDoContratoResponse,
  FiltrosHeaderCentroDeCusto,
} from '../../../@types/interfaces/contratoApi';
import {
  CentrosDeCustosActionTypes,
  CONTROLE_PAGINACAO,
  LIMPA_CENTRO_CUSTO,
  SET_CENTROS_DE_CUSTOS_DO_CONTRATO,
} from './types';
export function setCentrosDeCustosDoContrato(
  centrosDeCustosResponse: CentroDeCustosDoContratoResponse
): CentrosDeCustosActionTypes {
  return {
    type: SET_CENTROS_DE_CUSTOS_DO_CONTRATO,
    centrosDeCustosResponse,
  };
}

export function controlePaginacao(
  centrosDeCustosResponse: CentroDeCustosDoContratoResponse,
  headers: FiltrosHeaderCentroDeCusto,
  numeroPagina: number
): CentrosDeCustosActionTypes {
  return {
    type: CONTROLE_PAGINACAO,
    centrosDeCustosResponse,
    headers,
    numeroPagina,
  };
}

export function limpaCentroCusto(): CentrosDeCustosActionTypes {
  return {
    type: LIMPA_CENTRO_CUSTO,
  };
}
