import segurancaAxios from '../config/axios/SegurancaAxios';
import { logger } from '../util/logger';
import { EditarColaborador } from '../@types/interfaces/editarColaborador';

class PassageiroEditarApi {
  public editarPassageiro = async (
    headers: EditarColaborador
  ): Promise<boolean> => {
    try {
      const config = {
        headers: {
          'id-passageiro': headers.id,
          'id-contrato-mobilidade': headers['id-contrato-mobilidade'],
        },
      };

      const body = {
        email: headers.email,
        numeroTelefoneCelular: headers['numero-telefone-celular'],
        cargo: headers.cargo,
        solicitarCategoriaVeiculo: headers['solicitar-categoria-veiculo'],
        idContrato: headers['id-contrato-mobilidade'],
        numeroDeIdentificacaoDoCentroDeCusto:
          headers['numero-identificacao-centro-custo'],
      };

      const result = await segurancaAxios.put(
        'passageiro/atualizar',
        body,
        config
      );

      return !!result;
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${
          process.env.REACT_SERVER_NAME
        }", método "editarPassageiro".
        <'ERRO'>
          message: Não foi possível editar as informações do passageiro!
        Parâmetros da requisição:
          HEADERS: ${JSON.stringify(headers)},
          ENDPOINT: 'passageiro/ativar',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
      return false;
    }
  };
}

export default new PassageiroEditarApi();
