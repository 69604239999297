import styled from 'styled-components';

interface InputWidth {
  width: string;
}

export const Campo = styled.div`
  display: flex;
  flex-direction: column;
  height: 7vw;
`;

export const Label = styled.label`
  padding-bottom: 0.5vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--cinza-90);
`;

export const Input = styled.input<InputWidth>`
  width: ${(props) => props.width};
  height: 2.28vw;
  border: 0.06vw solid var(--cinza-60);
  border-radius: 0.51vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.28vw;
  padding: 0.64vw;
  color: var(--cinza-60);
`;

export const Erro = styled.span`
  padding: 0.4vw 0;
  height: 2vw;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1.47vw;
  color: var(--salmon);
  display: flex;
  align-items: center;
  max-width: 27.5vw;
`;

export const IconErro = styled.img`
  margin-right: 2%;
  width: 0.7vw;
  height: 0.7vw;
`;

export const LabelSpan = styled.span`
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.45vw;
`;
