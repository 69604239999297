import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { DatePickerDetalharFatura } from '../../components/DatePickerDetalharFatura';
import IsLoadingHOC from '../../hoc/IsLoadingHOC';
import ImgOrder from '../../assets/icon-order.svg';
import ImgPoints from '../../assets/points.svg';

import ImgDetalhe from '../../assets/icone-valor-total.svg';
import ImgDetalhar from '../../assets/icone-data-vencimento.svg';
import ImgDetalharMoney from '../../assets/icone-periodo.svg';

import * as Styled from './styles';
import { Badge } from '../../components/Badge';
import { Pagination } from '../../components/Pagination';
import useDetalhesFatura from '../../hooks/useDetalhesFatura';
import { IDetalharFatura } from '../../@types/interfaces/detalharFatura';
import {
  filtrarData,
  formatarCpf,
  formatarData,
  formatarDataSemDia,
  formatarNomeParceiro,
  formatarReal,
} from '../../helpers/formatacoes';
import useFaturas from '../../hooks/useFaturas';
import lodashHelpers from '../../helpers/lodashHelpers';
import { CardNoData } from '../../components/Cards/CardNoData';
import { Erro } from '../../components/Erro';
import useErro from '../../hooks/useErro';
import { useNavigate } from 'react-router-dom';
import ModalDetalharFatura from '../../components/ModalDetalharFatura';
import useDetalheViagem from '../../hooks/useDetalheViagem';
import { SeletorDetalharFatura } from '../../components/SeletorDetalharFatura';
import { InputDetalharFatura } from '../../components/InputDetalharFatura';
import useCentrosDeCustos from '../../hooks/useCentrosDeCustos';
import BotaoExportarRelatorio from '../../components/BotaoExportarRelatorio';

export interface DetalharProps {
  setLoading: (value: boolean) => void;
}

const Detalhar = ({ setLoading }: DetalharProps) => {
  const { centrosDeCustos, centroDeCustoSelecionado } = useCentrosDeCustos();
  const refsIcons: MutableRefObject<Array<HTMLDivElement | null>> = useRef([]);

  const quantidadeItemsPagina = 8;

  const quantidadePaginacao = (
    quantidadeTotalRegistro: number,
    itensPagina: number
  ) => {
    return Math.ceil(quantidadeTotalRegistro / itensPagina);
  };

  const {
    detalheFaturaResponse,
    downloadCsv,
    downloadXLSX,
    consultaDetalheFaturasPorContrato,
    ativaMenuDetalheFatura,
    handleOrdenaParceiro,
    handleOrdenaColaborador,
    handleOrdenaData,
    handleOrdenaTotal,
    handlePaginacaoNoDetalhes,
    handleBuscaColaborador,
    handleFiltraDataInicioViagemDetalhes,
    handleFiltraDataFinalViagemDetalhes,
    handleFiltraParceiroNoDetalhes,
    handleFiltraCentroCustoNoDetalhes,
  } = useDetalhesFatura();

  const arrayParceiros = detalheFaturaResponse.listaNomesParceiros;
  const parceiros = [];

  const parceirosLabels = {
    noveNovePop: '99App',
    wappa: 'Wappa',
    cooparioca: 'Cooparioca',
    uber: 'Uber',
    transpataxi: 'Transpataxi Rio',
    taxi01: 'Taxi01',
  };

  if (arrayParceiros.length > 0) {
    parceiros.push({ value: '', label: 'Todos' });
  }

  for (const parceiro of arrayParceiros) {
    const label: string =
      parceirosLabels[parceiro as keyof typeof parceirosLabels];
    if (label) {
      parceiros.push({ value: parceiro, label });
    }
  }

  const parceiroDefault = { value: '', label: 'Todos' };

  const { consultaDetalheViagem } = useDetalheViagem();

  const buscaColaborador = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => handleBuscaColaborador(event, setLoading);

  const buscaColaboradorDebounce = lodashHelpers.debounce(
    buscaColaborador,
    500
  );

  const buscaDataInicio = (dataStr: string) => {
    handleFiltraDataInicioViagemDetalhes(dataStr, setLoading).then(() => ({}));
  };

  const buscaDataFinal = (dataStr: string) => {
    handleFiltraDataFinalViagemDetalhes(dataStr, setLoading).then(() => ({}));
  };

  const navigate = useNavigate();

  const { codigoErro, limpaErro } = useErro();

  const [dataInicial, setDataInicial] = useState<Date>();

  const [dataFinal, setDataFinal] = useState<Date>();

  const dataAtual = new Date();

  const { setDetalheViagemSelecionado } = useDetalheViagem();
  const { faturaSelecionada } = useFaturas();
  const [mostrarModal, setMostrarModal] = useState<boolean>(false);

  const consultarDetalheDaViagem = async () => {
    try {
      await consultaDetalheViagem(setLoading);
    } catch (erro) {
      console.log(erro);
    } finally {
      setMostrarModal(true);
    }
  };

  useEffect(() => {
    consultaDetalheFaturasPorContrato(setLoading).then(() => ({}));
  }, [faturaSelecionada]);

  return (
    <React.Fragment>
      {codigoErro ? (
        <Erro
          codigo={codigoErro}
          textoBotao="Voltar à página inicial"
          onClick={() => {
            navigate('/login');
            limpaErro();
          }}
        />
      ) : (
        <Styled.Content>
          <React.Fragment>
            <Styled.Titulo>Faturas</Styled.Titulo>
            <Styled.MainExtract>
              <Styled.Extract>
                <Styled.IconDateDetalhar
                  imageUrl={ImgDetalharMoney}
                  width={12}
                  height={10}
                />
                <Styled.ContentExtract>
                  <Styled.TextExtract>
                    {faturaSelecionada ? (
                      `${formatarDataSemDia(
                        detalheFaturaResponse?.dataReferencia
                      )}`
                    ) : (
                      <Styled.TextExtract>
                        Não há valor disponível
                      </Styled.TextExtract>
                    )}
                  </Styled.TextExtract>
                  <Styled.SubTextExtract>Período</Styled.SubTextExtract>
                </Styled.ContentExtract>
              </Styled.Extract>
              <Styled.Extract>
                <Styled.IconDateDetalhar
                  imageUrl={ImgDetalhe}
                  width={12}
                  height={10}
                />
                <Styled.ContentExtract>
                  <Styled.TextExtract>
                    {faturaSelecionada ? (
                      `${formatarReal(faturaSelecionada.valorTotalDaFatura)}`
                    ) : (
                      <Styled.TextExtract>
                        Não há valor disponível
                      </Styled.TextExtract>
                    )}
                  </Styled.TextExtract>
                  <Styled.SubTextExtract>Valor Total</Styled.SubTextExtract>
                </Styled.ContentExtract>
              </Styled.Extract>
              <Styled.Extract>
                <Styled.IconDateDetalhar
                  imageUrl={ImgDetalhar}
                  width={12}
                  height={10}
                />
                <Styled.ContentExtract>
                  <Styled.TextExtract>
                    {faturaSelecionada ? (
                      `${formatarData(faturaSelecionada?.dataDeVencimento)}`
                    ) : (
                      <Styled.TextExtract>
                        Não há data disponível
                      </Styled.TextExtract>
                    )}
                  </Styled.TextExtract>
                  <Styled.SubTextExtract>Vencimento</Styled.SubTextExtract>
                </Styled.ContentExtract>
              </Styled.Extract>
              {faturaSelecionada && (
                <Badge texto={faturaSelecionada.estadoDoPagamentoDaFatura} />
              )}
            </Styled.MainExtract>
            <Styled.WrapperImgDetalhar>
              <Styled.WrapperSelectorDetalhar>
                <InputDetalharFatura
                  id="inputColaborador"
                  label="Buscar"
                  inputGrande
                  placeholder={
                    (detalheFaturaResponse?.headers &&
                      detalheFaturaResponse.headers['nome-colaborador']) ||
                    'Colaborador'
                  }
                  tipo="texto"
                  onChange={buscaColaboradorDebounce}
                />
                <SeletorDetalharFatura
                  id="centroCusto"
                  label="Centro de Custo"
                  defaultValue={centroDeCustoSelecionado}
                  options={centrosDeCustos}
                  onChange={(option) => {
                    handleFiltraCentroCustoNoDetalhes(option, setLoading).then(
                      () => ({})
                    );
                  }}
                />
                <SeletorDetalharFatura
                  id="parceiro"
                  label="Parceiro"
                  defaultValue={parceiroDefault}
                  options={parceiros}
                  onChange={(option) => {
                    handleFiltraParceiroNoDetalhes(option, setLoading).then(
                      () => ({})
                    );
                  }}
                />
                <DatePickerDetalharFatura
                  label="Data da Viagem"
                  dataSelecionada={dataInicial}
                  placeholder="Data inicial"
                  id="data-inicio"
                  onChange={(data) =>
                    filtrarData(data, buscaDataInicio, setDataInicial)
                  }
                  dataMaxima={dataAtual}
                />
                <DatePickerDetalharFatura
                  dataSelecionada={dataFinal}
                  placeholder="Data final"
                  id="data-fim"
                  onChange={(data) => {
                    filtrarData(data, buscaDataFinal, setDataFinal);
                  }}
                  dataMaxima={dataAtual}
                  dataMinima={dataInicial}
                />
                <BotaoExportarRelatorio
                  downloadRelatorio={downloadCsv}
                  setLoading={setLoading}
                  habilitado={detalheFaturaResponse.listaViagem.length > 0}
                  textoBotao={'Exportar CSV'}
                />
                <BotaoExportarRelatorio
                  downloadRelatorio={downloadXLSX}
                  setLoading={setLoading}
                  habilitado={detalheFaturaResponse.listaViagem.length > 0}
                  textoBotao={'Exportar Excel'}
                />
              </Styled.WrapperSelectorDetalhar>
            </Styled.WrapperImgDetalhar>
            {detalheFaturaResponse?.listaViagem.length > 0 ? (
              <React.Fragment>
                <Styled.TabelaFaturasDetalhar>
                  <Styled.TabelaHeadFaturasDetalhar>
                    <Styled.TabelaRowFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar>
                        <Styled.TabelaComIconeDetalhar>
                          Parceiro
                          <Styled.IconOrderDetalhar
                            id="iconeParceiro"
                            activated={detalheFaturaResponse.parceiroOrdenado}
                            imageUrl={ImgOrder}
                            width={12}
                            height={10}
                            onClick={handleOrdenaParceiro}
                          />
                        </Styled.TabelaComIconeDetalhar>
                      </Styled.TabelaHeaderFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar>
                        <Styled.TabelaComIconeDetalhar>
                          Colaborador
                          <Styled.IconOrderDetalhar
                            id="iconeColaborador"
                            activated={
                              detalheFaturaResponse.colaboradorOrdenado
                            }
                            imageUrl={ImgOrder}
                            width={12}
                            height={10}
                            onClick={handleOrdenaColaborador}
                          />
                        </Styled.TabelaComIconeDetalhar>
                      </Styled.TabelaHeaderFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar>
                        CPF/CPNPJ
                      </Styled.TabelaHeaderFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar>
                        <Styled.TabelaComIconeDetalhar>
                          Data da viagem
                          <Styled.IconOrderDetalhar
                            id="iconeVencimento"
                            activated={detalheFaturaResponse.dataOrdenada}
                            imageUrl={ImgOrder}
                            width={12}
                            height={10}
                            onClick={handleOrdenaData}
                          />
                        </Styled.TabelaComIconeDetalhar>
                      </Styled.TabelaHeaderFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar>
                        <Styled.TabelaComIconeDetalhar>
                          Valor da viagem(R$)
                          <Styled.IconOrderDetalhar
                            id="iconeTotal"
                            activated={detalheFaturaResponse.totalOrdenado}
                            imageUrl={ImgOrder}
                            width={12}
                            height={10}
                            onClick={handleOrdenaTotal}
                          />
                        </Styled.TabelaComIconeDetalhar>
                      </Styled.TabelaHeaderFaturasDetalhar>
                      <Styled.TabelaHeaderFaturasDetalhar></Styled.TabelaHeaderFaturasDetalhar>
                    </Styled.TabelaRowFaturasDetalhar>
                  </Styled.TabelaHeadFaturasDetalhar>
                  <Styled.TabelaBodyFaturasDetalhar>
                    {detalheFaturaResponse.listaViagem.map(
                      (detalhe: IDetalharFatura, index: number) => {
                        return (
                          <Styled.TabelaRowFaturasDetalhar
                            key={detalhe.numeroIdentificacaoViagem.toString()}>
                            <Styled.TabelaDataCellFaturasDetalhar>
                              <Styled.IconParceiroDiv>
                                <Styled.IconParceiro
                                  imageUrl={detalhe.iconeParceiro}
                                  width={10}
                                  height={10}
                                />
                                <Styled.IconParceiroText>
                                  {formatarNomeParceiro(detalhe.nomeParceiro)}
                                </Styled.IconParceiroText>
                              </Styled.IconParceiroDiv>
                            </Styled.TabelaDataCellFaturasDetalhar>

                            <Styled.TabelaDataCellFaturasDetalhar>
                              {detalhe.nomeColaborador}
                            </Styled.TabelaDataCellFaturasDetalhar>
                            <Styled.TabelaDataCellFaturasDetalhar>
                              {formatarCpf(detalhe.cpfCnpj)}
                            </Styled.TabelaDataCellFaturasDetalhar>
                            <Styled.TabelaDataCellFaturasDetalhar>
                              {formatarData(detalhe.dataViagem)}
                            </Styled.TabelaDataCellFaturasDetalhar>
                            <Styled.TabelaDataCellFaturasDetalhar>
                              {`${formatarReal(detalhe.valorViagem)}`}
                            </Styled.TabelaDataCellFaturasDetalhar>
                            <Styled.TabelaDataCellFaturasDetalhar hasPoints>
                              <Styled.IconContentDetalhar
                                id={`Menu-${index}`}
                                ref={(ref) => {
                                  refsIcons.current[index] = ref;
                                }}
                                activated={detalhe.menuAtivado}
                                onClick={() => {
                                  ativaMenuDetalheFatura(detalhe);
                                  setDetalheViagemSelecionado(detalhe);
                                }}>
                                <Styled.MenuDetalhar
                                  activated={detalhe.menuAtivado}>
                                  <Styled.MenuListDetalhar>
                                    <Styled.MenuItemDetalhar>
                                      <Styled.MenuRowDetalhar
                                        onClick={consultarDetalheDaViagem}>
                                        <Styled.IconDetalheDetalhar
                                          imageUrl={ImgDetalhe}
                                          width={20}
                                          height={20}
                                        />

                                        <Styled.BotaoRedirectDetalhar>
                                          Detalhar
                                        </Styled.BotaoRedirectDetalhar>
                                      </Styled.MenuRowDetalhar>
                                    </Styled.MenuItemDetalhar>
                                  </Styled.MenuListDetalhar>
                                </Styled.MenuDetalhar>
                                <Styled.IconPointsDetalhar
                                  imageUrl={ImgPoints}
                                  width={4}
                                  height={18}
                                />
                              </Styled.IconContentDetalhar>
                            </Styled.TabelaDataCellFaturasDetalhar>
                          </Styled.TabelaRowFaturasDetalhar>
                        );
                      }
                    )}
                    <ModalDetalharFatura
                      setLoading={setLoading}
                      modalVisivel={mostrarModal}
                      onClose={() => setMostrarModal(false)}
                    />
                  </Styled.TabelaBodyFaturasDetalhar>
                </Styled.TabelaFaturasDetalhar>
                <Pagination
                  quantidadeDePaginas={quantidadePaginacao(
                    detalheFaturaResponse.qtdRegistroTotal,
                    quantidadeItemsPagina
                  )}
                  onPageChange={(selectedItem) => {
                    handlePaginacaoNoDetalhes(selectedItem, setLoading).then(
                      () => ({})
                    );
                  }}
                  forcePage={detalheFaturaResponse.numeroPagina - 1}
                />
              </React.Fragment>
            ) : (
              <CardNoData
                titulo="Essa área está muito vazia, não acha?"
                descricao="No momento não há viagens finalizadas para esta fatura. Vamos viajar?"
              />
            )}
          </React.Fragment>
        </Styled.Content>
      )}
    </React.Fragment>
  );
};

export default IsLoadingHOC(Detalhar);
