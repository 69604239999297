import React from 'react';
import * as Styled from './styles';
import Img from '../../assets/notfound.svg';

export const NotFound: React.FC = () => {
  return (
    <Styled.NotFoundContainer>
      <Styled.Img imageUrl={Img} />
      <Styled.Title>Ops!</Styled.Title>
      <Styled.SubTitle>A página não foi encontrada</Styled.SubTitle>
      <Styled.Text>
        Código de erro: <b>404</b>
      </Styled.Text>
      <Styled.SubText>
        O endereço pode estar errado ou a página não existe mais, volte para a{' '}
        <Styled.Navlinks href="/">página inicial</Styled.Navlinks>
      </Styled.SubText>
    </Styled.NotFoundContainer>
  );
};
