import { Icon } from '../../styles/globals';
import styled, { css } from 'styled-components';

interface ThProps {
  withIcon?: boolean;
}

interface ImgProps {
  imageUrl?: string;
  width: number;
  height: number;
  activated?: boolean;
}

interface TdProps {
  hasPoints?: boolean;
}

interface PointsProps {
  activated?: boolean;
}

interface MenuProps {
  activated?: boolean;
}

interface MenuItemProps {
  disabled?: boolean;
}

export const TabelaFaturas = styled.table`
  max-height: 28.34vw;
  height: fit-content;
  border-collapse: collapse;
  width: 100%;
`;

export const TabelaHeadFaturas = styled.thead`
  background-color: var(--cinza-10);
  height: 2.8vw;
`;

export const TabelaRowFaturas = styled.tr`
  transition: var(--cinza-20) 300ms ease-in-out;
`;

export const TabelaHeaderFaturas = styled.th<ThProps>`
  padding: 0 0;
  div {
    display: flex;
    justify-content: center;
  }
  color: var(--black);
  font-weight: 400;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.03vw;

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const TabelaComIcone = styled.div`
  display: flex;
  align-items: center;
`;

export const IconOrder = styled.img<ImgProps>`
  margin-left: 0.19vw;
  width: 0.96vw;
  height: 0.76vw;
  ${({ activated }) =>
    activated &&
    css`
      transform: rotate(180deg) scaleX(-1);
    `}
  transition: all 200ms ease-in-out;
`;

export const TabelaBodyFaturas = styled.tbody``;

export const TabelaDataCellFaturas = styled.td<TdProps>`
  text-align: center;
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.89vw;
  line-height: 1.28vw;
  letter-spacing: 0.01vw;
  border-top: 0.06vw solid var(--cinza-20);
  border-bottom: 0.06vw solid var(--cinza-20);

  ${({ hasPoints }) =>
    hasPoints &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const IconContent = styled.div<PointsProps>`
  width: 1.53vw;
  height: 1.53vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;
`;

export const Menu = styled.div<MenuProps>`
  display: ${(props) => (props.activated ? 'flex' : 'none')};
  position: absolute;
  background-color: var(--white);
  left: -6.5vw;
  top: 0.7vw;
  border-radius: 0.25vw;
  box-shadow: 0 0.64vw 1.02vw rgba(0, 0, 0, 0.06),
    0 0.19vw 0.38vw rgba(0, 0, 0, 0.06), 0 0 0.06vw rgba(0, 0, 0, 0.04);
  z-index: 1;
  height: fit-content;
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.div<MenuItemProps>`
  color: var(--black);
  background-color: transparent;
  display: flex;
  align-items: center;
`;

export const MenuItemDownload = styled.button<MenuItemProps>`
  color: ${(props) => props.disabled && 'var(--cinza-50)'};
  cursor: ${(props) => props.disabled && 'not-allowed'};
  background-color: white;
  display: flex;
  z-index: 1;
`;

export const MenuRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 0.9vw;
  width: 7vw;
  height: 2.5vw;
  transition: all 300ms ease-in-out;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.04vw;
`;

export const IconDetalhe = styled.img`
  margin-right: 0.48vw;
  width: 0.9vw;
  height: 0.97vw;
`;

export const BotaoRedirect = styled.div`
  display: flex;
  align-items: center;
`;

export const IconPoints = styled(Icon)`
  margin: 0;
  width: 0.25vw;
  height: 1.15vw;
`;

export const MenuDownload = styled.div<MenuProps>`
  display: none;
  position: absolute;
  background-color: var(--white);
  left: -4.55vw;
  top: 3.5vw;
  border-radius: 0.25vw;
  box-shadow: 0 0.64vw 1.02vw rgba(0, 0, 0, 0.06),
    0 0.19vw 0.38vw rgba(0, 0, 0, 0.06), 0 0 0.06vw rgba(0, 0, 0, 0.04);

  ${({ activated }) =>
    activated &&
    css`
      display: flex;
    `}
`;

export const MenuListDownload = styled.ul`
  button {
    height: 50%;
  }
`;

export const MenuRowDownload = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 0.9vw;
  width: 4.58vw;
  height: 2.5vw;
  transition: all 300ms ease-in-out;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.04vw;
`;

export const IconFaturaAtestada = styled.img`
  width: 1.53vw;
  height: 1.53vw;
  //cursor: pointer;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;
`;

export const IconFaturaAAtestar = styled.img`
  width: 1.53vw;
  height: 1.53vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;
`;
export const IconFaturaAtesteDesativado = styled.img`
  filter: grayscale(100%);
  width: 1.53vw;
  height: 1.53vw;
  //cursor: pointer;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25vw;
  margin: 1.02vw 0;
  position: relative;
`;
