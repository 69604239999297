import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from '../../FormulariosNovoColaborador/SegundoFormularioNovoColaborador/styles';

import SetaBaixo from '../../../assets/chevron_down_gray.svg';
import Completo from '../../../assets/icone-completed.svg';
import IconeErro from '../../../assets/icone-erro.svg';

import InputFormularioNovoColaborador from '../../InputFormularioNovoColaborador';
import { SeletorNovoColaborador } from '../../SeletorNovoColaborador';
import ItemColaborador from '../../ItemColaborador';

import useCentrosDeCustos from '../../../hooks/useCentrosDeCustos';
import useContratos from '../../../hooks/useContratos';

import { SegundoFormularioProps } from '../../../@types/interfaces/segundoFormularioNovoColaborador';
import useColaborador from '../../../hooks/useColaborador';
import { IOption } from '../../../@types/interfaces/components';
import { CentroCusto } from '../../../@types/interfaces/CentroCusto';
import {
  handleBlur,
  checarCamposPreenchidos,
  handleChangeCentroCusto,
  handleBlurCentroCusto,
} from '../../../helpers/utilsFormulariosColaborador';
import { RadioButtonGroup } from '../../RadioButtonGroup';

const SegundoFormularioEditarColaborador = ({
  register,
  dados,
  setBotaoSubmitDesabilitado,
  setDados,
  setValue,
  errors,
  setLoading,
  setError,
  clearErrors,
}: SegundoFormularioProps) => {
  const [selecionado, setSelecionado] = useState(dados.categoria);
  const [centroCusto, setCentroCusto] = useState<CentroCusto[]>([]);
  const numeroCentroCusto: number[] = [];

  const navigate = useNavigate();

  const { contratoSelecionado } = useContratos();
  const { colaboradorResponse } = useColaborador();

  const {
    centrosDeCustos,
    centroDeCustoSelecionado,
    handleCentrosDeCustosPorContrato,
  } = useCentrosDeCustos();

  const centroDeCustoDisponivel: Array<IOption> = [];

  const options = [
    {
      label: 'Comum',
      value: 'comum',
      name: 'categoria',
      disable: false,
    },
    {
      label: 'Intermediário',
      value: 'intermediario',
      name: 'categoria',
      disable: false,
    },
    {
      label: 'Executivo',
      value: 'executivo',
      name: 'categoria',
      disable: false,
    },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelecionado(e.target.value);
    setDados({
      ...dados,
      categoria: e.target.value,
    });
  };

  useEffect(() => {
    const centrosDeCustoselecionados =
      colaboradorResponse?.autorizacoesUsuario[0][
        'numeroDeIdentificacaoDoCentroDeCusto_@CMU'
      ].map((numCentroCustoSelecionado) =>
        centrosDeCustos.filter(
          (item) => item.value === numCentroCustoSelecionado.toString()
        )
      );
    centrosDeCustoselecionados?.map((item) => centroCusto.push(item[0]));
    const disponiveis = centrosDeCustos?.filter(
      (item) => centroCusto.indexOf(item) === -1
    );
    disponiveis?.map((item) => centroDeCustoDisponivel.push(item));
  }, []);

  useEffect(() => {
    if (contratoSelecionado?.id) {
      handleCentrosDeCustosPorContrato(setLoading).then(() => ({}));
    }
  }, [contratoSelecionado]);

  return (
    <>
      <Styled.NumeracaoPagina id="NumeracaoPagina">
        <Styled.IconeSetaBaixo id="IconeSetaBaixo" src={SetaBaixo} />
        <Styled.Pagina>
          <Styled.PaginaNumero>
            <Styled.PaginaImagem src={Completo}></Styled.PaginaImagem>
          </Styled.PaginaNumero>
          <Styled.Linha />
          <Styled.PaginaNumero className="PaginaNumero">2</Styled.PaginaNumero>
        </Styled.Pagina>
      </Styled.NumeracaoPagina>
      <Styled.Form id="SegundoFormulario">
        <InputFormularioNovoColaborador
          id="inputCargo"
          label="Cargo"
          register={register}
          campo={'cargo'}
          value={dados.cargo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setDados({ ...dados, cargo: e.target.value })
          }
          onBlur={() =>
            handleBlur({
              campo: 'cargo',
              value: dados.cargo,
              setError,
              clearErrors,
              setValue,
              errors,
              setBotaoSubmitDesabilitado,
            })
          }
          errors={errors?.cargo}
        />
        <Styled.InputSeletor>
          <Styled.LabelSeletor>Centro de Custo Vinculado</Styled.LabelSeletor>
          {centrosDeCustos && centroDeCustoSelecionado && (
            <SeletorNovoColaborador
              value={centroCusto}
              isMulti={true}
              id="centro-de-custo"
              placeholder={''}
              options={centrosDeCustos}
              onChange={(option: any) =>
                handleChangeCentroCusto(option, setCentroCusto)
              }
              onBlur={() =>
                handleBlurCentroCusto({
                  setDados,
                  dados,
                  numeroCentroCusto,
                  setValue,
                  centroCusto,
                  setError,
                  clearErrors,
                  errors,
                  setBotaoSubmitDesabilitado,
                })
              }
            />
          )}
          <Styled.Erro>
            {errors?.centroCustoVinculado?.type === 'required' && (
              <>
                <Styled.IconeErro src={IconeErro} />
                {errors?.centroCustoVinculado?.message}
              </>
            )}
          </Styled.Erro>
          <Styled.Display>
            {centroCusto.length > 0 &&
              centroCusto.map((item) => {
                numeroCentroCusto.push(parseInt(item.value));
                return (
                  <ItemColaborador
                    key={item.value}
                    title={item.label}
                    centroCusto={centroCusto}
                    setCentroCusto={setCentroCusto}
                    dados={dados}
                    setDados={setDados}
                    numeroCentroCusto={numeroCentroCusto}
                    disabled
                  />
                );
              })}
          </Styled.Display>
        </Styled.InputSeletor>
        <Styled.InputCategoria>
          <RadioButtonGroup
            label={'Categoria Selecionada'}
            options={options}
            onChange={handleChange}
            selecionado={selecionado}
          />
        </Styled.InputCategoria>
        <Styled.FieldButton>
          <Styled.BotaoCancel
            id="botaoCancelar"
            onClick={() => navigate('/colaboradores')}>
            Cancelar
          </Styled.BotaoCancel>
          <Styled.BotaoSubmit
            id="botaoSubmit"
            onClick={() =>
              checarCamposPreenchidos(setBotaoSubmitDesabilitado, errors)
            }
            disabled={false}
            type="submit">
            Salvar
          </Styled.BotaoSubmit>
        </Styled.FieldButton>
      </Styled.Form>
    </>
  );
};

export default SegundoFormularioEditarColaborador;
