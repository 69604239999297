import useDetalheViagem from '../../hooks/useDetalheViagem';
import * as Styled from './styles';
import {
  formatarDataComHora,
  trocarNomeNoveNove,
} from '../../helpers/formatacoes';
import React from 'react';
import noImage from '../../assets/no-image.svg';

export const RestAplicada = () => {
  const { detalheViagemResponse } = useDetalheViagem();

  return (
    <Styled.DropdownContent>
      {!detalheViagemResponse.restricoesAplicadas ||
      detalheViagemResponse.restricoesAplicadas.length === 0 ? (
        'Não foi aplicada nenhum tipo de restrição nessa viagem.'
      ) : (
        <Styled.Rest>
          <Styled.RestMainContent>
            <Styled.RestMainImg
              src={
                detalheViagemResponse.restricoesAplicadas[0].icone == null ||
                undefined
                  ? noImage
                  : detalheViagemResponse.restricoesAplicadas[0].icone
              }
            />
            <Styled.RestBoldInfo>
              {trocarNomeNoveNove(
                detalheViagemResponse.restricoesAplicadas[0].nomeParceiro ==
                  null || undefined
                  ? 'Não foi possível retornar os dados da restrição'
                  : detalheViagemResponse.restricoesAplicadas[0].nomeParceiro
              )}
            </Styled.RestBoldInfo>
          </Styled.RestMainContent>
          <Styled.RestInfoContainer>
            <Styled.RestBoldInfo>Motivo da restrição </Styled.RestBoldInfo>
            <Styled.RestInfo>
              {' '}
              {': '}
              {detalheViagemResponse.restricoesAplicadas[0].motivoRestricao ==
                null || undefined
                ? ''
                : detalheViagemResponse.restricoesAplicadas[0].motivoRestricao}
            </Styled.RestInfo>
          </Styled.RestInfoContainer>
          <Styled.RestInfoContainer>
            <Styled.RestBoldInfo>Período </Styled.RestBoldInfo>
            <Styled.RestInfo>
              {': '}
              {detalheViagemResponse.restricoesAplicadas[0].dataCriacao ==
                null || undefined
                ? 'Data indisponível'
                : formatarDataComHora(
                    detalheViagemResponse.restricoesAplicadas[0].dataCriacao
                  )}{' '}
              {' - '}
              {detalheViagemResponse.restricoesAplicadas[0].dataBaixa == null ||
              undefined
                ? ''
                : formatarDataComHora(
                    detalheViagemResponse.restricoesAplicadas[0].dataBaixa
                  )}
            </Styled.RestInfo>
          </Styled.RestInfoContainer>
        </Styled.Rest>
      )}
    </Styled.DropdownContent>
  );
};
