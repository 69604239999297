import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/cardColaborador/operations';
import useContratos from './useContratos';
import useErro from './useErro';
import { IOption } from '../@types/interfaces/components';
import { logger } from '../util/logger';

function useCardColaborador() {
  const { cardColaboradorResponse } = useSelector(
    (state: RootState) => state.cardColaborador
  );

  const { limpaErro, mostraErro } = useErro();

  const { contratoSelecionado } = useContratos();

  const { fetchCardColaborador, fetchFiltrosHeadersColaborador } =
    bindActionCreators(
      {
        ...operations,
      },
      useDispatch()
    );
  const consultaCardColaborador = useCallback(
    async (setLoading: (value: boolean) => void) => {
      try {
        setLoading(true);
        const mesAtual = `${new Date().getMonth() + 1}`;
        const anoAtual = `${new Date().getFullYear()}`;
        const initialHeaders = {
          'id-contrato-mobilidade': contratoSelecionado?.id,
          'mes-referencia': mesAtual,
          'ano-referencia': anoAtual,
        };
        await fetchCardColaborador(initialHeaders);
        limpaErro();
      } catch (error) {
        setLoading(false);
        mostraErro(error?.response?.data?.code);
        const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "consultaCardColaborador".
        <'ERRO'>
          message: Não foi possível consultar o card do colaborador!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${contratoSelecionado?.id},
          ENDPOINT: 'contrato/cardColaborador',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
        logger.error(errorFormatado);
      } finally {
        setLoading(false);
      }
    },
    [
      contratoSelecionado,
      contratoSelecionado?.id,
      cardColaboradorResponse?.headers,
    ]
  );

  const handleFiltraMesColaborador = useCallback(
    async (option: IOption, setLoading: (value: boolean) => void) => {
      if (option) {
        const headers = {
          ...cardColaboradorResponse.headers,
          'mes-referencia': option.value,
          'ano-referencia': cardColaboradorResponse.headers?.['ano-referencia'],
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeadersColaborador(headers);
        } catch (error) {
          const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "handleFiltraMesColaborador".
        <'ERRO'>
          message: Não foi possível filtrar card do colaborador por mes!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${headers['id-contrato-mobilidade']},
          MES REFERENCIA: ${headers['mes-referencia']},
          ANO REFERENCIA: ${headers['ano-referencia']}
          ENDPOINT: 'contrato/cardColaborador',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
          logger.error(errorFormatado);
        } finally {
          setLoading(false);
        }
      }
    },
    [cardColaboradorResponse?.headers, cardColaboradorResponse]
  );

  const handleFiltraAnoColaborador = useCallback(
    async (option: IOption | null, setLoading: (value: boolean) => void) => {
      if (option) {
        const anoAtual = new Date();
        const novaData = new Date();
        const mesAtual = novaData.getMonth();

        const headers = {
          ...cardColaboradorResponse?.headers,
          'mes-referencia':
            option.value === anoAtual.getFullYear().toString()
              ? mesAtual + 1
              : cardColaboradorResponse?.headers?.['mes-referencia'],
          'ano-referencia': option.value,
          'id-contrato-mobilidade': contratoSelecionado?.id,
        };
        try {
          setLoading(true);
          await fetchFiltrosHeadersColaborador(headers);
        } catch (error) {
          const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "handleFiltraAnoColaborador".
        <'ERRO'>
          message: Não foi possível filtrar card do colaborador por ano!
        Parâmetros da requisição:
          ID CONTRATO MOBILIDADE: ${headers['id-contrato-mobilidade']},
          MES REFERENCIA: ${headers['mes-referencia']},
          ANO REFERENCIA: ${headers['ano-referencia']}
          ENDPOINT: 'contrato/cardColaborador',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
          logger.error(errorFormatado);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      cardColaboradorResponse?.headers,
      cardColaboradorResponse,
      cardColaboradorResponse?.headers?.['mes-referencia'],
    ]
  );

  return {
    fetchCardColaborador,
    consultaCardColaborador,
    cardColaboradorResponse,
    handleFiltraMesColaborador,
    handleFiltraAnoColaborador,
  };
}

export default useCardColaborador;
