import styled from 'styled-components';
import { Container } from '../../../styles/globals';

export const CardContainer = styled(Container)`
  padding: 0;
  display: flex;
  margin-top: 2.22vw;
  flex-direction: column;
  height: fit-content;
`;

export const WrapperTitulo = styled.div`
  margin-top: 0.69vw;
  width: 100%;
`;

export const WrapperIcon = styled.div`
  width: 100%;
`;

export const WrapperDescricao = styled.div`
  margin-top: 0.69vw;
  width: 100%;
`;

export const TituloError = styled.h2`
  font-size: 1.5vw;
  font-weight: 500;
  color: var(--cinza-60);
`;
export const TituloDescricao = styled.p`
  font-size: 1vw;
  font-weight: 500;
  color: var(--cinza-60);
`;

export const IconSearchError = styled.img`
  margin: 0;
  cursor: default;
  width: 2.77vw;
  height: 2.77vw;
`;
