import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 1.02vw;
  letter-spacing: 0.01vw;
  height: fit-content;
`;

export const WrapperSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-end;
  margin: 2.3vw 0 2.3vw 0;
  height: fit-content;
  .css-1nmdiq5-menu {
    height: max-content;
  }
`;

export const TableContent = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;
