import styled from 'styled-components';

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StarImgWrapper = styled.div`
  height: 1.05vw;
  margin-right: 0.27vw;
`;

export const StarImg = styled.img`
  height: 100%;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 0.27vw;
`;

export const Description = styled.p`
  font-size: 0.781vw;
  font-weight: 400;
`;
