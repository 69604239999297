import styled from 'styled-components';

export const Modal = styled.div`
  width: 35vw;
  height: 35vw;

  display: flex;
  flex-direction: column;

  border-radius: 1.73vw;

  background-color: var(--white);

  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 53.6vw;
    height: 53.6vw;
  }

  @media (max-width: 600px) {
    margin-bottom: 100%;
  }
`;

export const ModalImg = styled.img`
  height: 30vw;
  width: 15vw;

  margin-top: 6.04vw;

  @media (max-width: 768px) {
    margin-top: 20%;
    margin-bottom: 10%;

    height: 28.9vw;
    width: 17.4vw;
  }
`;

export const ModalTextoPai = styled.div`
  font-weight: 600;
  font-size: 1.3vw;
  line-height: 1.94vw;
  color: var(--black);
  margin-top: 3%;
  height: fit-content;

  @media (max-width: 768px) {
    font-size: 60%;
    margin-top: 1%;
    line-height: 0.55vw;
  }
`;

export const ModalTextoFilho = styled.div`
  padding: 0 5vw;
  font-weight: 500;
  font-size: 0.9vw;
  line-height: 1.73vw;
  letter-spacing: 0.03vw;
  color: var(--cinza-60);
  margin-top: 3%;
  max-width: 41.11vw;
  text-align: center;
  height: fit-content;

  @media (max-width: 768px) {
    font-size: 45%;
    line-height: 1.04vw;
  }
`;

export const ModalBotao = styled.button`
  width: 49%;
  height: 8%;
  border-radius: 0.55vw;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 2.08vw;
  color: var(--white);
  background-color: var(--action);
  margin-top: 3%;
  margin-bottom: 3.26vw;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 60%;
    height: 15%;

    font-size: 65%;
    margin-top: 1%;
  }
`;
