import {
  FiltrosHeadersCard,
  ICard,
} from '../../../@types/interfaces/dashboardCardApi';

export const GET_CARD_DASHBOARD = 'CARD/GET_CARD_DASHBOARD';

export const FILTRA_CAMPO = 'CARD/FILTRA_CAMPO';

interface GetCardDashboard {
  type: typeof GET_CARD_DASHBOARD;
  headers: FiltrosHeadersCard;
  cardResumoResponse: ICard;
}

interface FiltraCampo {
  type: typeof FILTRA_CAMPO;
  headers: FiltrosHeadersCard;
  cardResumoResponse: ICard;
}

export type CardActionTypes = GetCardDashboard | FiltraCampo;
