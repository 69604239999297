import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--white);
  display: flex;
  padding: 2.3vw 0;
  flex-direction: column;
  border-left: 10.55vw var(--branco-laterais) solid;
  border-right: 10.55vw var(--branco-laterais) solid;
  height: fit-content;
  margin-bottom: 3vw;
`;

export const WrapperTitulo = styled.div`
  margin-bottom: 2.3vw;
  width: 30.6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
`;

export const IconeVoltar = styled.img`
  width: 5%;
  height: 1.3vw;
  cursor: pointer;
`;

export const Titulo = styled.h1`
  font-weight: 500;
  font-size: 1.78vw;
  line-height: 2vw;
  padding-left: 1.5vw;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  padding-left: 3vw;
`;

export const WrapperForm = styled.form`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const WrapperImg = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  padding: 1.6vw;
  width: 22vw;
  height: 24vw;
  margin-top: 5vw;
  margin-right: 13vw;
`;

export const Label = styled.label`
  padding-bottom: 0.5vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.6vw;
  color: var(--cinza-90);
`;

export const Input = styled.input`
  width: 27.65vw;
  height: 2.5vw;
  border: 0.06vw solid var(--cinza-90);
  border-radius: 0.446vw;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.28vw;
  padding: 0.64vw;
  color: var(--cinza-60);
`;

export const Erro = styled.span`
  padding: 0.4vw 0;
  height: 2vw;
  font-weight: 500;
  font-size: 0.7vw;
  line-height: 1.47vw;
  color: var(--salmon);
  display: flex;
  align-items: center;
  max-width: 27.5vw;
`;

export const Campo = styled.div`
  width: 37.56vw;
  display: flex;
  flex-direction: column;
`;
