import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../store/ducks';
import * as operations from '../store/ducks/contrato/operations';
import * as actions from '../store/ducks/contrato/actions';

import { setCentrosDeCustosDoContrato } from '../store/ducks/centrosDeCustos/actions';

function useContratos() {
  const { listaContratos, contratoSelecionado } = useSelector(
    (state: RootState) => state.contrato
  );

  const { limpaContrato } = bindActionCreators(
    {
      ...actions,
      ...setCentrosDeCustosDoContrato,
      ...operations,
    },
    useDispatch()
  );

  return {
    listaContratos,
    contratoSelecionado,
    limpaContrato,
  };
}

export default useContratos;
