import segurancaAxios from '../config/axios/SegurancaAxios';
import { PassageiroStatusResponse } from '../@types/interfaces/passageiroAtivar';
import { AlterarStatusColaborador } from '../@types/interfaces/AlterarStatusColaborador';
import { logger } from '../util/logger';

class PassageiroAtivarApi {
  public ativarPassageiro = async (
    headers: AlterarStatusColaborador
  ): Promise<PassageiroStatusResponse | void> => {
    try {
      const config = {
        headers,
      };
      return await segurancaAxios.put('passageiro/ativar', {}, config);
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "ativarPassageiro".
        <'ERRO'>
          message: Não foi possível alterar o estado do passageiro!
        Parâmetros da requisição:
          ID PASSAGEIRO: ${headers['id-passageiro']},
          ENDPOINT: 'passageiro/ativar',
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    }
  };
}

export default new PassageiroAtivarApi();
