import {
  DetalharFaturaResp,
  FiltrosHeaderDetalheFatura,
  IDetalharFatura,
} from '../../../@types/interfaces/detalharFatura';
import {
  DetalharFaturaActionType,
  GET_DETALHES_FATURA_POR_CONTRATO,
  ATIVA_MENU_DETALHE_FATURA,
  ORDENA_VALOR_TOTAL,
  ORDENA_PARCEIRO,
  ORDENA_COLABORADOR,
  ORDENA_DATA,
  CONTROLE_PAGINACAO,
  FILTRA_CAMPO,
  LIMPA_FATURAS,
  GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD,
  GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD,
} from './types';

export function setDetalhesFaturaResponse(
  detalheFaturaResponse: DetalharFaturaResp
): DetalharFaturaActionType {
  return {
    type: GET_DETALHES_FATURA_POR_CONTRATO,
    detalheFaturaResponse,
  };
}

export function setDetalhesFaturaDownloadResponse(
  detalheFaturaDownloadResponse: File
): DetalharFaturaActionType {
  return {
    type: GET_DETALHES_FATURA_POR_CONTRATO_DOWNLOAD,
    detalheFaturaDownloadResponse,
  };
}

export function setDetalhesFaturaDownloadPDFResponse(
  detalheFaturaDownloadPDFResponse: File
): DetalharFaturaActionType {
  return {
    type: GET_DETALHES_FATURA_PDF_POR_CONTRATO_DOWNLOAD,
    detalheFaturaDownloadPDFResponse,
  };
}

export function ativaMenuDetalheFatura(
  detalheFatura: IDetalharFatura
): DetalharFaturaActionType {
  return {
    type: ATIVA_MENU_DETALHE_FATURA,
    detalheFatura,
  };
}

export function ordenaParceiro(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura
): DetalharFaturaActionType {
  return {
    type: ORDENA_PARCEIRO,
    detalheFaturaResponse,
    headers,
  };
}

export function ordenaColaborador(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura
): DetalharFaturaActionType {
  return {
    type: ORDENA_COLABORADOR,
    detalheFaturaResponse,
    headers,
  };
}

export function ordenaData(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura
): DetalharFaturaActionType {
  return {
    type: ORDENA_DATA,
    detalheFaturaResponse,
    headers,
  };
}

export function ordenaValorTotal(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura
): DetalharFaturaActionType {
  return {
    type: ORDENA_VALOR_TOTAL,
    detalheFaturaResponse,
    headers,
  };
}

export function filtraCampo(
  headers: FiltrosHeaderDetalheFatura,
  detalheFaturaResponse?: DetalharFaturaResp
): DetalharFaturaActionType {
  return {
    type: FILTRA_CAMPO,
    headers,
    detalheFaturaResponse,
  };
}

export function limpaFaturas(): DetalharFaturaActionType {
  return {
    type: LIMPA_FATURAS,
  };
}

export function controlePaginacao(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura,
  numeroPagina: number
): DetalharFaturaActionType {
  return {
    type: CONTROLE_PAGINACAO,
    detalheFaturaResponse,
    headers,
    numeroPagina,
  };
}

export function RemoverFiltros(
  detalheFaturaResponse: DetalharFaturaResp,
  headers: FiltrosHeaderDetalheFatura
): DetalharFaturaActionType {
  return {
    type: FILTRA_CAMPO,
    detalheFaturaResponse,
    headers,
  };
}
