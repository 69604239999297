import styled from 'styled-components';
import { Container } from '../../styles/globals';

export interface ImgProps {
  imageUrl: string;
}

export const NotFoundContainer = styled(Container)`
  padding: 2.56vw 2.56vw 2.56vw 3.52vw;
  display: flex;
  background: var(--white);
  margin-top: 2.05vw;
  border-radius: 0.25vw;
  flex-direction: column;
  min-height: 41.66vw;
  justify-content: center;
  align-items: center;
  height: fit-content;
`;

export const Img = styled.div<ImgProps>`
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  width: 38.46vw;
  height: 12.82vw;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Title = styled.h1`
  color: var(--action);
`;

export const SubTitle = styled.h2`
  margin-top: 0.64vw;
  color: var(--action);
  font-weight: 400;
`;

export const Text = styled.h3`
  margin-top: 0.64vw;
  color: var(--action);
  font-weight: 400;
`;

export const SubText = styled.p`
  margin-top: 0.64vw;
  color: var(--cinza-50);
  font-weight: 400;
`;

export const Navlinks = styled.a`
  margin-top: 0.32vw;
  color: var(--action);
`;
