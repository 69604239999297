import React, { ChangeEventHandler } from 'react';
import * as Styled from './styles';
import IconeErro from '../../assets/icone-erro.svg';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { DadosFormularioCentroCusto } from '../../@types/interfaces/formularioCentroCusto';

interface InputCadastroCentroCustoProps {
  id?: string;
  label: string;
  register: UseFormRegister<DadosFormularioCentroCusto>;
  errors?: FieldError;
  campo: 'nome' | 'descricao' | 'valor' | 'periodicidade' | 'agencia' | 'conta';
  width: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  labelSpan?: boolean;
}

const InputCadastroCentroCusto = ({
  id,
  label,
  register,
  errors,
  campo,
  width,
  value,
  onChange,
  labelSpan,
}: InputCadastroCentroCustoProps) => {
  return (
    <Styled.Campo>
      <Styled.Label>
        {label}{' '}
        {labelSpan && <Styled.LabelSpan>(sem o dígito)</Styled.LabelSpan>}
      </Styled.Label>

      <Styled.Input
        id={id}
        value={value}
        width={width}
        {...register(campo, { required: true })}
        onChange={onChange}
      />
      <Styled.Erro>
        {errors && (
          <>
            <Styled.IconErro src={IconeErro} />
            Campo obrigatório
          </>
        )}
      </Styled.Erro>
    </Styled.Campo>
  );
};

export default InputCadastroCentroCusto;
