import * as Styled from './styles';
import useDetalheViagem from '../../hooks/useDetalheViagem';
import iconTempo from '../../assets/icon-time.svg';
import iconCotacao from '../../assets/cotacao-icon.svg';
import noImage from '../../assets/no-image.svg';
import { CotacoesRealizadas } from '../CotacoesRealizadas';
import { RestAplicada } from '../RestricoesAplicadas';
import React from 'react';
import { Tab, Tabs } from '../AbaVerticalModal';
import {
  formatarDataComHora,
  trocarNomeNoveNove,
} from '../../helpers/formatacoes';

export const CotacaoFatura = () => {
  const { detalheViagemResponse } = useDetalheViagem();
  return (
    <Styled.DropdownContainer>
      <Tabs>
        <Tab label="Cotação Vencedora" icon={false}>
          <Styled.DropdownContent>
            <Styled.ContentDiv>
              <Styled.LeftContent>
                <Styled.CotacaoMainText>
                  Cotação Vencedora
                </Styled.CotacaoMainText>
                <Styled.CotacaoMainImg
                  src={
                    detalheViagemResponse.cotacaoVencedora == null || undefined
                      ? noImage
                      : detalheViagemResponse.cotacaoVencedora.icone
                  }
                />
                <Styled.CotacaoWrapper>
                  <Styled.CotacaoSubText>
                    {detalheViagemResponse.cotacaoVencedora == null || undefined
                      ? 'Sem informações do parceiro'
                      : trocarNomeNoveNove(
                          detalheViagemResponse.cotacaoVencedora.parceiro
                        )}
                  </Styled.CotacaoSubText>
                  <Styled.CotacaoSubText>
                    {detalheViagemResponse.cotacaoVencedora == null || undefined
                      ? ''
                      : trocarNomeNoveNove(
                          detalheViagemResponse.cotacaoVencedora.tipoCotacao
                        )}
                  </Styled.CotacaoSubText>
                </Styled.CotacaoWrapper>
              </Styled.LeftContent>
              <Styled.RightContent>
                <Styled.RightMainText>Detalhes da cotação</Styled.RightMainText>
                <Styled.RightSubContent>
                  <Styled.RightSubTextDiv>
                    <Styled.SubTextIcon src={iconCotacao} />
                    <Styled.SubText>
                      Valor Cotado: {'R$ '}
                      {detalheViagemResponse.cotacaoVencedora == null ||
                      undefined
                        ? 'Não foi possível retornar os dados do parceiro.'
                        : detalheViagemResponse.cotacaoVencedora.valorUtilizado}
                    </Styled.SubText>
                  </Styled.RightSubTextDiv>
                  <Styled.RightSubTextDiv>
                    <Styled.SubTextIcon src={iconTempo} />
                    <Styled.SubText>
                      {formatarDataComHora(
                        detalheViagemResponse.cotacaoVencedora.tsCriacao
                      )}
                    </Styled.SubText>
                  </Styled.RightSubTextDiv>
                </Styled.RightSubContent>
              </Styled.RightContent>
            </Styled.ContentDiv>
          </Styled.DropdownContent>
        </Tab>
        <Tab label="Cotações Realizadas" icon={true}>
          <Styled.DropdownContentRealizadas>
            <Styled.DropdownScroll>
              <CotacoesRealizadas
                cotacoesRealizadas={detalheViagemResponse.cotacoesRealizadas}
              />
            </Styled.DropdownScroll>
          </Styled.DropdownContentRealizadas>
        </Tab>
        <Tab label="Restrições Aplicadas" icon={true}>
          <RestAplicada />
        </Tab>
      </Tabs>
    </Styled.DropdownContainer>
  );
};
