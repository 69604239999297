import React, { ReactElement } from 'react';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';

interface ICardPanel {
  titulo?: string;
  children: React.ReactChild;
  botao?: string;
  icone?: string;
  caminho?: string;
}

export const CardListaLateral = ({
  children,
  botao,
  icone,
  caminho,
}: ICardPanel): ReactElement => {
  const navigate = useNavigate();

  return (
    <Styled.CardContainer>
      {botao && icone ? (
        <Styled.WrapperConteudo>
          <Styled.BotaoNovoColaborador
            onClick={() => {
              navigate(`${caminho}`);
            }}>
            <Styled.Icone src={icone} />
            {botao}
          </Styled.BotaoNovoColaborador>
        </Styled.WrapperConteudo>
      ) : (
        <Styled.WrapperTitulo></Styled.WrapperTitulo>
      )}
      {children}
    </Styled.CardContainer>
  );
};
