import { FiltrosHeaderDetalheViagem } from '../../../@types/interfaces/detalheViagem';
import api from '../../../api/detalheViagem';
import * as detalharFaturaAction from './actions';

export const fetchDetalheViagem =
  (headers: FiltrosHeaderDetalheViagem) => async (dispatch: any) => {
    try {
      const detalheViagemResponse = await api.getDetalheViagem(headers);
      dispatch(
        detalharFaturaAction.setDetalheViagemResponse(detalheViagemResponse)
      );

      return detalheViagemResponse;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };
