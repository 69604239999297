import {
  FaturaActionTypes,
  FaturasState,
  GET_FATURAS_POR_CONTRATO,
  ATIVA_MENU_FATURA,
  ORDENA_NUMERO_FATURA,
  ORDENA_PERIODO,
  ORDENA_VENCIMENTO,
  ORDENA_TOTAL,
  CONTROLE_PAGINACAO,
  SET_FATURA_SELECIONADA,
  LIMPA_FATURAS,
  FILTRA_CAMPO,
  ATESTE_FATURA,
} from './types';

const initialState: FaturasState = {
  faturasResponse: {
    listaRegistro: [],
    quantidadeRegistroPagina: 8,
    quantidadeTotalRegistro: 8,
    headers: {
      'numero-identificacao-centro-custo': '',
      'texto-estado-fatura': '',
      'numero-pagina': '1',
      'qtd-registro-pagina': '8',
      'ordenacao-do-campo': '',
      'campo-ordenado': '',
      'data-inicio': '',
      'data-fim': '',
    },
    faturaOrdenada: false,
    periodoOrdenado: false,
    vencimentoOrdenado: true,
    totalOrdenado: false,
    textoOrdemCampo: '',
    numeroPagina: 1,
  },
  faturaSelecionada: {
    id: '',
    numeroFatura: 0,
    dataDeVencimento: new Date(),
    dataReferencia: new Date(),
    estadoDaFatura: '',
    estadoDoPagamentoDaFatura: '',
    periodoReferencia: {
      dataFim: new Date(),
      dataInicio: new Date(),
    },
    quantidadeViagem: 0,
    valorTotalDaFatura: 0,
    menuAtivado: false,
    faturaSelecionada: '',
  },
};

const faturasReducer = (
  state = initialState,
  action: FaturaActionTypes
): FaturasState => {
  switch (action.type) {
    case GET_FATURAS_POR_CONTRATO: {
      const listaComMenusDesativados =
        action.faturasResponse?.listaRegistro.map((fatura) => {
          return { ...fatura, menuAtivado: false };
        });
      const newFaturasResponse = {
        ...action.faturasResponse,
        headers: initialState.faturasResponse.headers,
        listaRegistro: listaComMenusDesativados,
        vencimentoOrdenado: true,
      };
      return {
        ...state,
        faturasResponse: newFaturasResponse,
      };
    }

    case ATIVA_MENU_FATURA: {
      const newListaRegistro = state.faturasResponse?.listaRegistro.map(
        (faturaItem) => {
          if (faturaItem.id === action.fatura.id) {
            return { ...faturaItem, menuAtivado: !faturaItem.menuAtivado };
          }
          return { ...faturaItem, menuAtivado: false };
        }
      );
      const newFaturasResponse = {
        ...state.faturasResponse,
        listaRegistro: newListaRegistro,
      };
      return {
        ...state,
        faturasResponse: newFaturasResponse,
      };
    }

    case ORDENA_NUMERO_FATURA: {
      const newFaturaResponse = {
        ...state.faturasResponse,
        ...action.faturasResponse,
        headers: action.headers,
        faturaOrdenada: !state.faturasResponse?.faturaOrdenada,
        periodoOrdenado: false,
        totalOrdenado: false,
        vencimentoOrdenado: false,
        textoOrdemCampo: 'numeroFatura',
      };

      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }

    case ORDENA_PERIODO: {
      const newFaturaResponse = {
        ...state.faturasResponse,
        ...action.faturasResponse,
        headers: action.headers,
        faturaOrdenada: false,
        periodoOrdenado: !state.faturasResponse?.periodoOrdenado,
        totalOrdenado: false,
        vencimentoOrdenado: false,
        textoOrdemCampo: 'periodoReferencia',
      };

      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }
    case ORDENA_VENCIMENTO: {
      const newFaturaResponse = {
        ...state.faturasResponse,
        ...action.faturasResponse,
        headers: action.headers,
        faturaOrdenada: false,
        periodoOrdenado: false,
        totalOrdenado: false,
        vencimentoOrdenado: !state.faturasResponse?.vencimentoOrdenado,
        textoOrdemCampo: 'dataDeVencimento',
      };

      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }

    case FILTRA_CAMPO: {
      let newFaturaResponse;

      if (action?.faturasResponse) {
        newFaturaResponse = {
          ...state.faturasResponse,
          ...action.faturasResponse,
          headers: action.headers,
        };
      } else {
        newFaturaResponse = {
          ...state.faturasResponse,
          headers: action.headers,
        };
      }
      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }
    case ORDENA_TOTAL: {
      const newFaturaResponse = {
        ...state.faturasResponse,
        ...action.faturasResponse,
        headers: action.headers,
        faturaOrdenada: false,
        periodoOrdenado: false,
        totalOrdenado: !state.faturasResponse?.totalOrdenado,
        vencimentoOrdenado: false,
        textoOrdemCampo: 'valorTotalDaFatura',
      };

      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }

    case CONTROLE_PAGINACAO: {
      const newFaturaResponse = {
        ...state.faturasResponse,
        ...action.faturasResponse,
        headers: action.headers,
        numeroPagina: action.numeroPagina,
      };

      return {
        ...state,
        faturasResponse: newFaturaResponse,
      };
    }

    case LIMPA_FATURAS: {
      return initialState;
    }

    case SET_FATURA_SELECIONADA: {
      const faturaSelecionada = action.faturaSelecionada;
      return {
        ...state,
        faturaSelecionada,
      };
    }

    case ATESTE_FATURA: {
      const newListaRegistro = state.faturasResponse?.listaRegistro.map(
        (faturaItem) => {
          if (faturaItem.id === action.ateste.idFatura) {
            return {
              ...faturaItem,
              atesteFatura: action.ateste.estado,
            };
          }
          return { ...faturaItem };
        }
      );
      const newFaturasResponse = {
        ...state.faturasResponse,
        listaRegistro: newListaRegistro,
      };
      return {
        ...state,
        faturasResponse: newFaturasResponse,
      };
    }

    default:
      return state;
  }
};

export default faturasReducer;
