import { CardColaboradorState } from '../../../@types/interfaces/cardColaborador';
import {
  CardColaboradorActionTypes,
  GET_CARD_COLABORADOR,
  FILTRA_CAMPO,
} from './types';

const initialState: CardColaboradorState = {
  cardColaboradorResponse: {
    cardColaborador: [],
    headers: {
      'mes-referencia': '',
      'id-contrato-mobilidade': '',
      'ano-referencia': '',
    },
  },
};

const cardColaboradorReducer = (
  state = initialState,
  action: CardColaboradorActionTypes
) => {
  switch (action.type) {
    case GET_CARD_COLABORADOR: {
      return {
        ...state,
        cardColaboradorResponse: {
          cardColaborador: action.cardColaboradorResponse?.cardColaborador,
          headers: action.headers,
        },
      };
    }

    case FILTRA_CAMPO: {
      let newCardColaborador;
      if (action?.cardColaboradorResponse) {
        newCardColaborador = {
          cardColaborador: action.cardColaboradorResponse.cardColaborador,
          headers: action.headers,
        };
      } else {
        newCardColaborador = {
          ...state.cardColaboradorResponse,
          headers: action.headers,
        };
      }
      return {
        ...state,
        cardColaboradorResponse: newCardColaborador,
      };
    }

    default:
      return state;
  }
};

export default cardColaboradorReducer;
