import React, { useState } from 'react';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import useColaborador from '../../hooks/useColaborador';
import { Tooltip } from 'react-tippy';
import { IconsProps } from '../../@types/interfaces/IconsProps';
import { logger } from '../../util/logger';
import { IColaborador } from '../../@types/interfaces/Colaborador';

const Icons: React.FC<IconsProps> = (props) => {
  const navigate = useNavigate();

  const id = props.id;
  const status = props.status;
  const disabled = props.disabled;

  const { consultaColaborador } = useColaborador();

  const [updatedCollaborator, setUpdatedCollaborator] =
    useState<IColaborador | null>(null);

  const handleClick = async () => {
    try {
      const response = await consultaColaborador(id, props.setLoading);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setUpdatedCollaborator(response);
      navigate('/colaboradores/editar-colaborador', {
        state: { updatedCollaborator },
      });
    } catch (error) {
      const errorFormatado = `
        ERRO no MS "${process.env.REACT_SERVER_NAME}", método "consultaColaborador".
        <'ERRO'>
          message: Não foi possível retornar o colaborador!
        Resposta:
        <'ERRO DB'>
          code: ${error?.code}
          message: ${error.message}.
      `;
      logger.error(errorFormatado);
    }
  };

  return (
    <>
      {status ? (
        <Styled.IconsContainer isActive={status} disabled={disabled}>
          <Styled.Icons
            id={id}
            imageUrl={props.imageUrl}
            width={props.width}
            height={props.height}
            onClick={handleClick}
            isActive={status}
            disabled={disabled}
          />
        </Styled.IconsContainer>
      ) : (
        <Styled.IconsContainer isActive={status}>
          <Tooltip
            style={{ display: 'flex', alignItems: 'center' }}
            title="Só é possível alterar colaboradores ativos!"
            position="bottom"
            arrow={true}
            arrowSize="regular">
            <Styled.Icons
              imageUrl={props.imageUrl}
              width={props.width}
              height={props.height}
              isActive={status}
            />
          </Tooltip>
        </Styled.IconsContainer>
      )}
    </>
  );
};

export default Icons;
