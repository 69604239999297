import styled from 'styled-components';

interface DisabledProps {
  disabled?: boolean;
}

export const ItemColaborador = styled.div<DisabledProps>`
  display: flex;
  height: 25px;
  width: fit-content;
  border: 1px solid
    ${(props) => (props.disabled ? 'var(--cinza-40)' : 'var(--action)')};
  border-radius: 12px;
  padding: 10px;
  align-items: center;
`;

export const Label = styled.label<DisabledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 15px;
  color: ${(props) => (props.disabled ? 'var(--cinza-40)' : 'var(--black)')};

  @media (max-width: 768px) {
    font-size: 1.8vw;
  }
`;

export const Botao = styled.button<DisabledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  height: 0.8vw;
  width: 0.8vw;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled ? 'var(--cinza-40)' : 'var(--action)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: 768px) {
    height: 1.7vw;
    width: 1.7vw;
  }
`;

export const BotaoIcone = styled.img`
  svg {
    color: var(--cinza-40);
  }
  height: 50%;
  width: 50%;
`;
