export const LOGAR = 'AUTH/LOGAR';

export const DESLOGAR = 'AUTH/DESLOGAR';

export const SET_USUARIO_LOGADO = 'AUTH/SET_USUARIO_LOGADO';

export const SET_TIPO_USUARIO = 'AUTH/SET_TIPO_USUARIO';

export const SET_MODAL_COOKIE = 'AUTH/SET_MODAL_COOKIE';

export interface IAuth {
  usuarioLogado: boolean;
  tipoUsuario: string;
  cookieAceito: boolean;
}

export interface AuthState {
  auth: IAuth;
}

interface ISetUsuarioLogado {
  type: typeof SET_USUARIO_LOGADO;
  usuarioLogado: boolean;
}

interface ISetTipoUsuario {
  type: typeof SET_TIPO_USUARIO;
  tipoUsuario: string;
}

interface ISetModalCookie {
  type: typeof SET_MODAL_COOKIE;
  cookieAceito: boolean;
}
interface SigInAction {
  type: typeof LOGAR;
  auth: IAuth;
}
interface SigOutAction {
  type: typeof DESLOGAR;
  auth: IAuth;
}

export type AuthActionTypes =
  | SigInAction
  | SigOutAction
  | ISetUsuarioLogado
  | ISetTipoUsuario
  | ISetModalCookie;
