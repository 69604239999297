import styled from 'styled-components';
import DatePicker from 'react-datepicker';
export interface ImgProps {
  imageUrl?: string;
}

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 1.05vw;
  .react-datepicker {
    font-family: var(--type-first) !important;
    text-transform: capitalize;
    height: auto;
  }
  .react-datepicker__day--selected {
    background-color: var(--yellow);
    color: var(--txtDefault);
  }
  .react-datepicker__day--keyboard-selected {
    background-color: var(--yellow);
    color: var(--txtDefault);
  }
  .react-datepicker__day--today {
    border-radius: 0.32vw;
    background-color: var(--action);
    color: var(--white);
  }
  .react-datepicker__navigation-icon--previous {
    &:before {
      border-color: var(--action);
    }
  }
  .react-datepicker__navigation-icon--next {
    &:before {
      border-color: var(--action);
    }
  }

  .react-datepicker__input-container {
    height: 100%;
    width: 100%;
  }

  .react-datepicker-wrapper {
    height: 2.4vw;
    min-height: 2.4vw;
    min-width: 10vw;
    width: 10vw;
  }
`;

export const Label = styled.label`
  color: var(--cinza-60);
  font-weight: 500;
  font-size: 0.83vw;
  margin-bottom: 0.55vw;
`;

export const DateInput = styled(DatePicker)<ImgProps>`
  font-size: 0.83vw;
  height: 2.4vw;
  display: flex;
  flex-wrap: nowrap;
  font-weight: 500;
  color: var(--cinza-60);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0.51vw 2.82vw 0.51vw 0.64vw;
  border: 1px solid;
  border-color: var(--cinza-40);
  border-radius: 0.41vw;
  background-color: var(--white);
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: 93%;
  background-size: 1.41vw;
`;
