import React from 'react';
import * as Styled from './styles';
import useCardCentroCusto from '../../../hooks/useCardCentroCusto';
import IsLoadingHOC from '../../../hoc/IsLoadingHOC';
import { CardNoData } from '../CardNoData';
import {
  encurtadorDeTexto,
  formatarValorRestanteCardCentroCusto,
} from '../../../helpers/formatacoes';
import { ICentroCusto } from '../../../@types/interfaces/cardCentroCusto';

const CardCentroCusto = () => {
  const { cardCentroCustoResponse } = useCardCentroCusto();

  return (
    <React.Fragment>
      <Styled.CardContainerCentroCusto id={'cardCentroCusto'}>
        <Styled.TituloCardCentroCusto>
          <Styled.TituloCentroCusto>Centro de custo</Styled.TituloCentroCusto>
          <Styled.VejaMaisCentroCusto to={'/centro-de-custo'}>
            Veja mais
          </Styled.VejaMaisCentroCusto>
        </Styled.TituloCardCentroCusto>
        {cardCentroCustoResponse?.cardCentroCusto?.length > 0 ? (
          <React.Fragment>
            {cardCentroCustoResponse?.cardCentroCusto
              ?.slice(0, 5)
              .map((item: ICentroCusto) => (
                <Styled.ProgressBarWrapper key={item.nome}>
                  <Styled.ProgressBarTitle>
                    <Styled.ProgressBarContentText>
                      <Styled.ProgressBarText>
                        {encurtadorDeTexto(item.nome, 20)}
                      </Styled.ProgressBarText>
                      <Styled.ProgressBarSubText>
                        {formatarValorRestanteCardCentroCusto(
                          item.valorGasto,
                          item.porcentagemGasta
                        )}
                      </Styled.ProgressBarSubText>
                    </Styled.ProgressBarContentText>
                  </Styled.ProgressBarTitle>
                </Styled.ProgressBarWrapper>
              ))}
          </React.Fragment>
        ) : (
          <CardNoData
            titulo="Oops!"
            descricao="Não encontramos dados referentes à este mês para apresentar."
          />
        )}
      </Styled.CardContainerCentroCusto>
    </React.Fragment>
  );
};

export default IsLoadingHOC(CardCentroCusto);
